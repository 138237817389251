import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ILicenseInfo } from 'interfaces/public/license';
import i18n from 'translations';
import moment from 'moment';

export const columns: ColumnDescription<any, ILicenseInfo>[] = [
  {
    dataField: 'ticketId',
    text: i18n.t('licenseContent.columns.ticket'),
    sort: true,
  },
  {
    dataField: 'dongleId',
    text: i18n.t('licenseContent.columns.dongleId'),
    sort: true,
  },
  {
    dataField: 'productName',
    text: i18n.t('licenseContent.columns.product'),
    sort: true,
  },
  {
    dataField: 'licenseExp',
    text: i18n.t('licenseContent.columns.licenseExpiry'),
    style: {
      width: '200px',
    },
    formatter: (cell: any, row: ILicenseInfo) => {
      const d = moment(row.licenseExp);
      if(!d.isValid())  return row.licenseExp;
      return d.utc().format(i18n.t('formats.date.short'));
    },
  },
  {
    dataField: 'maintenanceExp',
    text: i18n.t('licenseContent.columns.maintenanceExpiry'),
    style: {
      width: '200px',
    },
    formatter: (cell: any, row: ILicenseInfo) => {
      const d = moment(row.maintenanceExp);
      if(!d.isValid())  return row.maintenanceExp;
      return d.utc().format(i18n.t('formats.date.short'));
    },
  },
  {
    dataField: 'isNetwork',
    text: i18n.t('licenseContent.columns.isNetwork'),
    formatter: (cell: any, row: ILicenseInfo) => {
      return (
        <input
          type={'checkbox'}
          style={{ zIndex: 0 }}
          defaultChecked={!!row.isNetwork}
          disabled={true}
        />
      );
    },
  },
  {
    dataField: 'isCommercial',
    text: i18n.t('licenseContent.columns.isCommercial'),
    formatter: (cell: any, row: ILicenseInfo) => {
      return (
        <input
          type={'checkbox'}
          style={{ zIndex: 0 }}
          defaultChecked={!!row.isCommercial}
          disabled={true}
        />
      );
    },
  },
];
