import React from 'react';

export const SettingsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.462 19.7541L17.0052 18.7925C16.5447 19.1159 16.0529 19.3946 15.5367 19.6248L15.1602 22.164C15.09 22.6465 14.6638 23.0039 14.1634 23H9.83335C9.33118 23.0019 8.9056 22.6405 8.83933 22.1557L8.46282 19.6257C7.9448 19.3974 7.45185 19.1185 6.99155 18.7934L4.54412 19.7522C4.07376 19.926 3.54352 19.74 3.29502 19.3141L1.13563 15.6767C0.887525 15.2536 0.992098 14.7177 1.38224 14.4126L3.46066 12.8314C3.39566 12.2796 3.39566 11.7223 3.46066 11.1704L1.38414 9.58916C0.9887 9.28477 0.883906 8.74324 1.13846 8.31957L3.30067 4.67673C3.54542 4.25177 4.07486 4.06812 4.54037 4.24683L6.99442 5.20752C7.45481 4.88405 7.94666 4.60527 8.46286 4.37518L8.83937 1.83603C8.90909 1.35435 9.33387 0.997111 9.8334 1.00003H14.1634C14.6671 0.996037 15.0954 1.35732 15.1631 1.84338L15.5387 4.37522C16.0565 4.60312 16.5491 4.88169 17.009 5.20662L19.4564 4.24777C19.9268 4.07439 20.4567 4.26028 20.7055 4.68592L22.8659 8.32232C23.1137 8.7453 23.0097 9.28095 22.6202 9.58641L20.5418 11.1686C20.6061 11.7204 20.6061 12.2777 20.5418 12.8296L22.6183 14.4108C23.0066 14.713 23.1133 15.2443 22.8705 15.6667L20.7017 19.3242C20.4567 19.7488 19.9275 19.9324 19.462 19.7541ZM7.19681 17.102L4.38107 18.2051L2.66786 15.3193L5.05055 13.5066L4.95036 12.6559C4.8991 12.2207 4.8991 11.7811 4.95036 11.3459L5.0505 10.4957L2.66801 8.68142L4.38106 5.79531L7.19767 6.89793L7.85675 6.43487C8.23768 6.16723 8.64522 5.93616 9.07356 5.74523L9.82583 5.40991L10.2573 2.50003H13.7441L14.1764 5.4145L14.9344 5.74812C15.3608 5.9358 15.7659 6.16493 16.1435 6.43171L16.8036 6.8981L19.6196 5.79486L21.3339 8.68043L18.9528 10.493L19.0519 11.3424C19.1028 11.7788 19.1028 12.2193 19.0519 12.6558L18.9528 13.505L21.3335 15.3178L19.6211 18.2056L16.8021 17.1022L16.1431 17.5649C15.7619 17.8327 15.3542 18.0638 14.9258 18.2549L14.1737 18.5903L13.7423 21.5H10.2583L9.82473 18.5869L9.06793 18.2532C8.64089 18.0649 8.23517 17.8353 7.8569 17.5682L7.19681 17.102Z"
    />
  </svg>
);
