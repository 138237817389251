import { createAsyncThunk } from '@reduxjs/toolkit';
import { licenseKeySlice } from 'slices/license-keys';
import { toastSlice, confirmationSlice } from 'slices';
import i18n from 'translations';
import Api from '../api';
import { RootState } from '../store/store';
import { tableQuery } from '../utils/table';
import { IMill } from '../interfaces/mill';
import { getSelectedMillId } from '../utils/common';

export interface ILicenseKeyPayload {
  id?: number;
  licenseKey?: string;
  enableDataCollection: boolean;
}

export const licenseKeyThunk = createAsyncThunk(
  'licenseKeys',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const query = tableQuery(state.licenseKey);
    const millId = getSelectedMillId(state.mill.data as IMill);
    return Api.get(
      `/toolpass-data-collection-consents/mills/${millId}/?${query}`
    );
  }
);

export const addLicenseKeyThunk = createAsyncThunk(
  'licenseKeys/add-licenseKey',
  async (payload: ILicenseKeyPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.post(
        `/toolpass-data-collection-consents`,
        payload
      );

      dispatch(licenseKeySlice.actions.closeAddLicenseKey());
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('settings.licenseKey.addToast.title'),
          message: i18n.t('settings.licenseKey.addToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editLicenseKeyThunk = createAsyncThunk(
  'licenseKeys/edit-licenseKey',
  async (payload: ILicenseKeyPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.put(
        `/toolpass-data-collection-consents/${payload.id}`,
        payload
      );
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('settings.licenseKey.editToast.title'),
          message: i18n.t('settings.licenseKey.editToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteLicenseKeyThunk = createAsyncThunk(
  'licenseKeys/delete',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.delete(
        `/toolpass-data-collection-consents/${id}`
      );

      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('settings.licenseKey.deleteToast.title'),
          message: i18n.t('settings.licenseKey.deleteToast.message'),
        })
      );

      if (response.statusCode === 200) return id;
      return response;
    } catch (err) {
      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('settings.licenseKey.deleteToast.title'),
          message: err.response.data.message.errors,
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);
