import { createSlice } from '@reduxjs/toolkit';

export interface ISigninState {
  show: boolean;
  showSignUpLink: boolean;
  linkToExistingAccount: boolean;
  invitedMillId: string;
}

interface IShowWithoutSignup {
  payload: {
    linkToExistingAccount?: boolean;
    invitedMillId?: string;
  };
}

export const initialState: ISigninState = {
  show: false,
  showSignUpLink: true,
  linkToExistingAccount: false,
  invitedMillId: '',
};

export const signinSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
    show(state) {
      state.show = true;
      state.showSignUpLink = !state.linkToExistingAccount;
    },
    showWithoutSignUp(state, action: IShowWithoutSignup) {
      state.show = true;
      state.showSignUpLink = false;
      state.linkToExistingAccount =
        action.payload.linkToExistingAccount || true;
      state.invitedMillId = action.payload.invitedMillId || '';
    },
    close(state) {
      state.show = false;
    },
    closeAndResetLinkToExistingAccount(state) {
      state.show = false;
      state.linkToExistingAccount = false;
    },
  },
});
