import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { companySlice } from 'slices';
import { IAddress } from 'thunks/settings';
import { getSessionStorageItem } from 'utils/localStorage';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Spinner2 } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ILabPayload, updateCompanyInfoThunk } from 'thunks/account';
import { labByCompanyIdThunk } from 'thunks/lab';
import { FormikHelpers } from 'formik';
import { CompanyInfoForm } from './CompanyInfoForm';

const schema: yup.AnySchema<ILabPayload> = yup
  .object()
  .shape({
    company: yup.object().shape({
      name: yup.string().required(),
      contactPerson: yup.string(),
      web: yup.string(),
      email: yup.string().email().required(),
      address: yup
        .object()
        .shape({
          country: yup.string().required(),
          phone: yup.string().required(),
          city: yup.string().required(),
          street: yup.string().required(),
          zipCode: yup.string().required(),
        })
        .required(),
    }),
    shippingAtSameAddress: yup.boolean().required(),
    shippingAddress: yup.object().when('shippingAtSameAddress', {
      is: false,
      then: yup.object().shape({
        city: yup.string().required(),
        street: yup.string().required(),
        zipCode: yup.string().required(),
      }),
    }),
    files: yup.array().required(),
  })
  .optional();

export const CompanyInfoFormContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const {
    id,
    shippingAtSameAddress = false,
    shippingAddress,
    company: labCompany,
  } = useAppSelector((state) => state.lab.data);

  const { message } = useAppSelector((state) => state.company);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const {
    id: labCompanyId,
    name,
    web,
    email,
    address,
    logo,
    contactPerson,
    companyInfoIsUpdated = true,
  } = labCompany || {};
  const { company } = useAppSelector((state) => state.auth.data.user);
  const { ordersCount } = useAppSelector((state) => state.auth.data);
  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);

  const initialValues = {
    id,
    company: {
      id: labCompanyId,
      contactPerson: contactPerson || '',
      name: name || '',
      web: web || '',
      email: email || '',
      address:
        address ||
        ({
          country: 'DE',
          street: '',
          zipCode: 0,
          phone: '',
          city: '',
        } as IAddress),
    },
    shippingAddress:
      shippingAddress ||
      ({ street: '', zipCode: 0, phone: '', city: '' } as IAddress),
    shippingAtSameAddress: shippingAtSameAddress || false,
    files: [],
  } as ILabPayload;

  React.useEffect(() => {
    const msg = companyInfoIsUpdated
      ? ''
      : t('settings.company.message.update');
    dispatch(companySlice.actions.setMessage({ message: msg }));
  }, [companyInfoIsUpdated]);

  React.useEffect(() => {
    (async () => {
      if (company) {
        setIsProcessing(true);
        await dispatch(labByCompanyIdThunk(company.id));
        setIsProcessing(false);
      }
    })();
  }, [company]);

  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleSubmit = async (
    values: ILabPayload,
    formik: FormikHelpers<ILabPayload>
  ) => {
    setIsProcessing(true);
    await dispatch(
      updateCompanyInfoThunk({
        ...values,
        company: { ...values.company, companyInfoIsUpdated: true },
        dataBaseStatusIsUpdated: company?.companyInfoIsUpdated,
        ordersCount,
      })
    );
    setIsProcessing(false);
    formik.resetForm({ values: { ...values, files: [] } });
  };

  return (
    <>
      <Spinner2 show={isProcessing} />
      <CompanyInfoForm
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={initialValues}
        imageUrl={logo && logo.url}
        message={message}
        isCountryEditable
      />
    </>
  );
};
