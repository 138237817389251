import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { LogoIcon } from 'icons';
import { authSlice } from 'slices';
import { ROOT_ROUTE } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ManHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth.data);

  const logout = () => {
    localStorage.clear();
    dispatch(authSlice.actions.gotoHome({ redirectTo: ROOT_ROUTE }));
  };

  return (
    <Navbar expand="md" className="h-100 user-navbar">
      <div style={{ position: 'absolute' }}>
        <strong>UserName: </strong> {`${user?.firstName} ${user?.lastName}`}
      </div>
      <div className="user-navbar-brand">
        <LogoIcon />
      </div>
      <Navbar.Collapse
        className="justify-content-end m-5"
        style={{ width: '200%' }}
      >
        <Nav.Link style={{ color: '#3A3F4B' }} role="button" onClick={logout}>
          <strong>Sign out</strong>
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};
