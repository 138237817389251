import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../api';
import { IMill } from '../interfaces/mill';
import { RootState } from '../store/store';
import { getSelectedMillId } from '../utils/common';
import { tableQuery } from '../utils/table';

export const messagesThunk = createAsyncThunk(
  'messages',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const query = tableQuery(state.messages);
    const millId = getSelectedMillId(state.mill.data as IMill);
    return Api.get(`/mills/${millId}/messages?${query}`);
  }
);
