import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AvatarImage } from 'components/User';

import i18n from 'translations';
import { DownloadIcon } from 'icons';
import { Badge } from 'react-bootstrap';

export const columns: ColumnDescription<any, any>[] = [
  {
    dataField: 'avatar',
    text: '',
    sort: false,
    style: {
      width: '72px',
      height: '72px',
    },
    formatter: () => {
      return <AvatarImage />;
    },
  },
  {
    dataField: 'id',
    text: i18n.t('contract.tabs.invoices.id'),
  },
  {
    dataField: 'lines.data.description',
    text: i18n.t('contract.tabs.invoices.itemDesc'),
    formatter: (cell: any, row: any) => {
      const descLines = row.lines.data.map((d: any) => d.description);
      return `${descLines.join('\n')}`;
    },
  },
  {
    dataField: 'amount_due',
    text: i18n.t('contract.tabs.billing.amount'),
    sort: true,
    formatter: (cell: any, row: any) => {
      return `€${row.total / 100}`;
    },
  },
  {
    dataField: 'invoice_pdf',
    text: i18n.t('contract.tabs.invoices.download'),
    sort: true,
    formatter: (cell: any, row: any) => {
      return (
        <a href={row.invoice_pdf} className="download-file-link" download>
          <DownloadIcon />
        </a>
      );
    },
  },
  {
    dataField: 'paid',
    text: i18n.t('contract.tabs.invoices.isPaid'),
    sort: true,
    formatter: (cell: any, row: any) => {
      return (
        <Badge pill variant={row.paid ? 'success' : 'danger'}>
          {row.paid
            ? i18n.t('contract.tabs.invoices.paid')
            : i18n.t('contract.tabs.invoices.due')}
        </Badge>
      );
    },
  },
];
