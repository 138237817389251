import React from 'react';

export const ClientIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9ZM12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 14.9559 4.13644 17.5879 6.29448 19.2813C6.64817 16.4449 9.06777 14.25 12 14.25C14.9322 14.25 17.3518 16.4449 17.7055 19.2813C19.8636 17.5879 21.25 14.9559 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM16.2779 20.2035C16.2597 20.1388 16.25 20.0705 16.25 20C16.25 17.6528 14.3472 15.75 12 15.75C9.65279 15.75 7.75 17.6528 7.75 20C7.75 20.0705 7.74026 20.1388 7.72207 20.2035C9.0014 20.872 10.4566 21.25 12 21.25C13.5434 21.25 14.9986 20.872 16.2779 20.2035Z"
    />
  </svg>
);
