import React from 'react';
import moment from 'moment';

import { IOrder } from 'interfaces/order';
import i18n from 'translations';

import placeholder from 'assets/images/placeholder.svg';
import { placeholderImage } from 'utils/image';
import { DownloadIcon } from 'icons';
import { Button } from 'react-bootstrap';
import { ReportGeneratorContainer } from 'components/ReportGenerator';
import { Badge, Card, Space } from 'antd';

export const statusOptions = [
  { value: '', label: i18n.t('component.status.any') },
  { value: 'Open', label: i18n.t('component.status.open') },
  { value: 'Downloaded', label: i18n.t('component.status.downloaded') },
  {
    value: 'Open,Downloaded',
    label: `${i18n.t('component.status.open')} & ${i18n.t(
      'component.status.downloaded'
    )}`,
  },
  { value: 'Closed', label: i18n.t('component.status.closed') },
];

export interface IColumnsProps {
  isMillRole: boolean;
  downloadProjectDocsClickHandler?: (id: number) => void;
}

export const columns = ({
  isMillRole,
  downloadProjectDocsClickHandler,
}: IColumnsProps) => {
  const cols = [
    {
      dataField: 'image',
      text: '',
      sort: false,
      style: {
        width: '180px',
      },
      formatter: (cell: any, row: IOrder) => {
        return (
          <img
            src={placeholderImage(row.image, placeholder)}
            alt=""
            className="order-image"
          />
        );
      },
    },
    {
      dataField: 'id',
      text: i18n.t('orders.columns.orderId'),
      sort: true,
      style: {
        width: '150px',
      },
    },
    {
      dataField: 'name',
      text: i18n.t('orders.columns.name'),
      sort: true,
      style: {
        width: '250px',
      },
      formatter: (cell: any, row: IOrder) => {
        return (
          <>
            {row.isDeleted && (
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <Badge.Ribbon text={i18n.t('orders.deleted')} color="red">
                  <Card size="small">{row.name}</Card>
                </Badge.Ribbon>
              </Space>
            )}
            {!row.isDeleted && row.name}
          </>
        );
      },
    },
    {
      dataField: 'created_at',
      text: i18n.t('orders.columns.date'),
      sort: true,
      formatter: (cell: any, row: IOrder) => {
        return moment(row.created_at).format(i18n.t('formats.date.long'));
      },
      style: {
        width: '200px',
      },
    },
    {
      dataField: 'messages',
      text: i18n.t('orders.columns.messages'),
      sort: false,
      formatter: (cell: any, row: IOrder) => {
        const msg = '-';
        if (row.newMessages > 0) {
          return (
            <div className="new-message">
              {row.messages.length}{' '}
              {row.messages.length === 1
                ? i18n.t('orders.message')
                : i18n.t('orders.messages')}{' '}
              ({row.newMessages} {i18n.t('orders.newMessage')})
            </div>
          );
          // eslint-disable-next-line no-else-return
        } else if (row.messages.length > 0) {
          return `${row.messages.length} ${
            row.messages.length === 1
              ? i18n.t('orders.message')
              : i18n.t('orders.messages')
          }`;
        }
        return msg;
      },
    },
    {
      dataField: 'components',
      text: i18n.t('orders.columns.components'),
      sort: false,
      formatter: (cell: any, row: IOrder) => {
        return row.components.length;
      },
      style: {
        width: '100px',
      },
    },
  ];

  if (isMillRole) {
    cols.splice(3, 0, {
      dataField: 'author.company.name',
      text: i18n.t('orders.columns.client'),
      sort: false,
      formatter: (cell: any, row: IOrder) => {
        return row.author?.company && Object.keys(row.author.company).length
          ? `${row.author.company.name}`
          : '-';
      },
      style: {
        width: '200px',
      },
    });
    cols.splice(4, 0, {
      dataField: 'responisble.firstName',
      text: i18n.t('orders.columns.responsible'),
      sort: false,
      formatter: (cell: any, row: IOrder) => {
        return row.responsible
          ? `${row.responsible.firstName} ${row.responsible.lastName}`
          : '';
      },
      style: {
        width: '200px',
      },
    });
    cols.push({
      dataField: '',
      text: i18n.t('orders.columns.report'),
      sort: false,
      formatter: (cell: any, row: IOrder) => {
        return row.components.length ? (
          <a
            style={{ color: 'gray' }}
            href="#"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ReportGeneratorContainer orderId={row.id} />
          </a>
        ) : (
          <p> - </p>
        );
      },
      style: {
        width: '100px',
      },
    });
    cols.push({
      dataField: '',
      text: i18n.t('orders.columns.download'),
      sort: false,
      formatter: (cell: any, row: IOrder) => {
        return (
          <Button
            disabled={row.components.length === 0 || row.isDeleted}
            onClick={(e: any) => {
              if (typeof downloadProjectDocsClickHandler === 'function') {
                downloadProjectDocsClickHandler(row.id || 0);
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            <DownloadIcon color="#FFFFFF" />
          </Button>
        );
      },
      style: {
        width: '40px',
      },
    });
  }

  return cols;
};
