import React from 'react';
import { Select } from 'components';
import { IOption } from 'components/Select';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { updateUserSettingsThunk } from 'thunks/account';
import { DEFAULT_PAGE_SIZE, PAGE_OPTIONS } from 'constants/constants';

export const PageSizeContainer = () => {
  const dispatch = useAppDispatch();
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );

  const getPageOptions = () => {
    return PAGE_OPTIONS.map((p: number) => ({ label: `${p}`, value: `${p}` }));
  };

  const getDefaultValue = () => {
    const defaultValue = PAGE_OPTIONS.find(
      (p: number) =>
        p ===
        +(userSettings?.pageSize ? userSettings.pageSize : DEFAULT_PAGE_SIZE)
    );
    return { label: `${defaultValue}`, value: `${defaultValue}` };
  };

  function handleChange(option: IOption) {
    dispatch(
      updateUserSettingsThunk({
        pageSize: option.value,
      })
    );
  }

  return (
    <>
      <div className="select-wrapper mb-10">
        <Select
          options={getPageOptions()}
          onChange={handleChange}
          defaultValue={getDefaultValue()}
        />
      </div>
    </>
  );
};
