import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiMeta, IApiState } from '../interfaces/api';
import { ILab } from '../interfaces/mill';
import { labThunk, labByCompanyIdThunk } from '../thunks/lab';

export interface ILabState extends IApiState {
  data: ILab;
}

export const initialState: ILabState = {
  data: {
    id: undefined,
    company: {
      id: 0,
      name: '',
      email: '',
      web: '',
      address: {
        phone: '',
        city: '',
        street: '',
        zipCode: 0,
      },
    },
    shippingAddress: { phone: '', city: '', street: '', zipCode: 0 },
    shippingAtSameAddress: false,
  },
};

export const labSlice = createSlice({
  name: 'lab',
  initialState,
  reducers: {},
  extraReducers: {
    [labThunk.fulfilled.type]: (
      state,
      action: PayloadAction<ILab, string, IApiMeta>
    ) => {
      state.data = action.payload;
      state.apiStatus = action.meta.requestStatus;
    },
    [labByCompanyIdThunk.fulfilled.type]: (
      state,
      action: PayloadAction<ILab, string, IApiMeta>
    ) => {
      state.data = action.payload;
      state.apiStatus = action.meta.requestStatus;
    },
  },
});
