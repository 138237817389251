import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Button, FileUpload } from 'components';
import { Modal } from 'components/Modal';
import { IUploadDownloadForm } from 'thunks/component';
import { isErrorString } from 'utils/handle-api-error';

interface IUploadDownloadFormProps {
  show: boolean;
  initialValues: IUploadDownloadForm;
  validationSchema: any | (() => any);
  error?: any;
  onClose: () => any;
  onSubmit: (
    values: IUploadDownloadForm,
    form: FormikHelpers<IUploadDownloadForm>
  ) => void | Promise<any>;
}

export const UploadDownloadForm = ({
  show,
  initialValues,
  validationSchema,
  error = undefined,
  onClose,
  onSubmit,
}: IUploadDownloadFormProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('component.upload.title')}
      show={show}
      onClose={onClose}
      size="lg"
    >
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
          dirty,
          isSubmitting,
        }) => {
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                  <Form.Label>{t('component.upload.name.label')}</Form.Label>
                  <Form.Control
                    name="name"
                    placeholder={t('component.upload.name.placeholder')}
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                  />
                </Form.Group>

                <Form.Group controlId="files">
                  <FileUpload
                    setFieldValue={setFieldValue}
                    accept="application/pdf,.doc,.docx,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*"
                    values={values.files}
                  />
                </Form.Group>
                <Button
                  dimension="lg"
                  full
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t('component.upload.button')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

UploadDownloadForm.defaultProps = {
  error: undefined,
};
