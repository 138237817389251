import React from 'react';
import { useLabRole } from 'hooks/role';
import { IUser } from '../../../interfaces/user';

import { AvatarImage } from '../../User';

interface IAvatarProps {
  user?: IUser;
}

export const Avatar = ({ user }: IAvatarProps) => {
  const isLabRole = useLabRole();

  return (
    <span className="avatar">
      <span className="avatar-img">
        <AvatarImage user={user} />
      </span>
      <span className="avatar-name">
        {isLabRole ? user?.company?.name : user?.firstName}
      </span>
    </span>
  );
};

Avatar.defaultProps = {
  user: undefined,
};
