import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon } from '../../icons';

interface ISearchProps {
  onChange: (value: string) => any;
}

export const Search: React.FC<ISearchProps> = ({ onChange }: ISearchProps) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onChange(search);
    }
  };

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <div className="search-input">
      <SearchIcon />
      <input
        className="form-control"
        name="search"
        placeholder={t('search')}
        value={search}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
