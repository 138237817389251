import React from 'react';

export const SupportIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.44 12.8C11.2267 13.2267 11.12 13.7133 11.12 14.26L13 14.34C13.0133 13.6467 13.4067 12.9467 14.18 12.24C14.9533 11.5333 15.48 10.9267 15.76 10.42C16.04 9.9 16.18 9.36 16.18 8.8C16.18 7.69333 15.7933 6.78667 15.02 6.08C14.26 5.36 13.28 5 12.08 5C11.12 5 10.28 5.27333 9.56 5.82C8.84 6.36667 8.32 7.08 8 7.96L9.72 8.68C10.1333 7.4 10.92 6.76 12.08 6.76C12.76 6.76 13.3 6.94667 13.7 7.32C14.1 7.68 14.3 8.17333 14.3 8.8C14.3 9.22667 14.1867 9.60667 13.96 9.94C13.7467 10.2733 13.3267 10.7267 12.7 11.3C12.0867 11.8733 11.6667 12.3733 11.44 12.8Z" />
    <path d="M13.02 18.36C13.2867 18.0933 13.42 17.7733 13.42 17.4C13.42 17.0133 13.2867 16.6933 13.02 16.44C12.7667 16.1733 12.4467 16.04 12.06 16.04C11.6867 16.04 11.3667 16.1733 11.1 16.44C10.8333 16.6933 10.7 17.0133 10.7 17.4C10.7 17.7733 10.8333 18.0933 11.1 18.36C11.3667 18.6267 11.6867 18.76 12.06 18.76C12.4467 18.76 12.7667 18.6267 13.02 18.36Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12Z"
    />
  </svg>
);
