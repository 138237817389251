import React from 'react';
import * as yup from 'yup';
import { useQuery } from '../../../hooks/query';

import { resetSlice } from '../../../slices';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IResetPayload, resetThunk } from '../../../thunks/auth';
import { Reset } from './Reset';

const schema = yup.object().shape({
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .required('')
    .oneOf([yup.ref('password'), null], 'Passwords must match.'),
});

export const ResetContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state) => state.auth);
  const { show } = useAppSelector((state) => state.reset);
  const query = useQuery();

  const handleSubmit = (values: IResetPayload) => {
    const code = query.get('code');
    if (code) {
      dispatch(resetThunk({ ...values, code }));
    }
  };

  return (
    <Reset
      show={show}
      onClose={resetSlice.actions.close}
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={
        {
          password: '',
          passwordConfirmation: '',
        } as IResetPayload
      }
      error={error}
    />
  );
};
