import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { EmailIcon, PasswordIcon, UserIcon } from 'icons';
import { ISigninPayload } from 'thunks/auth';
import { isErrorString } from 'utils/handle-api-error';
import { Prompt } from 'react-router-dom';
import { Button } from '../../Button';

interface ICreateNewAccountProps {
  initialValues: ISigninPayload;
  validationSchema: any | (() => any);
  error?: any;
  isLabUser: boolean;
  actualEmailByConfirmationToken?: string;
  onSubmit: (values: ISigninPayload) => void | Promise<any>;
  sendConfirmationEmail: (values: ISigninPayload) => void | Promise<any>;
}

export const CreateNewAccount = ({
  initialValues,
  validationSchema,
  error = undefined,
  onSubmit,
  isLabUser,
  actualEmailByConfirmationToken = '',
  sendConfirmationEmail,
}: ICreateNewAccountProps) => {
  const { t } = useTranslation();
  let resendConfirmationEmail = false;

  return (
    <>
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}

      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (resendConfirmationEmail) {
            sendConfirmationEmail(values);
          } else {
            onSubmit(values);
          }
        }}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
          resendConfirmationEmail =
            actualEmailByConfirmationToken !== '' &&
            actualEmailByConfirmationToken !== values.identifier;

          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit} data-cy="create-new-account">
                {isLabUser && (
                  <Form.Group controlId="companyName">
                    <Form.Label>{t('companyName.label')}</Form.Label>
                    <Form.Control
                      name="companyName"
                      placeholder={t('companyName.placeholder')}
                      value={values.companyName}
                      data-cy="cna-company-name"
                      onChange={handleChange}
                      isInvalid={touched.companyName && !!errors.companyName}
                    />
                    <UserIcon />
                  </Form.Group>
                )}
                <Form.Group controlId="identifier">
                  <Form.Label>{t('invitation.emailLabel')}</Form.Label>
                  <Form.Control
                    type="email"
                    name="identifier"
                    data-cy="cna-identifier"
                    placeholder={t('email.placeholder')}
                    value={values.identifier}
                    onChange={handleChange}
                    isInvalid={touched.identifier && !!errors.identifier}
                  />
                  <EmailIcon />
                </Form.Group>

                <Form.Group className="mb-40" controlId="password">
                  <Form.Label>{t('accountCreation.password.label')}</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    data-cy="cna-password"
                    placeholder={t('accountCreation.password.placeholder')}
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <PasswordIcon />
                </Form.Group>

                <Button data-cy="cna-submit" dimension="lg" full type="submit">
                  {resendConfirmationEmail
                    ? t('invitation.sendConfirmationEmail')
                    : t('invitation.createNewAccount')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

CreateNewAccount.defaultProps = {
  error: undefined,
  actualEmailByConfirmationToken: '',
};
