import React, { useEffect, useState } from 'react';
import { Col, FormLabel, Row } from 'react-bootstrap';
import { TableChangeType, TableChangeState } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button, Select, Spinner2 } from 'components';
import { PlusIcon } from 'icons';
import { IUser } from 'interfaces/user';
import { confirmationSlice, usersSlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Table } from 'components/Table';
import { Confirmation } from 'components/Modal';
import { IOption } from 'components/Select';
import { deleteUserThunk, usersThunk } from 'thunks/users';
import i18n from 'translations';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { getSessionStorageItem } from 'utils/localStorage';
import { columns, roleSearchOptions } from './columns';
import { Actions } from './Actions';
import { UserFormContainer } from '../UserFormContainer';

export const UsersTableContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { count, data, page, sizePerPage, selectedUserId } = useAppSelector(
    (state) => state.users
  );
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );
  const loggedUserId = useAppSelector((state) => state.auth.data.user.id);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);

  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleAddUser = () => {
    dispatch(usersSlice.actions.showAddUser({ openInEditMode: false }));
  };

  const handleDelete = (user: IUser) => {
    if (user.id) {
      dispatch(usersSlice.actions.selectUser(user.id));
      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('settings.users.deleteConfirm.title'),
          message: i18n.t('settings.users.deleteConfirm.message'),
          buttonOk: i18n.t('settings.users.deleteConfirm.buttonOk'),
          buttonCancel: i18n.t('settings.users.deleteConfirm.buttonCancel'),
        })
      );
    }
  };

  const handleEditUser = (user: IUser) => {
    if (user.id) {
      dispatch(usersSlice.actions.showAddUser({ user, openInEditMode: true }));
    }
  };

  const handleDeleteUser = async () => {
    if (selectedUserId) {
      setIsProcessing(true);
      await dispatch(deleteUserThunk(selectedUserId));
      setIsProcessing(false);
    }
  };

  const handleRoleChange = async (option: IOption) => {
    if (option.value === '') {
      dispatch(usersSlice.actions.clearFilters());
    } else {
      dispatch(
        usersSlice.actions.setFilters({
          millRole: option.value,
        })
      );
    }
    setIsProcessing(true);
    await dispatch(usersThunk());
    setIsProcessing(false);
  };

  const handleTableChange = async (
    type: TableChangeType,
    state: TableChangeState<IUser>
  ) => {
    if (type === 'pagination') {
      dispatch(
        usersSlice.actions.setPagination({
          page: state.page,
          sizePerPage: state.sizePerPage,
        })
      );
      setIsProcessing(true);
      await dispatch(usersThunk());
      setIsProcessing(false);
    } else if (type === 'sort') {
      let sort = `${state.sortField}:${state.sortOrder}`;
      if (state.sortField === 'firstName') {
        sort += `,lastName:${state.sortOrder}`;
      }
      dispatch(
        usersSlice.actions.setSort({
          sort,
        })
      );
      setIsProcessing(true);
      await dispatch(usersThunk());
      setIsProcessing(false);
    }
  };

  const addActionsToTable = () => {
    columns[columns.length - 1].formatter = (cell: any, user: IUser) => {
      return Actions({
        user,
        showActions: user.id !== loggedUserId,
        onDelete: handleDelete,
        onEdit: handleEditUser,
      });
    };
  };

  const setSizePerPage = () => {
    let setPageSize = sizePerPage;
    if (userSettings?.pageSize) {
      setPageSize = userSettings.pageSize;
    }

    dispatch(usersSlice.actions.setPageSize(setPageSize));
  };

  useEffect(() => {
    (async () => {
      setIsProcessing(true);
      addActionsToTable();
      setSizePerPage();
      await dispatch(usersThunk());
      setIsProcessing(false);
    })();
  }, []);

  return (
    <div data-cy="mill-users-table" className="page-users">
      <Spinner2 show={isProcessing} />
      <Row>
        <Col>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-40">
            <div className="select-wrapper mb-24 mb-md-0">
              <FormLabel>{t('settings.users.roles.title')}</FormLabel>
              <Select options={roleSearchOptions} onChange={handleRoleChange} />
            </div>
            <Button
              data-cy="add-new-user"
              className="width-auto"
              icon={<PlusIcon />}
              onClick={handleAddUser}
              size="lg"
            >
              {t('settings.users.add')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            page={page}
            data={data}
            columns={columns}
            totalSize={count}
            sizePerPage={sizePerPage}
            onTableChange={handleTableChange}
          />
        </Col>
      </Row>
      <UserFormContainer />
      <Confirmation key="mill-users-list" onConfirm={handleDeleteUser} />
    </div>
  );
};
