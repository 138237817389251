import React, { useState } from 'react';
import { resetSlice } from 'slices';
import { useAppDispatch } from 'store/hooks';
import { Spinner2 } from 'components';
import { projectSummaryReportThunk } from 'thunks/reports';
import { ReportGeneratorComponent } from './ReportGeneratorComponent';

interface IOrderIdProps {
  orderId: any;
}

export const ReportGeneratorContainer: React.FC<IOrderIdProps> = ({
  orderId,
}: IOrderIdProps) => {
  const dispatch = useAppDispatch();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const downloadFileHandler = async () => {
    setIsProcessing(true);
    dispatch(resetSlice.actions.startLoading());
    await dispatch(projectSummaryReportThunk(orderId));
    setIsProcessing(false);
  };

  return (
    <>
      {' '}
      <Spinner2 show={isProcessing} />{' '}
      <ReportGeneratorComponent downloadFile={downloadFileHandler} />{' '}
    </>
  );
};
