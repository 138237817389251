import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { PublicLayout } from '../../components/Layout';

export const AboutUs: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PublicLayout mainClasses="page page-about-us">
      <Container className='content-container'>
        <Row className='public-page'>
          <Col className='public-page-col'>
            <h1 className="mt-52 mb-58 fs-34">{t('footer.menu.aboutUs.title')}</h1>
            <div className="mt-52 mb-58"> <strong>{t('footer.menu.aboutUs.ceoMessage')}</strong>
              <div className='mt-20'>
                <div>Dr. Ferdinand Schwenkert</div>
                <div>CEO</div>
              </div>
            </div>

            <div className='public-page-text'>
              <Trans>
                {t('footer.menu.aboutUs.content')}
              </Trans>
            </div>
          </Col>
        </Row>

      </Container>
    </PublicLayout>);
};
