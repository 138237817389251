import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'api';
import i18n from 'translations';

export const fetchAppVersion = createAsyncThunk(
  'app/version',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get('/app/version');
      return response;
    } catch (err) {
      console.log(`Error on fetching app version: ${err}`);
      return rejectWithValue(i18n.t('support.downloadApp.notFound'));
    }
  }
);
