import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'api';

export const CONFIRMATION_ACTIONS = {
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  SUBSCRIBE: 'SUBSCRIBE',
  RESUBSCRIBE: 'RESUBSCRIBE',
};

export interface IPromotionsType {
  data: [IContractType];
  promotions?: IPromotion;
}

export interface IPromotion {
  id: number;
  couponId: string;
  description: string;
  value: number;
  ispercentage: boolean;
}

export interface IContractType {
  id: number;
  name: string;
  price: number;
  dataLimit: number;
  allowedClients: number;
  maxOrdersCount: number;
  support: boolean;
  lookupKey: string;
}

export interface ICustomContract extends IContractType {
  millId: number;
}

export const getContractTypesThunk = createAsyncThunk(
  'get/contract/types',
  async (_, { rejectWithValue }) => {
    try {
      return Api.get('/contract-types');
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
