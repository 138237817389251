import { createSlice } from '@reduxjs/toolkit';
import { IApiAction, IApiState } from '../interfaces/api';
import {
  COMPONENT_STATUS_DOWNLOADED,
  IComponent,
  IMessage,
} from '../interfaces/component';
import { IDownload } from '../interfaces/document';
import {
  componentThunk,
  createMessageThunk,
  deleteDownloadThunk,
  downloadAllDocumentsThunk,
  uploadDownloadThunk,
} from '../thunks/component';
import { defaultAsyncExtraReducers } from '../thunks/helpers';

export interface IComponentState extends IApiState {
  data?: IComponent;
  showUploadDownload: boolean;
}

export const initialState: IComponentState = {
  showUploadDownload: false,
};

export const componentSlice = createSlice({
  name: 'component',
  initialState,
  reducers: {
    showUploadDownload(state: IComponentState) {
      state.showUploadDownload = true;
    },
    closeUploadDownload(state: IComponentState) {
      state.showUploadDownload = false;
    },
    setComponentStatus(state: IComponentState, action: any) {
      if (state.data) {
        state.data.status = action.payload.value;
      }
    },
  },
  extraReducers: {
    ...defaultAsyncExtraReducers(componentThunk),
    [deleteDownloadThunk.fulfilled.type]: (
      state,
      action: IApiAction<IDownload>
    ) => {
      return {
        apiStatus: 'fulfilled',
        data: {
          ...state.data,
          downloads: state.data?.downloads.filter(
            (d) => d.id !== action.payload.id
          ),
        },
      } as IComponentState;
    },
    [uploadDownloadThunk.fulfilled.type]: (
      state,
      action: IApiAction<IDownload>
    ) => {
      const downloads = state.data?.downloads
        ? [action.payload, ...state.data?.downloads]
        : [action.payload];
      return {
        apiStatus: 'fulfilled',
        data: {
          ...state.data,
          downloads,
        },
      } as IComponentState;
    },
    [createMessageThunk.fulfilled.type]: (
      state,
      action: IApiAction<IMessage>
    ) => {
      const messages = state.data?.messages
        ? [action.payload, ...state.data?.messages]
        : [action.payload];
      return {
        apiStatus: 'fulfilled',
        data: {
          ...state.data,
          messages,
        },
      } as IComponentState;
    },
    [downloadAllDocumentsThunk.fulfilled.type]: (state: IComponentState) => {
      if (state.data) {
        state.data.status = COMPONENT_STATUS_DOWNLOADED;
      }
    },
  },
});
