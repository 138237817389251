import { createAsyncThunk } from '@reduxjs/toolkit';
import { IMill } from 'interfaces/mill';
import { RootState } from 'store/store';
import { getSelectedMillId } from 'utils/common';

import Api from '../api';
import {
  APP_DEFAULT_LANGUAGE,
  APP_LANGUAGE,
  API_TOKEN,
  SELECTED_MILL,
} from '../constants/constants';
import {
  ROOT_ROUTE,
  MILL_WELCOME_ROUTE,
  LAB_WELCOME_ROUTE,
  MILL_ORDERS_ROUTE,
  MANAGER_HOME_ROUTE,
} from '../constants/routes';
import {
  IUser,
  LAB_ROLE,
  MANAGER_ROLE,
  MILL_ROLE,
  MILL_USER_ROLE,
} from '../interfaces/user';
import {
  authSlice,
  confirmationSlice,
  forgotSlice,
  resetSlice,
  signupSlice,
  userConsentSlice,
} from '../slices';
import { IConfirmation } from '../slices/confirmation';
import { signinSlice } from '../slices/signin';
import i18n from '../translations';
import { setLocalStorage } from '../utils/localStorage';
import { getActiveMillId } from '../utils/mill';
import { millThunk } from './mill';

export interface ISigninPayload {
  identifier: string;
  password: string;
  linkToAnExistingAccount?: boolean;
  invitedMillId?: string;
  confirmationToken?: string;
  invitingMillCenter?: string;
  companyName?: string;
}

export interface ILinkToAnExistingAccountPayload {
  confirmationToken: string;
  millId: string;
}

export interface ISignupPayload {
  firstName: string;
  lastName: string;
  email: string;
  activationKey: string;
  promotions: boolean;
  policyAgreement: boolean | null | undefined;
  dataProcessingAgreement: boolean | null | undefined;
}

export interface IConfirmationPayload {
  confirmation: string;
}

export interface IForgotPayload {
  email: string;
}

export interface IResetPayload {
  password: string;
  passwordConfirmation: string;
  code: string;
}

export interface IAccountCreationForm {
  companyName?: string;
  identifier: string;
  password: string;
}

export interface IAccountCreationPayload extends IAccountCreationForm {
  confirmationToken: string;
  millId?: string;
}

export interface IMarkReadStatePayload {
  notificationIds: number[];
  read: boolean;
}

export const getRedirectUrlForMillRole = (user: IUser, ordersCount: number) => {
  let redirectUrl = MILL_WELCOME_ROUTE;
  if (user.millRole === MILL_USER_ROLE) {
    redirectUrl = MILL_ORDERS_ROUTE;
  } else {
    redirectUrl = ordersCount ? MILL_ORDERS_ROUTE : MILL_WELCOME_ROUTE;
  }

  return redirectUrl;
};

function redirectByRole(user: IUser, ordersCount: number) {
  const roleType = user.role?.type;
  if (roleType === MILL_ROLE) {
    window.location.href = getRedirectUrlForMillRole(user, ordersCount);
  } else if (roleType === LAB_ROLE) {
    window.location.href = LAB_WELCOME_ROUTE;
  } else if (roleType === MANAGER_ROLE) {
    window.location.href = MANAGER_HOME_ROUTE;
  } else {
    window.location.href = ROOT_ROUTE;
  }
}

function setLocalStorageForUser(language: string, millId: string, jwt: string) {
  // save token to localStorage
  setLocalStorage(API_TOKEN, jwt);

  // set user language to localStorage
  setLocalStorage(APP_LANGUAGE, language || APP_DEFAULT_LANGUAGE);

  // set selected millId, by default first mill will be chosen
  setLocalStorage(SELECTED_MILL, millId);
}

export const loginThunkToLinkToAnExistingAccount = createAsyncThunk(
  'auth/linkToAnExistingAccount',
  async (
    payload: ILinkToAnExistingAccountPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await Api.post(
        `/mills/${payload.millId}/link-to-account`,
        payload
      );

      const { user, mills, jwt } = response;
      const { language = APP_DEFAULT_LANGUAGE } = user;
      const { id: millId, name: millName } = getActiveMillId(mills);
      setLocalStorageForUser(language, millId, jwt);

      dispatch(signinSlice.actions.closeAndResetLinkToExistingAccount());

      dispatch(millThunk(millId));

      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('auth.millCenterAdded.title'),
          message: i18n.t('auth.millCenterAdded.message', {
            millName,
          }),
          buttonOk: i18n.t('auth.millCenterAdded.buttonOk'),
          redirectUrl:
            response.user.role.type === MILL_ROLE
              ? getRedirectUrlForMillRole(user, response.ordersCount)
              : LAB_WELCOME_ROUTE,
        } as IConfirmation)
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (payload: ISigninPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.post('auth/local', payload);

      const { user, mills = [], jwt } = response;
      const { language = APP_DEFAULT_LANGUAGE } = user.userSettings;
      const { id: millId } = getActiveMillId(mills);

      setLocalStorageForUser(language, millId, jwt);

      if (payload.linkToAnExistingAccount) {
        dispatch(
          loginThunkToLinkToAnExistingAccount({
            confirmationToken: payload.confirmationToken || '',
            millId: payload.invitedMillId || millId,
          })
        );
      } else {
        // close signin modal
        dispatch(signinSlice.actions.close());
        // redirect based on role
        redirectByRole(response.user, response.ordersCount);

        if (millId) {
          dispatch(millThunk(millId));
        }
      }

      return response;
    } catch (err) {
      // remove token from local storage
      localStorage.removeItem(API_TOKEN);

      return rejectWithValue(err.response.data);
    }
  }
);

export const registerThunk = createAsyncThunk(
  'auth/local/register',
  async (payload: ISignupPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.post('auth/local/register', payload);

      // close signin modal
      dispatch(signupSlice.actions.close());

      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('auth.registerConfirm.title'),
          message: i18n.t('auth.registerConfirm.message'),
          buttonOk: i18n.t('auth.registerConfirm.buttonOk'),
        } as IConfirmation)
      );
      dispatch(userConsentSlice.actions.closeUserConsentModal());
      return response;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err.response.data);
    }
  }
);

export const meThunk = createAsyncThunk(
  'users/me',
  async (_, { dispatch, getState }) => {
    try {
      const response = await Api.get('users/me');
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      if (millId) {
        dispatch(millThunk(`${millId}`));
      }
      return response;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
      }
    }
    return undefined;
  }
);

export const confirmationThunk = createAsyncThunk(
  'auth/confirmation',
  async (payload: IConfirmationPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get(
        `auth/email-confirmation?confirmation=${payload.confirmation}`
      );

      const { user, mills, jwt } = response;
      const { language = APP_DEFAULT_LANGUAGE } = user;
      const { id: millId } = getActiveMillId(mills);
      setLocalStorageForUser(language, millId, jwt);

      dispatch(millThunk(millId));

      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('auth.confirmationConfirm.title'),
          message: i18n.t('auth.confirmationConfirm.message', {
            name: response.user.firstName,
          }),
          buttonOk: i18n.t('auth.confirmationConfirm.buttonOk'),
          redirectUrl: MILL_WELCOME_ROUTE,
        } as IConfirmation)
      );

      return response;
    } catch (err) {
      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('auth.confirmationConfirm.title'),
          message: err.response.data.message,
          redirectUrl: ROOT_ROUTE,
        } as IConfirmation)
      );
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotThunk = createAsyncThunk(
  'auth/forgot',
  async (payload: IForgotPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.post('auth/forgot-password', payload);

      dispatch(forgotSlice.actions.close());

      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('auth.forgotConfirm.title'),
          message: i18n.t('auth.forgotConfirm.message'),
        } as IConfirmation)
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetThunk = createAsyncThunk(
  'auth/reset',
  async (payload: IResetPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.post('auth/reset-password', payload);

      dispatch(resetSlice.actions.close());

      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('auth.resetConfirm.title'),
          message: i18n.t('auth.resetConfirm.message'),
          redirectUrl: ROOT_ROUTE,
        } as IConfirmation)
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const accountCreationThunk = createAsyncThunk(
  'auth/account-creation',
  async (
    payload: IAccountCreationPayload,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      let response = await Api.post('auth/check-unique-email', payload);
      const { emailIsTaken } = response;
      if (emailIsTaken) {
        const state = getState() as RootState;
        if (state.auth.data.user.role?.name === LAB_ROLE) {
          dispatch(authSlice.actions.closeCreateNewAccountModal());
          dispatch(authSlice.actions.closeAccountCreation());
          dispatch(authSlice.actions.showExistingAccount());
        } else {
          throw new Error(i18n.t('email.taken'));
        }
      } else {
        response = await Api.post('auth/account-creation', payload);
        const { user, mills, jwt } = response;

        const { language = APP_DEFAULT_LANGUAGE } = user;
        const { id: millId } = getActiveMillId(mills);
        setLocalStorageForUser(language, millId, jwt);

        dispatch(authSlice.actions.closeAccountCreation());
        dispatch(authSlice.actions.closeCreateNewAccountModal());

        dispatch(millThunk(millId));

        dispatch(
          confirmationSlice.actions.show({
            title: i18n.t('auth.accountCreationConfirm.title'),
            message: i18n.t('auth.accountCreationConfirm.message', {
              name: response.user.firstName,
            }),
            buttonOk: i18n.t('auth.accountCreationConfirm.buttonOk'),
            redirectUrl:
              response.user.role.type === MILL_ROLE
                ? getRedirectUrlForMillRole(user, response.ordersCount)
                : LAB_WELCOME_ROUTE,
          } as IConfirmation)
        );
      }
      return response;
    } catch (err) {
      dispatch(authSlice.actions.closeAccountCreation());
      dispatch(authSlice.actions.closeCreateNewAccountModal());

      let error = err;

      if (err.message && typeof err.message === 'string') {
        error = {
          response: {
            data: {
              message: err.message,
            },
          },
        };
      }

      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('auth.accountCreationConfirm.error'),
          message: error.response.data.message,
          redirectUrl: ROOT_ROUTE,
        } as IConfirmation)
      );

      return rejectWithValue(error.response.data);
    }
  }
);

export const getEmailByConfirmationTokenThunk = createAsyncThunk(
  'auth/getEmailByConfirmationTokenThunk',
  async (confirmationToken: string, { rejectWithValue }) => {
    try {
      return await Api.post(`/auth/user-email-by-token`, {
        confirmationToken,
      });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendEmailConfirmationThunk = createAsyncThunk(
  'auth/resendEmailConfirmationThunk',
  async (payload: IAccountCreationPayload, { dispatch, rejectWithValue }) => {
    try {
      const { confirmationToken, identifier: email } = payload;
      const response = await Api.post(`/auth/resend-confirmation-email`, {
        confirmationToken,
        email,
      });

      dispatch(authSlice.actions.closeAccountCreation());
      dispatch(authSlice.actions.closeCreateNewAccountModal());

      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('auth.registerConfirm.title'),
          message: i18n.t('auth.registerConfirm.message'),
          buttonOk: i18n.t('auth.registerConfirm.buttonOk'),
          redirectUrl: ROOT_ROUTE,
        } as IConfirmation)
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const markNotificationReadState = createAsyncThunk(
  'auth/markNotificationReadState',
  async (payload: IMarkReadStatePayload, { rejectWithValue }) => {
    try {
      const { notificationIds, read } = payload;
      const response = await Api.post(`/notifications/markReadState`, {
        notificationIds,
        read,
      });

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNotifications = createAsyncThunk(
  'auth/getNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/notifications`);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
