import { createSlice } from '@reduxjs/toolkit';

export interface IResetState {
  show: boolean;
  isLoading: boolean;
}

export const initialState: IResetState = {
  show: false,
  isLoading: false,
};

export const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {
    show(state) {
      state.show = true;
    },
    close(state) {
      state.show = false;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
  },
});
