import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IGeoInfo } from 'interfaces/Manager/IhdUserGeoInfo';
import moment from 'moment';

export const detailColumns: ColumnDescription<any, IGeoInfo>[] = [
  {
    dataField: 'boardSerial',
    text: 'BoardSerial',
    formatter: (_cell: any, row: IGeoInfo) => {
      return row.boardSerial || '-';
    },
  },
  {
    dataField: 'mac',
    text: 'MAC',
    formatter: (_cell: any, row: IGeoInfo) => (
      <table>
        {row.mac.map((m) => {
          return (
            <tr id={m}>
              <td>{m}</td>
            </tr>
          );
        })}
      </table>
    ),
  },
  {
    dataField: 'pcName',
    text: 'PC Name',
    formatter: (_cell: any, row: IGeoInfo) => {
      return row.pcName || '-';
    },
  },
  {
    dataField: 'ip',
    text: 'IP(s)',
    formatter: (_cell: any, row: IGeoInfo) => {
      return (
        <div>
          <table>
            {row.ip.map((ip) => {
              return (
                <tr id={ip}>
                  <td>{ip}</td>
                </tr>
              );
            })}
          </table>
        </div>
      );
    },
  },
  {
    dataField: 'isRunning',
    text: 'RUNNING',
    formatter: (_cell: any, row: IGeoInfo) => {
      const isTrue = !!row.isRunning;
      return (
        <div style={{ backgroundColor: isTrue ? 'chartreuse' : '#f9dbdb' }}>
          {' '}
          {isTrue ? 'TRUE' : 'FALSE'}{' '}
        </div>
      );
    },
  },
  {
    dataField: 'lastUpdateReceivedAt',
    text: 'LAST UPDATE AT',
    formatter: (_cell: any, row: IGeoInfo) => {
      return moment(row.lastUpdateReceivedAt).format('DD.MM.YYYY HH:mm');
    },
  },
];
