import { SELECTED_MILL } from '../constants/constants';
import { getLocalStorageItem } from './localStorage';
import { IMill } from '../interfaces/mill';

export const getSelectedMillId = (data: IMill) => {
  return data.id || +getLocalStorageItem(SELECTED_MILL);
};

export const HD_TICKET_FORMAT = /^[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}$/;

export const getProgressStats = (max?: number, used?: number) => {
  let variant = 'primary';
  if (!max || !used)
    return {
      pct: 0,
      variant,
    };

  const maxNum = +max;
  const usedNum = +used;
  let pct = 0;
  if (usedNum >= maxNum) {
    pct = 100;
  } else {
    pct = +((usedNum / maxNum) * 100).toFixed(2);
  }

  if (pct > 80) {
    variant = 'danger';
  } else {
    variant = 'primary';
  }

  return {
    pct,
    variant,
  };
};

export const getDateLocaleString = (str: string) => {
  if (!str) return '-';

  const d = new Date(str);
  return d.toLocaleString();
};

export const downloadFile = (file: any) => {
  const element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', 'true');

  element.style.display = 'none';

  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};

export const getFormattedDate = (date: string) => {
  const d = new Date(date);
  return d
    .toLocaleString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replaceAll(',', '');
};

export const padLeft = (d: number) => {
  const v = d.toString();
  return v.padStart(2, '0');
};
