import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITable } from '../interfaces/table';
import { IOrder } from '../interfaces/order';
import {
  defaultTableState,
  filterSlice,
  paginationSlice,
  searchSlice,
  sortSlice,
} from '../utils/table';
import { RootState } from '../store/store';
import { ordersThunk } from '../thunks/orders';
import { IApiMeta } from '../interfaces/api';

export interface IOrdersState extends ITable<IOrder> {
  nonExpandable: any[];
}

export const initialState: IOrdersState = {
  ...defaultTableState(),
  sort: 'created_at:desc',
  apiStatus: undefined,
  nonExpandable: [],
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setPageSize(state, action: PayloadAction<number>) {
      state.sizePerPage = action.payload;
    },
    ...paginationSlice<IOrdersState>(),
    ...sortSlice<IOrdersState>(),
    ...filterSlice<IOrdersState>(),
    ...searchSlice<IOrdersState>(),
  },
  extraReducers: {
    [ordersThunk.pending.type]: (
      state,
      action: PayloadAction<ITable<IOrder>, string, IApiMeta>
    ) => {
      state.apiStatus = action.meta.requestStatus;
    },
    [ordersThunk.fulfilled.type]: (
      state,
      action: PayloadAction<ITable<IOrder>, string, IApiMeta>
    ) => {
      state.count = action.payload.count;
      state.data = action.payload.data;
      state.apiStatus = action.meta.requestStatus;
      state.nonExpandable = action.payload.data
        .map((order) => (order.components.length ? null : order.id))
        .filter((order) => order !== null);
    },
    [ordersThunk.rejected.type]: (
      state,
      action: PayloadAction<ITable<IOrder>, string, IApiMeta, any>
    ) => {
      state.apiStatus = action.meta.requestStatus;
      state.error = action.payload ? action.payload.error : action.error;
    },
  },
});

export const nonExpandableSelector = (state: RootState) => state.orders;
