import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'api';

export const activePolicyDocsThunk = createAsyncThunk(
  'activePolicyDocs',
  async (_, { rejectWithValue }) => {
    try {
      return Api.get(`/policy-documents/active`);
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err.response.data);
    }
  }
);
