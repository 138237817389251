import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMill } from 'interfaces/mill';

import { API_TOKEN, OPENER_IS_WIN_APP } from '../constants/constants';
import { ROOT_ROUTE } from '../constants/routes';
import { IApiAction, IApiState } from '../interfaces/api';
import {
  IUser,
  LAB_ROLE,
  MANAGER_ROLE,
  MILL_ADMIN_ROLE,
  MILL_ROLE,
  MILL_USER_ROLE,
} from '../interfaces/user';

import { RootState } from '../store/store';
import {
  updateCompanyInfoThunk,
  updateMillProfileThunk,
  updateUserSettingsThunk,
} from '../thunks/account';
import {
  forgotThunk,
  getEmailByConfirmationTokenThunk,
  getNotifications,
  loginThunk,
  loginThunkToLinkToAnExistingAccount,
  markNotificationReadState,
  meThunk,
  resendEmailConfirmationThunk,
  resetThunk,
} from '../thunks/auth';
import { defaultAsyncExtraReducers } from '../thunks/helpers';
import {
  ICompanyDetail,
  updateCompanyDetailsThunk,
  updateCompanyLandingPageDetailsThunk,
} from '../thunks/settings';
import { replaceNullValues } from './company';

export interface INotification {
  notificationId: number;
  message: string;
  notificationType: string;
  objectId: number;
  parentObjectId?: number;
  isRead: boolean;
}

export interface IAuthState extends IApiState {
  data: {
    jwt: string;
    ordersCount: number;
    user: IUser;
    mills: IMill[];
    notifications: INotification[];
  };
  emailByConfirmationToken: string;
  isAuthenticated: boolean;
  showAccountCreation: boolean;
  showExistingAccount: boolean;
  showCreateNewAccountModal: boolean;
  showLoggedInUserInvitationModal: boolean;
  showMillSelectionModal: boolean;
  showAddonSelectionModal: boolean;
  showUpcomingInvoiceModal: boolean;
  showConsentModel: boolean;
  showSubscriptionConsentModel: boolean;
  showAddonConsentModel: boolean;
}

export interface ICompanyInfo {
  company: ICompanyDetail;
}

export const initialState: IAuthState = {
  data: {
    jwt: '',
    ordersCount: 0,
    user: {} as IUser,
    mills: [],
    notifications: [],
  },
  emailByConfirmationToken: '',
  isAuthenticated: false,
  showAccountCreation: false,
  showExistingAccount: false,
  showCreateNewAccountModal: false,
  showLoggedInUserInvitationModal: false,
  showMillSelectionModal: false,
  showAddonSelectionModal: false,
  showUpcomingInvoiceModal: false,
  showConsentModel: false,
  showSubscriptionConsentModel: false,
  showAddonConsentModel: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.clear();
      return { ...initialState };
    },
    gotoHome: (state, action) => {
      const { payload } = action;
      const { redirectTo } = payload;
      if (redirectTo) {
        window.location.href = redirectTo;
      } else {
        window.location.href = ROOT_ROUTE;
      }
    },
    showAccountCreation: (state) => {
      state.showAccountCreation = true;
    },
    closeAccountCreation: (state) => {
      state.showAccountCreation = false;
    },
    showExistingAccount: (state) => {
      state.showExistingAccount = true;
    },
    closeExistingAccount: (state) => {
      state.showExistingAccount = false;
    },
    showCreateNewAccountModal: (state) => {
      state.showCreateNewAccountModal = true;
    },
    closeCreateNewAccountModal: (state) => {
      state.showCreateNewAccountModal = false;
      state.error = undefined;
    },
    showLoggedInUserInvitationModal: (state) => {
      state.showLoggedInUserInvitationModal = true;
    },
    closeLoggedInUserInvitationModal: (state) => {
      state.showLoggedInUserInvitationModal = false;
    },
    showMillSelectionModal: (state) => {
      state.showMillSelectionModal = true;
    },
    closeMillSelectionModal: (state) => {
      state.showMillSelectionModal = false;
    },
    showAddonSelectionModal: (state) => {
      state.showAddonSelectionModal = true;
    },
    closeAddonSelectionModal: (state) => {
      state.showAddonSelectionModal = false;
    },
    clearEmailByConfirmationToken: (state) => {
      state.emailByConfirmationToken = '';
    },
    showUpcomingInvoiceModal: (state) => {
      state.showUpcomingInvoiceModal = true;
    },
    closeUpcomingInvoiceModal: (state) => {
      state.showUpcomingInvoiceModal = false;
    },
    showConsentModel: (state) => {
      state.showConsentModel = true;
    },
    closeConsentModel: (state) => {
      state.showConsentModel = false;
    },
    showSubscriptionConsentModel: (state) => {
      state.showSubscriptionConsentModel = true;
    },
    closeSubscriptionConsentModel: (state) => {
      state.showSubscriptionConsentModel = false;
    },
    showAddonConsentModel: (state) => {
      state.showAddonConsentModel = true;
    },
    closeAddonConsentModel: (state) => {
      state.showAddonConsentModel = false;
    },
    addNotification: (state, action) => {
      const {
        notificationId,
        isRead = false,
        objectId,
        parentObjectId,
        message,
        notificationType,
      } = action.payload;

      const exists = state.data.notifications.find(
        (n) => n.notificationId === notificationId
      );

      if (!exists) {
        state.data.notifications.unshift({
          notificationId,
          isRead,
          objectId,
          parentObjectId,
          message,
          notificationType,
        });
      }
    },
  },
  extraReducers: {
    ...defaultAsyncExtraReducers(loginThunk, {
      pending: {
        isAuthenticated: false,
      },
      rejected: {
        isAuthenticated: false,
      },
      fulfilled: {
        isAuthenticated: true,
      },
    }),
    ...defaultAsyncExtraReducers(loginThunkToLinkToAnExistingAccount, {
      pending: {
        isAuthenticated: false,
      },
      rejected: {
        isAuthenticated: false,
      },
      fulfilled: {
        isAuthenticated: true,
      },
    }),
    ...defaultAsyncExtraReducers(meThunk, {
      pending: {
        isAuthenticated: false,
      },
      rejected: {
        isAuthenticated: false,
      },
      fulfilled: {
        isAuthenticated: true,
      },
    }),

    [forgotThunk.pending.type]: (state, action: IApiAction<IAuthState>) => {
      return {
        ...state,
        apiStatus: action.meta.requestStatus,
        error: undefined,
        isAuthenticated: false,
      };
    },
    [forgotThunk.rejected.type]: (state, action: IApiAction<IAuthState>) => {
      return {
        ...state,
        apiStatus: action.meta.requestStatus,
        error: action.payload.data,
        isAuthenticated: false,
      };
    },

    [resetThunk.pending.type]: (state, action: IApiAction<IAuthState>) => {
      return {
        ...state,
        apiStatus: action.meta.requestStatus,
        error: undefined,
        isAuthenticated: false,
      };
    },
    [resetThunk.rejected.type]: (state, action: IApiAction<IAuthState>) => {
      return {
        ...state,
        apiStatus: action.meta.requestStatus,
        error: action.payload.data,
        isAuthenticated: false,
      };
    },

    [updateMillProfileThunk.pending.type]: (state) => {
      return {
        ...state,
        error: undefined,
      };
    },
    [updateMillProfileThunk.fulfilled.type]: (
      state,
      action: IApiAction<IAuthState>
    ) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: undefined,
      };
    },
    [updateMillProfileThunk.rejected.type]: (
      state,
      action: IApiAction<IAuthState>
    ) => {
      return {
        ...state,
        error: action.payload.data,
      };
    },
    [getEmailByConfirmationTokenThunk.pending.type]: (state) => {
      state.error = undefined;
    },
    [getEmailByConfirmationTokenThunk.fulfilled.type]: (
      state,
      action: IApiAction<IAuthState>
    ) => {
      state.emailByConfirmationToken = action.payload.emailByConfirmationToken;
    },
    [getEmailByConfirmationTokenThunk.rejected.type]: (
      state,
      action: IApiAction<IAuthState>
    ) => {
      state.error = action.payload.data;
      state.emailByConfirmationToken = '';
    },
    [updateCompanyDetailsThunk.fulfilled.type]: (
      state,
      action: IApiAction<ICompanyDetail>
    ) => {
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            company: {
              ...state.data.user.company,
              ...replaceNullValues(action.payload),
            },
          },
        },
        error: undefined,
      };
    },
    [updateCompanyLandingPageDetailsThunk.fulfilled.type]: (
      state,
      action: IApiAction<any>
    ) => {
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            company: {
              ...state.data.user.company,
              ...action.payload,
            },
          },
        },
        error: undefined,
      };
    },

    [updateCompanyInfoThunk.pending.type]: (state) => {
      return {
        ...state,
        error: undefined,
      };
    },
    [updateCompanyInfoThunk.fulfilled.type]: (
      state,
      action: IApiAction<ICompanyInfo>
    ) => {
      state.data.user.company = action.payload.company;
      state.error = undefined;
    },
    [updateCompanyInfoThunk.rejected.type]: (
      state,
      action: IApiAction<IAuthState>
    ) => {
      return {
        ...state,
        error: action.payload.data,
      };
    },
    [resendEmailConfirmationThunk.rejected.type]: (
      state,
      action: IApiAction<IAuthState>
    ) => {
      state.error = action.payload.data;
    },
    [markNotificationReadState.fulfilled.type]: (state, action) => {
      const { notificationIds, isRead } = action.payload;
      state.data.notifications.forEach((n: INotification) => {
        if (notificationIds.includes(n.notificationId)) {
          // eslint-disable-next-line no-param-reassign
          n.isRead = isRead;
        }
      });
    },
    [getNotifications.fulfilled.type]: (state, action) => {
      state.data.notifications = action.payload.notifications;
    },
    [updateUserSettingsThunk.fulfilled.type]: (
      state,
      action: PayloadAction<IUser>
    ) => {
      state.data.user = action.payload;
    },
    'signin/close': (state) => {
      return {
        ...state,
        error: undefined,
      };
    },
  },
});

// selectors
export const isTokenPresent = () => !!localStorage.getItem(API_TOKEN);
export const isWinAppViewerMode = () =>
  !!localStorage.getItem(OPENER_IS_WIN_APP);

export const authSelector = (state: RootState) => state.auth;

export const isMillRoleSelector = () =>
  createSelector(authSelector, (state: IAuthState) => {
    if (!state.data) return false;
    const { user } = state.data;
    return user.roleId ? user?.roleId === 1 : user?.role?.type === MILL_ROLE;
  });

export const isLabRoleSelector = () =>
  createSelector(authSelector, (state: IAuthState) => {
    if (!state.data) return false;
    const { user } = state.data;
    return user.roleId ? user?.roleId === 3 : user?.role?.type === LAB_ROLE;
  });

export const isManagerRoleSelector = () =>
  createSelector(authSelector, (state: IAuthState) => {
    if (!state.data) return false;
    const { user } = state.data;
    return user.roleId ? user?.roleId === 4 : user?.role?.type === MANAGER_ROLE;
  });

export const isMillAdminRoleSelector = () =>
  createSelector(authSelector, isMillRoleSelector, (state: IAuthState) => {
    if (!state.data) return false;
    const { user } = state.data;
    return user.millRole === MILL_ADMIN_ROLE;
  });

export const isMillUserRoleSelector = () =>
  createSelector(authSelector, isMillRoleSelector, (state: IAuthState) => {
    if (!state.data) return false;
    const { user } = state.data;
    return user.millRole === MILL_USER_ROLE;
  });
