import React from 'react';

export const UserIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8997 11.521C15.1504 10.8448 16 9.52161 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 9.52161 8.84961 10.8448 10.1003 11.521C7.29727 12.3415 5.25 14.9317 5.25 18C5.25 18.4142 5.58579 18.75 6 18.75C6.41421 18.75 6.75 18.4142 6.75 18C6.75 15.1005 9.10051 12.75 12 12.75C14.8995 12.75 17.25 15.1005 17.25 18C17.25 18.4142 17.5858 18.75 18 18.75C18.4142 18.75 18.75 18.4142 18.75 18C18.75 14.9317 16.7027 12.3415 13.8997 11.521ZM14.5 8C14.5 9.38071 13.3807 10.5 12 10.5C10.6193 10.5 9.5 9.38071 9.5 8C9.5 6.61929 10.6193 5.5 12 5.5C13.3807 5.5 14.5 6.61929 14.5 8Z"
      fill="#C7CED8"
    />
  </svg>
);
