import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IConfirmation {
  title: string;
  message: string;
  buttonOk: string;
  buttonCancel?: string;
  redirectUrl?: string;
  actionKey?: string;
}

export interface IConfirmationState extends IConfirmation {
  show: boolean;
}

export const initialState: IConfirmationState = {
  show: false,
  title: '',
  message: '',
  buttonOk: '',
  actionKey: '',
  buttonCancel: undefined,
  redirectUrl: undefined,
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    show(state, action: PayloadAction<IConfirmation>) {
      state.show = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.buttonOk = action.payload.buttonOk || 'Ok';
      state.buttonCancel = action.payload.buttonCancel;
      state.redirectUrl = action.payload.redirectUrl;
      state.actionKey = action.payload.actionKey;
    },
    close(state) {
      state.show = false;
    },
    reset() {
      return { ...initialState };
    },
  },
});
