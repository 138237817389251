import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button, ImageUpload } from 'components';
import { IMillProfilePayload } from 'thunks/account';
import { hasError, showError } from 'utils/handle-api-error';
import { Prompt } from 'react-router-dom';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { setSessionStorage } from 'utils/localStorage';

interface IAccountFormProps {
  initialValues: IMillProfilePayload;
  validationSchema: any | (() => any);
  error?: any;
  onSubmit: (
    values: IMillProfilePayload,
    form: FormikHelpers<IMillProfilePayload>
  ) => void | Promise<any>;
  imageUrl?: string;
}

export const AccountForm = ({
  initialValues,
  validationSchema,
  error = undefined,
  imageUrl = undefined,
  onSubmit,
}: IAccountFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          dirty,
          setFieldValue,
        }) => {
          setSessionStorage(TAB_FORM_IS_DIRTY, `${dirty}`);
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form data-cy="user-profile" onSubmit={handleSubmit}>
                <Form.Group controlId="files">
                  <ImageUpload
                    setFieldValue={setFieldValue}
                    accept="image/*"
                    values={values.files}
                    imageUrl={imageUrl}
                  />
                </Form.Group>

                <Form.Group controlId="firstName">
                  <Form.Label>{t('firstName.label')}</Form.Label>
                  <Form.Control
                    name="firstName"
                    data-cy="prof-first-name"
                    placeholder={t('firstName.placeholder')}
                    value={values.firstName}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                </Form.Group>

                <Form.Group controlId="lastName">
                  <Form.Label>{t('lastName.label')}</Form.Label>
                  <Form.Control
                    name="lastName"
                    data-cy="prof-last-name"
                    placeholder={t('lastName.label')}
                    value={values.lastName}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                  />
                </Form.Group>
                <Form.Group controlId="email" className="mb-60">
                  <Form.Label>{t('email.label')}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    data-cy="prof-email"
                    placeholder={t('email.placeholder')}
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={
                      touched.email &&
                      (!!errors.email || hasError(error, 'email'))
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {showError(error, 'email')}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button dimension="lg" type="submit">
                  {t('account.profile.button')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

AccountForm.defaultProps = {
  error: undefined,
  imageUrl: undefined,
};
