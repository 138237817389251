import React from 'react';

export const MessageIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.75C3.30964 3.75 2.75 4.30964 2.75 5V16C2.75 16.6904 3.30964 17.25 4 17.25H6.75V21.5858C6.75 21.8085 7.01929 21.9201 7.17677 21.7626L11.6893 17.25H20C20.6904 17.25 21.25 16.6904 21.25 16V5C21.25 4.30964 20.6904 3.75 20 3.75H4ZM1.25 5C1.25 3.48122 2.48122 2.25 4 2.25H20C21.5188 2.25 22.75 3.48122 22.75 5V16C22.75 17.5188 21.5188 18.75 20 18.75H12.3107L8.23744 22.8232C7.13499 23.9257 5.25 23.1449 5.25 21.5858V18.75H4C2.48122 18.75 1.25 17.5188 1.25 16V5Z"
    />
  </svg>
);
