import React, { useEffect } from 'react';
import { Col, Container, FormLabel, Row } from 'react-bootstrap';
import { TableChangeType, TableChangeState } from 'react-bootstrap-table-next';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Table, Search, EmptyState, Select, Spinner2 } from 'components';
import { UserLayout } from 'components/Layout';
import { OrderEmptyIcon, SearchEmptyIcon } from 'icons';
import { IOrder } from 'interfaces/order';
import { ordersSlice, resetSlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ordersThunk } from 'thunks/orders';
import { INotification, isWinAppViewerMode } from 'slices/auth';
import downloadIcon from 'assets/images/download.svg';
import { useMillRole } from 'hooks/role';
import { downloadAppThunk } from 'thunks/support';
import { IOption } from 'components/Select';
import { clientsThunk } from 'thunks/clients';
import { useClientOptions, useResponsiblePersonsOptions } from 'hooks/clients';
import { responsiblePersonsThunk } from 'thunks/responsible-persons';
import { downloadAllProjectDocumentsThunk } from 'thunks/component';
import { MESSAGETYPE } from 'constants/constants';
import { markNotificationReadState } from 'thunks/auth';
import { expandRow } from './expandRow';
import { columns, statusOptions } from './columns';

const sortInitialState = {} as any;

export const OrdersTableContainer: React.FC = () => {
  const [sortOrderState, setSortOrderState] = React.useState(sortInitialState);
  const notifications = useAppSelector(
    (state) => state.auth.data.notifications
  );
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMillRole = useMillRole();
  const history = useHistory();
  const clientOptions = useClientOptions(t('orders.select.any.client'));
  const responsibleOptions = useResponsiblePersonsOptions(
    t('orders.select.any.responsible')
  );
  const isWinAppViewerModeEnabled = isWinAppViewerMode();
  const {
    count,
    data,
    page,
    sizePerPage,
    nonExpandable,
    apiStatus,
    search,
  } = useAppSelector((state) => state.orders);

  const setSizePerPage = () => {
    let pageSize = sizePerPage;
    if (userSettings?.pageSize) {
      pageSize = userSettings.pageSize;
    }

    dispatch(ordersSlice.actions.setPageSize(pageSize));
  };

  useEffect(() => {
    setSizePerPage();
    dispatch(ordersThunk());
    dispatch(clientsThunk());
    dispatch(responsiblePersonsThunk());
  }, []);

  useEffect(() => {
    if (!notifications || !notifications.length) return;

    const notificationIds: number[] = [];
    notifications.forEach((n: INotification) => {
      if (n.notificationType === MESSAGETYPE.PROJECT_CREATED && !n.isRead) {
        notificationIds.push(n.notificationId);
      }
    });

    if (!notificationIds.length) return;

    dispatch(markNotificationReadState({ read: true, notificationIds }));
  }, []);

  const handleTableChange = (
    type: TableChangeType,
    state: TableChangeState<IOrder>
  ) => {
    if (type === 'pagination') {
      dispatch(
        ordersSlice.actions.setPagination({
          page: state.page,
          sizePerPage: state.sizePerPage,
        })
      );
      dispatch(ordersThunk());
    } else if (type === 'sort') {
      // HFlow-185: toggle sort order
      const key = `${state.sortField}`;
      const sortByOrder = sortOrderState[key] === 'desc' ? 'asc' : 'desc';
      const sort = `${state.sortField}:${sortByOrder}`;
      setSortOrderState({ ...sortOrderState, [key]: `${sortByOrder}` });
      // HFlow-185: end toggle sort order
      dispatch(
        ordersSlice.actions.setSort({
          sort,
        })
      );
      dispatch(ordersThunk());
    }
  };

  const handleStatusChange = (option: IOption) => {
    if (option.value === '') {
      dispatch(ordersSlice.actions.clearFilters());
    } else {
      dispatch(
        ordersSlice.actions.setFilters({
          'components.status': option.value,
        })
      );
    }
    dispatch(ordersThunk());
  };

  const handleClientChange = (option: IOption) => {
    if (option.value === '') {
      dispatch(ordersSlice.actions.clearFilters());
    } else {
      dispatch(
        ordersSlice.actions.setFilters({
          author: option.value,
        })
      );
    }
    dispatch(ordersThunk());
  };

  const handleResponsibleChange = (option: IOption) => {
    if (option.value === '') {
      dispatch(ordersSlice.actions.clearFilters());
    } else {
      dispatch(
        ordersSlice.actions.setFilters({
          responsible: option.value,
        })
      );
    }
    dispatch(ordersThunk());
  };

  const handleSearch = (value: string) => {
    if (value === '') {
      dispatch(ordersSlice.actions.clearSearch());
    } else {
      dispatch(
        ordersSlice.actions.setSearch({
          search: value,
        })
      );
    }
    dispatch(ordersThunk());
  };

  const downloadProjectDocsClickHandler = (id: number) => {
    dispatch(resetSlice.actions.startLoading());
    dispatch(downloadAllProjectDocumentsThunk(id));
  };

  const handleDownloadApp = () => {
    dispatch(downloadAppThunk());
  };

  const padding = isWinAppViewerModeEnabled
    ? 'py-30 py-md-30 pl-md-30'
    : 'py-60 py-md-70 pl-md-126';

  return (
    <UserLayout
      mainClasses={`page page-orders ${
        isWinAppViewerModeEnabled ? 'pt-0' : ''
      }`}
    >
      <div className={`d-flex flex-column w-100 ${padding} px-20 pr-md-54`}>
        <Spinner2 show={apiStatus === 'pending'} />
        <Container fluid>
          <Row>
            <Col>
              <h1 className="fs-44 mb-10">{t('orders.title')}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-40">
                <div className="d-flex flex-column flex-md-row w-100">
                  <div className="select-wrapper mb-24 mb-md-0 mr-md-32">
                    <FormLabel>{t('clients.status.label')}</FormLabel>
                    <Select
                      options={statusOptions}
                      onChange={handleStatusChange}
                    />
                  </div>
                  {isMillRole && (
                    <>
                      <div className="select-wrapper mb-24 mb-md-0 mr-md-32">
                        <FormLabel>{t('orders.columns.client')}</FormLabel>
                        <Select
                          options={clientOptions}
                          onChange={handleClientChange}
                        />
                      </div>
                      <div className="select-wrapper width-auto mb-24 mb-md-0 mr-md-32">
                        <FormLabel>{t('orders.columns.responsible')}</FormLabel>
                        <Select
                          options={responsibleOptions}
                          onChange={handleResponsibleChange}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="search-wrapper">
                  <Search onChange={handleSearch} />
                </div>
              </div>
            </Col>
          </Row>

          {apiStatus === 'fulfilled' && Boolean(data.length) && (
            <Row>
              <Col>
                <Table
                  page={page}
                  data={data}
                  columns={[
                    ...columns({ isMillRole, downloadProjectDocsClickHandler }),
                  ]}
                  totalSize={count}
                  sizePerPage={sizePerPage}
                  onTableChange={handleTableChange}
                  wrapperClasses="table-orders"
                  expandRow={{
                    ...expandRow({
                      nonExpandable,
                      history,
                      isMillRole,
                    }),
                  }}
                />
              </Col>
            </Row>
          )}
        </Container>
        {apiStatus === 'fulfilled' && !data.length && (
          <>
            {isMillRole || search ? (
              <EmptyState
                icon={search ? <SearchEmptyIcon /> : <OrderEmptyIcon />}
                title={search ? t('orders.noSearch') : t('orders.noOrders')}
              />
            ) : (
              <EmptyState
                icon={<OrderEmptyIcon />}
                title={t('orders.noSearch')}
                subtitle={t('orders.winApp')}
              >
                {!isWinAppViewerModeEnabled && (
                  <div className="download-application">
                    <img
                      src={downloadIcon}
                      alt={t('orders.downloadApp')}
                      className="mb-16"
                    />
                    <Link to="#" onClick={handleDownloadApp}>
                      {t('orders.downloadApp')}
                    </Link>
                  </div>
                )}
              </EmptyState>
            )}
          </>
        )}
      </div>
    </UserLayout>
  );
};
