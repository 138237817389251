import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../api';
import { toastSlice } from '../slices';
import { RootState } from '../store/store';
import i18n from '../translations';
import { SELECTED_MILL } from '../constants/constants';
import { IMill } from '../interfaces/mill';
import { getLocalStorageItem } from '../utils/localStorage';
import { tableQuery } from '../utils/table';

export interface IOrderPayload {
  id: number;
  millId?: number;
  labId?: number;
  responsible: string | null;
}

export const ordersThunk = createAsyncThunk(
  'orders',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const query = tableQuery(state.orders);
    const { id: millId = getLocalStorageItem(SELECTED_MILL) } = state.mill
      .data as IMill;

    return Api.get(`/mills/${millId}/projects?${query}`);
  }
);

export const updateOrderThunk = createAsyncThunk(
  'order/update',
  async (payload: IOrderPayload, { dispatch, rejectWithValue }) => {
    try {
      const { id, millId, labId } = payload;
      const response = Api.put(
        `/mills/${millId}/labs/${labId}/projects/${id}`,
        payload
      );

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.updateOrderToast.title'),
          message: i18n.t('component.updateOrderToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
