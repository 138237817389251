import React from 'react';

import { useTranslation } from 'react-i18next';
import { LoggedInUserInvitation } from '../LoggedInUserInvitation/LoggedInUserInvitation';
import { authSlice, signinSlice } from '../../../slices';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
// import { ISigninPayload, loginThunk } from '../../../thunks/auth';

export const ExistingAccountUserInvitationContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { error, showExistingAccount } = useAppSelector((state) => state.auth);
  const millName = 'Mill center one'; // should be picked from api response

  const onConnectToAnExistingAccount = () => {
    dispatch(authSlice.actions.closeExistingAccount());
    dispatch(
      signinSlice.actions.showWithoutSignUp({
        linkToExistingAccount: true,
        invitedMillId: '',
      })
    );
  };

  const onCreateNewAccount = () => {
    dispatch(authSlice.actions.closeExistingAccount());
    dispatch(authSlice.actions.showCreateNewAccountModal());
  };

  return (
    <LoggedInUserInvitation
      show={showExistingAccount}
      title={t('invitation.existingAccount')}
      message={t('invitation.existingAccountMsg', { millName })}
      onClose={signinSlice.actions.close}
      error={error}
      onConnectToThisAccount={onConnectToAnExistingAccount}
      onCreateNewAccount={onCreateNewAccount}
    />
  );
};
