import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Autolinker from 'autolinker';
import { UserLayout } from '../../components/Layout';
import { Button } from '../../components';
import { DownloadIcon, OpenIcon } from '../../icons';
import labCustomLogo from '../../assets/images/custom-logo.svg';
import labDashboard from '../../assets/images/dashboard-lab.svg';
import labServiceApp from '../../assets/images/service-app.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { placeholderImage } from '../../utils/image';
import { simpleFormat } from '../../utils/format';
import { downloadAppThunk } from '../../thunks/support';

import { LAB_ORDERS_ROUTE } from '../../constants/routes';

export const Welcome: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const AutoLinkerRef = React.useRef(
    new Autolinker({
      newWindow: false,
    })
  );
  const { data, apiStatus } = useAppSelector((state) => state.mill);

  const handleDownloadApp = () => {
    dispatch(downloadAppThunk());
  };

  const redirectToOrdersPage = () => {
    history.push(LAB_ORDERS_ROUTE);
  };

  return (
    <UserLayout mainClasses="page page-light">
      <div className="pt-100 mt-98">
        <Container fluid className="full-vw">
          {apiStatus === 'fulfilled' && (
            <Row className="page page-light">
              <Col md={{ span: 6, offset: 2 }}>
                <img
                  src={placeholderImage(data.company.logo, labCustomLogo)}
                  alt={data.landingPageHeader || t('labWelcome.title')}
                  className="mb-84"
                />
                <h1 className="fs-34 mb-10">
                  {data.landingPageHeader || t('labWelcome.title')}
                </h1>
                <div
                  className="fs-24 lh-36 mb-146"
                  dangerouslySetInnerHTML={{
                    __html: simpleFormat(
                      AutoLinkerRef.current.link(
                        data.landingPageContent || t('labWelcome.content')
                      )
                    ),
                  }}
                />
              </Col>
            </Row>
          )}
          <Row className="page">
            <Col md={{ span: 8, offset: 2 }}>
              <Row>
                <Col md={5}>
                  <div className="pt-58 pt-md-116 mb-40">
                    <span className="label-big">Step 1</span>
                  </div>
                  <img
                    src={labServiceApp}
                    alt={t('labWelcome.serviceApp.title')}
                    className="mb-26"
                  />
                  <h3 className="mb-10 fs-24">
                    {t('labWelcome.serviceApp.title')}
                  </h3>

                  <p className="fs-16 mb-50">
                    {t('labWelcome.serviceApp.content')}
                  </p>
                  <Button
                    onClick={handleDownloadApp}
                    className="mb-158"
                    icon={<DownloadIcon color="#FFFFFF" />}
                  >
                    {t('labWelcome.serviceApp.button')}
                  </Button>
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <div className="pt-58 pt-md-116 mb-40">
                    <span className="label-big">Step 2</span>
                  </div>
                  <img
                    src={labDashboard}
                    alt={t('labWelcome.dashboard.title')}
                    className="mb-26"
                  />

                  <h3 className="mb-10 fs-24">
                    {t('labWelcome.dashboard.title')}
                  </h3>
                  <p className="fs-16 mb-50">
                    {t('labWelcome.dashboard.content')}
                  </p>
                  <Button
                    onClick={redirectToOrdersPage}
                    className="mb-158"
                    icon={<OpenIcon />}
                  >
                    {t('labWelcome.dashboard.button')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </UserLayout>
  );
};
