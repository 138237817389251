import { ColumnDescription } from 'react-bootstrap-table-next';
import { IManagerMillUser } from 'interfaces/Manager/user';

export const columns: ColumnDescription<any, IManagerMillUser>[] = [
  {
    dataField: 'id',
    text: 'Id',
    hidden: true,
  },
  {
    dataField: 'dealerId',
    text: 'Dealer Id',
  },
  {
    dataField: 'name',
    text: 'Name',
  },
];
