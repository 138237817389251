import React from 'react';

export const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 4C8.25 3.0335 9.0335 2.25 10 2.25H14C14.9665 2.25 15.75 3.0335 15.75 4V5.5H19.25C19.6642 5.5 20 5.83579 20 6.25C20 6.66421 19.6642 7 19.25 7H18.5052L17.5712 18.2076C17.4633 19.5033 16.3801 20.5 15.0799 20.5H8.92014C7.61992 20.5 6.53676 19.5033 6.42878 18.2076L5.49481 7H4.75C4.33579 7 4 6.66421 4 6.25C4 5.83579 4.33579 5.5 4.75 5.5H8.25V4ZM9.75 5.5H14.25V4C14.25 3.86193 14.1381 3.75 14 3.75H10C9.86193 3.75 9.75 3.86193 9.75 4V5.5ZM7.00001 7L7.9236 18.083C7.96679 18.6013 8.40006 19 8.92014 19H15.0799C15.6 19 16.0332 18.6013 16.0764 18.083L17 7H7.00001Z"
      fill="#FA9600"
    />
  </svg>
);
