import React from 'react';
import { Button, Select } from 'components';
import {
  CONFIGURATION_TYPE,
  FILE_TYPES,
  TAB_FORM_IS_DIRTY,
} from 'constants/constants';
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { Prompt } from 'react-router-dom';
import { setSessionStorage } from 'utils/localStorage';
import { useTranslation } from 'react-i18next';
import { UploadComponent } from 'components/Upload/UploadComponent';
import { IDealerObject } from '../Models/dealers';

interface IDealerDetailFormProps {
  detailData: IDealerObject;
  imageUri: string;
  validationSchema: any | (() => any);
  onSubmit: (values: IDealerObject) => void | Promise<any>;
}

export const DealersDetailView = ({
  detailData,
  imageUri,
  validationSchema,
  onSubmit,
}: IDealerDetailFormProps) => {
  const { t } = useTranslation();

  const CONFIGOPTIONS = [
    {
      value: 'None',
      label: 'None',
    },
    {
      value: 'FixedJson',
      label: 'Fixed Json',
    },
    {
      value: 'FixedFile',
      label: 'Fixed File',
    },
    {
      value: 'DynamicUrl',
      label: 'Dynamic Url',
    },
  ];

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={detailData}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          dirty,
          setFieldValue,
        }) => {
          setSessionStorage(TAB_FORM_IS_DIRTY, `${dirty}`);
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />
              <Form
                data-cy="user-profile"
                onSubmit={handleSubmit}
                className="form-control-bordered"
              >
                <Row>
                  <Col md={10}>
                    <Form.Group
                      controlId="dealerId"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        Dealer Id
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="dealerId"
                        placeholder="Dealer Id"
                        value={values?.dealerId}
                        onChange={handleChange}
                        isInvalid={touched.dealerId && !!errors.dealerId}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="name"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        Name
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="name"
                        placeholder="Name"
                        value={values?.name}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="parentDealerId"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        Parent Dealer Id
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="parentDealerId"
                        placeholder="Parent Dealer Id"
                        value={values?.parentDealerId}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="logo" className="mb-36">
                      <UploadComponent
                        key={Date.now()}
                        setFieldValue={setFieldValue}
                        accept="image/*"
                        values={values?.files?.logo}
                        propField="files.logo"
                        fileType={FILE_TYPES.IMAGE}
                        url={imageUri}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Accordion>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="0"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        Configurations
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="card-body-dealer">
                        <Form.Group
                          controlId="componentBaseURL"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Components Base URL
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="componentBaseURL"
                            placeholder="Components Base Url"
                            value={
                              values?.componentBaseURL
                                ? values?.componentBaseURL
                                : ''
                            }
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="configurationType"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Config Type
                          </Form.Label>
                          <div className="col-sm-5 form-control-bordered padding-zero">
                            <Select
                              value={CONFIGOPTIONS.find(
                                (opt) => opt.value === values?.configurationType
                              )}
                              onChange={(configType: any) => {
                                setFieldValue(
                                  'configurationType',
                                  configType.value
                                );
                              }}
                              options={CONFIGOPTIONS}
                            />
                          </div>
                        </Form.Group>
                        {values?.configurationType ===
                          CONFIGURATION_TYPE.FIXED_JSON && (
                          <Form.Group
                            controlId="fixedConfigJson"
                            className="form-inline form-inline-dealer"
                          >
                            <Form.Label className="col-sm-3 mr-20 text-left">
                              Configurations Json
                            </Form.Label>
                            <Form.Control
                              className="col-sm-5 form-control-bordered jsonObject"
                              name="fixedConfigJson"
                              placeholder="Configurations Json"
                              value={values?.fixedConfigJson}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        )}
                        {values?.configurationType ===
                          CONFIGURATION_TYPE.FIXED_FILE && (
                          <Form.Group className="form-inline form-inline-dealer">
                            <Form.Label className="col-sm-3 mr-20 text-left">
                              Configurations File
                            </Form.Label>
                            <UploadComponent
                              setFieldValue={setFieldValue}
                              accept=".json"
                              values={values?.files?.configFile}
                              fileType={FILE_TYPES.FILE}
                              propField="files.configFile"
                              path={
                                values?.fixedConfigFile?.name ||
                                values?.files?.configFile[0]?.name
                              }
                            />
                          </Form.Group>
                        )}
                        {values?.configurationType ===
                          CONFIGURATION_TYPE.DYNAMIC_URL && (
                          <Form.Group
                            controlId="dynamicConfigUrlPath"
                            className="form-inline form-inline-dealer"
                          >
                            <Form.Label className="col-sm-3 mr-20 text-left">
                              Configurations Url
                            </Form.Label>
                            <Form.Control
                              className="col-sm-5 form-control-bordered"
                              name="dynamicConfigUrlPath"
                              disabled={
                                !(
                                  values?.configurationType ===
                                  CONFIGURATION_TYPE.DYNAMIC_URL
                                )
                              }
                              placeholder="Configurations Url"
                              value={
                                values?.dynamicConfigUrlPath
                                  ? values?.dynamicConfigUrlPath
                                  : ''
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="1"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        Dealer Contact
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <Form.Group
                          controlId="dealerContact.address1"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 1
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address1"
                            placeholder="Address 1"
                            value={values?.dealerContact?.address1}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.address2"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 2
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address2"
                            placeholder="Address 2"
                            value={values?.dealerContact?.address2}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.address3"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 3
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address3"
                            placeholder="Address 3"
                            value={values?.dealerContact?.address3}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.zipCode"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Zip Code
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.zipCode"
                            placeholder="Zip Code"
                            value={values?.dealerContact?.zipCode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.city"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            City
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.city"
                            placeholder="City"
                            value={values?.dealerContact?.city}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.district"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            District
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.district"
                            placeholder="District"
                            value={values?.dealerContact?.district}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.state"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            State
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.state"
                            placeholder="State"
                            value={values?.dealerContact?.state}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.country"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Country
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.country"
                            placeholder="Country"
                            value={values?.dealerContact?.country}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Phone
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.phone"
                            placeholder="Phone"
                            value={values?.dealerContact?.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Email
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.email"
                            placeholder="Email"
                            value={values?.dealerContact?.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Web Url
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.webUrl"
                            placeholder="Web Url"
                            value={values?.dealerContact?.webUrl}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="2"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        Support Contact
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <Form.Group
                          controlId="supportContact.address1"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 1
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.address1"
                            placeholder="Address 1"
                            value={values?.supportContact?.address1}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.address2"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 2
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.address2"
                            placeholder="Address 2"
                            value={values?.supportContact?.address2}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.address3"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 3
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.address3"
                            placeholder="Address 3"
                            value={values?.supportContact?.address3}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.zipCode"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Zip Code
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.zipCode"
                            placeholder="Zip Code"
                            value={values?.supportContact?.zipCode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.city"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            City
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.city"
                            placeholder="City"
                            value={values?.supportContact?.city}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.district"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            District
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.district"
                            placeholder="District"
                            value={values?.supportContact?.district}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.state"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            State
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.state"
                            placeholder="State"
                            value={values?.supportContact?.state}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.country"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Country
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.country"
                            placeholder="Country"
                            value={values?.supportContact?.country}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Phone
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.phone"
                            placeholder="Phone"
                            value={values?.supportContact?.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Email
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.email"
                            placeholder="Email"
                            value={values?.supportContact?.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Web Url
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.webUrl"
                            placeholder="Web Url"
                            value={values?.supportContact?.webUrl}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="3"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        Sales Contact
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <Form.Group
                          controlId="salesContact.address1"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 1
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.address1"
                            placeholder="Address 1"
                            value={values?.salesContact?.address1}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.address2"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 2
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.address2"
                            placeholder="Address 2"
                            value={values?.salesContact?.address2}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.address3"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Address 3
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.address3"
                            placeholder="Address 3"
                            value={values?.salesContact?.address3}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.zipCode"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Zip Code
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.zipCode"
                            placeholder="Zip Code"
                            value={values?.salesContact?.zipCode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.city"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            City
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.city"
                            placeholder="City"
                            value={values?.salesContact?.city}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.district"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            District
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.district"
                            placeholder="District"
                            value={values?.salesContact?.district}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.state"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            State
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.state"
                            placeholder="State"
                            value={values?.salesContact?.state}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.country"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Country
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.country"
                            placeholder="Country"
                            value={values?.salesContact?.country}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Phone
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.phone"
                            placeholder="Phone"
                            value={values?.salesContact?.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Email
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.email"
                            placeholder="Email"
                            value={values?.salesContact?.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            Web Url
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.webUrl"
                            placeholder="Web Url"
                            value={values?.salesContact?.webUrl}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Row className="mt-8">
                  <Col md={10} />
                  <Col md={2}>
                    <Button
                      dimension="lg"
                      type="submit"
                      className="search-btn create-button ml-10"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

DealersDetailView.defaultProps = {};
