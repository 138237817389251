import { createSlice } from '@reduxjs/toolkit';

export interface ILandingPagePreviewState {
  show: boolean;
}

export const initialState: ILandingPagePreviewState = {
  show: false,
};

export const previewLandingPageSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    show(state) {
      state.show = true;
    },
    close(state) {
      state.show = false;
    },
  },
});
