export function setLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function getLocalStorageItem(key: string) {
  return localStorage.getItem(key) || '';
}

export function setSessionStorage(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

export function getSessionStorageItem(key: string) {
  return sessionStorage.getItem(key) || '';
}
