import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IManagerMillActivity } from 'interfaces/Manager/user';
import { idsToLinks } from 'pages/Manager/Home';
import { MANAGER_ORDERS_VIEW_ROUTE } from 'constants/routes';
import moment from 'moment';

export const columns: ColumnDescription<any, IManagerMillActivity>[] = [
  {
    dataField: 'id',
    text: 'Mill Id',
    style: {
      width: '10%',
    },
    sort: true,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return b - a;
      }
      return a - b;
    },
  },
  {
    dataField: 'name',
    text: 'Name',
    style: {
      width: '20%',
    },
    sort: true,
  },
  {
    dataField: 'email',
    text: 'Mill Email',
    style: {
      width: '20%',
    },
    sort: true,
  },
  {
    dataField: 'projects',
    text: 'Project Ids',
    style: {
      width: '10%',
    },
    sort: false,
    formatter: (cell: any, row: IManagerMillActivity) => {
      if (!row?.projects?.length) return 'None';
      const linkedElements = idsToLinks(
        row.projects,
        `${MANAGER_ORDERS_VIEW_ROUTE}`,
        `${row.id}`,
        `&millId=${row.id}`
      );

      return <>{linkedElements}</>;
    },
  },
  {
    dataField: 'createdAt',
    text: 'Created At',
    style: {
      width: '15%',
    },
    formatter: (cell: any, row: IManagerMillActivity) => {
      return moment(row.createdAt).format('DD.MM.YYYY');
    },
    sort: true,
  },
];
