import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../api';
import { toastSlice } from '../slices';
import i18n from '../translations';
import { MILL_ORDERS_ROUTE, MILL_WELCOME_ROUTE } from '../constants/routes';

export interface IAddress {
  state?: string;
  street?: string;
  zipCode?: number;
  phone?: string;
  city?: string;
  country?: string;
}

export interface ICompanyDetail {
  id: number;
  name: string;
  address: IAddress;
  files?: any;
  logo?: any;
  email?: string;
  web?: string;
  contactPerson?: string;
  companyInfoIsUpdated?: boolean;
  ordersCount?: number;
  dataBaseStatusIsUpdated?: boolean;
  vatNumber?: string;
}

export interface IValidateVAT {
  countryCode?: string;
  vatNumber?: string;
}

export interface ILandingPage {
  id?: number;
  logo?: any;
  files?: any;
  landingPageHeader: string;
  landingPageContent: string;
  landingPageInfoIsUpdated?: boolean;
}
export const redirectRoute = (routeName: string, delay: number) => {
  setTimeout(() => {
    window.location.href = routeName;
  }, delay);
};

export const updateCompanyDetailsThunk = createAsyncThunk(
  'update/mill/company/details',
  async (payload: ICompanyDetail, { dispatch, rejectWithValue }) => {
    const { ordersCount, dataBaseStatusIsUpdated } = payload;
    try {
      const formData = new FormData();
      const logoIsSelected = !!payload.files?.length;
      if (logoIsSelected) {
        formData.append('files.logo', payload.files[0]);
      }

      formData.append('data', JSON.stringify({ ...payload, files: undefined }));
      const response = await Api.put(`/companies/${payload.id}`, formData);

      if (!dataBaseStatusIsUpdated) {
        redirectRoute(
          ordersCount ? MILL_ORDERS_ROUTE : MILL_WELCOME_ROUTE,
          1000
        );
      }

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('account.updateCompanyToast.title'),
          message: i18n.t('account.updateCompanyToast.message'),
        })
      );

      return response;
    } catch (err) {
      if (err.response.data.message.errors.includes('VAT')) {
        dispatch(
          toastSlice.actions.show({
            title: i18n.t('account.invalidVAT.title'),
            message: i18n.t('account.invalidVAT.message'),
          })
        );
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCompanyLandingPageDetailsThunk = createAsyncThunk(
  'update/mill/company/landing/page/details',
  async (payload: ILandingPage, { dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();
      const logoIsSelected = !!payload.files?.length;
      if (logoIsSelected) {
        formData.append('files.logo', payload.files[0]);
      }

      const {
        landingPageHeader,
        landingPageContent,
        landingPageInfoIsUpdated,
      } = payload;
      formData.append(
        'data',
        JSON.stringify({
          landingPageHeader,
          landingPageContent,
          landingPageInfoIsUpdated,
        })
      );
      const response = await Api.put(`/mills/${payload.id}`, formData);

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('account.updateCompanyToast.title'),
          message: i18n.t('account.updateCompanyToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
