import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetSlice, toastSlice } from 'slices';
import i18n from '../translations';
import Api from '../api';

export const projectSummaryReportThunk = createAsyncThunk(
  'project-summary',
  async (projectId: number | string, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get(`/reports/project/summary/${projectId}`);
      window.location.href = response;
      dispatch(resetSlice.actions.endLoading());
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('reports.download.success.title'),
          message: i18n.t('reports.download.success.message'),
        })
      );
      return response;
    } catch (err) {
      console.error(err);
      dispatch(resetSlice.actions.endLoading());
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('reports.download.error.title'),
          message: i18n.t('reports.download.error.message'),
        })
      );
      return rejectWithValue(err.response.data);
    }
  }
);
