import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APP_LANGUAGE } from '../constants/constants';
import { IApiAction, IApiState } from '../interfaces/api';
import { ITable } from '../interfaces/table';
import { IUser } from '../interfaces/user';
import { addUserThunk, editUserThunk, usersThunk } from '../thunks/users';

import {
  defaultTableState,
  filterSlice,
  paginationSlice,
  sortSlice,
} from '../utils/table';

export interface IAddUserState extends IApiState {
  user?: IUser;
}

export interface IUsersState extends ITable<IUser> {
  showAddUser: boolean;
  isEditMode: boolean;
  editingUser?: IUser;
  addUser: IAddUserState;
  selectedUserId?: number;
}

interface IEditMode {
  openInEditMode?: boolean;
  user?: IUser;
}

export const initialState: IUsersState = {
  showAddUser: false,
  isEditMode: false,
  editingUser: undefined,
  addUser: {},
  selectedUserId: undefined,
  ...defaultTableState(),
  sort: 'created_at:desc',
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    showAddUser(state, action: PayloadAction<IEditMode>) {
      state.showAddUser = true;
      state.isEditMode = !!action.payload.openInEditMode;
      state.editingUser = action.payload.user;
    },
    closeAddUser(state) {
      state.showAddUser = false;
      state.editingUser = undefined;
      state.isEditMode = false;
      state.addUser.error = undefined;
    },
    selectUser(state, action: PayloadAction<number>) {
      state.selectedUserId = action.payload;
    },
    deselectUser(state) {
      state.selectedUserId = undefined;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.sizePerPage = action.payload;
    },
    updateUserLanguage(state, action: PayloadAction<string>) {
      localStorage.setItem(APP_LANGUAGE, action.payload);
      window.location.reload();
    },
    ...paginationSlice<IUsersState>(),
    ...sortSlice<IUsersState>(),
    ...filterSlice<IUsersState>(),
  },
  extraReducers: {
    [addUserThunk.pending.type]: (state, action: IApiAction<any>) => {
      state.addUser.user = undefined;
      state.addUser.apiStatus = action.meta.requestStatus;
      state.addUser.error = undefined;
    },
    [addUserThunk.rejected.type]: (state, action: IApiAction<any>) => {
      state.addUser.user = undefined;
      state.addUser.apiStatus = action.meta.requestStatus;
      state.addUser.error = action.payload.data;
    },
    [addUserThunk.fulfilled.type]: (state, action: IApiAction<any>) => {
      state.addUser.user = action.payload;
      state.addUser.apiStatus = action.meta.requestStatus;
      state.addUser.error = undefined;
    },
    [editUserThunk.pending.type]: (state, action: IApiAction<any>) => {
      state.addUser.apiStatus = action.meta.requestStatus;
      state.addUser.error = undefined;
    },
    [editUserThunk.rejected.type]: (state, action: IApiAction<any>) => {
      state.addUser.apiStatus = action.meta.requestStatus;
      state.addUser.error = action.payload.data;
    },
    [editUserThunk.fulfilled.type]: (state, action: IApiAction<any>) => {
      const index = state.data.findIndex(
        (u) => u.id === action.payload.user.id
      );
      if (index !== -1) {
        state.data[index] = { ...state.data[index], ...action.payload.user };
      }
      state.addUser.apiStatus = action.meta.requestStatus;
      state.addUser.error = undefined;
    },
    [usersThunk.fulfilled.type]: (
      state,
      action: PayloadAction<ITable<IUser>>
    ) => {
      state.count = action.payload.count;
      state.data = action.payload.data;
    },
  },
});
