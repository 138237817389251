import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserLayout } from 'components/Layout';
import { TabsControl } from 'components/TabsControl';
import { ITabConfig } from 'components/TabsControl/TabsControl';
import {
  LAB_ACCOUNT_COMPANY_INFO_ROUTE,
  LAB_ACCOUNT_SETTINGS_ROUTE,
  LAB_ACCOUNT_PROFILE_ROUTE,
} from 'constants/routes';
import { useQuery } from 'hooks/query';
import { SharedAccountSettings } from 'pages/Shared';
import i18n from 'translations';
import { AccountFormContainer } from 'pages/Mill/Account/AccountFormContainer';
import { CompanyInfoFormContainer } from './CompanyInfoFormContainer';

const tabs: ITabConfig[] = [
  {
    eventKey: 'company-info',
    title: i18n.t('account.tabs.company'),
    children: (
      <Row>
        <Col lg={{ span: 5 }}>
          <CompanyInfoFormContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'profile',
    title: i18n.t('account.tabs.profile'),
    children: (
      <Row>
        <Col lg={{ span: 4 }}>
          <p className="fs-20 lh-26 mb-40">{i18n.t('account.profile.info')}</p>
          <AccountFormContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'settings',
    title: i18n.t('account.tabs.settings'),
    children: (
      <Row>
        <Col lg={{ span: 12 }}>
          <p className="fs-20 lh-26 mb-60">{i18n.t('account.settings.info')}</p>
          <SharedAccountSettings />
        </Col>
      </Row>
    ),
  },
];

export const TabsContainer = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();

  const handleTabChange = (eventKey: any) => {
    if (eventKey === 'company-info') {
      history.push(LAB_ACCOUNT_COMPANY_INFO_ROUTE);
    } else if (eventKey === 'profile') {
      history.push(LAB_ACCOUNT_PROFILE_ROUTE);
    } else if (eventKey === 'settings') {
      history.push(LAB_ACCOUNT_SETTINGS_ROUTE);
    }
  };

  return (
    <UserLayout mainClasses="page page-tabs page-account flex-column">
      <div className="page-header py-60 py-md-70 px-20 pl-md-126 pr-md-54">
        <Container fluid>
          <Row>
            <Col>
              <h1 className="fs-44 mb-88">{t('account.title')}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="px-20 pl-md-126 pr-md-54">
        <TabsControl
          activeKey={query.get('tab') || 'company-info'}
          id="account"
          tabs={tabs}
          onTabChange={handleTabChange}
        />
      </div>
    </UserLayout>
  );
};
