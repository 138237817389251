import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../api';

export interface ILicenseContent {
  ticketId: string;
  url: string;
  baseURL: string | undefined;
}

export const licenseContentThunk = createAsyncThunk(
  'license-content',
  async (licenseContent: ILicenseContent, { rejectWithValue }) => {
    try {
      return Api.post(
        licenseContent.url,
        { ticketId: licenseContent.ticketId },
        { baseURL: licenseContent.baseURL }
      );
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);
