import React from 'react';
import { Select } from 'components';
import { IOption } from 'components/Select';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import i18n from 'translations';
import { updateUserSettingsThunk } from 'thunks/account';
import { getCountriesList, getSelectedCountry } from 'utils/countries';

const PROJECTFILESDOWNLOADOPTIONS = [
  { value: 'flat', label: i18n.t('project.download.flat') },
  { value: 'hierarchical', label: i18n.t('project.download.hierarchical') },
];

export const ProjectUserPrefsContainer = () => {
  const dispatch = useAppDispatch();
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );

  function handleChange(option: IOption) {
    dispatch(
      updateUserSettingsThunk({
        projectDownloadFolderStructure: option.value,
      })
    );
  }

  function handleChangeDateFormat(option: IOption) {
    dispatch(
      updateUserSettingsThunk({
        reportDateFormat: option.value,
      })
    );
  }

  return (
    <>
      <div className="mb-10">
        {i18n.t('account.settings.project.folderStructure')}
      </div>
      <div className="select-wrapper mb-10">
        <Select
          options={PROJECTFILESDOWNLOADOPTIONS}
          onChange={handleChange}
          defaultValue={
            userSettings.projectDownloadFolderStructure &&
            PROJECTFILESDOWNLOADOPTIONS.find(
              (opt) => opt.value === userSettings.projectDownloadFolderStructure
            )
          }
        />
      </div>
      <div className="mb-10">{i18n.t('reports.dateFormat')} </div>
      <div className="select-wrapper mb-10">
        <Select
          options={getCountriesList()}
          onChange={handleChangeDateFormat}
          defaultValue={getSelectedCountry(
            userSettings.reportDateFormat || 'de'
          )}
          menuPlacement="top"
        />
      </div>
    </>
  );
};
