import React from 'react';
import { Spinner as BsSpinner } from 'react-bootstrap';

export const Spinner: React.FC = () => (
  <div className="spinner">
    <BsSpinner animation="border" variant="primary" role="status">
      <span className="sr-only">Loading...</span>
    </BsSpinner>
  </div>
);
