import React from 'react';
import { Pagination as BsPagination } from 'react-bootstrap';

import { IPagination } from '../../interfaces/table';

interface IPaginationProps extends IPagination {
  count: number;
  onPageClick: (num: number) => void;
  onPrevClick: () => void;
  onNextClick: () => void;
}

export const Pagination = ({
  page,
  sizePerPage,
  count = 0,
  onPageClick,
  onPrevClick,
  onNextClick,
}: IPaginationProps) => {
  const totalPages = Math.ceil(count / sizePerPage);

  const renderItems = () => {
    const items = [];
    for (let i = 1; i <= totalPages; i += 1) {
      items.push(
        <BsPagination.Item
          key={i}
          active={page === i}
          onClick={() => onPageClick(i)}
        >
          {i}
        </BsPagination.Item>
      );
    }
    return items;
  };

  return (
    <>
      {totalPages > 1 && (
        <BsPagination>
          {page > 1 && <BsPagination.Prev onClick={onPrevClick} />}
          {renderItems()}
          {totalPages > page && <BsPagination.Next onClick={onNextClick} />}
        </BsPagination>
      )}
    </>
  );
};
