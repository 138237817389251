import React from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordFormContainer } from '../PasswordFormContainer';

export const SettingsFirstColumn = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-60">
        <h4 className="mb-26">{t('account.settings.password.title')}</h4>
        <PasswordFormContainer />
      </div>
    </>
  );
};
