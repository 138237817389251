import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Button, Modal } from '../../../components';
import { IActivationPayload } from '../../../thunks/clients';

export interface IActivationProps {
  show: boolean;
  initialValues: IActivationPayload;
  validationSchema: any | (() => any);
  onClose: () => void;
  onCopyMessage: (message: string) => void;
  onSubmit: (
    values: IActivationPayload,
    form: FormikHelpers<IActivationPayload>
  ) => void | Promise<any>;
}

export const ActivationModal: React.FC<IActivationProps> = ({
  show,
  validationSchema,
  initialValues,
  onClose,
  onCopyMessage,
  onSubmit,
}: IActivationProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('clients.activation.title')}
      show={show}
      onClose={onClose}
      dialogClassName="modal-activation"
      data-cy="activation-instructions-modal"
    >
      <p className="text-center mb-40">{t('clients.activation.info')}</p>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="message" className="mb-60">
                  <Form.Label>
                    {t('clients.activation.message.label')}
                  </Form.Label>
                  <Form.Control
                    name="message"
                    placeholder={t('clients.activation.message.placeholder')}
                    value={values.message}
                    onChange={handleChange}
                    as="textarea"
                    rows={12}
                    isInvalid={touched.message && !!errors.message}
                  />
                </Form.Group>
                <div className="text-right">
                  <Button
                    dimension="lg"
                    onClick={() => onCopyMessage(values.message)}
                    className="mr-20"
                    variant="outline-primary"
                    data-cy="copy-activation-msg"
                  >
                    {t('clients.activation.copy')}
                  </Button>
                  <Button dimension="lg" type="submit">
                    {t('clients.activation.send')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
