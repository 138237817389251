import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserLayout } from 'components/Layout';
import { ITabConfig } from 'components/TabsControl/TabsControl';
import { TabsControl } from 'components/TabsControl';
import { useQuery } from 'hooks/query';
import {
  MILL_SETTINGS_COMPANY_DETAILS_ROUTE,
  MILL_SETTINGS_LANDING_PAGE_ROUTE,
  MILL_SETTINGS_USERS_ROUTE,
  MILL_SETTINGS_USERS_LICENSE_KEYS,
} from 'constants/routes';
import i18n from 'translations';
import { CompanyDetailContainer } from './CompanyDetail/CompanyDetailContainer';
import { UsersTableContainer } from './Users';
import { LandingPageContainer } from './LandingPage/LandingPageContainer';
import { LicenseKeysTableContainer } from './LicenseKeys';

const tabs: ITabConfig[] = [
  {
    eventKey: 'landing-page',
    title: i18n.t('settings.tabs.landingPage'),
    children: (
      <Row>
        <Col lg={{ span: 7 }}>
          <LandingPageContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'company-details',
    title: i18n.t('settings.tabs.companyDetails'),
    children: (
      <Row className="page-account">
        <Col lg={{ span: 5 }}>
          <CompanyDetailContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'users',
    title: i18n.t('settings.tabs.users'),
    children: (
      <Row>
        <Col>
          <UsersTableContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'license-keys',
    title: i18n.t('settings.tabs.licenseKeys'),
    children: (
      <Row>
        <Col>
          <LicenseKeysTableContainer />
        </Col>
      </Row>
    ),
  },
];

export const SettingsContainer = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();

  const handleTabChange = (eventKey: any) => {
    if (eventKey === 'landing-page') {
      history.push(MILL_SETTINGS_LANDING_PAGE_ROUTE);
    } else if (eventKey === 'company-details') {
      history.push(MILL_SETTINGS_COMPANY_DETAILS_ROUTE);
    } else if (eventKey === 'users') {
      history.push(MILL_SETTINGS_USERS_ROUTE);
    } else if (eventKey === 'license-keys') {
      history.push(MILL_SETTINGS_USERS_LICENSE_KEYS);
    }
  };

  return (
    <UserLayout mainClasses="page page-tabs flex-column">
      <div className="page-header py-60 py-md-70 px-20 pl-md-126 pr-md-54">
        <Container fluid>
          <Row>
            <Col>
              <h1 className="fs-44 mb-88">{t('settings.title')}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="px-20 pl-md-126 pr-md-54">
        <TabsControl
          activeKey={query.get('tab') || 'landing-page'}
          id="mill-settings"
          tabs={tabs}
          onTabChange={handleTabChange}
        />
      </div>
    </UserLayout>
  );
};
