import React from 'react';
import * as yup from 'yup';

import { isValidUserConsent } from 'components/Modal/AddonSelection/ForcedPolicyConsent';
import { Signup } from './Signup';
import { signinSlice, signupSlice, userConsentSlice } from '../../../slices';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ISignupPayload, registerThunk } from '../../../thunks/auth';
import { Confirmation } from '../../Modal/Confirmation';

const schema: yup.SchemaOf<ISignupPayload> = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  activationKey: yup.string().required(),
  promotions: yup.boolean().required(),
  policyAgreement: yup.boolean().optional(),
  dataProcessingAgreement: yup.boolean().optional(),
});

export const SignupContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.signup);
  const { error } = useAppSelector((state) => state.register);
  const { policyIsChecked, dataProcessingIsChecked } = useAppSelector(
    (state) => state.userConsent
  );

  const handleSubmit = (values: ISignupPayload) => {
    if (!isValidUserConsent(dispatch, policyIsChecked, dataProcessingIsChecked))
      return;

    dispatch(
      registerThunk({
        ...values,
        policyAgreement: policyIsChecked,
        dataProcessingAgreement: dataProcessingIsChecked,
        promotions: !values.promotions,
      })
    );
  };

  const handleSignin = () => {
    dispatch(signupSlice.actions.close());
    dispatch(signinSlice.actions.show());
  };

  const handleClose = () => {
    dispatch(userConsentSlice.actions.selectPolicy(null));
    dispatch(userConsentSlice.actions.selectDataProcessing(null));
    dispatch(signupSlice.actions.close());
  };

  return (
    <>
      <Signup
        show={show}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onSignin={handleSignin}
        validationSchema={schema}
        initialValues={
          {
            firstName: '',
            lastName: '',
            email: '',
            activationKey: '',
            promotions: false,
            policyAgreement: false,
            dataProcessingAgreement: false,
          } as ISignupPayload
        }
        error={error}
      />
      <Confirmation />
    </>
  );
};
