import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Autolinker from 'autolinker';

import { IMessage } from 'interfaces/component';
import { AvatarImage } from 'components/User';
import { simpleFormat } from 'utils/format';
import { CompanyLogo } from 'components/User/CompanyLogo';

interface IMessagesProps {
  messages?: IMessage[];
}

export const Messages = ({ messages = [] }: IMessagesProps) => {
  const { t } = useTranslation();
  const AutoLinkerRef = React.useRef(
    new Autolinker({
      newWindow: false,
    })
  );

  return (
    <ul className="messages list-unstyled">
      {Boolean(messages.length) &&
        messages.map((message) => (
          <li key={message.id} className="d-flex">
            <div className="avatar mr-40">
              {message.author?.avatar && <AvatarImage user={message.author} />}
              {!message.author?.avatar && (
                <CompanyLogo
                  logo={message.author?.company?.logo}
                  isMillRole={!!message.author?.millRole}
                />
              )}
            </div>
            <div
              className={`${
                message.author &&
                ((message.author.role as any) === 3 ||
                  message.author.role?.id === 3)
                  ? 'message-content-dentist bg-lightgreen'
                  : 'message-content bg-white'
              }  mb-20 p-24`}
            >
              <div className="mb-16">
                {message.author && (
                  <span className="fw-800 mr-10 fs-18 lh-28">
                    {`${message.author.firstName} ${message.author.lastName}`}
                  </span>
                )}
                <span className="color-gray fs-16 lh-24">
                  {moment(message.created_at).format(
                    t('formats.date.describe')
                  )}
                </span>
              </div>
              <div
                className="color-gray-dark"
                dangerouslySetInnerHTML={{
                  __html: simpleFormat(
                    AutoLinkerRef.current.link(message.message)
                  ),
                }}
              />
            </div>
          </li>
        ))}
    </ul>
  );
};

Messages.defaultProps = {
  messages: [],
};
