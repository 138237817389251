import React from 'react';

import avatarMillPlaceholder from '../../assets/images/avatar-mill.svg';
import avatarLabPlaceholder from '../../assets/images/avatar-lab.svg';

interface ICompanyLogoProps {
  logo?: any;
  isMillRole?: boolean;
}

export const CompanyLogo = ({
  logo = undefined,
  isMillRole,
}: ICompanyLogoProps) => {
  const placeholder = isMillRole ? avatarMillPlaceholder : avatarLabPlaceholder;
  const logoUrl = logo && logo.url ? logo.url : placeholder;
  const companyLogo = typeof logo === 'string' ? logo : logoUrl;

  return <img src={companyLogo} className="avatar-image" alt="" />;
};

CompanyLogo.defaultProps = {
  logo: undefined,
  isMillRole: true,
};
