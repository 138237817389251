import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import de from './de';
import it from './it';
import sp from './sp';
import pl from './pl';
import fr from './fr';
import { APP_DEFAULT_LANGUAGE, APP_LANGUAGE } from '../constants/constants';

i18n.use(initReactI18next).init({
  resources: {
    de: {
      translation: de,
    },
    it: {
      translation: it,
    },
    fr: {
      translation: fr,
    },
    sp: {
      translation: sp,
    },
    pl: {
      translation: pl,
    },
    en: {
      translation: en,
    },
  },
  fallbackLng: APP_DEFAULT_LANGUAGE,
});

const lang = localStorage.getItem(APP_LANGUAGE);
if (lang) {
  i18n.changeLanguage(lang);
}

declare global {
  interface Window {
    i18n: any;
  }
}

window.i18n = i18n;

export default i18n;
