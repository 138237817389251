import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import { PublicLayout } from '../../components/Layout';

export const Contact: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout mainClasses="page page-contact">
      <Container className='content-container'>
        <Row className='public-page'>
          <Col className='public-page-col'>
            <h1 className="mt-52 mb-58 fs-34">{t('footer.menu.contact.title')}</h1>
            <div>
              <Trans>
                {t('footer.menu.contact.content')}
              </Trans>
            </div>
          </Col>
        </Row>
      </Container>
    </PublicLayout>
  );

};
