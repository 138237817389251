/* eslint-disable no-param-reassign */
import { IManagerProject } from 'interfaces/Manager/user';
import { getFormattedDate } from 'utils/common';

interface ISumStats {
  componentsCount: number;
  documentsCount: number;
  documentsSize: number;
}

const getInitialStats = () => {
  return {
    componentsCount: 0,
    documentsCount: 0,
    documentsSize: 0,
  } as ISumStats;
};

const getSumStats = (data: any) => {
  return data.reduce((result: ISumStats, nextObj: any) => {
    result.componentsCount += nextObj.componentsCount || 0;
    result.documentsCount += nextObj.documentsCount || 0;
    result.documentsSize += nextObj.documentsSize || 0;
    return result;
  }, getInitialStats());
};

export const getColumns = (data: any) => {
  const sumStats = getSumStats(data);
  return [
    {
      dataField: 'id',
      text: 'Project Id',
    },
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      style: {
        width: '200px',
      },
      formatter: (cell: any, row: IManagerProject) => {
        return getFormattedDate(row.createdAt);
      },
    },
    {
      dataField: 'componentsCount',
      text: `Components Count (${sumStats.componentsCount})`,
    },
    {
      dataField: 'documentsCount',
      text: `Documents Count(${sumStats.documentsCount})`,
    },
    {
      dataField: 'documentsSize',
      text: `Documents Size ${(sumStats.documentsSize / 1000).toFixed(3)} (GB)`,
      formatter: (cell: any, row: IManagerProject) => {
        return `${row.documentsSize ? row.documentsSize.toFixed(2) : 0} MB`;
      },
    },
  ];
};
