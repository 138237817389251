import { createSlice } from '@reduxjs/toolkit';
import { IApiAction, IApiState } from 'interfaces/api';
import { defaultAsyncExtraReducers } from 'thunks/helpers';
import { activePolicyDocsThunk } from 'thunks/policy-documents';

export interface IActivePolicyDocument {
  id: number;
  termsOfServiceDocENSignedUrl: string;
  termsOfServiceDocDESignedUrl: string;
  dataProcessingPolicyDocENSignedUrl: string;
  dataProcessingPolicyDocDESignedUrl: string;
}

export interface IActivePolicyDocState extends IApiState {
  data?: IActivePolicyDocument;
}

export const initialState: IActivePolicyDocState = {};

export const activePolicyDocSlice = createSlice({
  name: 'activePolicyDocuments',
  initialState,
  reducers: {},
  extraReducers: {
    ...defaultAsyncExtraReducers(activePolicyDocsThunk),
    [activePolicyDocsThunk.rejected.type]: (
      state,
      action: IApiAction<IApiState>
    ) => {
      state.error = action.error;
    },
  },
});
