export const OPENER_IS_WIN_APP = '@hyperflow:openerIsWinApp';
export const APP_LANGUAGE = '@hyperflow:language';
export const API_TOKEN = '@hyperflow:token';
export const SELECTED_MILL = '@hyperflow:selectedMillId';
export const APP_DEFAULT_LANGUAGE = 'en';
export const TAB_FORM_IS_DIRTY = '@hyperflow:tabFormIsDirty';
export const ANALYTICS_PAGE_SIZE = '@ana:pageSize';
export const LICENSE_CONTENT_SEARCH = '@license:search';

export const SUPPORT_EMAIL_TO = 'support@hyperdentconnect.net';

export const SUBSCRIPTION_PLANS = {
  BASIC: 'basic',
  ESSENTIAL: 'essential',
  ENTERPRISE: 'enterprise',
  CUSTOM: 'custom',
};

export const CUSTOM_PLAN = {
  LOOKUP_KEY: 'pr_custom_plan',
};

export const COMPONENT_UPDATE_PHASE = {
  NONE: 'None',
  UPLOADING: 'Uploading',
  UPLOADED: 'Uploaded',
  DOWNLOADING: 'Downloading',
  DOWNLOADED: 'Downloaded',
};

export const MESSAGETYPE = {
  PROJECT_CREATED: 'ProjectCreated',
  MESSAGE_RECEIVED: 'MessageReceived',
  CLIENT_INVITATION: 'ClientInvitation',
};

export const EUROPEAN_UNION = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LT', // Lithuania
  'LU', // Luxembourg
  'LV', // Latvia
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SE', // Sweden
  'SI', // Slovenia
  'SK', // Slovakia
];

export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_OPTIONS = [5, 10, 15, 20, 25, 50, 100];
export const POLICY_LANG_OPTIONS = {
  ENGLISH: 'EN',
  DEUTSCH: 'DE',
};
export const LICENSE_TABS = {
  ACTIVE: 'active-licenses',
  ORDERED: 'ordered-licenses',
  DETAILS: 'license-details',
};
export const FILE_TYPES = {
  IMAGE: 'image',
  FILE: 'file',
};

export const CONFIGURATION_TYPE = {
  NONE: 'None',
  FIXED_JSON: 'FixedJson',
  FIXED_FILE: 'FixedFile',
  DYNAMIC_URL: 'DynamicUrl',
};
