import React from 'react';
import * as yup from 'yup';

import { clientsSlice } from '../../../slices';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addClientThunk, IClientPayload } from '../../../thunks/clients';
import { ClientForm } from './ClientForm';

const schema: yup.SchemaOf<IClientPayload> = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  landingPageInfoIsUpdated: yup.boolean().optional(),
});

export const ClientFormContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.clients.addClient?.error);
  const showAddClient = useAppSelector((state) => state.clients.showAddClient);

  const handleSubmit = (values: IClientPayload) => {
    dispatch(addClientThunk(values));
  };

  return (
    <ClientForm
      show={showAddClient}
      onClose={clientsSlice.actions.closeAddClient}
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={
        {
          firstName: '',
          lastName: '',
          email: '',
        } as IClientPayload
      }
      error={error}
    />
  );
};
