import React from 'react';

export const ContactUsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 6.5H6.5C5.39543 6.5 4.5 7.39543 4.5 8.5V15.5C4.5 16.6046 5.39543 17.5 6.5 17.5H17.5C18.6046 17.5 19.5 16.6046 19.5 15.5V8.5C19.5 7.39543 18.6046 6.5 17.5 6.5ZM6 8.5C6 8.22386 6.22386 8 6.5 8H17.5C17.7761 8 18 8.22386 18 8.5V8.59825L13.0614 11.405C12.3721 11.7968 11.5273 11.7968 10.838 11.405L6 8.65543V8.5ZM6 10.3808V15.5C6 15.7761 6.22386 16 6.5 16H17.5C17.7761 16 18 15.7761 18 15.5V10.3236L13.8026 12.7091C12.6536 13.3621 11.2458 13.3621 10.0968 12.7091L6 10.3808Z"
      fill="#FFFFFF"
    />
  </svg>
);
