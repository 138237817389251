import { createSlice } from '@reduxjs/toolkit';
import { IApiState } from '../interfaces/api';
import { IUser } from '../interfaces/user';
import { registerThunk } from '../thunks/auth';
import { defaultAsyncExtraReducers } from '../thunks/helpers';

export interface IRegisterState extends IApiState {
  data?: {
    user: IUser;
  };
}

export const initialState: IRegisterState = {
  data: {
    user: {} as IUser,
  },
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: {
    ...defaultAsyncExtraReducers(registerThunk),
    'signup/close': (state: any) => {
      return {
        ...state,
        error: undefined,
      };
    },
  },
});
