import { createSlice } from '@reduxjs/toolkit';
import { fetchAppVersion } from 'thunks/app-version';
import { IApiState } from '../interfaces/api';

export interface IAppVersionState extends IApiState {
  version: string;
}

export const initialState: IAppVersionState = {
  version: '',
};

export const appVersionSlice = createSlice({
  name: 'appVersion',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAppVersion.pending.type]: (state) => {
      return {
        ...state,
        version: '',
        error: undefined,
      };
    },
    [fetchAppVersion.fulfilled.type]: (state, action: any) => {
      return {
        ...state,
        version: action.payload.version,
        error: undefined,
      };
    },
    [fetchAppVersion.rejected.type]: (state, action: IAppVersionState) => {
      return {
        ...state,
        error: action.error,
      };
    },
  },
});
