import React from 'react';

export const CameraIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9.05C9.92893 9.05 8.25 10.7289 8.25 12.8C8.25 14.8711 9.92893 16.55 12 16.55C14.0711 16.55 15.75 14.8711 15.75 12.8C15.75 10.7289 14.0711 9.05 12 9.05ZM9.75 12.8C9.75 11.5574 10.7574 10.55 12 10.55C13.2426 10.55 14.25 11.5574 14.25 12.8C14.25 14.0426 13.2426 15.05 12 15.05C10.7574 15.05 9.75 14.0426 9.75 12.8Z"
      fill="#393F4B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0541 4.25C9.30084 4.25 8.6321 4.732 8.3939 5.4466L8.1261 6.25H6C4.48122 6.25 3.25 7.48122 3.25 9V17C3.25 18.5188 4.48122 19.75 6 19.75H18C19.5188 19.75 20.75 18.5188 20.75 17V9C20.75 7.48122 19.5188 6.25 18 6.25H15.8739L15.6061 5.4466C15.3679 4.732 14.6992 4.25 13.9459 4.25H10.0541ZM9.81692 5.92094C9.85095 5.81886 9.94648 5.75 10.0541 5.75H13.9459C14.0535 5.75 14.1491 5.81886 14.1831 5.92094L14.7928 7.75H18C18.6904 7.75 19.25 8.30964 19.25 9V17C19.25 17.6904 18.6904 18.25 18 18.25H6C5.30964 18.25 4.75 17.6904 4.75 17V9C4.75 8.30964 5.30964 7.75 6 7.75H9.20724L9.81692 5.92094Z"
      fill="#393F4B"
    />
  </svg>
);
