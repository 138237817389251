import { CompanyLogo } from 'components/User/CompanyLogo';
import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IOption } from 'components/Select';
import { IUser } from 'interfaces/user';
import i18n from 'translations';

export const statusOptions: IOption[] = [
  { value: '', label: i18n.t('clients.status.any') },
  { value: true, label: i18n.t('clients.status.active') },
  { value: false, label: i18n.t('clients.status.inactive') },
];

export const columns: ColumnDescription<any, IUser>[] = [
  {
    dataField: 'avatar',
    text: '',
    sort: false,
    style: {
      width: '72px',
      height: '72px',
    },
    formatter: (cell: any, row: IUser) => {
      return <CompanyLogo logo={row?.company?.logo} isMillRole={false} />;
    },
  },
  {
    dataField: 'labName',
    text: i18n.t('clients.columns.lab'),
    formatter: (cell, row: IUser) => {
      return <>{row?.company && row?.company?.name}</>;
    },
    sort: true,
  },
  {
    dataField: 'email',
    text: i18n.t('clients.columns.email'),
    sort: true,
  },
  {
    dataField: 'firstName',
    text: i18n.t('clients.columns.contactPerson'),
    formatter: (cell, row: IUser) => {
      return <>{`${row?.company?.contactPerson || '---'} `}</>;
    },
    sort: true,
  },
  {
    dataField: 'confirmed',
    text: i18n.t('clients.columns.status'),
    formatter: (cell, row: IUser) => {
      return (
        <>
          {row.confirmed
            ? i18n.t('clients.status.active')
            : i18n.t('clients.status.inactive')}
        </>
      );
    },
    sort: true,
  },
  {
    dataField: 'actions',
    text: '',
    isDummyField: true,
  },
];
