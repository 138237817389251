import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { authSlice, signinSlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppDispatch } from 'store/store';
import { Invitation } from './Invitation';

export const InvitationContainer: React.FC = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const { showAccountCreation } = useAppSelector((state) => state.auth);
  const { company, id: millId } = useAppSelector((state) => state.mill.data);
  const name = company?.name;
  const location = useLocation();
  const { millId: invitedMillId } = useParams() as any;
  const isLabUser = location.pathname.includes('/lab/home');

  const onClose = () => {
    dispatch(authSlice.actions.closeAccountCreation());
  };

  const onConnectToAnExistingAccount = () => {
    dispatch(authSlice.actions.closeAccountCreation());
    dispatch(
      signinSlice.actions.showWithoutSignUp({
        linkToExistingAccount: true,
        invitedMillId: isLabUser ? invitedMillId : millId,
      })
    );
  };
  return (
    <Invitation
      show={showAccountCreation}
      onClose={onClose}
      millName={name}
      onConnectToAnExistingAccount={onConnectToAnExistingAccount}
    />
  );
};
