import { Confirmation } from 'components/Modal';
import { CUSTOM_PLAN, SUBSCRIPTION_PLANS } from 'constants/constants';
import { ContactUsIcon, PlusIcon } from 'icons';
import React, { useRef } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  IContractType,
  ICustomContract,
  IPromotion,
} from 'thunks/contract-type';
import { IAddOn } from 'thunks/payments';
import moment from 'moment';
import i18n from 'translations';
import { getPromotionalPrice } from 'components/Modal/AddonSelection/AddonSelection';

interface IContractTypeProps {
  types?: [IContractType];
  customContract?: ICustomContract;
  subscribedContractType?: IContractType;
  subscribe: (
    lookupKey: string,
    isAnUpdate: boolean,
    isResumingSubscription: boolean
  ) => void;
  unsubscribe: () => void;
  handleCancelConfirmation: () => void;
  showAddonModal: () => void;
  filesKeepDurationKey: string;
  addOns: Array<IAddOn>;
  subscriptionCancelDate?: number;
  activePromotion?: IPromotion;
}

interface ICustomButton {
  t: any;
  handleCancelConfirmation: () => void;
  subscribe: (
    lookupKey: string,
    isAnUpdate: boolean,
    isResumingSubscription: boolean
  ) => void;
  contractType?: IContractType;
  customContract?: ICustomContract;
}

const CustomContractAction: React.FC<ICustomButton> = ({
  contractType,
  t,
  customContract,
  handleCancelConfirmation,
  subscribe,
}: ICustomButton) => {
  const ref = useRef<any>();
  return (
    <>
      {customContract && (
        <Button
          onClick={() => {
            if (contractType?.id === customContract?.id) {
              handleCancelConfirmation();
            } else {
              subscribe(
                CUSTOM_PLAN.LOOKUP_KEY,
                contractType?.name !== SUBSCRIPTION_PLANS.CUSTOM &&
                  contractType?.name !== SUBSCRIPTION_PLANS.BASIC,
                false
              );
            }
          }}
        >
          {contractType?.name === 'custom'
            ? t(`contract.tabs.contractType.unsubscribe`)
            : t(`contract.tabs.contractType.subscribe`)}
        </Button>
      )}

      <Button
        className="ml-10"
        title={t(`support.contact.title`)}
        onClick={() => {
          ref.current.click();
        }}
      >
        <a ref={ref} href="mailto:support@hyperdentconnect.net">
          {!customContract && t(`support.contact.title`)}
          {customContract && <ContactUsIcon />}
        </a>
      </Button>
    </>
  );
};
CustomContractAction.defaultProps = {
  customContract: undefined,
  contractType: undefined,
};

export const ContractType: React.FC<IContractTypeProps> = ({
  types,
  subscribe,
  unsubscribe,
  subscribedContractType,
  handleCancelConfirmation,
  customContract,
  showAddonModal,
  filesKeepDurationKey,
  addOns,
  subscriptionCancelDate,
  activePromotion,
}: IContractTypeProps) => {
  const { t } = useTranslation();
  const cardWidth = 100 / (types?.length ?? 3);

  const basicType = types?.find(
    (type) => type.name === SUBSCRIPTION_PLANS.BASIC
  );
  const customType = types?.find(
    (type) => type.name === SUBSCRIPTION_PLANS.CUSTOM
  );
  const selectedAddOn = addOns?.find(
    (type) => type.key === filesKeepDurationKey
  );

  const cancelAtPeriodEnd = !!subscriptionCancelDate;

  return (
    <>
      {activePromotion?.description && (
        <div className="p-4 bg-danger text-white text-uppercase d-flex justify-content-center">
          <h5 className="text-white">{activePromotion?.description}</h5>
        </div>
      )}
      <div className="d-flex w-100 justify-content-center page-contract-type">
        {types?.map((type) => (
          <Card
            key={type.id}
            style={{ width: `${cardWidth}%`, alignItems: 'center' }}
          >
            <Card.Body>
              <Card.Title
                style={{
                  backgroundColor:
                    subscribedContractType?.id === type.id ? 'cyan' : '',
                }}
                className="text-uppercase fs-30 d-flex justify-content-center"
              >
                <span>{t(`contract.tabs.contractType.${type.name}`)}</span>
              </Card.Title>
              <Card.Subtitle className="mb-0 text-muted d-flex justify-content-center">
                {activePromotion?.description &&
                type.name !== 'custom' &&
                type.name !== 'basic' ? (
                  <span>
                    <small className="mr-10">
                      <del>
                        {`€${
                          type.name !== 'custom'
                            ? type.price
                            : customContract?.price || 'X'
                        }/${t('contract.tabs.contractType.month')}`}
                      </del>
                    </small>
                    <span className="text-danger">
                      {`€${getPromotionalPrice(
                        type.price,
                        activePromotion
                      )}/${t('contract.tabs.contractType.month')}`}
                    </span>
                  </span>
                ) : (
                  <span>
                    <div>
                      {`€${
                        type.name !== 'custom'
                          ? type.price
                          : customContract?.price || 'X'
                      }/${t('contract.tabs.contractType.month')}`}
                    </div>
                  </span>
                )}
              </Card.Subtitle>
              {subscribedContractType?.id === type.id &&
              !!selectedAddOn?.price ? (
                <div className="mb-2 fs-14 text-muted d-flex justify-content-center">
                  <small className={activePromotion ? 'text-danger' : ''}>
                    {`+ €${getPromotionalPrice(
                      selectedAddOn?.price,
                      activePromotion
                    )}/${t('contract.tabs.contractType.month')}`}
                  </small>
                  &nbsp;
                </div>
              ) : (
                <div className="mb-2 fs-14 text-muted d-flex justify-content-center">
                  &nbsp;
                </div>
              )}
              {subscribedContractType?.id === type.id && cancelAtPeriodEnd ? (
                <div className="mb-2 fs-14 text-muted d-flex justify-content-center">
                  <small>{`${t(
                    'contract.tabs.contractType.cancelsAt'
                  )}  ${moment(subscriptionCancelDate).format(
                    i18n.t('formats.date.short')
                  )}`}</small>
                  &nbsp;
                </div>
              ) : (
                <div className="mb-2 fs-14 text-muted d-flex justify-content-center">
                  &nbsp;
                </div>
              )}
              <hr />
              <div className="d-flex justify-content-center">
                {!['custom'].includes(type.name) && (
                  <Button
                    className="px-40"
                    disabled={[SUBSCRIPTION_PLANS.BASIC].includes(type.name)}
                    onClick={() => {
                      if (
                        type.id === subscribedContractType?.id &&
                        !cancelAtPeriodEnd
                      ) {
                        handleCancelConfirmation();
                      } else if (
                        type.id === subscribedContractType?.id &&
                        cancelAtPeriodEnd
                      ) {
                        subscribe(type.lookupKey, false, cancelAtPeriodEnd);
                      } else {
                        subscribe(
                          type.lookupKey,
                          basicType?.id !== subscribedContractType?.id &&
                            customType?.id !== subscribedContractType?.id &&
                            type.id !== subscribedContractType?.id,
                          cancelAtPeriodEnd
                        );
                      }
                    }}
                  >
                    {[SUBSCRIPTION_PLANS.BASIC].includes(type.name) &&
                      t(`contract.tabs.contractType.${type.name}`)}
                    {![SUBSCRIPTION_PLANS.BASIC].includes(type.name) &&
                      subscribedContractType?.id !== type.id &&
                      t('contract.tabs.contractType.subscribe')}
                    {![SUBSCRIPTION_PLANS.BASIC].includes(type.name) &&
                      subscribedContractType?.id === type.id &&
                      (cancelAtPeriodEnd
                        ? t('contract.tabs.contractType.resubscribe')
                        : t('contract.tabs.contractType.unsubscribe'))}
                  </Button>
                )}
                {[SUBSCRIPTION_PLANS.CUSTOM].includes(type.name) && (
                  <CustomContractAction
                    contractType={subscribedContractType}
                    customContract={customContract}
                    t={t}
                    subscribe={subscribe}
                    handleCancelConfirmation={handleCancelConfirmation}
                  />
                )}

                {![SUBSCRIPTION_PLANS.BASIC].includes(type.name) &&
                  subscribedContractType?.id === type.id && (
                    <Button
                      onClick={showAddonModal}
                      disabled={cancelAtPeriodEnd}
                      role="button"
                      className="btn btn-circle-sm ml-1"
                    >
                      <PlusIcon />
                    </Button>
                  )}
              </div>
              <hr />
              <Card.Text className="mt-40">
                {`- ${t('contract.tabs.contractType.orders', {
                  ordersLimit:
                    type.name !== 'custom'
                      ? type.maxOrdersCount
                      : customContract?.maxOrdersCount || '(X)',
                })}`}
              </Card.Text>
              <Card.Text className="mt-20">
                {`- ${t('contract.tabs.contractType.clients', {
                  clients:
                    type.name !== 'custom'
                      ? type.allowedClients
                      : customContract?.allowedClients || '(X)',
                })}`}
              </Card.Text>
              <Card.Text className="mt-20">
                {`- ${
                  type.support
                    ? t('contract.tabs.contractType.supportAvailable')
                    : t('contract.tabs.contractType.supportNotAvailable')
                }`}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Confirmation onConfirm={unsubscribe} />
    </>
  );
};

ContractType.defaultProps = {
  types: undefined,
  subscribedContractType: undefined,
  customContract: undefined,
  subscriptionCancelDate: undefined,
  activePromotion: undefined,
};
