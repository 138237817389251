import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IManagerMillUser } from 'interfaces/Manager/user';
import { idsToLinks } from 'pages/Manager/Home';
import { MANAGER_ORDERS_VIEW_ROUTE } from 'constants/routes';

export const columns: ColumnDescription<any, IManagerMillUser>[] = [
  {
    dataField: 'id',
    text: 'Mill Id',
  },
  {
    dataField: 'name',
    text: 'Name',
  },
  {
    dataField: 'contractType',
    text: 'Contract Type',
  },
  {
    dataField: 'clientsCount',
    text: 'Clients Count',
  },
  {
    dataField: 'allowedClients',
    text: 'Allowed Clients',
  },
  {
    dataField: 'ordersCreated',
    text: 'Orders Created',
  },
  {
    dataField: 'maxOrdersCount',
    text: 'Max Orders Count',
  },
  {
    dataField: 'projectIds',
    text: 'Project Ids',
    formatter: (cell: any, row: IManagerMillUser) => {
      if (!row?.projectIds?.length) return 'None';

      const linkedElements = idsToLinks(
        row.projectIds.split(','),
        `${MANAGER_ORDERS_VIEW_ROUTE}`,
        `${row.id}`,
        `&millId=${row.id}`
      );

      return <>{linkedElements}</>;
    },
  },
];
