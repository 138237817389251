import React from 'react';
import { IIcon } from './Icon';

export const DownloadIcon = ({ color = '#FA9600' }: IIcon) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 19.25C20.25 19.9404 19.6904 20.5 19 20.5L5 20.5C4.30964 20.5 3.75 19.9404 3.75 19.25L3.75 18C3.75 17.5858 3.41421 17.25 3 17.25C2.58579 17.25 2.25 17.5858 2.25 18L2.25 19.25C2.25 20.7688 3.48122 22 5 22L19 22C20.5188 22 21.75 20.7688 21.75 19.25L21.75 18C21.75 17.5858 21.4142 17.25 21 17.25C20.5858 17.25 20.25 17.5858 20.25 18L20.25 19.25Z"
      fill={color}
    />
    <path
      d="M11.4697 15.7803C11.7626 16.0732 12.2374 16.0732 12.5303 15.7803L16.5303 11.7803C16.8232 11.4874 16.8232 11.0126 16.5303 10.7197C16.2374 10.4268 15.7626 10.4268 15.4697 10.7197L12.75 13.4393L12.75 3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3L11.25 13.4393L8.53033 10.7197C8.23744 10.4268 7.76256 10.4268 7.46967 10.7197C7.17678 11.0126 7.17678 11.4874 7.46967 11.7803L11.4697 15.7803Z"
      fill={color}
    />
  </svg>
);
