import React from 'react';

export const OrderIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 13.25C7 12.8358 7.33579 12.5 7.75 12.5H16.25C16.6642 12.5 17 12.8358 17 13.25C17 13.6642 16.6642 14 16.25 14H7.75C7.33579 14 7 13.6642 7 13.25Z" />
    <path d="M7 17.25C7 16.8358 7.33579 16.5 7.75 16.5H16.25C16.6642 16.5 17 16.8358 17 17.25C17 17.6642 16.6642 18 16.25 18H7.75C7.33579 18 7 17.6642 7 17.25Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.01456 3.25H8.68934L9.88388 2.05546C10.3996 1.53973 11.0991 1.25 11.8284 1.25H12.1716C12.9009 1.25 13.6004 1.53973 14.1161 2.05546L15.3107 3.25H17.9855C17.9956 3.2498 18.0056 3.2498 18.0157 3.25H19C20.5188 3.25 21.75 4.48122 21.75 6V21C21.75 22.5188 20.5188 23.75 19 23.75H5C3.48122 23.75 2.25 22.5188 2.25 21V6C2.25 4.48122 3.48122 3.25 5 3.25H5.98436C5.99444 3.2498 6.00451 3.2498 6.01456 3.25ZM14.4697 4.53033C14.6146 4.67523 14.804 4.74845 14.9939 4.74998L15 4.75H16.7865L15.882 6.55903C15.6703 6.98251 15.2374 7.25001 14.764 7.25001H9.23611C8.76264 7.25001 8.32982 6.98251 8.11808 6.55903L7.21356 4.75H9L9.00668 4.74997C9.19639 4.74829 9.38558 4.67508 9.53033 4.53033L10.9445 3.11612C11.179 2.8817 11.4969 2.75 11.8284 2.75H12.1716C12.5031 2.75 12.821 2.8817 13.0555 3.11612L14.4697 4.53033ZM5.53651 4.75H5C4.30964 4.75 3.75 5.30964 3.75 6V21C3.75 21.6904 4.30964 22.25 5 22.25H19C19.6904 22.25 20.25 21.6904 20.25 21V6C20.25 5.30964 19.6904 4.75 19 4.75H18.4636L17.2236 7.22985C16.7578 8.16151 15.8056 8.75001 14.764 8.75001H9.23611C8.19449 8.75001 7.24226 8.1615 6.77644 7.22985L5.53651 4.75Z"
    />
  </svg>
);
