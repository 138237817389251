import React from 'react';
import { millConsentThunk } from 'thunks/mill';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getLocalStorageItem } from 'utils/localStorage';
import { SELECTED_MILL } from 'constants/constants';
import { UserConsentContainer } from 'components/Modal/AddonSelection/UserConsentContainer';
import { useTranslation } from 'react-i18next';
import { isWinAppViewerMode } from '../../slices/auth';
import { MillSelectionContainer } from '../Modal/MillSelection/MillSelectionContainer';
import { ToastContainer } from '../Toast/ToastContainer';
import { Header, UserHeader } from './Header';
import { UserMain } from './Main';

interface IUserLayout {
  children: React.ReactNode;
  mainClasses?: string;
}

export const UserLayout = ({ children, mainClasses }: IUserLayout) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showConsentModel } = useAppSelector((state) => state.auth);

  const userConsent = async (
    policyAgreement: boolean,
    dataProcessingAgreement: boolean
  ) => {
    if (policyAgreement && dataProcessingAgreement) {
      const millId = getLocalStorageItem(SELECTED_MILL);
      dispatch(millConsentThunk(millId));
    }
  };

  return (
    <>
      {!isWinAppViewerMode() && (
        <Header>
          <UserHeader />
        </Header>
      )}
      <UserMain mainClasses={mainClasses}>{children}</UserMain>
      <ToastContainer />
      <MillSelectionContainer />
      <UserConsentContainer
        show={showConsentModel}
        title={t('home.policiesupdate.title')}
        message={t('home.policiesupdate.content')}
        canClose={false}
        userConsent={userConsent}
        onClose={undefined}
      />
    </>
  );
};

UserLayout.defaultProps = {
  mainClasses: '',
};
