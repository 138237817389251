import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  LAB_ACCOUNT_COMPANY_INFO_ROUTE,
  LAB_ACCOUNT_ROUTE,
} from 'constants/routes';
import { toastSlice } from 'slices';
import Api from '../api';

export const forceUserToEnterLabInfo = (
  companyInfoIsUpdated: boolean,
  dispatch: any
) => {
  const { location } = window;

  if (!companyInfoIsUpdated && !location.pathname.includes(LAB_ACCOUNT_ROUTE)) {
    window.location.href = LAB_ACCOUNT_COMPANY_INFO_ROUTE;
    dispatch(
      toastSlice.actions.show({
        title: 'Update company Info',
        message: 'Please update company information first.',
      })
    );
  }
};

export const labThunk = createAsyncThunk('lab', async (id?: number) => {
  return Api.get(`/labs/${id}`);
});

export const labByCompanyIdThunk = createAsyncThunk(
  'labByCompanyId',
  async (companyId?: any) => {
    return Api.get(`/labs/companies/${companyId}`);
  }
);

export const updateSelectedLabThunk = createAsyncThunk(
  'update/lab',
  async (id: string) => {
    return Api.post(`/labs/${id}`, {});
  }
);
