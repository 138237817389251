import React from 'react';
import { Toast as BsToast } from 'react-bootstrap';

interface IToastProps {
  show: boolean;
  title: string;
  message: string;
  onClose?: () => void;
}

export const Toast: React.FC<IToastProps> = ({
  show,
  title,
  message,
  onClose,
}: IToastProps) => {
  const handleClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <BsToast
      show={show}
      onClose={onClose}
      delay={5000}
      animation
      autohide
      onClick={handleClick}
    >
      <BsToast.Header>{title}</BsToast.Header>
      <BsToast.Body>{message}</BsToast.Body>
    </BsToast>
  );
};

Toast.defaultProps = {
  onClose: undefined,
};
