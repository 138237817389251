import { useQuery } from 'hooks/query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { subscribePlanThunk } from 'thunks/payments';

export const PaymentSuccessPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const query = useQuery();

  const updateSubscription = (sessionId: string) => {
    dispatch(
      subscribePlanThunk({
        sessionId,
      })
    );
  };

  React.useEffect(() => {
    const sessionId = query.get('session_id');
    if (sessionId) {
      updateSubscription(sessionId);
    }
  }, []);

  return <div>{t('contract.tabs.contractType.payment.success')}</div>;
};
