import React from 'react';

import { authSlice, confirmationSlice } from 'slices';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  addOnsSubscriptionThunk,
  getAddOnsListThunk,
  IMillAddOns,
} from 'thunks/payments';
import { Spinner2 } from 'components';
import { useTranslation } from 'react-i18next';
import { AddonSelection, getPromotionalPrice } from './AddonSelection';
import { UserConsentContainer } from './UserConsentContainer';

export const AddonSelectionContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [selectedLookupKey, setSelectedLookupKey] = React.useState<string>('');
  const { showAddonSelectionModal, showAddonConsentModel } = useAppSelector(
    (state) => state.auth
  );
  const { filesKeepDurationKey, addOns } = useAppSelector(
    (state) => state.payments
  );

  const activePromotions = useAppSelector(
    (state) => state.contractTypes.activePromotion
  );

  const onClose = () => {
    dispatch(authSlice.actions.closeAddonSelectionModal());
    dispatch(authSlice.actions.closeAddonConsentModel());
  };

  const handleConfirmation = () => {
    dispatch(confirmationSlice.actions.close());
  };

  const onAddonUpdates = async (selection: IMillAddOns) => {
    setSelectedLookupKey(selection.filesKeepDurationKey);
    dispatch(authSlice.actions.showAddonConsentModel());
  };
  const closeConsentDialog = () => {
    dispatch(authSlice.actions.closeAddonConsentModel());
  };

  const userConsentAddOn = async (
    policyAgreement: boolean,
    dataProcessingAgreement: boolean
  ) => {
    setIsProcessing(true);
    onClose();
    const selectedObj = addOns.filter((item) => item.key === selectedLookupKey);
    await dispatch(
      addOnsSubscriptionThunk({
        policyAgreement,
        dataProcessingAgreement,
        filesKeepDurationKey: selectedLookupKey,
        confirmationMessage: selectedObj[0].price
          ? t('contract.tabs.contractType.addOnConfirmation', {
              addOnDuration: selectedObj[0].value,
              getPromotionalPrice: getPromotionalPrice(
                selectedObj[0].price,
                activePromotions
              ),
            })
          : t('contract.tabs.contractType.addOnUnsubscription'),
      })
    );
    setIsProcessing(false);
    onClose();
  };

  React.useEffect(() => {
    dispatch(getAddOnsListThunk());
  }, []);

  return (
    <>
      <Spinner2 show={isProcessing} />
      <AddonSelection
        show={showAddonSelectionModal}
        handleConfirmation={handleConfirmation}
        onClose={onClose}
        onAddonUpdates={onAddonUpdates}
        filesKeepDurationKey={filesKeepDurationKey}
        addOns={addOns}
        activePromotion={activePromotions}
      />
      <UserConsentContainer
        show={showAddonConsentModel}
        title={t('contract.addons.title')}
        message={t(
          'contract.tabs.contractType.upgradesubscriptionConfirmation'
        )}
        canClose
        userConsent={userConsentAddOn}
        onClose={closeConsentDialog}
      />
    </>
  );
};
