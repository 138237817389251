/* eslint-disable prettier/prettier */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../Button';
import { Modal } from '../../Modal/Modal';
import { CreateNewAccountContainer } from '../CreateNewAccount/CreateNewAccountContainer';

interface IInvitationProps {
  show: boolean;
  millName: string;
  onClose: () => any;
  onConnectToAnExistingAccount: () => void;
}

export const Invitation = ({
  show,
  millName,
  onClose,
  onConnectToAnExistingAccount,
}: IInvitationProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('invitation.title')}
      show={show}
      onClose={onClose}
      closeOnOutSideClick={false}
    >
      {millName && (
        <p className="mb-16 fs-18 text-center">
          {t('invitation.message', { millName })}
        </p>
      )}
      <CreateNewAccountContainer />

      <div className="mt-20 fs-14 label-small text-center">
        {t('invitation.or')}
      </div>

      <p className="mt-8 mb-20 fs-14 text-center">
        {t('invitation.connectToAccountMsg')}{' '}
      </p>

      <Button
        className="btn-outline-primary"
        dimension="lg"
        full
        onClick={onConnectToAnExistingAccount}
      >
        {t('invitation.connectToAccount')}
      </Button>
    </Modal>
  );
};
