import React from 'react';

export const MessageEmptyIcon = () => (
  <svg
    width="172"
    height="172"
    viewBox="0 0 172 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6666 26.875C23.719 26.875 19.7083 30.8858 19.7083 35.8333V114.667C19.7083 119.614 23.719 123.625 28.6666 123.625H48.3749V154.698C48.3749 156.294 50.3048 157.094 51.4335 155.965L83.7735 123.625H143.333C148.281 123.625 152.292 119.614 152.292 114.667V35.8333C152.292 30.8858 148.281 26.875 143.333 26.875H28.6666ZM8.95825 35.8333C8.95825 24.9487 17.782 16.125 28.6666 16.125H143.333C154.218 16.125 163.042 24.9487 163.042 35.8333V114.667C163.042 125.551 154.218 134.375 143.333 134.375H88.2263L59.0349 163.566C51.134 171.467 37.6249 165.872 37.6249 154.698V134.375H28.6666C17.782 134.375 8.95825 125.551 8.95825 114.667V35.8333Z"
      fill="#C7CED8"
    />
  </svg>
);
