import React from 'react';
import { Container, Navbar, Nav, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import footerLogo from '../../../assets/images/footer_logo.gif';

const footerLogoNavigationLink =
  'http://atpscan.global.hornetsecurity.com/index.php?atp_str=ZT7TxYNNHXLvZ6DWSfgy9UfI5UzYZ9FvJi2CTOcJc-gyDHPJ9ST4-nzn1i_si4ZX0COPviigF1Pic6M4s_68dNLWmzNeyAz1J0AnxQwts1s_ryD7z9V3O1s4-XF2kjEbjKip3_9uvejNyTRyN6uSH4evKfZl3CPGkugYl5T9FdwxkW1StrY0YWcAJOVY4rslKF8Eni5kwkE5Jm4tw7Cn4AJkxtli2Y3rnhJXsZZPMUSnusj1LU1IoQVV8QM1rnWpVmrkix8BHWwaco0isnQF-BcjloOxBuevkw-bhgS3T9gPZIK1IyM6OiOzZiHKID6eqVAyjVIjOjojfSKmn9PW0dkoiSXXNEidKw';

const LogoIcon: React.FC = () => (
  <Image src={footerLogo} className="public-footer-image" />
);

export const PublicFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container className="public-footer-container">
      <Row>
        <Col>
          <Navbar className="navbar-footer h-100 w-100">
            <Navbar.Brand
              href={footerLogoNavigationLink}
              target="_blank"
              className="w-25 public-footer-logo"
            >
              <LogoIcon />
            </Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link href="/about-us">
                {t('footer.menu.aboutUs.title')}
              </Nav.Link>
              <Nav.Link href="/contact">
                {t('footer.menu.contact.title')}
              </Nav.Link>
              <Nav.Link href="/terms-of-service">
                {t('footer.menu.termsOfService.title')}
              </Nav.Link>
              <Nav.Link href="/data-processing-agreement">
                {t('footer.menu.dataProcessing.title')}
              </Nav.Link>
            </Nav>
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
};
