import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE } from 'constants/constants';
import {
  IFilters,
  IFilter,
  IPagination,
  ISort,
  ISearch,
} from '../interfaces/table';

export function defaultTableState() {
  return {
    count: 0,
    data: [],
    page: 1,
    sizePerPage: DEFAULT_PAGE_SIZE,
    sort: undefined,
    filters: undefined,
    search: undefined,
  };
}

export function paginationSlice<T extends IPagination>() {
  return {
    setPagination(state: T, action: PayloadAction<IPagination>) {
      state.page = action.payload.page;
      state.sizePerPage = action.payload.sizePerPage;
    },
  };
}

export function paginationQuery(state: IPagination) {
  const { page, sizePerPage } = state;
  if (!page || !sizePerPage) {
    return '';
  }
  const start = (page - 1) * sizePerPage;
  return `_start=${start}&_limit=${sizePerPage}`;
}

export function sortQuery(state: ISort) {
  return state.sort ? `_sort=${state.sort}` : '';
}

export function filtersQuery(state: IFilters) {
  if (state.filters) {
    const values: string[] = [];
    Object.entries(state.filters).forEach(([key, value]) => {
      values.push(`${key}=${value}`);
    });
    return values.join('&');
  }
  return '';
}

export function searchQuery(state: ISearch) {
  return state.search ? `_q=${state.search}` : '';
}

export function sortSlice<T extends ISort>() {
  return {
    setSort(state: T, action: PayloadAction<ISort>) {
      state.sort = action.payload.sort;
    },
    clearSort(state: T) {
      state.sort = undefined;
    },
  };
}

export function filterSlice<T extends IFilters>() {
  return {
    setFilters(state: T, action: PayloadAction<IFilter>) {
      state.filters = action.payload;
    },
    clearFilters(state: T) {
      state.filters = undefined;
    },
  };
}

export function searchSlice<T extends ISearch>() {
  return {
    setSearch(state: T, action: PayloadAction<ISearch>) {
      state.search = action.payload.search;
    },
    clearSearch(state: T) {
      state.search = undefined;
    },
  };
}

export function tableQuery(state: any) {
  const pagination = paginationQuery(state);

  let filters = filtersQuery(state);
  filters = filters ? `&${filters}` : '';

  let search = searchQuery(state);
  search = search ? `&${search}` : '';

  let sort = sortQuery(state);
  sort = sort ? `&${sort}` : '';

  return `${pagination}${filters}${search}${sort}`;
}
