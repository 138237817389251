import React from 'react';
import { Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Link } from 'react-router-dom';
import { TERMS_OF_SERVICE_ROUTE, DATA_AGREEMENT_ROUTE } from 'constants/routes';
import { userConsentSlice } from 'slices';
import greenTick from '../../../assets/images/greenTick.jpg';
import redCross from '../../../assets/images/redCross.jpg';

export const isValidUserConsent = (
  dispatch: any,
  policyIsChecked: any,
  dataProcessingIsChecked: any
) => {
  if (!policyIsChecked || !dataProcessingIsChecked) {
    dispatch(userConsentSlice.actions.selectPolicy(!!policyIsChecked));
    dispatch(
      userConsentSlice.actions.selectDataProcessing(!!dataProcessingIsChecked)
    );
    return false;
  }

  return true;
};

export const ForcedPolicyConsent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { policyIsChecked, dataProcessingIsChecked } = useAppSelector(
    (state) => state.userConsent
  );
  const handleChangeTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(userConsentSlice.actions.selectPolicy(e.target.checked));
  };
  const handleChangeAgreement = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(userConsentSlice.actions.selectDataProcessing(e.target.checked));
  };
  return (
    <>
      <Form.Check
        id="terms"
        className="mb-1 ml-6 custom-checkbox custom-control"
        type="checkbox"
        label={
          <>
            <Trans i18nKey="signUp.serviceterms">
              <Link to={TERMS_OF_SERVICE_ROUTE}>
                {{ service: t('footer.menu.termsOfService.title') }}
              </Link>
            </Trans>
            {policyIsChecked !== null && !policyIsChecked && (
              <img src={redCross} className="consent-image" alt="" />
            )}
            {policyIsChecked !== null && !!policyIsChecked && (
              <img src={greenTick} className="consent-image" alt="" />
            )}
          </>
        }
        checked={!!policyIsChecked}
        onChange={handleChangeTerms}
      />
      <Form.Check
        id="dataAgreement"
        className="mb-3 ml-6 custom-checkbox custom-control"
        type="checkbox"
        label={
          <>
            <Trans i18nKey="signUp.dataprocessing">
              <Link to={DATA_AGREEMENT_ROUTE}>
                {{ agreement: t('footer.menu.dataProcessing.title') }}
              </Link>
            </Trans>
            {dataProcessingIsChecked !== null && !dataProcessingIsChecked && (
              <img src={redCross} className="consent-image" alt="" />
            )}
            {dataProcessingIsChecked !== null && !!dataProcessingIsChecked && (
              <img src={greenTick} className="consent-image" alt="" />
            )}
          </>
        }
        checked={!!dataProcessingIsChecked}
        onChange={handleChangeAgreement}
      />
    </>
  );
};
