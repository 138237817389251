import React from 'react';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { IIdParam } from '../../../interfaces/api';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import { createMessageThunk, IMessageForm } from '../../../thunks/component';
import { MessageForm } from './MessageForm';
import { Messages } from './Messages';

const schema: yup.SchemaOf<IMessageForm> = yup.object().shape({
  message: yup.string().required(),
});

export const MessageContainer: React.FC = () => {
  const { id } = useParams<IIdParam>();
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.component.data?.messages);

  const handleSubmit = (
    values: IMessageForm,
    form: FormikHelpers<IMessageForm>
  ) => {
    dispatch(createMessageThunk({ ...values, component: id }));
    form.resetForm();
  };

  return (
    <>
      <MessageForm
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={{
          message: '',
        }}
      />
      <Messages messages={messages} />
    </>
  );
};
