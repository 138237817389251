import React, { useEffect } from 'react';
import notificationBellIcon from 'assets/images/notification-bell.svg';
import {
  Badge,
  Button,
  ListGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { authSlice, INotification } from 'slices/auth';
import { useDispatch } from 'react-redux';
import { markNotificationReadState } from 'thunks/auth';
import { API_BASE_URL } from 'api';
import { io } from 'socket.io-client';
import { MESSAGETYPE, SELECTED_MILL } from 'constants/constants';
import { millSlice, toastSlice } from 'slices';
import { generatePath, useHistory } from 'react-router-dom';
import {
  LAB_COMPONENT_ROUTE,
  LAB_ORDERS_ROUTE,
  MILL_COMPONENT_ROUTE,
  MILL_ORDERS_ROUTE,
} from 'constants/routes';
import { IUser } from 'interfaces/user';
import { getLocalStorageItem } from 'utils/localStorage';
import { Radio } from 'antd';

const NOTIFICATION_VALUE = {
  ALL: 'all',
  UNREAD: 'unread',
};

const notificationIsRelevantToUser = (user: IUser, args: any) => {
  const { millId, labCompanyId, userId, responsible } = args;
  const selectedMillId = +getLocalStorageItem(SELECTED_MILL);
  const isMillUser = user.role?.type === 'mill';
  const millIsSame = selectedMillId === +millId;
  const conditionResult =
    user.id !== userId &&
    (isMillUser
      ? millIsSame && (!responsible || responsible === user.id)
      : millIsSame && +labCompanyId === user.company?.id);

  return conditionResult;
};

interface INotificationsFilter {
  onChangeNotificationFilter: (e: any) => void;
  t: any;
}

const NotificationsFilter: React.FC<INotificationsFilter> = ({
  onChangeNotificationFilter,
  t,
}: INotificationsFilter) => (
  <Radio.Group
    defaultValue={NOTIFICATION_VALUE.ALL}
    size="small"
    style={{ margin: '10px 0 10px 0', width: 250 }}
    onChange={onChangeNotificationFilter}
  >
    <Radio.Button value={NOTIFICATION_VALUE.ALL}>
      {t('notifications.filter.all')}
    </Radio.Button>
    <Radio.Button value={NOTIFICATION_VALUE.UNREAD}>
      {t('notifications.filter.unread')}
    </Radio.Button>
  </Radio.Group>
);

export const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const socket = io(API_BASE_URL || 'http://localhost:1337');

  const { notifications, user } = useAppSelector((state) => state.auth.data);
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );
  const isMillRole = user?.role?.type === 'mill';

  const [notificationFilter, setNotificationFilter] = React.useState(
    NOTIFICATION_VALUE.ALL
  );

  useEffect(() => {
    socket.on('connect', () => {
      // console.log('Client connection is established');
    });

    return () => {
      socket.off('connect');
    };
  }, []);

  useEffect(() => {
    socket.on(MESSAGETYPE.PROJECT_CREATED, (args) => {
      if (
        !!userSettings.notificationOnNewProjectIsCreated &&
        notificationIsRelevantToUser(user, args)
      ) {
        dispatch(authSlice.actions.addNotification(args));
        dispatch(millSlice.actions.updateProjectCreatedCount());
        dispatch(
          toastSlice.actions.show({
            title: t('notifications.new'),
            message: args.message,
          })
        );
      }
    });

    socket.on(MESSAGETYPE.MESSAGE_RECEIVED, (args) => {
      if (
        !!userSettings.notificationOnNewMessageReceived &&
        notificationIsRelevantToUser(user, args)
      ) {
        dispatch(authSlice.actions.addNotification(args));
        dispatch(
          toastSlice.actions.show({
            title: t('notifications.new'),
            message: args.message,
          })
        );
      }
    });

    return () => {
      socket.off(MESSAGETYPE.PROJECT_CREATED);
      socket.off(MESSAGETYPE.MESSAGE_RECEIVED);
    };
  }, [userSettings]);

  const notificationsCount = (notifications?.filter((n) => !n.isRead)).length;
  const handleClickNotification = (group = []) => {
    const notification = group[0] as INotification;
    const notificationIds = group.map((n: INotification) => n.notificationId);
    const { notificationType, objectId, parentObjectId } = notification;
    dispatch(markNotificationReadState({ notificationIds, read: true }));
    switch (notificationType) {
      case MESSAGETYPE.MESSAGE_RECEIVED:
        {
          const params = { projectId: parentObjectId, id: objectId };
          const goToLink = isMillRole
            ? generatePath(MILL_COMPONENT_ROUTE, params)
            : generatePath(LAB_COMPONENT_ROUTE, params);
          if (notification.isRead) {
            history.push(goToLink);
          } else {
            window.location.href = goToLink;
          }
        }
        break;
      case MESSAGETYPE.PROJECT_CREATED:
        {
          const goToLink = isMillRole ? MILL_ORDERS_ROUTE : LAB_ORDERS_ROUTE;
          if (notification.isRead) {
            history.push(goToLink);
          } else {
            window.location.href = goToLink;
          }
        }
        break;

      default:
        break;
    }
  };

  const handleMarkAsUnread = (group = []) => {
    const notification = group[0];
    const { notificationId } = notification;
    dispatch(
      markNotificationReadState({
        notificationIds: [notificationId],
        read: false,
      })
    );
  };

  const getNotificationsGroups = () => {
    const map = new Map();
    const filteredNotifications =
      notificationFilter === NOTIFICATION_VALUE.ALL
        ? notifications
        : notifications.filter((n) => !n.isRead);
    filteredNotifications.forEach((n: INotification) => {
      const key = `${n.notificationType}-${n.objectId}-${n.parentObjectId}`;
      const v = map.get(key) || [];
      v.push(n);
      map.set(key, v);
    });

    return Array.from(map.values());
  };

  const onChangeNotificationFilter = (e: any) => {
    setNotificationFilter(e.target.value);
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <Popover id="popover-positioned-bottom">
          <Popover.Title as="h3">{t('notifications.label')}</Popover.Title>
          <Popover.Content>
            {notifications?.length > 0 && (
              <>
                <NotificationsFilter
                  onChangeNotificationFilter={onChangeNotificationFilter}
                  t={t}
                />
                <ListGroup variant="flush" className="notification-list">
                  {getNotificationsGroups().map((group: any) => {
                    const n =
                      group.find((no: INotification) => !no.isRead) || group[0];

                    return (
                      <ListGroup.Item
                        className={`${
                          n.isRead ? '' : 'notification-item-unread'
                        }`}
                        key={n.notificationId}
                        onClick={() => {
                          handleClickNotification(group);
                        }}
                      >
                        <div className="notification-item">
                          <div className="notification-item-message">
                            {n.message}
                          </div>
                          <div
                            className={`${
                              n.isRead ? '' : 'notification-circle'
                            }`}
                          />
                        </div>
                        {n.isRead && (
                          <div>
                            <Button
                              variant="link"
                              className="mark-as-unread"
                              onClick={(e) => {
                                handleMarkAsUnread(group);
                                e.stopPropagation();
                              }}
                            >
                              {t('notifications.markAsUnread')}
                            </Button>
                          </div>
                        )}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </>
            )}
            {notifications?.length === 0 && t('notifications.none')}
          </Popover.Content>
        </Popover>
      }
    >
      <Button
        variant="secondary"
        className="rounded-circle notification-btn-container"
      >
        <div className="notification">
          <img src={notificationBellIcon} alt="Alerts" className="app-icon" />
          <div className="notification-bubble">
            {notificationsCount > 0 && (
              <Badge variant="danger">{notificationsCount}</Badge>
            )}
          </div>
        </div>
      </Button>
    </OverlayTrigger>
  );
};
