import { useMillRole } from 'hooks/role';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectUserPrefsContainer } from '../ProjectUserPrefsContainer';
import { PageSizeContainer } from '../PageSizeContainer';

export const SettingsThirdColumn = () => {
  const { t } = useTranslation();
  const isMillRole = useMillRole();
  return (
    <>
      {isMillRole && (
        <div data-cy="user-settings-language-container" className="mb-40">
          <h4 className="mb-10">{t('account.settings.project.title')}</h4>
          <ProjectUserPrefsContainer />
        </div>
      )}
      <div data-cy="user-settings-language-container" className="mb-40">
        <h4 className="mb-10">Page Size</h4>
        <PageSizeContainer />
      </div>
    </>
  );
};
