import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { Table } from 'components/Table';
import Api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authSlice, toastSlice } from 'slices';
import { useTranslation } from 'react-i18next';
import { Spinner2 } from 'components';
import { Space } from 'antd';
import { DEFAULT_PAGE_SIZE } from 'constants/constants';
import moment from 'moment';
import { Modal } from '../../../../../components/Modal/Modal';
import { columns } from './columns';

interface IUpcomingInvoiceProps {
  title: string;
  subTitle: string;
}

export const UpcomingInvoiceContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [upcoming, setUpcomingInvoices] = useState([]);
  const [upcomingTitle, setUpcomingTitle] = useState('');
  const [nextPaymentdate, setNextPaymentDate] = useState('');
  const { showUpcomingInvoiceModal } = useAppSelector((state) => state.auth);

  const onClose = () => {
    dispatch(authSlice.actions.closeUpcomingInvoiceModal());
  };

  const UpcomingInvoiceTitle: React.FC<IUpcomingInvoiceProps> = ({
    title,
    subTitle,
  }: IUpcomingInvoiceProps) => {
    return (
      <div>
        <p>
          {t('contract.tabs.invoices.amountDue')} : {title}
        </p>
        <p className="small">
          {t('contract.tabs.invoices.dateDue')} : {subTitle}{' '}
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (!showUpcomingInvoiceModal) return;
    (async () => {
      try {
        setIsProcessing(true);
        const response = await Api.get('/payments/upcoming/invoices');
        if (response.upcomingInvoice.lines) {
          setUpcomingInvoices(response.upcomingInvoice.lines.data);
          setUpcomingTitle(`€${response.upcomingInvoice.amount_due / 100}`);
          setNextPaymentDate(
            `${moment(
              response.upcomingInvoice.next_payment_attempt * 1000
            ).format('YYYY-MM-DD')}`
          );
        }
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        dispatch(
          toastSlice.actions.show({
            title: t('contract.error'),
            message: t('contract.tabs.invoices.errorMessage'),
          })
        );
      }
    })();
  }, [showUpcomingInvoiceModal]);
  return (
    <>
      <Spinner2 show={isProcessing} />
      <Modal
        show={showUpcomingInvoiceModal && nextPaymentdate !== ''}
        onClose={onClose}
        title={
          <UpcomingInvoiceTitle
            title={upcomingTitle}
            subTitle={nextPaymentdate}
          />
        }
        closeOnOutSideClick={false}
        size="lg"
        scrollable
        footer={
          <>
            <Button className="btn-upcoming-invoice" onClick={onClose}>
              {t('contract.tabs.invoices.buttonOk')}
            </Button>
          </>
        }
      >
        <Space direction="vertical">
          <Row>
            <Col>
              <Table
                data={upcoming}
                columns={columns}
                totalSize={upcoming.length}
                sizePerPage={DEFAULT_PAGE_SIZE}
              />
            </Col>
          </Row>
        </Space>
      </Modal>
    </>
  );
};
