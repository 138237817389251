import React from 'react';
import * as yup from 'yup';

import { toastSlice } from 'slices';
import { Spinner2 } from 'components';
import { useTranslation } from 'react-i18next';
import { licenseKeySlice } from '../../../../slices';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  addLicenseKeyThunk,
  ILicenseKeyPayload,
} from '../../../../thunks/license-keys';
import { LicenseKeysForm } from './LicenseKeysForm';

const schema: yup.SchemaOf<ILicenseKeyPayload> = yup.object().shape({
  id: yup.number().optional(),
  licenseKey: yup.string().required(),
  enableDataCollection: yup.boolean().required(),
});

export const LicenseKeysFormContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const error = useAppSelector(
    (state) => state.licenseKey.addLicenseKey?.error
  );
  const { showAddLicenseKey } = useAppSelector((state) => state.licenseKey);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  const handleSubmit = async (values: ILicenseKeyPayload) => {
    try {
      setIsProcessing(true);
      await dispatch(addLicenseKeyThunk(values));
      setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
      dispatch(
        toastSlice.actions.show({
          title: t('settings.licenseKey.error.title'),
          message: t('csettings.licenseKey.error.message'),
        })
      );
    }
  };

  return (
    <>
      <Spinner2 show={isProcessing} />
      <LicenseKeysForm
        show={showAddLicenseKey}
        onClose={licenseKeySlice.actions.closeAddLicenseKey}
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={
          {
            id: undefined,
            licenseKey: '',
            enableDataCollection: true,
          } as ILicenseKeyPayload
        }
        error={error}
      />
    </>
  );
};
