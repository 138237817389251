import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { labByCompanyIdThunk } from 'thunks/lab';
import { UserLayout } from 'components/Layout';
import { TabsControl } from 'components/TabsControl';
import { ITabConfig } from 'components/TabsControl/TabsControl';
import { MILL_CLIENT_COMPANY_INFO_ROUTE } from 'constants/routes';
import { useQuery } from 'hooks/query';
import { ArrowLeftIcon } from 'icons';
import { IIdParam } from 'interfaces/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import i18n from 'translations';
import { CompanyInfo } from './CompanyInfo';

const tabs: ITabConfig[] = [
  {
    eventKey: 'company-info',
    title: i18n.t('settings.company.info.title'),
    children: (
      <Row>
        <Col lg={{ span: 5 }}>
          <CompanyInfo />
        </Col>
      </Row>
    ),
  },
];

export const TabsContainer = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id } = useParams<IIdParam>();
  const { t } = useTranslation();
  const { data } = useAppSelector((state) => state.client);

  useEffect(() => {
    if (id) {
      dispatch(labByCompanyIdThunk(`${id}`));
    }
  }, [id]);

  const handleTabChange = (eventKey: any) => {
    if (eventKey === 'company-info') {
      history.push(generatePath(MILL_CLIENT_COMPANY_INFO_ROUTE, { id }));
    }
  };

  const handleBack = () => {
    // const prevState = history.location.state as IPrevState;
    // history.push(prevState?.from || MILL_CLIENTS_ROUTE);
    history.goBack();
  };

  return (
    <UserLayout mainClasses="page page-tabs page-client flex-column">
      <div className="page-header py-60 py-md-70 px-20 pl-md-126 pr-md-54">
        <Container fluid>
          <Row>
            <Col>
              <Link
                to="#"
                onClick={handleBack}
                className="btn-back d-flex align-items-center mb-24"
                data-cy="client-detail-back"
              >
                <ArrowLeftIcon /> <span>{t('goBack')}</span>
              </Link>
              <h1 className="fs-44 mb-88">{data?.lab?.name}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="px-20 pl-md-126 pr-md-54">
        <TabsControl
          activeKey={query.get('tab') || 'company-info'}
          id="company-info"
          tabs={tabs}
          onTabChange={handleTabChange}
        />
      </div>
    </UserLayout>
  );
};
