import React, { useEffect, useMemo } from 'react';
import { useGetDealerListQuery } from 'thunks/RTKQuery/dealer';
import { Spinner2 } from 'components/Spinner2';
import { DealerListView } from './DealersListView';

interface IDealerListViewContainer {
  setIsUpdateMode: (mode: boolean) => void;
  setSelectedId: (id: number) => void;
  setErrorString: (err: string) => void;
}

export const DealerListViewContainer = ({
  setIsUpdateMode,
  setSelectedId,
  setErrorString,
}: IDealerListViewContainer) => {
  const params = 'id, name, dealerId';
  const {
    data: dealerData = [],
    error: dealerListError,
    isLoading: dealerListLoading,
  } = useGetDealerListQuery(params);

  useEffect(() => {
    if (dealerListError && 'message' in dealerListError) {
      setErrorString(`Error :${dealerListError.message}`);
    }
  }, [dealerListError]);

  const dealerList = useMemo(() => {
    const data = dealerData.slice();
    data.sort((a: any, b: any) => {
      return b.id - a.id;
    });
    return data;
  }, [dealerData]);

  const handleOnSelect = async (row: any) => {
    setIsUpdateMode(true);
    setSelectedId(row.id);
  };

  return (
    <>
      <Spinner2 show={dealerListLoading} />
      <DealerListView dealerData={dealerList} handleOnSelect={handleOnSelect} />
    </>
  );
};
