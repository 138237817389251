import React from 'react';
import BootstrapTable, {
  BootstrapTableProps,
} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import classNames from 'classnames';

interface ITableProps extends Omit<BootstrapTableProps, 'keyField'> {
  remote?: boolean;
  keyField?: string;
  totalSize: number;
  page?: number;
  sizePerPage: number;
}

export const Table = ({
  remote = true,
  keyField = 'id',
  data,
  columns,
  page = 1,
  sizePerPage,
  totalSize,
  onTableChange,
  wrapperClasses = '',
  ...rest
}: ITableProps) => {
  return (
    <BootstrapTable
      remote={remote}
      keyField={keyField}
      data={data}
      columns={columns}
      bordered={false}
      pagination={paginationFactory({
        page,
        sizePerPage,
        totalSize,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
      })}
      onTableChange={onTableChange}
      wrapperClasses={classNames('table-responsive', wrapperClasses)}
      {...rest}
    />
  );
};

Table.defaultProps = {
  remote: true,
  page: 1,
  keyField: 'id',
};
