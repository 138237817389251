/* eslint-disable prettier/prettier */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../Button';
import { Modal } from '../../Modal/Modal';
import { isErrorString } from '../../../utils/handle-api-error';

interface ILoggedInUserInvitationProps {
  show: boolean;
  title: string;
  message: string;
  error?: any;
  onClose: () => any;
  onConnectToThisAccount: () => void;
  onCreateNewAccount: () => void;
}

export const LoggedInUserInvitation = ({
  show,
  title,
  message,
  error = undefined,
  onClose,
  onConnectToThisAccount,
  onCreateNewAccount,
}: ILoggedInUserInvitationProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      show={show}
      onClose={onClose}
      closeOnOutSideClick={false}
    >
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}
      <p className="mb-30 fs-18 text-center">{message}</p>

      <Button dimension="lg" full onClick={onConnectToThisAccount}>
        {t('invitation.addMillToAccountTxt')}
      </Button>

      <Button
        className="btn-outline-primary mt-28"
        dimension="lg"
        full
        onClick={onCreateNewAccount}
      >
        {t('invitation.createNewAccount')}
      </Button>
    </Modal>
  );
};

LoggedInUserInvitation.defaultProps = {
  error: undefined,
};
