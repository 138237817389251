import { MILL_SETTINGS_LANDING_PAGE_ROUTE } from 'constants/routes';
import React from 'react';
import * as yup from 'yup';

import { clientsSlice, confirmationSlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  CONFIRMATION_ACTIONS,
  IActivationPayload,
  sendActivationInstructionsThunk,
} from 'thunks/clients';
import { useTranslation } from 'react-i18next';
import { ActivationModal } from './ActivationModal';

const schema: yup.SchemaOf<IActivationPayload> = yup.object().shape({
  message: yup.string().required(),
  landingPageInfoIsUpdated: yup.boolean().optional(),
});

export const ActivationModalContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showActivation, activationInstructions } = useAppSelector(
    (state) => state.clients
  );

  const mill = useAppSelector((state) => state.mill.data);
  const landingPageInfoIsUpdated = !!mill?.landingPageInfoIsUpdated;

  const handleSubmit = (values: IActivationPayload) => {
    dispatch(
      sendActivationInstructionsThunk({ ...values, landingPageInfoIsUpdated })
    );
  };

  const handleClose = () => {
    dispatch(clientsSlice.actions.closeActivation());

    if (landingPageInfoIsUpdated !== true) {
      dispatch(
        confirmationSlice.actions.show({
          title: t('settings.landingPage.notify.title'),
          message: t('settings.landingPage.notify.message'),
          buttonOk: t('settings.landingPage.notify.okText'),
          buttonCancel: t('settings.landingPage.notify.cancelText'),
          redirectUrl: MILL_SETTINGS_LANDING_PAGE_ROUTE,
          actionKey: CONFIRMATION_ACTIONS.LANDING_PAGE,
        })
      );
    }
  };

  const handleCopyMessage = (message: string) => {
    navigator.clipboard.writeText(message);
  };

  return (
    <ActivationModal
      show={showActivation && !!activationInstructions}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onCopyMessage={handleCopyMessage}
      validationSchema={schema}
      initialValues={
        {
          message: activationInstructions,
        } as IActivationPayload
      }
    />
  );
};
