import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import React, { useState } from 'react';
import { Col, FormLabel, Row } from 'react-bootstrap';
import { getLocalStorageItem, setLocalStorage } from 'utils/localStorage';
import { ANALYTICS_PAGE_SIZE } from 'constants/constants';
import { IOption, Select } from 'components/Select';
import { DEFAULT_PAGE_SIZE, PAGE_OPTIONS } from './constants';

const pageSize = getLocalStorageItem(ANALYTICS_PAGE_SIZE);
if (!pageSize) {
  setLocalStorage(ANALYTICS_PAGE_SIZE, `${DEFAULT_PAGE_SIZE}`);
}

export const getPageSize = () => {
  return getLocalStorageItem(ANALYTICS_PAGE_SIZE) || DEFAULT_PAGE_SIZE;
};

export const ManagerSettings: React.FC = () => {
  const [selectedPageSize] = useState(getPageSize());

  const getPageOptions = () => {
    return PAGE_OPTIONS.map((p: number) => ({ label: `${p}`, value: `${p}` }));
  };

  const handlePageChange = (option: IOption) => {
    setLocalStorage(ANALYTICS_PAGE_SIZE, option.value);
  };

  const getDefaultValue = () => {
    const defaultValue = PAGE_OPTIONS.find(
      (p: number) => p === +selectedPageSize
    );
    return { label: `${defaultValue}`, value: `${defaultValue}` };
  };

  return (
    <ManagerLayout>
      <h1>Settings</h1>
      <Row className="mt-1">
        <Col md={7}>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-40">
            <div className="select-wrapper mb-24 mb-md-0">
              <FormLabel>PAGE SIZE</FormLabel>
              <div className="mt-2">
                <Select
                  options={getPageOptions()}
                  onChange={handlePageChange}
                  defaultValue={getDefaultValue()}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </ManagerLayout>
  );
};
