import React from 'react';
import { Modal as BsModal, ModalProps } from 'react-bootstrap';
import { useAppDispatch } from '../../store/hooks';

interface IModalProps extends ModalProps {
  show: boolean;
  children: React.ReactNode;
  title: string | React.ReactNode;
  closeOnOutSideClick?: boolean;
  onClose: () => any;
}

export const Modal: React.FC<IModalProps> = ({
  show,
  children,
  title,
  onClose,
  closeOnOutSideClick = true,
  footer,
  ...rest
}: IModalProps) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    const action = onClose();
    if (action && action.type) {
      dispatch(action);
    }
  };

  return (
    <BsModal
      onHide={handleClose}
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={closeOnOutSideClick}
      footer={footer}
      {...rest}
    >
      <BsModal.Header closeButton>
        <BsModal.Title>{title}</BsModal.Title>
      </BsModal.Header>
      <BsModal.Body>{children}</BsModal.Body>
      <BsModal.Footer>{footer}</BsModal.Footer>
    </BsModal>
  );
};

Modal.defaultProps = {
  closeOnOutSideClick: true,
};
