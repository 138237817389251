import React, { useState } from 'react';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { dealerDetailSlice } from 'slices';
import { DealerListViewContainer } from './DealersListView/DealersListViewContainer';
import { DealersDetailViewContainer } from './DealersDetailView/DealersDetailViewContainer';

export const DealersManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState(0);
  const [errorString, setErrorString] = useState('');
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);

  const createDealer = () => {
    setIsUpdateMode(false);
    dispatch(dealerDetailSlice.actions.resetData());
  };

  return (
    <ManagerLayout>
      <h1 className="ml-2 mb-4 mt-3">Dealers Management</h1>
      <hr />

      <div className="d-flex w-100 justify-content-center page-contract-type mb-3">
        <Col md={5} />
        <Col md={5}>
          <div className="alert alert-error text-left font-size-sm">
            {errorString}
          </div>
        </Col>
        <Col md={2}>
          <Button
            onClick={createDealer}
            className="btn-outline-primary create-button"
          >
            New Dealer
          </Button>
        </Col>
      </div>
      <div className="d-flex w-100 justify-content-center page-contract-type">
        <Col md={4} className="mr-20">
          <DealerListViewContainer
            setIsUpdateMode={setIsUpdateMode}
            setSelectedId={setSelectedId}
            setErrorString={setErrorString}
          />
        </Col>
        <Col md={8}>
          <DealersDetailViewContainer
            isUpdateMode={isUpdateMode}
            selectedId={selectedId}
            setErrorString={setErrorString}
          />
        </Col>
      </div>
    </ManagerLayout>
  );
};
