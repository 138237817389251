import React from 'react';
import { Select } from 'components';
import { languageOptions } from 'components/Layout/Header/Language';
import { IOption } from 'components/Select';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateUserSettingsThunk } from 'thunks/account';

export const LanguageContainer = () => {
  const dispatch = useAppDispatch();
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );

  const handleChange = (option: IOption) => {
    dispatch(
      updateUserSettingsThunk({
        language: option.value,
      })
    );
  };
  return (
    <div data-cy="language" className="select-wrapper">
      <Select
        options={languageOptions}
        onChange={handleChange}
        defaultValue={
          userSettings.language &&
          languageOptions.find((lang) => lang.value === userSettings.language)
        }
      />
    </div>
  );
};
