import React from 'react';
import { Container } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import { PublicLayout } from '../../components/Layout';

export const DesktopApp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout mainClasses="page page-desktop-app">
      <Container>
        <h1 className="mt-102 mb-58 fs-34">{t('footer.menu.desktopApp.title')}</h1>
        <div>
          <Trans>
            {t('footer.menu.desktopApp.content')}
          </Trans>
        </div>
      </Container>
    </PublicLayout>
  );

};
