import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CameraIcon } from '../../icons';
import { FILE_TYPES } from '../../constants/constants';

import avatarMillPlaceholder from '../../assets/images/avatar-mill.svg';

export interface IUploadComponentProps {
  setFieldValue: any;
  maxFiles?: number;
  accept?: string;
  fileType: string;
  propField: string;
  values: any[];
  path?: string;
  url?: string;
  disable?: boolean;
}

export const UploadComponent = ({
  setFieldValue,
  accept = 'image/*',
  maxFiles = 1,
  fileType = FILE_TYPES.IMAGE,
  propField = '',
  values,
  path,
  url,
  disable,
}: IUploadComponentProps) => {
  const { t } = useTranslation();
  const [previewUrl, setPreviewUrl] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop: (files) => {
      const newFiles = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFieldValue(propField, newFiles);
    },
  });

  useEffect(() => {
    if (fileType === FILE_TYPES.IMAGE) {
      setPreviewUrl(values && values.length ? values[0].preview : url ?? '');
    }
  }, [values]);

  return (
    <div>
      {fileType === FILE_TYPES.IMAGE && (
        <div {...getRootProps({ className: 'image-upload' })}>
          <input {...getInputProps()} />
          <div
            className="image-upload-image"
            data-cy="image-upload-circle-image"
            style={{
              backgroundImage: `url(${previewUrl || avatarMillPlaceholder})`,
            }}
          >
            <aside className="button">
              <CameraIcon />
            </aside>
          </div>
        </div>
      )}
      {fileType === FILE_TYPES.FILE && (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            className="form-inline form-inline-dealer image-upload-control-bordered"
            data-cy="image-upload-control"
          >
            <div className="input-text col-sm-10 padding-zero">
              <input
                type="text"
                value={disable ? '' : path}
                className="form-control bg-white form-control-bordered input-control"
                readOnly
              />
            </div>
            <div className="browse-btn col-sm-2 padding-zero">
              <Button className="img-upload-btn" disabled={disable}>
                {t('imageUpload.button')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
