import { createSlice } from '@reduxjs/toolkit';
import { companyThunk } from '../thunks/company';
import { IApiAction, IApiState } from '../interfaces/api';
import {
  ICompanyDetail,
  updateCompanyDetailsThunk,
  updateCompanyLandingPageDetailsThunk,
} from '../thunks/settings';
import { millThunk } from '../thunks/mill';
import { IMill } from '../interfaces/mill';

export interface ICompanyState extends IApiState {
  data: ICompanyDetail;
  message: string;
  isLoading: boolean;
}

export const initialState: ICompanyState = {
  data: {
    id: 0,
    name: '',
    address: {
      city: '',
      zipCode: 0,
      country: '',
    },
    files: [],
    logo: {},
  },
  message: '',
  isLoading: false,
};

export const replaceNullValues = (data: ICompanyDetail) => {
  const { id, name, address: addressObj, files, logo } = data;
  const { street, city, zipCode, country } = addressObj;

  return {
    id,
    name: name || '',
    address: {
      street: street || '',
      city: city || '',
      zipCode: zipCode || 0,
      country: country || '',
    },
    files,
    logo,
  };
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setMessage: (state, { payload }) => {
      const { message } = payload;
      state.message = message;
    },
  },
  extraReducers: {
    [updateCompanyDetailsThunk.pending.type]: (state) => {
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };
    },
    [updateCompanyDetailsThunk.fulfilled.type]: (
      state,
      action: IApiAction<ICompanyDetail>
    ) => {
      return {
        ...state,
        data: replaceNullValues(action.payload),
        error: undefined,
        isLoading: false,
      };
    },
    [updateCompanyDetailsThunk.rejected.type]: (
      state,
      action: IApiAction<ICompanyDetail>
    ) => {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    },
    [companyThunk.fulfilled.type]: (
      state,
      action: IApiAction<ICompanyDetail>
    ) => {
      state.data = action.payload;
      state.error = undefined;
    },
    [updateCompanyLandingPageDetailsThunk.fulfilled.type]: (
      state,
      action: IApiAction<IMill>
    ) => {
      const address = { ...state.data.address };
      state.data = { ...action.payload.company, address };
    },
    [millThunk.fulfilled.type]: (state, action: IApiAction<IMill>) => {
      state.data = action.payload.company;
      state.apiStatus = action.meta.requestStatus;
    },
    [millThunk.rejected.type]: (state, action: IApiAction<IMill>) => {
      state.error = action.error;
    },
  },
});
