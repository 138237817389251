import React from 'react';
import classNames from 'classnames';

import { IMessageItem } from '../../../interfaces/message';
import { MessageItem } from './MessageItem';

interface IMessagesListProps {
  items: IMessageItem[];
  isMillRole: boolean;
}

export const MessagesList = ({ items, isMillRole }: IMessagesListProps) => {
  return (
    <ul
      className={classNames('messages-list list-unstyled', {
        mill: isMillRole,
        lab: !isMillRole,
      })}
    >
      {items.map((item) => (
        <MessageItem
          key={item.message.id}
          item={item}
          isMillRole={isMillRole}
        />
      ))}
    </ul>
  );
};
