import React from 'react';

import { IUser } from '../../interfaces/user';

import avatarMillPlaceholder from '../../assets/images/avatar-mill.svg';
import avatarLabPlaceholder from '../../assets/images/avatar-lab.svg';

interface IAvatarImageProps {
  user?: IUser;
}

export const AvatarImage = ({ user = undefined }: IAvatarImageProps) => {
  const isMillRole = !!user?.millRole;

  const millUserAvatar =
    user && user.avatar && user.avatar.url
      ? user.avatar.url
      : avatarMillPlaceholder;
  const labCompanyLogo = user?.company?.logo
    ? user.company.logo.url || user.company.logo
    : avatarLabPlaceholder;

  const placeholder = isMillRole ? millUserAvatar : labCompanyLogo;

  return <img src={placeholder} className="avatar-image" alt="" />;
};

AvatarImage.defaultProps = {
  user: undefined,
};
