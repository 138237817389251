import React, { useEffect } from 'react';
import { Col, Container, FormLabel, Row } from 'react-bootstrap';
import { TableChangeType, TableChangeState } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { Button, Select, Spinner2 } from 'components';
import { UserLayout } from 'components/Layout';
import { PlusIcon } from 'icons';
import { IUser } from 'interfaces/user';
import { clientsSlice, confirmationSlice, toastSlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import i18n from 'translations';
import {
  activationInstructionsThunk,
  blockClientThunk,
  clientsThunk,
  CONFIRMATION_ACTIONS,
  deleteClientThunk,
  IBlockClientPayload,
} from 'thunks/clients';
import { Table } from 'components/Table';
import { Confirmation } from 'components/Modal';
import { IOption } from 'components/Select';
import {
  MILL_CLIENT_COMPANY_INFO_ROUTE,
  MILL_SETTINGS_LANDING_PAGE_ROUTE,
} from 'constants/routes';
import { ClientFormContainer } from '../ClientFormContainer';
import { ActivationModalContainer } from '../ActivationModalContainer';
import { columns, statusOptions } from './columns';
import { ClientsActions } from './ClientsActions';

export const ClientsTableContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedUserId, setSelectedUserId] = React.useState<number>();
  const [selectedBlockStatus, setSelectedBlockStatus] = React.useState<boolean>(
    false
  );
  const { t } = useTranslation();
  const history = useHistory();
  const { count, data, page, sizePerPage, apiStatus } = useAppSelector(
    (state) => state.clients
  );
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );

  const handleActive = (user: IUser) => {
    if (!user) return;
    dispatch(activationInstructionsThunk(user.id));
  };

  const handleAddClient = () => {
    dispatch(clientsSlice.actions.showAddClient());
  };

  const handleDelete = (user: IUser) => {
    if (!user) return;

    setSelectedUserId(user.id);

    dispatch(
      confirmationSlice.actions.show({
        title: i18n.t('clients.deleteConfirm.title'),
        message: i18n.t('clients.deleteConfirm.message'),
        buttonOk: i18n.t('clients.deleteConfirm.buttonOk'),
        buttonCancel: i18n.t('clients.deleteConfirm.buttonCancel'),
        actionKey: CONFIRMATION_ACTIONS.DELETE_CLIENT,
      })
    );
  };

  const handleBlocked = (user: IUser) => {
    // 1- if user object is null/undefinded then do noothing
    if (!user) return;

    // 2- when user is blocked by system then show a message and do nothing
    if (user.blockedBySystem) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('clients.blockNotAllowedToast.title'),
          message: i18n.t('clients.blockNotAllowedToast.message'),
        })
      );
      return;
    }

    // 3- milling center can block/unblock a client now
    setSelectedUserId(user.id);
    const newBlockStatus = !user.blockedByMill;
    setSelectedBlockStatus(newBlockStatus);
    dispatch(
      confirmationSlice.actions.show({
        title: i18n.t(
          newBlockStatus
            ? 'clients.blockedConfirm.title'
            : 'clients.unblockedConfirm.title'
        ),
        message: i18n.t(
          newBlockStatus
            ? 'clients.blockedConfirm.message'
            : 'clients.unblockedConfirm.message'
        ),
        buttonOk: i18n.t(
          newBlockStatus
            ? 'clients.blockedConfirm.buttonOk'
            : 'clients.unblockedConfirm.buttonOk'
        ),
        buttonCancel: i18n.t(
          newBlockStatus
            ? 'clients.blockedConfirm.buttonCancel'
            : 'clients.unblockedConfirm.buttonCancel'
        ),
        actionKey: CONFIRMATION_ACTIONS.BLOCKED_CLIENT,
      })
    );
  };

  const handleConfirmationCallback = (actionKey?: string) => {
    if (actionKey === CONFIRMATION_ACTIONS.DELETE_CLIENT && selectedUserId) {
      dispatch(deleteClientThunk(selectedUserId));
    } else if (actionKey === CONFIRMATION_ACTIONS.LANDING_PAGE) {
      dispatch(confirmationSlice.actions.close());
      history.push(MILL_SETTINGS_LANDING_PAGE_ROUTE);
    } else if (
      actionKey === CONFIRMATION_ACTIONS.BLOCKED_CLIENT &&
      selectedUserId
    ) {
      const params: IBlockClientPayload = {
        userId: selectedUserId,
        blockedByMill: selectedBlockStatus,
      };
      dispatch(blockClientThunk(params));
      dispatch(confirmationSlice.actions.close());
    }
  };

  const handleStatusChange = (option: IOption) => {
    if (option.value === '') {
      dispatch(clientsSlice.actions.clearFilters());
    } else {
      dispatch(
        clientsSlice.actions.setFilters({
          confirmed: option.value,
        })
      );
    }
    dispatch(clientsThunk());
  };

  const handleTableChange = (
    type: TableChangeType,
    state: TableChangeState<IUser>
  ) => {
    if (type === 'pagination') {
      dispatch(
        clientsSlice.actions.setPagination({
          page: state.page,
          sizePerPage: state.sizePerPage,
        })
      );
      dispatch(clientsThunk());
    } else if (type === 'sort') {
      let sort = `${state.sortField}:${state.sortOrder}`;
      if (state.sortField === 'firstName') {
        sort += `,lastName:${state.sortOrder}`;
      }
      dispatch(
        clientsSlice.actions.setSort({
          sort,
        })
      );
      dispatch(clientsThunk());
    }
  };

  const addActionsToTable = () => {
    columns[columns.length - 1].formatter = (cell: any, user: IUser) => {
      return ClientsActions({
        user,
        onActivate: handleActive,
        onDelete: handleDelete,
        onBlocked: handleBlocked,
      });
    };
  };

  const setSizePerPage = () => {
    let pageSize = sizePerPage;
    if (userSettings?.pageSize) {
      pageSize = userSettings.pageSize;
    }

    dispatch(clientsSlice.actions.setPageSize(pageSize));
  };

  useEffect(() => {
    setSizePerPage();
    addActionsToTable();
    dispatch(clientsThunk());
  }, []);

  return (
    <UserLayout mainClasses="page page-clients">
      <div
        data-cy="mill-clients-page"
        className="w-100 py-60 py-md-70 px-20 pl-md-126 pr-md-54"
      >
        <Spinner2 show={apiStatus === 'pending'} />
        <Container fluid>
          <Row>
            <Col>
              <h1 className="fs-44 mb-10">{t('clients.title')}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-40">
                <div className="select-wrapper mb-24 mb-md-0">
                  <FormLabel>{t('clients.status.label')}</FormLabel>
                  <Select
                    options={statusOptions}
                    onChange={handleStatusChange}
                  />
                </div>
                <Button
                  icon={<PlusIcon />}
                  data-cy="add-mill-client"
                  onClick={handleAddClient}
                  size="lg"
                >
                  {t('clients.addClient')}
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                page={page}
                data={data}
                columns={columns}
                totalSize={count}
                sizePerPage={sizePerPage}
                onTableChange={handleTableChange}
                rowEvents={{
                  onClick: (e: any, row: IUser) => {
                    if (e.target.nodeName === 'TD') {
                      history.push(
                        generatePath(MILL_CLIENT_COMPANY_INFO_ROUTE, {
                          id: row.company?.id,
                        })
                      );
                    }
                  },
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <ClientFormContainer />
      <ActivationModalContainer />
      <Confirmation onConfirm={handleConfirmationCallback} />
    </UserLayout>
  );
};
