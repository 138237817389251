import { createSlice } from '@reduxjs/toolkit';
import { IApiState } from '../interfaces/api';
import { downloadAppThunk } from '../thunks/support';

export interface IDownloadappState extends IApiState {
  message: string;
}

export const initialState: IDownloadappState = {
  message: '',
};

export const downloadAppSlice = createSlice({
  name: 'downloadApp',
  initialState,
  reducers: {},
  extraReducers: {
    [downloadAppThunk.pending.type]: (state) => {
      return {
        ...state,
        message: '',
        error: undefined,
      };
    },
    [downloadAppThunk.fulfilled.type]: (state, action: any) => {
      return {
        ...state,
        message: action.message,
        error: undefined,
      };
    },
    [downloadAppThunk.rejected.type]: (state, action: IDownloadappState) => {
      return {
        ...state,
        error: action.error,
      };
    },
  },
});
