import React from 'react';

interface IMainProps {
  children: React.ReactNode;
  additionalClasses?: string;
}

export const Main: React.FC<IMainProps> = ({
  children,
  additionalClasses,
}: IMainProps) => {
  return <main className={`main ${additionalClasses}`}>{children}</main>;
};

Main.defaultProps = {
  additionalClasses: '',
};
