import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IToastPayload {
  title: string;
  message: string;
}

export interface IToastState extends IToastPayload {
  show: boolean;
}

export const initialState: IToastState = {
  show: false,
  title: '',
  message: '',
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    show(state, action: PayloadAction<IToastPayload>) {
      state.show = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
    },
    close(state) {
      state.show = false;
    },
    reset() {
      return { ...initialState };
    },
  },
});
