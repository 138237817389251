import React, { useState, useEffect } from 'react';
import NoDataFound from 'components/NoDataFound';
import { Spinner2 } from 'components/Spinner2';
import { Table } from 'components/Table';
import Api from 'api';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import { TableChangeState } from 'react-bootstrap-table-next';
import { columns } from './columns';
import { getPageSize } from '../Settings';

export const UsersView: React.FC = () => {
  const [state, setState] = useState({ data: [], count: 0, page: 1 });
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize] = useState<number>(+getPageSize());

  const { data, count, page } = state;

  const getPageUrl = (pageNum: number) => {
    return `/v2/analytics/mills/userStats?_start=${
      (pageNum - 1) * pageSize
    }&_limit=${pageSize}`;
  };

  const fetchData = (pageNum: number) => {
    setIsLoading(true);
    Api.get(getPageUrl(pageNum))
      .then((response) => {
        // sort data based on id
        response.data.sort((a: any, b: any) => {
          return a.id - b.id;
        });
        setState({ data: response.data, count: response.count, page: pageNum });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  const handleTableChange = (
    _type: any,
    changedState: TableChangeState<any>
  ) => {
    fetchData(changedState.page);
  };

  return (
    <ManagerLayout>
      <h1 className="ml-2 mb-4 mt-3">hdConnect Users</h1>
      <hr />
      {!isLoading && count > 0 && (
        <Table
          data={data}
          columns={columns}
          sizePerPage={pageSize}
          totalSize={count}
          page={page}
          onTableChange={handleTableChange}
        />
      )}
      {!isLoading && count === 0 && <NoDataFound />}
      <Spinner2 show={isLoading} />
    </ManagerLayout>
  );
};
