import { createSlice } from '@reduxjs/toolkit';
import { IApiState } from '../interfaces/api';
import { ILabContact, sendLabContactEmailThunk } from '../thunks/support';

export interface ILabContactState extends IApiState {
  data: ILabContact;
}

export const initialState: ILabContactState = {
  data: {
    topic: '',
    message: '',
  },
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: {
    [sendLabContactEmailThunk.pending.type]: (state) => {
      return {
        ...state,
        error: undefined,
      };
    },
    [sendLabContactEmailThunk.fulfilled.type]: (state) => {
      return {
        ...state,
        data: { ...initialState.data },
        error: undefined,
      };
    },
    [sendLabContactEmailThunk.rejected.type]: (
      state,
      action: ILabContactState
    ) => {
      return {
        ...state,
        error: action.error,
      };
    },
  },
});
