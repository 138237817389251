import React from 'react';
import { Button as BsButton, ButtonProps } from 'react-bootstrap';
import classNames from 'classnames';

interface IButtonProps extends ButtonProps {
  children: React.ReactNode;
  dimension?: 'sm' | 'md' | 'lg';
  icon?: any;
  full?: boolean;
}

export const Button: React.FC<IButtonProps> = ({
  children,
  icon,
  full,
  ...rest
}: IButtonProps) => {
  const sizes =
    rest.dimension === 'md' ? undefined : { size: rest.dimension || 'lg' };
  const attributes = { ...rest };

  return (
    <BsButton
      {...sizes}
      {...attributes}
      className={classNames(rest.className, { 'btn-full': full })}
    >
      {icon && <span className="btn-icon">{icon}</span>}
      {children}
    </BsButton>
  );
};

Button.defaultProps = {
  dimension: 'md',
  icon: undefined,
  full: false,
};
