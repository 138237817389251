import { createSlice } from '@reduxjs/toolkit';
import { IApiState } from '../interfaces/api';
import { IUser } from '../interfaces/user';
import { clientThunk } from '../thunks/client';
import { defaultAsyncExtraReducers } from '../thunks/helpers';

export interface IClientState extends IApiState {
  data?: IUser;
}

export const initialState: IClientState = {};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: {
    ...defaultAsyncExtraReducers(clientThunk),
  },
});
