import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Alert } from 'react-bootstrap';

const FallBackComponent = () => {
  const { t } = useTranslation();
  return (
    <Container className="mt-50">
      <Alert variant="danger">
        <Alert.Heading className="text-center">
          {t('errorBoundary.errorMessage')}
        </Alert.Heading>
        <p className="text-center mt-10">{t('errorBoundary.errorFix')}</p>
        <hr />
      </Alert>
    </Container>
  );
};
export default FallBackComponent;
