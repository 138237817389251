import { createSlice } from '@reduxjs/toolkit';
import { IApiState, IApiAction } from 'interfaces/api';
import { licenseContentThunk } from 'thunks/license-content';

export interface IProductInfo {
  mainProductInfo: {};
  activationLicenses: [];
  orderedLicenses: [];
  licenseDetails: [];
  nonExpandables: [];
}

export interface ILicenseContentState extends IApiState {
  data: IProductInfo;
}

export const initialState: ILicenseContentState = {
  data: {
    mainProductInfo: {},
    activationLicenses: [],
    orderedLicenses: [],
    licenseDetails: [],
    nonExpandables: [],
  },
};

export const licenseContentSlice = createSlice({
  name: 'licenseContent',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = {
        mainProductInfo: {},
        activationLicenses: [],
        orderedLicenses: [],
        licenseDetails: [],
        nonExpandables: [],
      };
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: {
    [licenseContentThunk.pending.type]: (state) => {
      return {
        ...state,
        error: undefined,
      };
    },
    [licenseContentThunk.rejected.type]: (
      state,
      action: ILicenseContentState
    ) => {
      return {
        ...state,
        error: action.error,
      };
    },
    [licenseContentThunk.fulfilled.type]: (
      state,
      action: IApiAction<IProductInfo>
    ) => {
      state.data = action.payload;

      const arr: any = action.payload.licenseDetails
        ?.filter((x: any) => {
          return x.children.length <= 0;
        })
        .map((y: any) => y.code);
      state.data.nonExpandables = arr;
      state.error = undefined;
    },
  },
});
