import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { Spinner2 } from 'components';
import { INotification } from 'slices/auth';
import { markNotificationReadState } from 'thunks/auth';
import { UserLayout } from '../../../components/Layout';
import {
  MILL_ORDERS_ROUTE,
  LAB_ORDERS_ROUTE,
  MESSAGES_PARTIAL_ROUTE,
  MILL_MESSAGES_ROUTE,
  LAB_MESSAGES_ROUTE,
  MILL_CLIENT_COMPANY_INFO_ROUTE,
} from '../../../constants/routes';
import { Confirmation } from '../../../components/Modal';
import { useMillRole } from '../../../hooks/role';
import { ArrowLeftIcon } from '../../../icons';
import { IComponentParam } from '../../../interfaces/api';
import { ComponentStatus } from '../../../interfaces/component';
import { componentSlice, confirmationSlice } from '../../../slices';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import i18n from '../../../translations';
import {
  componentThunk,
  deleteDownloadThunk,
  downloadAllDocumentsThunk,
  updateComponentThunk,
} from '../../../thunks/component';
import { BasicInfo } from './BasicInfo';
import { Details } from './Details';
import { MessageContainer } from './MessagesContainer';
import { UploadDownloadContainer } from './UploadDownloadContainer';
import { updateOrderThunk } from '../../../thunks/orders';
import { responsiblePersonsThunk } from '../../../thunks/responsible-persons';

export interface IPrevState {
  from: string | null;
}

export const ComponentContainer: React.FC = () => {
  const { t } = useTranslation();
  const { id, projectId } = useParams<IComponentParam>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(
    (state) => state.auth.data.notifications
  );
  const { data, apiStatus } = useAppSelector((state) => state.component);
  const { apiStatus: responsibleApiStatus } = useAppSelector(
    (state) => state.responsiblePersons
  );

  const [downloadId, setDownloadId] = useState<number | undefined>(undefined);
  const isMillRole = useMillRole();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const getGoBackToOrdersPage = () => {
    return isMillRole ? MILL_ORDERS_ROUTE : LAB_ORDERS_ROUTE;
  };

  const getGoBackToMessagesPage = () => {
    return isMillRole ? MILL_MESSAGES_ROUTE : LAB_MESSAGES_ROUTE;
  };

  const routeState = history.location.state as IPrevState;
  const navigatedFrom = routeState && routeState.from ? routeState.from : '';

  const navigateBackToMsgsPage = navigatedFrom.includes(MESSAGES_PARTIAL_ROUTE);
  const backArrowText = navigateBackToMsgsPage
    ? t('component.messages.title')
    : t('component.orders');

  useEffect(() => {
    if (!notifications || !notifications.length) return;

    const notificationIds: number[] = [];
    notifications.forEach((n: INotification) => {
      if (
        n?.parentObjectId === +projectId &&
        n?.objectId === +id &&
        !n.isRead
      ) {
        notificationIds.push(n.notificationId);
      }
    });

    if (!notificationIds.length) return;

    dispatch(markNotificationReadState({ read: true, notificationIds }));
  }, []);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsProcessing(true);
        dispatch(responsiblePersonsThunk());
        await dispatch(componentThunk({ id, projectId }));
        setIsProcessing(false);
      }
    })();
  }, []);

  const handleBack = () => {
    const goBackUrl = navigateBackToMsgsPage
      ? getGoBackToMessagesPage()
      : getGoBackToOrdersPage();
    history.push(goBackUrl);
  };

  const handleStatusChange = async (value: ComponentStatus) => {
    setIsProcessing(true);
    await dispatch(
      updateComponentThunk({
        id,
        status: value,
        projectId,
      })
    );
    dispatch(componentSlice.actions.setComponentStatus({ value }));
    setIsProcessing(false);
  };

  const handleResponsibleChange = async (value: string) => {
    if (data?.project && data?.project.id) {
      setIsProcessing(true);
      await dispatch(
        updateOrderThunk({
          id: data?.project.id,
          millId: data?.project.millId,
          labId: data?.project.labId,
          responsible: value || null,
        })
      );
      setIsProcessing(false);
    }
  };

  const handleDownloadConfirmation = (docId: number) => {
    setDownloadId(docId);
    dispatch(
      confirmationSlice.actions.show({
        title: i18n.t('component.download.deleteConfirm.title'),
        message: i18n.t('component.download.deleteConfirm.message'),
        buttonOk: i18n.t('component.download.deleteConfirm.buttonOk'),
        buttonCancel: i18n.t('component.download.deleteConfirm.buttonCancel'),
      })
    );
  };

  const handleDownloadDelete = async () => {
    if (downloadId) {
      setIsProcessing(true);
      await dispatch(deleteDownloadThunk(downloadId));
      setIsProcessing(false);
    }
  };

  const handleDownloadUploadModal = () => {
    dispatch(componentSlice.actions.showUploadDownload());
  };

  const handleDownloadAllDocuments = async () => {
    setIsProcessing(true);
    await dispatch(downloadAllDocumentsThunk({ projectId, componentId: id }));
    setIsProcessing(false);
  };

  const handleUserClick = () => {
    if (data?.project?.author?.company?.id) {
      const link = generatePath(MILL_CLIENT_COMPANY_INFO_ROUTE, {
        id: data?.project?.author?.company?.id,
      });
      history.push(link, { from: history.location.pathname });
    }
  };

  return (
    <UserLayout mainClasses="page page-component">
      <div className="w-100 py-60 py-md-70 px-20 pl-md-126 pr-md-54">
        <Spinner2 show={isProcessing} />
        <Container fluid>
          {apiStatus === 'fulfilled' && responsibleApiStatus === 'fulfilled' && (
            <>
              <Link to="#" onClick={handleBack} className="btn-back">
                <ArrowLeftIcon /> <span>{backArrowText}</span>
              </Link>

              <h1 className="fs-34 mt-24">{data?.name}</h1>
              <div className="mb-44">
                <small>{data?.displayName}</small>
              </div>
              <BasicInfo
                component={data}
                isMillRole={isMillRole}
                onStatusChange={handleStatusChange}
                onResponsibleChange={handleResponsibleChange}
                onUserClick={handleUserClick}
              />
              <Details
                component={data}
                isMillRole={isMillRole}
                onDownloadDelete={handleDownloadConfirmation}
                onDownloadUpload={handleDownloadUploadModal}
                onDownloadAllDocuments={handleDownloadAllDocuments}
              />
              <h4 className="mb-30">{t('component.messages.title')}</h4>
              <MessageContainer />
            </>
          )}
        </Container>
      </div>
      <Confirmation onConfirm={handleDownloadDelete} />
      <UploadDownloadContainer />
    </UserLayout>
  );
};
