import { createSlice } from '@reduxjs/toolkit';
import { IApiAction, IApiState } from 'interfaces/api';
import {
  addOnsSubscriptionThunk,
  getAddOnsListThunk,
  getMillAddOnThunk,
  IAddOn,
  unsubscribePlanThunk,
} from 'thunks/payments';

// THIS KEY IS ALSO USED ON STRIPE TO GET RELEVANT ADDON, KEEP IN MIND WHEN EDITING
export const FILES_KEEP_DURATION_ADDON = {
  DURATION_FREE: { key: 'files_duration_free', value: 30, price: 0 },
  DURATION_150X: { key: 'files_duration_150x', value: 45, price: 5 },
  DURATION_200X: { key: 'files_duration_200x', value: 60, price: 10 },
  DURATION_250X: { key: 'files_duration_250x', value: 75, price: 15 },
  DURATION_300X: { key: 'files_duration_300x', value: 90, price: 20 },
};

export interface ISubscriptionUnits extends IApiState {
  filesKeepDurationKey: string;
  addOns: Array<IAddOn>;
}

export const initialState: ISubscriptionUnits = {
  filesKeepDurationKey: '',
  addOns: [],
};
export const paymentsSlice = createSlice({
  name: 'payments-slice',
  initialState,
  reducers: {},
  extraReducers: {
    [unsubscribePlanThunk.rejected.type]: (
      state,
      action: IApiAction<IApiState>
    ) => {
      state.error = action.payload.error;
    },
    [addOnsSubscriptionThunk.fulfilled.type]: (
      state: ISubscriptionUnits,
      action: IApiAction<ISubscriptionUnits>
    ) => {
      state.filesKeepDurationKey = action.payload.filesKeepDurationKey;
    },
    [getMillAddOnThunk.fulfilled.type]: (
      state: ISubscriptionUnits,
      action: IApiAction<any>
    ) => {
      state.filesKeepDurationKey = action.payload.filesKeepDurationKey;
    },
    [getAddOnsListThunk.fulfilled.type]: (
      state: ISubscriptionUnits,
      action: IApiAction<any>
    ) => {
      state.addOns = action.payload?.sort(
        (a: IAddOn, b: IAddOn) => a.value - b.value
      );
    },
  },
});
