import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Button } from 'components';
import { IPasswordPayload } from 'thunks/account';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { setSessionStorage } from 'utils/localStorage';

interface IPasswordFormProps {
  initialValues: IPasswordPayload;
  validationSchema: any | (() => any);
  onSubmit: (
    values: IPasswordPayload,
    form: FormikHelpers<IPasswordPayload>
  ) => void | Promise<any>;
}

export const PasswordForm = ({
  initialValues,
  validationSchema,
  onSubmit,
}: IPasswordFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
          setSessionStorage(TAB_FORM_IS_DIRTY, `${dirty}`);
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form data-cy="user-password-form" onSubmit={handleSubmit}>
                <Form.Group controlId="password">
                  <Form.Label>
                    {t('account.settings.password.label')}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    data-cy="input-password"
                    placeholder={t('account.settings.password.placeholder')}
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                  />
                </Form.Group>

                <Form.Group className="mb-32" controlId="passwordConfirmation">
                  <Form.Label>
                    {t('account.settings.passwordConfirmation.label')}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="passwordConfirmation"
                    data-cy="input-password-confirmation"
                    placeholder={t(
                      'account.settings.passwordConfirmation.placeholder'
                    )}
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    isInvalid={
                      touched.passwordConfirmation &&
                      !!errors.passwordConfirmation
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {!!errors && errors.passwordConfirmation}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  dimension="md"
                  type="submit"
                  className="btn-update-password'"
                >
                  {t('account.settings.updatePassword')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
