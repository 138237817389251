import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { signupSlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PublicLayout } from '../../components/Layout';
import { activePolicyDocsThunk } from 'thunks/policy-documents';
import { Select, Spinner2 } from 'components';
import { IOption } from 'components/Select';
import { POLICY_LANG_OPTIONS } from 'constants/constants';

export const TermsOfService: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data, apiStatus } = useAppSelector((state) => state.activePolicies);
  const [pdfLink, setPdfLink] = useState<string | undefined>('');
  const [isDataAvailable, setDataAvailable] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const LANGUAGEOPTIONS = [
    { value: 'EN', label: 'EN' },
    { value: 'DE', label: 'DE' },
  ];
  const handleLanguage = (lang: IOption) => {
    if (!data) {
      setDataAvailable(false);
    } else {
      if (lang.value === POLICY_LANG_OPTIONS.ENGLISH) {
        if (data?.termsOfServiceDocENSignedUrl) {
          setDataAvailable(true);
          setPdfLink(data?.termsOfServiceDocENSignedUrl);
        } else if (data?.termsOfServiceDocDESignedUrl) {
          setDataAvailable(true);
          setPdfLink(data?.termsOfServiceDocDESignedUrl);
        }
        else {
          setDataAvailable(false);
        }
      }
      else {
        if (data?.termsOfServiceDocDESignedUrl) {
          setDataAvailable(true);
          setPdfLink(data?.termsOfServiceDocDESignedUrl);
        } else if (data?.termsOfServiceDocENSignedUrl) {
          setDataAvailable(true);
          setPdfLink(data?.termsOfServiceDocENSignedUrl);
        }
        else {
          setDataAvailable(false);
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsProcessing(true);
      dispatch(signupSlice.actions.close());
      await dispatch(activePolicyDocsThunk());
      setIsProcessing(false);
    })();
  }, []);

  useEffect(() => {
    if (apiStatus === 'fulfilled') {
      if (data) {
        const url = data?.termsOfServiceDocENSignedUrl ? data?.termsOfServiceDocENSignedUrl : data?.termsOfServiceDocDESignedUrl;
        if (url) {
          setDataAvailable(true)
          setPdfLink(url);
        }
      };
    }
  }, [apiStatus]);

  return (
    <><Spinner2 show={isProcessing} /><PublicLayout mainClasses="page page-privacy-policy">
      <Container className='content-container policy-public-page'>
        <Row className='d-flex justify-content-between'>
          <h1 className="policy-public-page-heading mt-12 mb-8 fs-28">{t('footer.menu.termsOfService.title')}</h1>
          {isDataAvailable && <div className='lang-select mt-12 mb-8 align-items-center ml-auto'>
            <Select
              options={LANGUAGEOPTIONS}
              onChange={handleLanguage}
              defaultValue={LANGUAGEOPTIONS.find(
                (opt) => opt.value === POLICY_LANG_OPTIONS.ENGLISH
              )}
            />
          </div>
          }
        </Row>
        {isDataAvailable && <Row className='d-flex justify-content-between'>
          <div className='iframe-div-width policy-public-page-heading'>
            <iframe className='mb-20 iframe-height' src={pdfLink}></iframe>
          </div>
          <Col></Col>
        </Row>
        }
        {!isDataAvailable && <Row className='d-flex justify-content-between'>
          <div className='iframe-div-width policy-public-page-heading'>
            <Trans>
              {t('footer.menu.termsOfService.content')}
            </Trans>
          </div>
        </Row>
        }
      </Container>
    </PublicLayout></>
  );
};
