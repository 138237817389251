import React from 'react';
import { CompanyLogo } from 'components/User/CompanyLogo';
import { useMillRole } from 'hooks/role';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks';
import { FormInfo } from './FormInfo';

export const CompanyInfo = () => {
  const { data, apiStatus } = useAppSelector((state) => state.lab);
  const { t } = useTranslation();
  const isMillRole = useMillRole();

  return (
    <>
      {apiStatus === 'fulfilled' && (
        <div data-cy="client-company-info" className="company-info">
          <div className="mb-40">
            <CompanyLogo logo={data?.company?.logo} isMillRole={isMillRole} />
          </div>

          <h4 className="mb-18">{t('account.company.information')}</h4>
          <FormInfo
            label={t('companyName.label')}
            value={data?.company?.name}
            dataCy="client-company-name"
          />
          <FormInfo
            label={t('web.label')}
            value={data?.company?.web}
            dataCy="client-company-web"
          />

          <h4 className="mb-18">{t('account.company.contact')}</h4>
          <FormInfo
            label={t('clients.columns.contactPerson')}
            dataCy="client-company-contact-person"
            value={`${data?.company?.contactPerson || ''}`}
          />
          <FormInfo
            label={t('email.label')}
            value={data?.company?.email}
            dataCy="client-company-contact-email"
          />
          <FormInfo
            label={t('phone.label')}
            value={data?.company?.address?.phone}
            parentClasses="mb-40"
            dataCy="client-company-contact-phone"
          />

          <h4 className="mb-18">{t('account.company.address')}</h4>

          <FormInfo
            label={t('state.label')}
            value={data?.company?.address?.state}
            dataCy="client-company-address-state"
          />
          <FormInfo
            label={t('street.label')}
            value={data?.company?.address?.street}
            dataCy="client-company-address-street"
          />
          <FormInfo
            label={t('city.label')}
            value={data?.company?.address?.city}
            dataCy="client-company-address-city"
          />
          <FormInfo
            label={t('zip.label')}
            value={`${data?.company?.address?.zipCode || ''}`}
            parentClasses="mb-40"
            dataCy="client-company-address-zipcode"
          />

          <h4 className="mb-18">{t('account.company.shipping.title')}</h4>
          <FormInfo
            label={t('street.label')}
            value={
              data?.shippingAtSameAddress
                ? data?.company?.address?.street
                : data?.shippingAddress?.street
            }
            dataCy="client-company-shipping-address-street"
          />
          <FormInfo
            label={t('city.label')}
            value={
              data?.shippingAtSameAddress
                ? data?.company?.address?.city
                : data?.shippingAddress?.city
            }
            dataCy="client-company-shipping-address-city"
          />
          <FormInfo
            label={t('zip.label')}
            value={`${
              data?.shippingAtSameAddress
                ? data?.company?.address?.zipCode || 0
                : data?.shippingAddress?.zipCode || 0
            }`}
            dataCy="client-company-shipping-address-zipcode"
          />
        </div>
      )}
    </>
  );
};
