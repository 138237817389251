import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { authSlice, isTokenPresent } from '../../../slices/auth';
import { signinSlice } from '../../../slices/signin';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Language } from './Language';
import labCustomLogo from '../../../assets/images/custom-logo.svg';
import { placeholderImage } from '../../../utils/image';
import { ROOT_ROUTE } from '../../../constants/routes';

export const PublicLabHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data, apiStatus } = useAppSelector((state) => state.mill);

  const handleSignIn = () => {
    dispatch(signinSlice.actions.show());
  };

  const handleLogout = () => {
    const payload = { redirectTo: ROOT_ROUTE };
    dispatch(authSlice.actions.logout());
    dispatch(authSlice.actions.gotoHome(payload));
  };

  return (
    <>
      <Navbar expand="md" className="h-100 user-navbar lab-navbar">
        <div className="user-navbar-brand">
          {apiStatus === 'fulfilled' && (
            <img
              src={placeholderImage(data.company.logo, labCustomLogo)}
              alt=""
            />
          )}
        </div>
        <Navbar.Toggle aria-controls="public-navbar-nav" />
        <Navbar.Collapse id="public-navbar-nav">
          <Nav className="ml-auto align-items-center">
            {isTokenPresent() ? (
              <Nav.Link
                data-cy="public-lab-header-signout"
                onClick={handleLogout}
              >
                {t('signOut.title')}
              </Nav.Link>
            ) : (
              <Nav.Link
                data-cy="public-lab-header-signin"
                onClick={handleSignIn}
              >
                {t('signIn.title')}
              </Nav.Link>
            )}
            <Language />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
