import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { LangIcon } from '../../../icons';
import { IOption } from '../../Select';
import i18n from '../../../translations';
import { APP_LANGUAGE } from '../../../constants/constants';

export const languageOptions: IOption[] = [
  { value: 'en', label: i18n.t('language.en') },
  { value: 'de', label: i18n.t('language.de') },
  { value: 'it', label: i18n.t('language.it') },
  { value: 'fr', label: i18n.t('language.fr') },
  { value: 'sp', label: i18n.t('language.sp') },
  { value: 'pl', label: i18n.t('language.pl') },
];

export const Language: React.FC = () => {
  const handleLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem(APP_LANGUAGE, language);
    window.location.reload();
  };

  return (
    <NavDropdown
      title={<LangIcon />}
      id="language-dropdown"
      className="language-dropdown"
    >
      <div className="arrow right" />
      {languageOptions.map((lang) => (
        <NavDropdown.Item
          key={lang.value}
          eventKey={lang.value}
          href="#"
          onClick={() => handleLanguage(lang.value)}
        >
          {lang.label}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};
