import React from 'react';
import { FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import i18n from 'translations';

import { IPasswordPayload, updatePasswordThunk } from 'thunks/account';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { getSessionStorageItem } from 'utils/localStorage';
import { PasswordForm } from './PasswordForm';

const schema = yup.object().shape({
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], `${i18n.t('password.mismatch')}`),
});

export const PasswordFormContainer = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);
  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleSubmit = (
    values: IPasswordPayload,
    form: FormikHelpers<IPasswordPayload>
  ) => {
    dispatch(updatePasswordThunk(values));
    form.resetForm();
  };

  return (
    <PasswordForm
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={
        {
          password: '',
          passwordConfirmation: '',
        } as IPasswordPayload
      }
    />
  );
};
