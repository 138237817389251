import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserLayout } from '../../../components/Layout';
import { ITabConfig } from '../../../components/TabsControl/TabsControl';
import { TabsControl } from '../../../components/TabsControl';
import { ContactFormContainer } from './Contact/ContactFormContainer';
import { DownloadAppFormContainer } from './DownloadApp/DownloadAppFormContainer';
import i18n from '../../../translations';
import {
  LAB_SUPPORT_CONTACT_ROUTE,
  LAB_SUPPORT_DOWNLOAD_APP_ROUTE,
} from '../../../constants/routes';
import { useQuery } from '../../../hooks/query';

const tabs: ITabConfig[] = [
  {
    eventKey: 'contact',
    title: i18n.t('support.tabs.contact'),
    children: (
      <Row>
        <Col lg={{ span: 7 }}>
          <p className="fs-20 lh-26 mb-60">{i18n.t('support.contact.info')}</p>
          <ContactFormContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'download-app',
    title: i18n.t('support.tabs.downloadApp'),
    children: (
      <Row>
        <Col lg={{ span: 7 }}>
          <DownloadAppFormContainer />
        </Col>
      </Row>
    ),
  },
];

export const Support = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();

  const handleTabChange = (eventKey: any) => {
    if (eventKey === 'contact') {
      history.push(LAB_SUPPORT_CONTACT_ROUTE);
    } else if (eventKey === 'download-app') {
      history.push(LAB_SUPPORT_DOWNLOAD_APP_ROUTE);
    }
  };

  return (
    <UserLayout mainClasses="page page-tabs flex-column">
      <div className="page-header py-60 py-md-70 px-20 pl-md-126 pr-md-54">
        <Container fluid>
          <Row>
            <Col>
              <h1 className="fs-44 mb-88">{t('support.title')}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="px-20 pl-md-126 pr-md-54">
        <TabsControl
          activeKey={query.get('tab') || 'contact'}
          id="support"
          tabs={tabs}
          onTabChange={handleTabChange}
        />
      </div>
    </UserLayout>
  );
};
