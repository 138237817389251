import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import { Spinner } from '../../components';
import { PublicLabLayout } from '../../components/Layout';
import { API_TOKEN } from '../../constants/constants';
import { useQuery } from '../../hooks/query';
import { useLabRole } from '../../hooks/role';
import { IMillIdParam } from '../../interfaces/api';
import { authSlice, resetSlice, signinSlice } from '../../slices';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { confirmationThunk, meThunk } from '../../thunks/auth';
import { millThunk } from '../../thunks/mill';
import { simpleFormat } from '../../utils/format';
import { getLocalStorageItem } from '../../utils/localStorage';
import { LandingPage } from '../Shared/LandingPage/LandingPage';

export const HomeLab: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const [invitationPopupIsShown, setInvitationPopupIsShown] = React.useState(
    false
  );

  const { data, apiStatus } = useAppSelector((state) => state.mill);
  const { data: authData, apiStatus: authApiStatus } = useAppSelector(
    (state) => state.auth
  );
  const { millId } = useParams<IMillIdParam>();
  const isLabRole = useLabRole();
  const query = useQuery();
  const jwt = getLocalStorageItem(API_TOKEN);

  const handleSignIn = () => {
    dispatch(signinSlice.actions.show());
  };

  useEffect(() => {
    const activation = query.get('activation');
    const confirmation = query.get('confirmation');

    if (authApiStatus === 'fulfilled' && !invitationPopupIsShown) {
      setInvitationPopupIsShown(true);
      if (jwt && activation && isLabRole) {
        dispatch(authSlice.actions.showLoggedInUserInvitationModal());
      } else if (activation && isLabRole) {
        dispatch(authSlice.actions.showAccountCreation());
      } else if (activation) {
        dispatch(authSlice.actions.showCreateNewAccountModal());
      } else if (confirmation) {
        dispatch(confirmationThunk({ confirmation }));
      }
    }

    if (!jwt && !invitationPopupIsShown) {
      const containsLabHomePath = location.pathname.includes('/lab/home');
      setInvitationPopupIsShown(true);
      if (activation && containsLabHomePath) {
        dispatch(authSlice.actions.showAccountCreation());
      } else if (activation) {
        dispatch(authSlice.actions.showCreateNewAccountModal());
      } else if (confirmation) {
        dispatch(confirmationThunk({ confirmation }));
      }
    }

    const code = query.get('code');
    if (code) {
      dispatch(resetSlice.actions.show());
    }
  }, [authApiStatus]);

  useEffect(() => {
    if (millId) {
      dispatch(millThunk(millId));
    }
    if (jwt) {
      dispatch(meThunk());
    }
  }, []);

  return (
    <PublicLabLayout mainClasses="page-home-lab">
      {apiStatus === 'fulfilled' ? (
        <div className="page-container">
          <LandingPage
            logo={data.company?.logo}
            landingPageHeader={data.landingPageHeader || t('labWelcome.title')}
            landingPageContent={simpleFormat(
              data.landingPageContent || t('labWelcome.content')
            )}
            onSignin={handleSignIn}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </PublicLabLayout>
  );
};
