import React from 'react';

import { SettingsFirstColumn } from './SettingsFirstColumn';
import { SettingsThirdColumn } from './SettingsThirdColumn';
import { SettingsSecondColumn } from './SettingsSecondColumn';

export const UserSettings = () => {
  return (
    <div className="d-flex">
      <div className="col-4">
        <SettingsFirstColumn />
      </div>
      <div className="col-4">
        <SettingsSecondColumn />
      </div>
      <div className="col-4">
        <SettingsThirdColumn />
      </div>
    </div>
  );
};
