import React from 'react';

export interface IEmptyStateProps {
  icon?: any;
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export const EmptyState: React.FC<IEmptyStateProps> = ({
  icon = undefined,
  title,
  subtitle,
  children,
}: IEmptyStateProps) => {
  return (
    <div className="empty-state">
      {icon && <div className="empty-state-icon mb-24">{icon}</div>}
      <div className="empty-state-title mb-10">{title}</div>
      {subtitle && <div className="empty-state-subtitle">{subtitle}</div>}
      {children}
    </div>
  );
};
