import React from 'react';
import RSelect, { components, MenuPlacement } from 'react-select';
import { DropDownIcon } from '../../icons';

export interface IOption {
  value: any;
  label: string;
}

export interface ISelectProps {
  options: IOption[];
  isSearchable?: boolean;
  size?: 'sm' | 'lg';
  defaultValue?: any;
  value?: any;
  isInvalid?: boolean;
  isDisabled?: boolean;
  menuPlacement?: MenuPlacement;
  onChange?: (value: any) => void;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon />
    </components.DropdownIndicator>
  );
};

export const Select: React.FC<ISelectProps> = ({
  options,
  isSearchable = true,
  size = 'lg',
  onChange,
  defaultValue,
  value,
  isInvalid = false,
  isDisabled = false,
  menuPlacement = 'auto',
  ...rest
}: ISelectProps) => {
  return (
    <RSelect
      options={options}
      defaultValue={defaultValue || (options && options[0])}
      value={value}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      onChange={onChange}
      data-cy="bs-select-control"
      className={`select-container bs-select-control ${size} ${
        isInvalid ? 'error-border is-invalid' : ''
      }`}
      classNamePrefix="select"
      components={{ DropdownIndicator }}
      menuPlacement={menuPlacement}
      {...rest}
    />
  );
};
