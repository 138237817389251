import React, { useEffect } from 'react';
import { Col, Container, FormLabel, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { EmptyState, Select, Spinner2 } from 'components';
import { UserLayout } from 'components/Layout';
import { Pagination } from 'components/Pagination';
import { IOption } from 'components/Select';
import { useMillRole } from 'hooks/role';
import { MessageEmptyIcon } from 'icons';
import { messagesSlice } from 'slices';
import { isWinAppViewerMode } from 'slices/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { messagesThunk } from 'thunks/messages';
import i18n from 'translations';
import { MessagesList } from './MessagesList';

export const statusOptions = [
  { value: '', label: i18n.t('messages.status.any') },
  { value: true, label: i18n.t('messages.status.read') },
  { value: false, label: i18n.t('messages.status.unread') },
];

export const MessagesContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data, count, sizePerPage, page, apiStatus } = useAppSelector(
    (state) => state.messages
  );
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );
  const isMillRole = useMillRole();
  const isWinAppViewerModeEnabled = isWinAppViewerMode();

  const setSizePerPage = () => {
    let pageSize = sizePerPage;
    if (userSettings?.pageSize) {
      pageSize = userSettings.pageSize;
    }

    dispatch(messagesSlice.actions.setPageSize(pageSize));
  };

  useEffect(() => {
    setSizePerPage();
    dispatch(messagesThunk());
  }, []);

  const handleStatusChange = (option: IOption) => {
    if (option.value === '') {
      dispatch(messagesSlice.actions.clearFilters());
    } else {
      dispatch(
        messagesSlice.actions.setFilters({
          read: option.value,
        })
      );
    }
    dispatch(messagesThunk());
  };

  const handlePageClick = (num: number) => {
    dispatch(
      messagesSlice.actions.setPagination({
        page: num,
        sizePerPage,
      })
    );
    dispatch(messagesThunk());
  };

  const handlePrevClick = () => {
    dispatch(
      messagesSlice.actions.setPagination({
        page: page - 1,
        sizePerPage,
      })
    );
    dispatch(messagesThunk());
  };

  const handleNextClick = () => {
    dispatch(
      messagesSlice.actions.setPagination({
        page: page + 1,
        sizePerPage,
      })
    );
    dispatch(messagesThunk());
  };

  const padding = isWinAppViewerModeEnabled
    ? 'py-30 py-md-30 pl-md-30'
    : 'py-60 py-md-70 pl-md-126';

  return (
    <UserLayout
      mainClasses={`page page-messages ${
        isWinAppViewerModeEnabled ? 'pt-0' : ''
      }`}
    >
      <div className={`w-100 ${padding} px-20 pr-md-54 d-flex flex-column`}>
        <Spinner2 show={apiStatus === 'pending'} />
        <Container fluid>
          <Row>
            <Col>
              <h1 className="fs-44 mb-20">{t('messages.title')}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="select-wrapper mb-24 mb-md-70">
                <FormLabel>{t('messages.title')}</FormLabel>
                <Select options={statusOptions} onChange={handleStatusChange} />
              </div>
            </Col>
          </Row>
          {Boolean(data.length) && (
            <>
              <Row>
                <Col>
                  <MessagesList items={data} isMillRole={isMillRole} />
                  <Pagination
                    page={page}
                    sizePerPage={sizePerPage}
                    count={count}
                    onPageClick={handlePageClick}
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
        {apiStatus === 'fulfilled' && !data.length && (
          <EmptyState
            icon={<MessageEmptyIcon />}
            title={t('messages.noMessages')}
          />
        )}
      </div>
    </UserLayout>
  );
};
