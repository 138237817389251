import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiMeta } from '../interfaces/api';
import { IMessageItem } from '../interfaces/message';
import { ITable } from '../interfaces/table';
import { messagesThunk } from '../thunks/messages';
import {
  defaultTableState,
  filterSlice,
  paginationSlice,
} from '../utils/table';

export interface IMessagesState extends ITable<IMessageItem> {}

export const initialState: IMessagesState = {
  ...defaultTableState(),
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setPageSize(state, action: PayloadAction<number>) {
      state.sizePerPage = action.payload;
    },
    ...paginationSlice<IMessagesState>(),
    ...filterSlice<IMessagesState>(),
  },
  extraReducers: {
    [messagesThunk.fulfilled.type]: (
      state,
      action: PayloadAction<ITable<IMessageItem>, string, IApiMeta>
    ) => {
      state.count = action.payload.count;
      state.data = action.payload.data;
      state.apiStatus = action.meta.requestStatus;
    },
    [messagesThunk.rejected.type]: (
      state,
      action: PayloadAction<ITable<IMessageItem>, string, IApiMeta>
    ) => {
      state.apiStatus = action.meta.requestStatus;
    },
    [messagesThunk.pending.type]: (
      state,
      action: PayloadAction<ITable<IMessageItem>, string, IApiMeta>
    ) => {
      state.apiStatus = action.meta.requestStatus;
    },
  },
});
