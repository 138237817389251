import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../api';
import { ILab } from '../interfaces/mill';
import { redirectRoute } from './settings';
import { toastSlice, usersSlice } from '../slices';
import i18n from '../translations';
import { LAB_WELCOME_ROUTE, LAB_ORDERS_ROUTE } from '../constants/routes';

export interface IMillProfilePayload {
  firstName: string;
  lastName: string;
  email: string;
  files: any[];
}

export interface ILabPayload extends ILab {
  files: any[];
  ordersCount?: number;
  dataBaseStatusIsUpdated?: boolean;
}

export interface IPasswordPayload {
  password: string;
  passwordConfirmation: string;
}

export interface IUserSettingsPayload {
  [key: string]: boolean | string;
}

export interface ILanguagePayload {
  language: string;
}

export const updateMillProfileThunk = createAsyncThunk(
  'users/mill/profile',
  async (payload: IMillProfilePayload, { dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();
      if (payload.files.length) {
        formData.append('files.avatar', payload.files[0]);
      }
      formData.append('data', JSON.stringify({ ...payload, files: undefined }));

      const response = await Api.put('/users/profile', formData);

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('account.updateProfileToast.title'),
          message: i18n.t('account.updateProfileToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePasswordThunk = createAsyncThunk(
  'auth/password',
  async (payload: IPasswordPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.put('/auth/password', payload);

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('account.updatePasswordToast.title'),
          message: i18n.t('account.updatePasswordToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserSettingsThunk = createAsyncThunk(
  'users/settings',
  async (payload: IUserSettingsPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.put('/users/settings', payload);

      if (payload.language) {
        const language = payload.language as string;
        dispatch(usersSlice.actions.updateUserLanguage(language));
      }

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('account.updateNotificationToast.title'),
          message: i18n.t('account.updateNotificationToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateLanguageThunk = createAsyncThunk(
  'users/language',
  async (payload: ILanguagePayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.put('/users/language', payload);

      dispatch(usersSlice.actions.updateUserLanguage(payload.language));

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('account.updateLanguageToast.title'),
          message: i18n.t('account.updateLanguageToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCompanyInfoThunk = createAsyncThunk(
  'users/companyInfo',
  async (payload: ILabPayload, { dispatch, rejectWithValue }) => {
    const { dataBaseStatusIsUpdated, ordersCount } = payload;
    try {
      const formData = new FormData();
      if (payload.files.length) {
        formData.append('files.logo', payload.files[0]);
      }
      formData.append('data', JSON.stringify({ ...payload, files: undefined }));

      const response = await Api.put('/users/companyInfo', formData);

      if (!dataBaseStatusIsUpdated) {
        redirectRoute(ordersCount ? LAB_ORDERS_ROUTE : LAB_WELCOME_ROUTE, 1000);
      }

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('account.updateCompanyToast.title'),
          message: i18n.t('account.updateCompanyToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
