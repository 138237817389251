import countriesList from 'country-list';
import i18n from 'translations';

const selectACountry = { value: '', label: i18n.t('countries.empty') };

const countries = countriesList
  .getData()
  .sort((a: any, b: any) => a.name.localeCompare(b.name))
  .map((o) => ({ value: o.code, label: o.name }));

const countriesDict = countries.reduce((map, nextObj) => {
  map.set(nextObj.value, nextObj);
  return map;
}, new Map());

export const getCountriesList = () => {
  return [selectACountry, ...countries];
};

export const getSelectedCountry = (value: string = '') => {
  if (!value) return selectACountry;
  return countriesDict.get(value.toUpperCase());
};
