import { ColumnDescription } from 'react-bootstrap-table-next';

import i18n from 'translations';

export const columns: ColumnDescription<any, any>[] = [
  {
    dataField: 'description',
    text: i18n.t('contract.tabs.invoices.itemDesc'),
  },
  {
    dataField: 'amount',
    text: i18n.t('contract.tabs.billing.amount'),
    sort: true,
    formatter: (cell: any, row: any) => {
      const amount =
        row.discount_amounts.length && row.discount_amounts[0].amount > 0
          ? row.discount_amounts[0].amount
          : row.amount;
      return `€${amount / 100} `;
    },
  },
];
