import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../api';
import { confirmationSlice, toastSlice, usersSlice } from '../slices';
import { RootState } from '../store/store';
import { tableQuery } from '../utils/table';
import i18n from '../translations';
import { IMill } from '../interfaces/mill';
import { getSelectedMillId } from '../utils/common';

export interface IUserPayload {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  millRole: string;
}

export const usersThunk = createAsyncThunk('users', async (_, { getState }) => {
  const state = getState() as RootState;
  const query = tableQuery(state.users);
  const millId = getSelectedMillId(state.mill.data as IMill);
  return Api.get(`/mills/${millId}/users?${query}`);
});

export const addUserThunk = createAsyncThunk(
  'users/add-user',
  async (payload: IUserPayload, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.post(`/mills/${millId}/users`, payload);

      dispatch(usersSlice.actions.closeAddUser());
      dispatch(usersThunk());
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('settings.users.addToast.title'),
          message: i18n.t('settings.users.addToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editUserThunk = createAsyncThunk(
  'users/edit-user',
  async (payload: IUserPayload, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.put(`/mills/${millId}/users`, payload);

      dispatch(usersSlice.actions.closeAddUser());
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('settings.users.editToast.title'),
          message: i18n.t('settings.users.editToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserThunk = createAsyncThunk(
  'users/delete',
  async (id: number, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.delete(`/mills/${millId}/users/${id}`);

      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('settings.users.deleteToast.title'),
          message: i18n.t('settings.users.deleteToast.message'),
        })
      );

      dispatch(usersThunk());

      return response;
    } catch (err) {
      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('settings.users.deleteToast.title'),
          message: err.response.data.message.errors,
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);
