import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { TERMS_OF_SERVICE_ROUTE, DATA_AGREEMENT_ROUTE } from 'constants/routes';
import { Link } from 'react-router-dom';
import { NullableBoolean } from 'slices/user-consent';
import { Modal } from '../Modal';
import greenTick from '../../../assets/images/greenTick.jpg';
import redCross from '../../../assets/images/redCross.jpg';

interface IUserConsentProps {
  show: boolean;
  title: string;
  message: string;
  canClose: boolean;
  userConsent: (
    policyAgreement: boolean,
    dataProcessingAgreement: boolean
  ) => void;
  onClose?: () => void;
}

export const UserConsentContainer: React.FC<IUserConsentProps> = ({
  show,
  title,
  message,
  canClose,
  userConsent,
  onClose,
}: IUserConsentProps) => {
  const { t } = useTranslation();

  const [policyApproval, setPolicyApproval] = useState<NullableBoolean>(null);
  const [dataProcessingApproval, setdataProcessingApproval] = useState<
    NullableBoolean
  >(null);
  const closeDialog = () => {
    if (canClose) {
      setPolicyApproval(null);
      setdataProcessingApproval(null);
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  const handleChangeTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPolicyApproval(e.target.checked);
  };
  const handleChangeAgreement = (e: React.ChangeEvent<HTMLInputElement>) => {
    setdataProcessingApproval(e.target.checked);
  };
  const updateConsent = () => {
    if (!!policyApproval && !!dataProcessingApproval) {
      userConsent(policyApproval, dataProcessingApproval);
      closeDialog();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onClose={closeDialog}
        title={title}
        closeOnOutSideClick={false}
        size="lg"
        scrollable
        footer={
          <>
            <Button
              className="btn-upcoming-invoice"
              onClick={updateConsent}
              disabled={!policyApproval || !dataProcessingApproval}
            >
              {t('contract.tabs.invoices.buttonOk')}
            </Button>
          </>
        }
      >
        <p className="text-center mb-50">{message}</p>
        <Space direction="vertical">
          <Form>
            <Form.Check
              id="termsOfService"
              className="mb-1 ml-6 custom-checkbox custom-control"
              type="checkbox"
              label={
                <>
                  <Trans i18nKey="signUp.serviceterms">
                    <Link to={TERMS_OF_SERVICE_ROUTE} target="_blank">
                      {{ service: t('footer.menu.termsOfService.title') }}
                    </Link>
                  </Trans>
                  {policyApproval !== null && !policyApproval && (
                    <img src={redCross} className="consent-image" alt="" />
                  )}
                  {policyApproval !== null && !!policyApproval && (
                    <img src={greenTick} className="consent-image" alt="" />
                  )}
                </>
              }
              checked={!!policyApproval}
              onChange={handleChangeTerms}
            />
            <Form.Check
              id="dataAgreement"
              className="mb-3 ml-6 custom-checkbox custom-control"
              type="checkbox"
              label={
                <>
                  <Trans i18nKey="signUp.dataprocessing">
                    <Link to={DATA_AGREEMENT_ROUTE} target="_blank">
                      {{ agreement: t('footer.menu.dataProcessing.title') }}
                    </Link>
                  </Trans>
                  {dataProcessingApproval !== null &&
                    !dataProcessingApproval && (
                      <img src={redCross} className="consent-image" alt="" />
                    )}
                  {dataProcessingApproval !== null &&
                    !!dataProcessingApproval && (
                      <img src={greenTick} className="consent-image" alt="" />
                    )}
                </>
              }
              checked={!!dataProcessingApproval}
              onChange={handleChangeAgreement}
            />
          </Form>
        </Space>
      </Modal>
    </>
  );
};

UserConsentContainer.defaultProps = {
  onClose: undefined,
};
