import React from 'react';
import { Confirmation } from 'components/Modal';
import { useTranslation } from 'react-i18next';

import { Card, Radio, Space } from 'antd';
import { Button } from 'react-bootstrap';
import { IAddOn } from 'thunks/payments';
import { IPromotion } from 'thunks/contract-type';
import { Modal } from '../Modal';

interface IAddonSelectionProps {
  show: boolean;
  filesKeepDurationKey: string;
  addOns: Array<IAddOn>;
  handleConfirmation: () => any;
  onAddonUpdates: (selection: any) => any;
  onClose: () => any;
  activePromotion?: IPromotion;
}

export const getPromotionalPrice = (
  price: number,
  activePromotion?: IPromotion
) => {
  if (activePromotion && price > 0) {
    return activePromotion?.ispercentage
      ? (price / 100) * (activePromotion?.value ?? 100)
      : price - (activePromotion?.value ?? 0);
  }
  return price;
};

export const AddonSelection = ({
  show,
  onClose,
  addOns,
  handleConfirmation,
  onAddonUpdates,
  filesKeepDurationKey,
  activePromotion,
}: IAddonSelectionProps) => {
  const { t } = useTranslation();
  const [
    filesKeepDurationKeySelection,
    onFilesKeepDurationKeySelection,
  ] = React.useState(filesKeepDurationKey);

  const onChangeFilesKeepingDurationKey = (newSelection: any) => {
    onFilesKeepDurationKeySelection(newSelection.target.value);
  };

  React.useEffect(() => {
    onFilesKeepDurationKeySelection(filesKeepDurationKey);
  }, [filesKeepDurationKey]);
  const updateAddons = () => {
    onAddonUpdates({
      filesKeepDurationKey: filesKeepDurationKeySelection,
    });
  };

  return (
    <Modal
      title={t('contract.addons.title')}
      show={show}
      onClose={onClose}
      closeOnOutSideClick={false}
      size="sm"
    >
      {activePromotion?.description && (
        <div className="p-4 bg-danger text-white text-uppercase d-flex justify-content-center">
          <h5 className="text-white">{activePromotion?.description}</h5>
        </div>
      )}
      <Space direction="vertical">
        <Card title={t('contract.addons.keepFiles')}>
          <Radio.Group
            value={filesKeepDurationKeySelection}
            onChange={onChangeFilesKeepingDurationKey}
          >
            {Array.isArray(addOns) &&
              addOns.length &&
              addOns.map((a: IAddOn) => (
                <Radio key={a.id} value={a.key}>
                  {`${a.value} ${t(
                    'contract.addons.days'
                  )} ( €${getPromotionalPrice(a.price, activePromotion)} / ${t(
                    'contract.addons.month'
                  )} )`}
                </Radio>
              ))}
          </Radio.Group>
        </Card>
        <Button onClick={updateAddons} style={{ width: '100%' }}>
          Update Subscription
        </Button>
      </Space>
      <Confirmation
        key="add-on-selection-confirmation"
        onConfirm={handleConfirmation}
      />
    </Modal>
  );
};

AddonSelection.defaultProps = {
  activePromotion: undefined,
};
