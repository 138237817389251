import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContainer } from '../LanguageContainer';
import { NotificationsContainer } from '../NotificationsContainer';

export const SettingsSecondColumn = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-60">
        <h4 className="mb-42">{t('account.settings.notifications.title')}</h4>
        <NotificationsContainer />
      </div>
      <div data-cy="user-settings-language-container" className="mb-40">
        <h4 className="mb-26">{t('account.settings.language.title')}</h4>
        <LanguageContainer />
      </div>
    </>
  );
};
