import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { toastSlice } from 'slices';
import { Spinner2 } from 'components';
import Api from '../../../../api';
import { columns } from './Table/columns';

export const UsageDetailContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [statisticsData, setStatisticsData] = useState([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { id: millId } = useAppSelector((state) => state.mill.data);

  useEffect(() => {
    try {
      if (millId) {
        setIsProcessing(true);
        const url = `mills/${millId}/stats`;
        Api.get(url).then((response) => {
          setStatisticsData(response.statistics);
          setIsProcessing(false);
        });
      }
    } catch (error) {
      setIsProcessing(false);
      dispatch(
        toastSlice.actions.show({
          title: t('contract.error'),
          message: t('contract.tabs.usageDetail.errorMessage'),
        })
      );
    }
  }, [millId]);
  return (
    <div>
      <Spinner2 show={isProcessing} />
      {statisticsData.length ? (
        <Row>
          <Col>
            <Table
              data={statisticsData}
              columns={columns}
              totalSize={statisticsData.length}
              sizePerPage={10}
              keyField="name"
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
