import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getSessionStorageItem } from 'utils/localStorage';
import * as yup from 'yup';
import { Spinner2 } from 'components';
import { FormikHelpers } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  IMillProfilePayload,
  updateMillProfileThunk,
} from '../../../thunks/account';
import { AccountForm } from './AccountForm';

const schema: yup.SchemaOf<IMillProfilePayload> = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  files: yup.array().required(),
});

export const AccountFormContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const error = useAppSelector((state) => state.auth.error);
  const { firstName, lastName, email, avatar } = useAppSelector(
    (state) => state.auth.data.user
  );
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);

  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleSubmit = async (
    values: IMillProfilePayload,
    formik: FormikHelpers<IMillProfilePayload>
  ) => {
    setIsProcessing(true);
    await dispatch(updateMillProfileThunk(values));
    setIsProcessing(false);
    formik.resetForm({ values: { ...values, files: [] } });
  };

  return (
    <>
      <Spinner2 show={isProcessing} />
      <AccountForm
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={
          {
            firstName,
            lastName,
            email,
            files: [],
          } as IMillProfilePayload
        }
        error={error}
        imageUrl={avatar && avatar.url}
      />
    </>
  );
};
