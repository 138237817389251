import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { DeleteIcon } from '../../icons';

export interface IFileUploadProps {
  setFieldValue: any;
  maxFiles?: number;
  accept?: string;
  values: any[];
}

export const FileUpload = ({
  setFieldValue,
  accept = 'image/*',
  maxFiles = 1,
  values,
}: IFileUploadProps) => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop: (files) => {
      setFieldValue('files', files);
    },
  });
  const hasFiles = !!values.length;

  const handleDelete = (index: number) => {
    const files = acceptedFiles.filter((f, i) => i !== index);
    setFieldValue('files', files);
  };
  return (
    <div>
      <div {...getRootProps({ className: 'file-upload' })}>
        <input {...getInputProps()} />
        <div
          className={classNames('file-upload-input', {
            hide: hasFiles,
          })}
        >
          <div className="label-small">{t('fileUpload.label')}</div>
        </div>
      </div>
      {hasFiles && (
        <ul className="list-unstyled file-upload-files">
          {values.map((file, i) => (
            <li key={`${file.lastModified}`}>
              <span>{file.name}</span>
              <Link
                to="#"
                className="file-upload-delete"
                onClick={() => handleDelete(i)}
              >
                <DeleteIcon />
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
