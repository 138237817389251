import React from 'react';
import classNames from 'classnames';
import { Form, FormLabel, InputGroup } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, ImageUpload, Spinner2 } from 'components';
import { Select } from 'components/Select';
import { ILabPayload } from 'thunks/account';
import { getCountriesList, getSelectedCountry } from 'utils/countries';
import { Prompt } from 'react-router-dom';
import { TAB_FORM_IS_DIRTY, EUROPEAN_UNION } from 'constants/constants';
import { useLabRole } from '../../../hooks/role';

interface ICompanyInfoFormProps {
  initialValues: ILabPayload;
  validationSchema: any | (() => any);
  onSubmit: (
    values: ILabPayload,
    form: FormikHelpers<ILabPayload>
  ) => void | Promise<any>;
  imageUrl?: string;
  showShippingAddress?: boolean;
  message?: string;
  isLoading?: boolean;
  isCountryEditable?: boolean;
}

export const CompanyInfoForm = ({
  initialValues,
  validationSchema,
  imageUrl = undefined,
  onSubmit,
  showShippingAddress = true,
  message = '',
  isLoading = false,
  isCountryEditable = true,
}: ICompanyInfoFormProps) => {
  const { t } = useTranslation();

  const { country = 'DE' } = initialValues.company.address;
  const isLabRole = useLabRole();

  const isVATEnabled = (countryCode: any) => {
    return !isLabRole && EUROPEAN_UNION.includes(countryCode);
  };

  const [vatNumberEnabled, setVatNumberEnabled] = React.useState<boolean>(true);

  const handleSelectChange = async (countryCode: any) => {
    const isEnable = isVATEnabled(countryCode);
    setVatNumberEnabled(isEnable);
  };

  React.useEffect(() => {
    const isEnable = isVATEnabled(!country ? 'DE' : country.toUpperCase());
    setVatNumberEnabled(isEnable);
  }, [country]);

  return (
    <>
      <Spinner2 show={isLoading} />
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
          dirty,
        }) => {
          sessionStorage.setItem(TAB_FORM_IS_DIRTY, `${dirty}`);
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />
              {!message && (
                <p className="fs-20 lh-26 mb-60">
                  {t('account.company.companyInfo')}
                </p>
              )}

              {message && (
                <p className="fs-20 lh-26 mb-60 text-danger">{message}</p>
              )}
              <Form
                onSubmit={handleSubmit}
                className="form-company company-detail-form"
                data-cy="comp-details"
              >
                <h4 className="mb-20">{t('account.company.logo')}</h4>
                <Form.Group controlId="files" className="mb-36">
                  <ImageUpload
                    setFieldValue={setFieldValue}
                    accept="image/*"
                    values={values.files}
                    imageUrl={imageUrl}
                  />
                </Form.Group>

                <h4>{t('account.company.information')}</h4>
                <Form.Group controlId="company.name">
                  <Form.Label>{t('companyName.label')}</Form.Label>
                  <Form.Control
                    name="company.name"
                    placeholder={t('companyName.placeholder')}
                    value={values.company?.name}
                    onChange={handleChange}
                    data-cy="comp-name"
                    isInvalid={touched.company?.name && !!errors.company?.name}
                  />
                </Form.Group>

                <Form.Group controlId="company.web">
                  <Form.Label>{t('web.label')}</Form.Label>
                  <Form.Control
                    name="company.web"
                    placeholder={t('web.placeholder')}
                    value={values.company?.web}
                    onChange={handleChange}
                    data-cy="comp-web"
                    isInvalid={touched.company?.web && !!errors.company?.web}
                  />
                </Form.Group>
                <Form.Group controlId="company.address.country">
                  <FormLabel>{t('country.label')}</FormLabel>
                  <Select
                    options={getCountriesList()}
                    isSearchable
                    onChange={(changedCountryObj: any) => {
                      setFieldValue(
                        'company.address.country',
                        changedCountryObj.value
                      );
                      setFieldValue('company.vatNumber', '');
                      handleSelectChange(changedCountryObj.value);
                    }}
                    data-cy="comp-country"
                    value={
                      !values.company?.address?.country
                        ? getSelectedCountry('')
                        : getSelectedCountry(values.company?.address?.country)
                    }
                    isDisabled={!isCountryEditable}
                    isInvalid={
                      touched.company?.address?.country &&
                      !!errors.company?.address?.country
                    }
                  />
                </Form.Group>
                <Form.Group controlId="company.vatnumber">
                  <FormLabel>{t('vatId.label')}</FormLabel>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {!values.company.address.country
                        ? '--'
                        : values.company.address.country.toUpperCase()}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="company.vatNumber"
                    placeholder={t('vatId.placeholder')}
                    value={values.company?.vatNumber}
                    disabled={!vatNumberEnabled}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      if (/^[a-z0-9]+$/i.test(val) || val === '') {
                        handleChange(e);
                      }
                    }}
                    data-cy="comp-vat"
                    isInvalid={
                      touched.company?.vatNumber && !!errors.company?.vatNumber
                    }
                  />
                </Form.Group>
                <h4>{t('account.company.contact')}</h4>

                <Form.Group controlId="contactPerson">
                  <Form.Label>{t('contactPerson.label')}</Form.Label>
                  <Form.Control
                    name="company.contactPerson"
                    placeholder={t('contactPerson.placeholder')}
                    value={values.company?.contactPerson}
                    onChange={handleChange}
                    data-cy="comp-contact-person"
                    isInvalid={
                      touched.company?.contactPerson &&
                      !!errors.company?.contactPerson
                    }
                  />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>{t('email.label')}</Form.Label>
                  <Form.Control
                    type="email"
                    name="company.email"
                    placeholder={t('email.placeholder')}
                    value={values.company?.email}
                    onChange={handleChange}
                    data-cy="comp-email"
                    isInvalid={
                      touched.company?.email && !!errors.company?.email
                    }
                  />
                </Form.Group>

                <Form.Group controlId="company.address.phone" className="mb-30">
                  <Form.Label>{t('phone.label')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="company.address.phone"
                    placeholder={t('phone.placeholder')}
                    value={values.company?.address?.phone}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      if (/^[0-9]+$/i.test(val) || val === '') {
                        handleChange(e);
                      }
                    }}
                    data-cy="comp-phone"
                    isInvalid={
                      touched.company?.address?.phone &&
                      !!errors.company?.address?.phone
                    }
                  />
                </Form.Group>

                <h4>{t('account.company.address')}</h4>

                <Form.Group controlId="company.address.state">
                  <Form.Label>{t('state.label')}</Form.Label>
                  <Form.Control
                    name="company.address.state"
                    placeholder={t('state.placeholder')}
                    value={values.company?.address?.state}
                    onChange={handleChange}
                    data-cy="comp-state"
                    isInvalid={
                      touched.company?.address?.state &&
                      !!errors.company?.address?.state
                    }
                  />
                </Form.Group>

                <Form.Group controlId="company.address.street">
                  <Form.Label>{t('street.label')}</Form.Label>
                  <Form.Control
                    name="company.address.street"
                    placeholder={t('street.placeholder')}
                    value={values.company?.address?.street}
                    onChange={handleChange}
                    data-cy="comp-street"
                    isInvalid={
                      touched.company?.address?.street &&
                      !!errors.company?.address?.street
                    }
                  />
                </Form.Group>

                <Form.Group controlId="company.address.city">
                  <Form.Label>{t('city.label')}</Form.Label>
                  <Form.Control
                    name="company.address.city"
                    placeholder={t('city.placeholder')}
                    value={values.company?.address?.city}
                    onChange={handleChange}
                    data-cy="comp-city"
                    isInvalid={
                      touched.company?.address?.city &&
                      !!errors.company?.address?.city
                    }
                  />
                </Form.Group>

                <Form.Group
                  controlId="company.address.zipCode"
                  className="mb-30"
                >
                  <Form.Label>{t('zip.label')}</Form.Label>
                  <Form.Control
                    maxLength={10}
                    name="company.address.zipCode"
                    placeholder={t('zip.placeholder')}
                    value={values.company?.address?.zipCode}
                    onChange={handleChange}
                    data-cy="comp-zipcode"
                    isInvalid={
                      touched.company?.address?.zipCode &&
                      !!errors.company?.address?.zipCode
                    }
                  />
                </Form.Group>

                {showShippingAddress && (
                  <>
                    <h4>{t('account.company.shipping.title')}</h4>

                    <Form.Group
                      controlId="shippingAtSameAddress"
                      className={classNames({
                        'mb-60': values.shippingAtSameAddress,
                      })}
                    >
                      <Form.Label>
                        {t('account.company.shipping.address')}
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        name="shippingAtSameAddress"
                        label={t('account.company.shipping.sameAsAddress')}
                        checked={values.shippingAtSameAddress}
                        onChange={handleChange}
                        data-cy="comp-shipping-at-same-address"
                        custom
                        style={{ zIndex: 0 }}
                      />
                    </Form.Group>

                    {!values.shippingAtSameAddress && (
                      <>
                        <Form.Group controlId="shippingAddress.street">
                          <Form.Label>{t('street.label')}</Form.Label>
                          <Form.Control
                            name="shippingAddress.street"
                            placeholder={t('street.placeholder')}
                            value={values.shippingAddress?.street}
                            onChange={handleChange}
                            data-cy="comp-shipping-street"
                            isInvalid={
                              touched.shippingAddress?.street &&
                              !!errors.shippingAddress?.street
                            }
                          />
                        </Form.Group>

                        <Form.Group controlId="shippingAddress.city">
                          <Form.Label>{t('city.label')}</Form.Label>
                          <Form.Control
                            name="shippingAddress.city"
                            placeholder={t('city.placeholder')}
                            value={values.shippingAddress?.city}
                            onChange={handleChange}
                            data-cy="comp-shipping-city"
                            isInvalid={
                              touched.shippingAddress?.city &&
                              !!errors.shippingAddress?.city
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="shippingAddress.zipCode"
                          className="mb-60"
                        >
                          <Form.Label>{t('zip.label')}</Form.Label>
                          <Form.Control
                            maxLength={10}
                            name="shippingAddress.zipCode"
                            placeholder={t('zip.placeholder')}
                            value={values.shippingAddress?.zipCode}
                            onChange={handleChange}
                            data-cy="comp-shipping-zipcode"
                            isInvalid={
                              touched.shippingAddress?.zipCode &&
                              !!errors.shippingAddress?.zipCode
                            }
                          />
                        </Form.Group>
                      </>
                    )}
                  </>
                )}
                <Button
                  className={showShippingAddress ? '' : 'mt-60'}
                  dimension="lg"
                  type="submit"
                >
                  {t('account.company.button')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

CompanyInfoForm.defaultProps = {
  imageUrl: undefined,
  showShippingAddress: true,
  message: '',
  isLoading: false,
  isCountryEditable: true,
};
