import {
  TAB_FORM_IS_DIRTY,
  EUROPEAN_UNION,
  SUBSCRIPTION_PLANS,
} from 'constants/constants';
import { CompanyInfoForm } from 'pages/Lab/Account/CompanyInfoForm';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ILabPayload } from 'thunks/account';
import { getSessionStorageItem } from 'utils/localStorage';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  IAddress,
  ICompanyDetail,
  updateCompanyDetailsThunk,
} from 'thunks/settings';
import { companySlice } from 'slices';
import { FormikHelpers } from 'formik';

interface ICompanyDetailSchema {
  company: ICompanyDetail;
  files: any[];
}
const isVATEnabled = (countryCode: any) => {
  return EUROPEAN_UNION.includes(countryCode);
};

const schema: yup.AnySchema<ICompanyDetailSchema> = yup
  .object()
  .shape({
    company: yup.object().shape({
      name: yup.string().required(),
      contactPerson: yup.string(),
      web: yup.string(),
      email: yup.string().email().required(),
      vatNumber: yup.string().when('address.country', {
        is: (val: any) => isVATEnabled(val),
        then: yup.string().required(),
      }),
      address: yup
        .object()
        .shape({
          country: yup.string().required(),
          phone: yup.string().required(),
          city: yup.string().required(),
          street: yup.string().required(),
          zipCode: yup.string().required(),
        })
        .required(),
    }),
    files: yup.array().required(),
  })
  .optional();

export const CompanyDetailContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const {
    id,
    company: millCompany,
    contractType: millContractType,
  } = useAppSelector((state) => state.mill.data);

  const { message, isLoading } = useAppSelector((state) => state.company);
  const { company } = useAppSelector((state) => state.auth.data.user);
  const { ordersCount } = useAppSelector((state) => state.auth.data);

  const {
    id: millCompanyId,
    name,
    web,
    email,
    address,
    logo,
    contactPerson,
    companyInfoIsUpdated = true,
    vatNumber,
  } = millCompany || {};

  const isCountryEditable =
    !millContractType || millContractType?.name === SUBSCRIPTION_PLANS.BASIC;

  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);

  React.useEffect(() => {
    const msg = companyInfoIsUpdated
      ? ''
      : t('settings.company.message.update');
    dispatch(companySlice.actions.setMessage({ message: msg }));
  }, [companyInfoIsUpdated]);

  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleSubmit = async (
    values: ILabPayload,
    formik: FormikHelpers<ILabPayload>
  ) => {
    const payload: ICompanyDetail = {
      ...values,
      ...values.company,
      id: millCompanyId,
      companyInfoIsUpdated: true,
      ordersCount,
      dataBaseStatusIsUpdated: company?.companyInfoIsUpdated,
    };
    const response = await dispatch(updateCompanyDetailsThunk(payload));
    if (response.payload.statusCode >= 400) {
      return;
    }
    formik.resetForm({ values: { ...values, files: [] } });
  };

  return (
    <CompanyInfoForm
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={
        {
          id,
          company: {
            id: millCompanyId,
            contactPerson: contactPerson || '',
            name: name || '',
            web: web || '',
            email: email || '',
            vatNumber: vatNumber || '',
            address:
              address ||
              ({
                country: 'DE',
                street: '',
                state: '',
                zipCode: 0,
                phone: '',
                city: '',
              } as IAddress),
          },
          shippingAddress: {
            street: '',
            zipCode: 0,
            phone: '',
            city: '',
          } as IAddress,
          files: [],
        } as ILabPayload
      }
      imageUrl={logo && logo.url}
      showShippingAddress={false}
      message={message}
      isLoading={isLoading}
      isCountryEditable={isCountryEditable}
    />
  );
};
