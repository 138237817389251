import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TableChangeType, TableChangeState } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Switch, Spinner2 } from 'components';
import { PlusIcon } from 'icons';
import { ILicenseKey } from 'interfaces/user';
import { confirmationSlice, licenseKeySlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Table } from 'components/Table';
import { Confirmation } from 'components/Modal';
import {
  ILicenseKeyPayload,
  deleteLicenseKeyThunk,
  editLicenseKeyThunk,
  licenseKeyThunk,
} from 'thunks/license-keys';
import i18n from 'translations';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { getSessionStorageItem } from 'utils/localStorage';
import { columns } from './columns';
import { Actions } from './Actions';
import { LicenseKeysFormContainer } from '../LicenseKeysContainer';

export const LicenseKeysTableContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    count,
    data,
    page,
    sizePerPage,
    selectedLicenseKeyId,
  } = useAppSelector((state) => state.licenseKey);

  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);

  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleAddLicenseKey = () => {
    dispatch(licenseKeySlice.actions.showAddLicenseKey());
  };

  const handleDelete = (licenseKey: ILicenseKey) => {
    if (licenseKey.id) {
      dispatch(licenseKeySlice.actions.selectLicenseKey(licenseKey.id));
      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('settings.licenseKey.deleteConfirm.title'),
          message: i18n.t('settings.licenseKey.deleteConfirm.message'),
          buttonOk: i18n.t('settings.licenseKey.deleteConfirm.buttonOk'),
          buttonCancel: i18n.t(
            'settings.licenseKey.deleteConfirm.buttonCancel'
          ),
        })
      );
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    licenseKey: ILicenseKey
  ) => {
    const params: ILicenseKeyPayload = {
      id: licenseKey.id,
      licenseKey: licenseKey.licenseKey,
      enableDataCollection: !licenseKey.enableDataCollection,
    };
    dispatch(editLicenseKeyThunk(params));
  };

  const handleDeleteLicenseKey = async () => {
    if (selectedLicenseKeyId) {
      setIsProcessing(true);
      await dispatch(deleteLicenseKeyThunk(selectedLicenseKeyId));
      setIsProcessing(false);
    }
  };

  const handleTableChange = async (
    type: TableChangeType,
    state: TableChangeState<ILicenseKey>
  ) => {
    if (type === 'pagination') {
      dispatch(
        licenseKeySlice.actions.setPagination({
          page: state.page,
          sizePerPage: state.sizePerPage,
        })
      );
      setIsProcessing(true);
      await dispatch(licenseKeyThunk());
      setIsProcessing(false);
    } else if (type === 'sort') {
      const sort = `${state.sortField}:${state.sortOrder}`;
      dispatch(
        licenseKeySlice.actions.setSort({
          sort,
        })
      );
      setIsProcessing(true);
      await dispatch(licenseKeyThunk());
      setIsProcessing(false);
    }
  };

  const addActionsToTable = () => {
    columns[columns.length - 1].formatter = (
      cell: any,
      licenseKey: ILicenseKey
    ) => {
      return Actions({
        licenseKey,
        showActions: true,
        onDelete: handleDelete,
      });
    };
  };

  const addSwitchToTable = () => {
    columns[columns.length - 2].formatter = (
      cell: any,
      licenseKey: ILicenseKey
    ) => {
      return (
        <Switch
          style={{ zIndex: 0 }}
          key={licenseKey.id}
          label=" "
          id={licenseKey.id.toString()}
          data-cy={`check-${licenseKey.id}`}
          onChange={(e) => handleChange(e, licenseKey)}
          defaultChecked={!!licenseKey.enableDataCollection}
        />
      );
    };
  };

  const setSizePerPage = () => {
    let pageSize = sizePerPage;
    if (userSettings?.pageSize) {
      pageSize = userSettings.pageSize;
    }

    dispatch(licenseKeySlice.actions.setPageSize(pageSize));
  };

  useEffect(() => {
    (async () => {
      setIsProcessing(true);
      addSwitchToTable();
      addActionsToTable();
      setSizePerPage();
      await dispatch(licenseKeyThunk());
      setIsProcessing(false);
    })();
  }, []);

  return (
    <div data-cy="mill-license-keys-table" className="page-license-keys">
      <Spinner2 show={isProcessing} />
      <Row>
        <Col>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-40">
            <div className="select-wrapper mb-24 mb-md-0" />
            <Button
              data-cy="add-new-license-key"
              className="width-auto"
              icon={<PlusIcon />}
              onClick={handleAddLicenseKey}
              size="lg"
            >
              {t('settings.licenseKey.add')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            page={page}
            data={data}
            columns={columns}
            totalSize={count}
            sizePerPage={sizePerPage}
            onTableChange={handleTableChange}
          />
        </Col>
      </Row>
      <LicenseKeysFormContainer />
      <Confirmation
        key="mill-users-licenseKey-list"
        onConfirm={handleDeleteLicenseKey}
      />
    </div>
  );
};
