import React from 'react';

import { Toast } from '.';
import { toastSlice } from '../../slices';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const ToastContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { show, title, message } = useAppSelector((state) => state.toast);

  const handleClose = () => {
    dispatch(toastSlice.actions.close());
  };

  return (
    <Toast show={show} title={title} message={message} onClose={handleClose} />
  );
};
