import React from 'react';
import { IIcon } from './Icon';

export const PlusIcon = ({ color = '#ffffff' }: IIcon) => (
  <svg
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Artboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M10.7246,5.00732 C10.3104,5.00732 9.9746,5.34311 9.9746,5.75732 L9.9746,9.25 L6.48199,9.25 C6.06778,9.25 5.73199,9.5858 5.73199,10 C5.73199,10.4142 6.06778,10.75 6.48199,10.75 L9.9746,10.75 L9.9746,14.2426 C9.9746,14.6568 10.3104,14.9926 10.7246,14.9926 C11.1388,14.9926 11.4746,14.6568 11.4746,14.2426 L11.4746,10.75 L14.9673,10.75 C15.3815,10.75 15.7173,10.4142 15.7173,10 C15.7173,9.5858 15.3815,9.25 14.9673,9.25 L11.4746,9.25 L11.4746,5.75732 C11.4746,5.34311 11.1388,5.00732 10.7246,5.00732 Z"
        id="Path"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M0.97461,10 C0.97461,4.61522 5.33983,0.25 10.7246,0.25 C16.1094,0.25 20.4746,4.61522 20.4746,10 C20.4746,15.3848 16.1094,19.75 10.7246,19.75 C5.33983,19.75 0.97461,15.3848 0.97461,10 Z M10.7246,1.75 C6.16826,1.75 2.47461,5.44365 2.47461,10 C2.47461,14.5563 6.16826,18.25 10.7246,18.25 C15.281,18.25 18.9746,14.5563 18.9746,10 C18.9746,5.44365 15.281,1.75 10.7246,1.75 Z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);
