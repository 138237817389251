import React, { ChangeEventHandler } from 'react';
import { Form, FormCheckProps } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface ISwitchProps extends FormCheckProps {
  label: string;
  id: string;
  defaultChecked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const Switch = ({
  label,
  id,
  onChange,
  defaultChecked,
  ...rest
}: ISwitchProps) => {
  const [value, setValue] = React.useState(defaultChecked);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
    onChange(e);
  };

  return (
    <div className="switch">
      <Form.Check
        type="switch"
        label={label}
        id={id}
        name={id}
        defaultChecked={defaultChecked}
        onChange={handleChange}
        {...rest}
      />
      <div className={classNames('switch-label', { active: value })}>
        {value ? t('switch.on') : t('switch.off')}
      </div>
    </div>
  );
};

Switch.defaultProps = {
  defaultChecked: undefined,
};
