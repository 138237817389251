import React from 'react';

export const ArrowLeftIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.21967 12.0303C3.92678 11.7374 3.92678 11.2626 4.21967 10.9697L9.21967 5.96967C9.51256 5.67678 9.98744 5.67678 10.2803 5.96967C10.5732 6.26256 10.5732 6.73744 10.2803 7.03033L6.56066 10.75H19.25C19.6642 10.75 20 11.0858 20 11.5C20 11.9142 19.6642 12.25 19.25 12.25H6.56066L10.2803 15.9697C10.5732 16.2626 10.5732 16.7374 10.2803 17.0303C9.98744 17.3232 9.51256 17.3232 9.21967 17.0303L4.21967 12.0303Z"
      fill="#393F4B"
    />
  </svg>
);
