import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { COMPONENT_UPDATE_PHASE } from 'constants/constants';
import { IComponent } from 'interfaces/component';
import { IDocument, IDownload } from 'interfaces/document';
import { DeleteIcon, DownloadIcon, UploadIcon } from 'icons';

import placeholder from 'assets/images/placeholder.svg';
import { Button } from 'components';
import { placeholderImage } from 'utils/image';

export interface IDetailsProps {
  isMillRole: boolean;
  component?: IComponent;
  onDownloadDelete: (id: number) => void;
  onDownloadUpload: () => void;
  onDownloadAllDocuments: () => void;
}

export const Details = ({
  component,
  isMillRole,
  onDownloadDelete,
  onDownloadUpload,
  onDownloadAllDocuments,
}: IDetailsProps) => {
  const { t } = useTranslation();
  const filesUpdateStatus = component?.filesUpdateStatus;

  return (
    <div className="border-bottom pb-60 mb-60">
      <Row>
        <Col md={2}>
          <h4>{t('component.component')}</h4>
        </Col>
        <Col md={5}>
          <p className="text-left">{component?.comment}</p>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="mb-32 mb-md-0">
          <div className="label-small mb-20">{t('component.preview')}</div>
          <img
            src={placeholderImage(component?.image, placeholder)}
            alt=""
            className="component-preview-image img-fluid w-100"
          />
        </Col>

        <Col md={5} className="mb-32 mb-md-0">
          <div className="d-flex align-items-baseline justify-content-between">
            <div className="label-small mb-20">{t('component.documents')}</div>
            {isMillRole &&
              Boolean(component?.documents.length) &&
              !component?.project?.isDeleted && (
                <a
                  href="#"
                  className="btn btn-outline-primary btn-sm btn-download"
                  onClick={onDownloadAllDocuments}
                >
                  {t('component.downloadAll')}
                </a>
              )}
          </div>
          {component?.documents.length ? (
            <ul className="list-group">
              {component?.documents.map((doc: IDocument) => (
                <li className="list-group-item download-file" key={doc.id}>
                  <span>{doc.name}</span>
                  {isMillRole &&
                    filesUpdateStatus !== COMPONENT_UPDATE_PHASE.UPLOADING &&
                    !component?.project?.isDeleted && (
                      <a
                        href={doc.file && doc.file.url}
                        className="download-file-link"
                        download={!component.project?.isDeleted}
                      >
                        <DownloadIcon />
                      </a>
                    )}
                </li>
              ))}
            </ul>
          ) : (
            t('component.noFiles')
          )}
        </Col>

        <Col md={5} className="mb-32 mb-md-0">
          <div className="label-small mb-20">{t('component.downloads')}</div>
          {component?.downloads.length ? (
            <ul className="list-group">
              {component?.downloads.map((doc: IDownload) => (
                <li className="list-group-item download-file" key={doc.id}>
                  <span>{doc.name}</span>
                  {isMillRole ? (
                    <div>
                      {doc.file && (
                        <a
                          href={doc.file && doc.file.url}
                          className="download-file-link mr-16"
                          download
                        >
                          <DownloadIcon />
                        </a>
                      )}
                      <a
                        href="#"
                        className="download-file-link"
                        onClick={() => onDownloadDelete(doc.id)}
                      >
                        <DeleteIcon />
                      </a>
                    </div>
                  ) : (
                    <>
                      {doc.file && (
                        <a
                          href={doc.file && doc.file.url}
                          className="download-file-link"
                          download
                        >
                          <DownloadIcon />
                        </a>
                      )}
                    </>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            t('component.noFiles')
          )}
          {isMillRole && (
            <Button
              icon={<UploadIcon />}
              className="btn-block mt-22"
              size="lg"
              onClick={onDownloadUpload}
            >
              {t('component.uploadPdf')}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};
