import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { ICompany } from 'interfaces/mill';
import { Modal } from '../Modal';
import labCustomLogo from '../../../assets/images/custom-logo.svg';

export interface IMill {
  id: number;
  company: ICompany;
}

interface IMillSelectionProps {
  show: boolean;
  onClose: () => any;
  onMillSelection: (millId: string) => void;
  mills: Array<any>;
}

const MillCenterLogo = (mill: IMill) => (
  <>
    <li key={mill.id} className="mb-10">
      <span> {mill.company.name}</span>
    </li>
    <img className="mb-40" src={mill.company.logo || labCustomLogo} alt="" />
  </>
);

export const MillSelection = ({
  show,
  onClose,
  onMillSelection,
  mills,
}: IMillSelectionProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('header.selectMill')}
      show={show}
      onClose={onClose}
      closeOnOutSideClick={false}
      size="sm"
    >
      {mills && (
        <ul className="list-unstyled mill-selection">
          {mills.map((mill) => (
            <Link
              key={mill.id}
              to=""
              onClick={() => {
                onMillSelection(mill.id.toString());
              }}
            >
              <MillCenterLogo {...mill} />
            </Link>
          ))}
        </ul>
      )}
    </Modal>
  );
};
