import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IOption } from 'components/Select';
import { AvatarImage } from 'components/User';

import { IUser } from 'interfaces/user';
import { capitalize } from 'utils/format';
import i18n from 'translations';

export const roleOptions = [
  { value: 'admin', label: i18n.t('settings.users.roles.admin') },
  { value: 'user', label: i18n.t('settings.users.roles.user') },
];

export const roleSearchOptions: IOption[] = [
  { value: '', label: i18n.t('settings.users.roles.all') },
  ...roleOptions,
];

export const columns: ColumnDescription<any, IUser>[] = [
  {
    dataField: 'avatar',
    text: '',
    sort: false,
    style: {
      width: '72px',
      height: '72px',
    },
    formatter: (cell: any, row: IUser) => {
      return <AvatarImage user={row} />;
    },
  },
  {
    dataField: 'firstName',
    text: i18n.t('settings.users.columns.name'),
    formatter: (cell, row: IUser) => {
      return (
        <div className="d-flex align-items-center">
          <span>{`${row.firstName} ${row.lastName}`}</span>
        </div>
      );
    },
    sort: true,
  },
  {
    dataField: 'email',
    text: i18n.t('settings.users.columns.email'),
    sort: true,
  },
  {
    dataField: 'millRole',
    text: i18n.t('settings.users.columns.role'),
    sort: true,
    formatter: (cell: any, row: IUser) => {
      return row.millRole ? capitalize(row.millRole) : '';
    },
  },
  {
    dataField: 'actions',
    text: '',
    isDummyField: true,
  },
];
