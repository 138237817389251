import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IManagerUser } from 'interfaces/Manager/user';
import { getFormattedDate, padLeft } from 'utils/common';
import {
  MANAGER_MILLS_VIEW_ROUTE,
  MANAGER_ORDERS_VIEW_ROUTE,
} from 'constants/routes';
import { idsToLinks } from '../Home';

export const columns: ColumnDescription<any, IManagerUser>[] = [
  {
    dataField: 'id',
    text: 'Id',
  },
  {
    dataField: 'email',
    text: 'Email',
  },
  {
    dataField: 'millRole',
    text: 'Role',
  },
  {
    dataField: 'projectsCount',
    text: '#Projects',
  },
  {
    dataField: 'company',
    text: 'Company',
  },
  {
    dataField: 'createdAt',
    text: 'Created At',
    style: {
      width: '200px',
    },
    formatter: (cell: any, row: IManagerUser) => {
      return getFormattedDate(row.createdAt);
    },
  },
  {
    dataField: 'projectIds',
    text: 'Projects',
    formatter: (cell: any, row: IManagerUser) => {
      if (!row?.projectIds?.length) return 'None';
      const linkedElements = idsToLinks(
        row.projectIds.split(','),
        `${MANAGER_ORDERS_VIEW_ROUTE}`,
        `${row.id}`,
        `&millId=${row.mills[0]}`
      );

      return <>{linkedElements}</>;
    },
  },
  {
    dataField: 'month',
    text: 'Period',
    formatter: (cell: any, row: IManagerUser) => {
      return row.month && row.year ? `${row.month}.${padLeft(row.year)}` : '-';
    },
  },
  {
    dataField: 'mills',
    text: 'Mills',
    formatter: (cell: any, row: IManagerUser) => {
      if (!row?.mills?.length) return 'None';

      const linkedElements = idsToLinks(
        row.mills,
        `${MANAGER_MILLS_VIEW_ROUTE}`,
        `${row.id}`
      );

      return <>{linkedElements}</>;
    },
  },
  {
    dataField: 'labs',
    text: 'Labs',
    formatter: (cell: any, row: IManagerUser) => {
      return row.labs && row.labs.length ? row.labs.join(', ') : 'None';
    },
  },
];
