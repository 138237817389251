import React from 'react';
import {
  ForgotContainer,
  SigninContainer,
  SignupContainer,
  ResetContainer,
} from '../Auth';
import { CreateNewAccountModalContainer } from '../Auth/CreateNewAccountModal/CreateNewAccountModalContainer';
import { ExistingAccountUserInvitationContainer } from '../Auth/ExistingAccountUserInvitation/ExistingAccountUserInvitationContainer';
import { InvitationContainer } from '../Auth/Invitation/InvitationContainer';
import { LoggedInUserInvitationContainer } from '../Auth/LoggedInUserInvitation/LoggedInUserInvitationContainer';
import { ToastContainer } from '../Toast/ToastContainer';
import { Footer, PublicFooter } from './Footer';
import { Header, PublicHeader } from './Header';
import { Main } from './Main';

interface IPublicLayout {
  children: React.ReactNode;
  mainClasses?: string;
}

export const PublicLayout: React.FC<IPublicLayout> = ({
  children,
  mainClasses,
}: IPublicLayout) => (
  <>
    <Header>
      <PublicHeader />
    </Header>
    <Main additionalClasses={mainClasses}>{children}</Main>
    <Footer>
      <PublicFooter />
    </Footer>
    <ToastContainer />
    <SigninContainer />
    <SignupContainer />
    <ForgotContainer />
    <ResetContainer />
    <ExistingAccountUserInvitationContainer />
    <InvitationContainer />
    <CreateNewAccountModalContainer />
    <LoggedInUserInvitationContainer />
  </>
);

PublicLayout.defaultProps = {
  mainClasses: '',
};
