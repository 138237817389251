import React, { Fragment } from 'react';
import { Button } from '../components';
import { PlusIcon } from '../icons';

export const Components: React.FC = () => {
  return (
    <Fragment>
      <h1>Buttons</h1>
      <div className="mb-3">
        <Button dimension="sm" className="mr-2">
          Small
        </Button>
        <Button dimension="md" className="mr-2">
          Medium
        </Button>
        <Button>Large</Button>
      </div>

      <div className="mb-3">
        <Button dimension="sm" variant="outline-primary" className="mr-2">
          Small
        </Button>
        <Button dimension="md" variant="outline-primary" className="mr-2">
          Medium
        </Button>
        <Button variant="outline-primary">Large</Button>
      </div>

      <div className="mb-3">
        <Button dimension="sm" disabled className="mr-2">
          Small
        </Button>
        <Button dimension="md" disabled className="mr-2">
          Medium
        </Button>
        <Button icon={<PlusIcon />} disabled>
          Large
        </Button>
      </div>
    </Fragment>
  );
};
