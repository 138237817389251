import React from 'react';
import { Link } from 'react-router-dom';

import { TableActions } from 'components/Table/TableActions';
import { ILicenseKey } from 'interfaces/user';
import i18n from 'translations';

export interface IActionsProps {
  licenseKey: ILicenseKey;
  showActions: boolean;
  onDelete: (licenseKey: ILicenseKey) => void;
}

export const Actions = ({
  licenseKey,
  showActions,
  onDelete,
}: IActionsProps) => {
  return (
    <TableActions>
      {showActions && (
        <>
          <Link
            data-cy="delete-licenseKey-action"
            to="?tab=license-keys"
            onClick={() => onDelete(licenseKey)}
          >
            {i18n.t('settings.licenseKey.actions.delete')}
          </Link>
        </>
      )}
    </TableActions>
  );
};
