import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { detailColumns } from './detailColumns';
import { useAppSelector } from 'store/hooks';

export const LicenseInfoSummary: React.FC = () => {
  const { activationLicenses } = useAppSelector(
    (state) => state.licenseContent.data
  );
  const { t } = useTranslation();

  return (
    <>
      <Row className="d-flex justify-content-between">
        <Col md={2}>
          <small className="ml-2 mb-2 fs-10 font-weight-bold">
            {Object.keys(activationLicenses).length}{' '}
            {t('licenseContent.licenses')}
          </small>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col>
          <div className="div-border">
            <BootstrapTable
              keyField="code"
              data={activationLicenses}
              columns={detailColumns}
              wrapperClasses="table-license-content table-wrapper"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
