import React, { useState, useEffect } from 'react';
import NoDataFound from 'components/NoDataFound';
import { Spinner2 } from 'components/Spinner2';
import Api from 'api';
import { Table } from 'components/Table';
import { useQuery } from 'hooks/query';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import { TableChangeState } from 'react-bootstrap-table-next';
import { columns } from './columns';
import { getPageSize } from '../Settings';

export const MillView: React.FC = () => {
  const [state, setState] = useState({ data: [], count: 0, page: 1 });
  const [pageSize] = useState<number>(+getPageSize());
  const [isLoading, setIsLoading] = useState(false);
  const params: any = useQuery();

  const { data, count, page } = state;

  const getPageUrl = (pageNum: number) => {
    return `/v2/analytics/mills?_start=${
      (pageNum - 1) * pageSize
    }&_limit=${pageSize}`;
  };

  const fetchData = (pageNum: number) => {
    setIsLoading(true);
    const selectedMillId = params.get('id');
    Api.post(getPageUrl(pageNum), {
      ids: selectedMillId ? [selectedMillId] : [],
    })
      .then((response) => {
        // sort data based on id
        const { data: stats, count: total } = response;
        stats.sort((a: any, b: any) => {
          return a.id - b.id;
        });

        setState({ data: stats, count: total, page: pageNum });
        setIsLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchData(page);
  }, []);

  const handleTableChange = (
    _type: any,
    changedState: TableChangeState<any>
  ) => {
    fetchData(changedState.page);
  };

  return (
    <ManagerLayout>
      <h1 className="ml-2 mb-4 mt-3">Mills</h1>
      <hr />
      {!isLoading && data.length > 0 && (
        <Table
          data={data}
          columns={columns}
          sizePerPage={pageSize}
          totalSize={count}
          page={page}
          onTableChange={handleTableChange}
        />
      )}
      {!isLoading && data.length === 0 && <NoDataFound />}
      <Spinner2 show={isLoading} />
    </ManagerLayout>
  );
};
