import { MILL_CONTRACT_TYPE_PAGE_ROUTE } from 'constants/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { redirectRoute } from 'thunks/settings';

export const PaymentFailurePage: React.FC = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    redirectRoute(MILL_CONTRACT_TYPE_PAGE_ROUTE, 2000);
  }, []);
  return <div>{t('contract.tabs.contractType.payment.failure')}</div>;
};
