import React from 'react';

import { authSlice, millSlice } from 'slices';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { SELECTED_MILL } from 'constants/constants';
import { setLocalStorage } from 'utils/localStorage';
import { LAB_ORDERS_ROUTE } from 'constants/routes';
import { millThunk } from 'thunks/mill';
import { MillSelection } from './MillSelection';

export const MillSelectionContainer: React.FC = () => {
  const dispatch = useAppDispatch();

  const { data, showMillSelectionModal } = useAppSelector(
    (state) => state.auth
  );

  const { mills } = data || {};

  const switchToMillCenter = (millId: string) => {
    dispatch(millSlice.actions.setSelectedMill({ id: millId }));
    setLocalStorage(SELECTED_MILL, millId);
    dispatch(millThunk(millId));
    window.location.href = LAB_ORDERS_ROUTE;
  };

  const onMillSelection = (millId: string) => {
    dispatch(authSlice.actions.showMillSelectionModal());
    switchToMillCenter(millId);
  };

  const onClose = () => {
    dispatch(authSlice.actions.closeMillSelectionModal());
  };

  return (
    <MillSelection
      show={showMillSelectionModal}
      onClose={onClose}
      onMillSelection={onMillSelection}
      mills={mills}
    />
  );
};
