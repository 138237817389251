import React from 'react';

export const OrderEmptyIcon = () => (
  <svg
    width="172"
    height="172"
    viewBox="0 0 172 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.1667 94.959C50.1667 91.9905 52.5731 89.584 55.5417 89.584H116.458C119.427 89.584 121.833 91.9905 121.833 94.959C121.833 97.9275 119.427 100.334 116.458 100.334H55.5417C52.5731 100.334 50.1667 97.9275 50.1667 94.959Z"
      fill="#C7CED8"
    />
    <path
      d="M50.1667 123.626C50.1667 120.657 52.5731 118.251 55.5417 118.251H116.458C119.427 118.251 121.833 120.657 121.833 123.626C121.833 126.594 119.427 129.001 116.458 129.001H55.5417C52.5731 129.001 50.1667 126.594 50.1667 123.626Z"
      fill="#C7CED8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.1044 23.2923H62.2736L70.8345 14.7314C74.5305 11.0354 79.5434 8.95898 84.7704 8.95898H87.2296C92.4566 8.95898 97.4695 11.0354 101.165 14.7314L109.726 23.2923H128.896C128.968 23.2909 129.04 23.2909 129.113 23.2923H136.167C147.051 23.2923 155.875 32.116 155.875 43.0006V150.501C155.875 161.385 147.051 170.209 136.167 170.209H35.8333C24.9487 170.209 16.125 161.385 16.125 150.501V43.0006C16.125 32.116 24.9487 23.2923 35.8333 23.2923H42.8879C42.9602 23.2909 43.0323 23.2909 43.1044 23.2923ZM103.699 32.468C104.738 33.5065 106.095 34.0312 107.457 34.0421L107.5 34.0423H120.303L113.821 47.007C112.304 50.042 109.202 51.9591 105.808 51.9591H66.1921C62.799 51.9591 59.697 50.042 58.1795 47.007L51.6972 34.0423H64.5L64.5479 34.0421C65.9075 34.0301 67.2633 33.5054 68.3007 32.468L78.4359 22.3328C80.1159 20.6528 82.3945 19.709 84.7704 19.709H87.2296C89.6055 19.709 91.8841 20.6528 93.5641 22.3328L103.699 32.468ZM39.6783 34.0423H35.8333C30.8858 34.0423 26.875 38.0531 26.875 43.0006V150.501C26.875 155.448 30.8858 159.459 35.8333 159.459H136.167C141.114 159.459 145.125 155.448 145.125 150.501V43.0006C145.125 38.0531 141.114 34.0423 136.167 34.0423H132.322L123.436 51.8146C120.098 58.4914 113.273 62.7091 105.808 62.7091H66.1921C58.7272 62.7091 51.9029 58.4914 48.5645 51.8146L39.6783 34.0423Z"
      fill="#C7CED8"
    />
  </svg>
);
