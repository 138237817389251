import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, Prompt } from 'react-router-dom';

import { ILandingPage } from 'thunks/settings';
import { Button } from 'components/Button';
import { ImageUploadControl } from 'components/Upload/ImageUploadControl';
import { isErrorString } from 'utils/handle-api-error';
import { LAB_HOME_ROUTE } from 'constants/routes';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { setSessionStorage } from 'utils/localStorage';
import { PreviewLandingPageContainer } from './Preview/PreviewLandingPageContainer';

interface ILandingPageProps {
  initialValues: ILandingPage;
  onSubmit: (values: ILandingPage) => void | Promise<any>;
  validationSchema: any | (() => any);
  error?: any;
  showLandingPagePreview: () => void;
}

export const LandingPageForm: React.FC<ILandingPageProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  error = undefined,
  showLandingPagePreview,
}: ILandingPageProps) => {
  const { t } = useTranslation();

  const copyUrl = initialValues.id
    ? `${window.location.origin}${generatePath(LAB_HOME_ROUTE, {
        millId: initialValues.id,
      })}`
    : '';

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(copyUrl);
  };

  return (
    <>
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}

      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
          dirty,
        }) => {
          setSessionStorage(TAB_FORM_IS_DIRTY, `${dirty}`);
          return (
            <>
              {values.id && (
                <Form.Group controlId="copy">
                  <Form.Label>
                    {t('settings.landingPage.copy.label')}
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      name="copy"
                      data-cy="lp-copy-url"
                      value={copyUrl}
                      readOnly
                    />
                    <Button className="ml-20 w-25" onClick={handleCopyUrl}>
                      {t('settings.landingPage.copy.button')}
                    </Button>
                  </div>
                </Form.Group>
              )}

              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form
                onSubmit={handleSubmit}
                className="landing-page-form"
                data-cy="landing-page"
              >
                <Form.Group controlId="logo" className="mb-60 browse-input">
                  <Form.Label>{t('settings.landingPage.logo')}</Form.Label>
                  <ImageUploadControl
                    setFieldValue={setFieldValue}
                    accept="image/*"
                    imageUrl={values.files}
                    imageName={values.logo?.name}
                  />
                </Form.Group>
                <Form.Group controlId="landingPageHeader">
                  <Form.Label>
                    {t('settings.landingPage.header.label')}
                  </Form.Label>
                  <Form.Control
                    name="landingPageHeader"
                    placeholder={t('settings.landingPage.header.placeholder')}
                    value={values.landingPageHeader}
                    onChange={handleChange}
                    data-cy="lp-header"
                    isInvalid={
                      touched.landingPageHeader && !!errors.landingPageHeader
                    }
                  />
                </Form.Group>

                <Form.Group controlId="landingPageContent" className="mb-72">
                  <Form.Label>
                    {t('settings.landingPage.content.label')}
                  </Form.Label>
                  <Form.Control
                    name="landingPageContent"
                    placeholder={t('settings.landingPage.content.placeholder')}
                    value={values.landingPageContent}
                    onChange={handleChange}
                    data-cy="lp-content"
                    as="textarea"
                    rows={8}
                    isInvalid={
                      touched.landingPageContent && !!errors.landingPageContent
                    }
                  />
                </Form.Group>

                <Button
                  className="btn-outline-primary width-auto"
                  onClick={() => {
                    showLandingPagePreview();
                  }}
                >
                  {t('settings.landingPage.preview.btnTxt')}
                </Button>
                <Button className="ml-20" type="submit">
                  {t('settings.landingPage.update')}
                </Button>

                <PreviewLandingPageContainer {...values} />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

LandingPageForm.defaultProps = {
  error: undefined,
};
