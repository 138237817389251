import { Spinner2 } from 'components';
import { PublicLayout } from 'components/Layout';
import NoDataFound from 'components/NoDataFound';
import { ITabConfig, TabsControl } from 'components/TabsControl/TabsControl';
import { Container, Row, Col, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import i18n from 'translations';
import { columns } from './LicenseSummary/columns';
import { useState } from 'react';
import { LICENSE_CONTENT_SEARCH, LICENSE_TABS } from 'constants/constants';
import React from 'react';
import { HD_TICKET_FORMAT } from 'utils/common';
import { getLocalStorageItem } from 'utils/localStorage';
import { LicenseInfoSummary } from './LicenseSummary';
import { useQuery } from 'hooks/query';
import { useHistory } from 'react-router-dom';
import { ACTIVE_LICENSES, LICENSE_DETAILS, ORDERED_LICENSES } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ILicenseContent, licenseContentThunk } from 'thunks/license-content';
import { LicenseInfoDetails } from './LicenseDetails';
import { licenseContentSlice } from 'slices';
import { OrderedLicenses } from './OrderedLicenses';

const tabs: ITabConfig[] = [
  {
    eventKey: LICENSE_TABS.ACTIVE,
    title: i18n.t('licenseContent.tabActive'),
    children: <LicenseInfoSummary />,
  },
  {
    eventKey: LICENSE_TABS.ORDERED,
    title: i18n.t('licenseContent.tabOrdered'),
    children: <OrderedLicenses />,
  },
  {
    eventKey: LICENSE_TABS.DETAILS,
    title: i18n.t('licenseContent.tabDetails'),
    children: <LicenseInfoDetails />,
  },
];

export const LicenseContainer = () => {
  const { mainProductInfo } = useAppSelector(
    (state) => state.licenseContent.data
  );
  const error = useAppSelector((state) => state.licenseContent.error);

  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const getPageUrl = () => {
    return `/v1/ticket-licenses-details`;
  };
  const query = useQuery();
  const history = useHistory();

  function validFormat(ticketId: string) {
    return HD_TICKET_FORMAT.test(ticketId);
  }

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchLicense(search);
    }
  };

  const fetchData = async (ticketId: string) => {
    setIsProcessing(true);
    localStorage.setItem(LICENSE_CONTENT_SEARCH, ticketId);
    const payload: ILicenseContent = {
      url: getPageUrl(),
      ticketId,
      baseURL: process.env.REACT_APP_ACTIVITY_BASE_URL,
    };

    await dispatch(licenseContentThunk(payload));
    setIsProcessing(false);
  };

  const searchLicense = async (e: string) => {
    if (validFormat(search)) {
      await fetchData(search);
    } else {
      dispatch(
        licenseContentSlice.actions.setError({
          message: t('licenseContent.invalid'),
        })
      );
      dispatch(licenseContentSlice.actions.resetData());
    }
  };

  const clickSearch = () => {
    searchLicense(search);
  };

  React.useEffect(() => {
    const searchQuery = getLocalStorageItem(LICENSE_CONTENT_SEARCH);
    if (searchQuery) {
      if (validFormat(searchQuery)) {
        setSearch(searchQuery);
        fetchData(searchQuery);
      } else {
        dispatch(
          licenseContentSlice.actions.setError({
            message: t('licenseContent.invalid'),
          })
        );
      }
    }
  }, []);

  const handleTabChange = (eventKey: any) => {
    if (eventKey === LICENSE_TABS.ACTIVE) {
      history.push(ACTIVE_LICENSES);
    } else if (eventKey === LICENSE_TABS.ORDERED) {
      history.push(ORDERED_LICENSES);
    } else if (eventKey === LICENSE_TABS.DETAILS) {
      history.push(LICENSE_DETAILS);
    }
  };

  return (
    <PublicLayout mainClasses="page">
      <Container className="license-page-container">
        <Row className="license-public-page">
          <Col className="license-public-page-col">
            <Row className="d-flex justify-content-between">
              <Col md={4}></Col>
              <Col md={8}>
                <small
                  className="fs-10"
                  style={{ color: !error ? 'grey' : 'red' }}
                >
                  {t('licenseContent.format')}: XXXXX-XXXXX-XXXXX-XXXXX-XXXXX
                </small>
                {!!error && (
                  <small className="fs-12" style={{ color: 'red' }}>
                    {' - ' + error.message}
                  </small>
                )}
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col md={4}>
                <h1 className="mt-12 mb-8 fs-24">
                  {t('licenseContent.heading')}
                </h1>
              </Col>
              <Col md={6}>
                <Row className="mb-8">
                  <input
                    className="form-control search-input search-text"
                    name="search"
                    placeholder={t('licenseContent.placeholder')}
                    value={search}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Row>
              </Col>
              <Col md={2}>
                <Button
                  disabled={!search}
                  onClick={clickSearch}
                  className="search-btn"
                >
                  {t('search')}
                </Button>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col>
                {!isProcessing && Object.keys(mainProductInfo).length > 0 && (
                  <>
                    <BootstrapTable
                      keyField="ticketId"
                      data={[mainProductInfo]}
                      columns={columns}
                      wrapperClasses="table-license-content"
                    />
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {!isProcessing && Object.keys(mainProductInfo).length === 0 && <Row className="license-public-page license-nodata-row">
          <Col className="license-public-page-col">
            <Row className="d-flex justify-content-between">
              <Col md={12}>
                <NoDataFound />
              </Col>
            </Row>
          </Col>
        </Row>}
        {!isProcessing && Object.keys(mainProductInfo).length > 0 && <Row className="license-public-page license-bottom-row">
          <Col className="license-public-page-col">
            <div className="div-tabs">
              <TabsControl
                activeKey={query.get('tab') || LICENSE_TABS.ACTIVE}
                id="license-info"
                tabs={tabs}
                onTabChange={handleTabChange}
              />
            </div>
          </Col>
        </Row>}
        <Spinner2 show={isProcessing} />
      </Container>
    </PublicLayout>
  );
};
