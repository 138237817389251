import React from 'react';

export const DropDownIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24001 8.24555C5.56002 7.91815 6.07885 7.91815 6.39886 8.24555L12 13.976L17.6011 8.24555C17.9211 7.91815 18.44 7.91815 18.76 8.24555C19.08 8.57295 19.08 9.10377 18.76 9.43117L12.5794 15.7545C12.2594 16.0819 11.7406 16.0818 11.4206 15.7545L5.24001 9.43117C4.92 9.10377 4.92 8.57295 5.24001 8.24555Z"
      fill="#2E323B"
    />
  </svg>
);
