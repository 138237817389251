/* eslint-disable prettier/prettier */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from '../../Modal/Modal';
import { CreateNewAccountContainer } from '../CreateNewAccount/CreateNewAccountContainer';

interface IInvitationProps {
  show: boolean;
  onClose: () => any;
}

export const CreateNewAccountModal = ({ show, onClose }: IInvitationProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('invitation.title')}
      show={show}
      onClose={onClose}
      closeOnOutSideClick={false}
    >
      <CreateNewAccountContainer />
    </Modal>
  );
};
