import React from 'react';
import { FilePdfOutlined } from '@ant-design/icons';

interface IReportGeneratorProps {
  downloadFile: () => void;
}

export const ReportGeneratorComponent: React.FC<IReportGeneratorProps> = ({
  downloadFile,
}: IReportGeneratorProps) => {
  return (
    <div>
      <FilePdfOutlined style={{ fontSize: 24 }} onClick={downloadFile} />
    </div>
  );
};
