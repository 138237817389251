import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { Table } from 'components/Table';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { getSessionStorageItem } from 'utils/localStorage';
import Api from 'api';
import { useAppDispatch } from 'store/hooks';
import { toastSlice } from 'slices';
import { useTranslation } from 'react-i18next';
import { Spinner2 } from 'components';
import { columns } from './columns';

export const InvoicesTableContainer: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);

  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  useEffect(() => {
    (async () => {
      try {
        setIsProcessing(true);
        const response = await Api.get('/payments/invoices');
        setInvoices(response.invoices);
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        dispatch(
          toastSlice.actions.show({
            title: t('contract.error'),
            message: t('contract.tabs.invoices.errorMessage'),
          })
        );
      }
    })();
  }, []);

  return (
    <div data-cy="mill-billing-table" className="page-users">
      <Spinner2 show={isProcessing} />
      <Row>
        <Col>
          <Table
            page={1}
            data={invoices}
            columns={columns}
            totalSize={invoices.length}
            sizePerPage={10}
          />
        </Col>
      </Row>
    </div>
  );
};
