import { IContractType, ICustomContract } from 'thunks/contract-type';
import { ICompanyDetail, IAddress } from '../thunks/settings';

export const MILL_ROLE = 'mill';
export const LAB_ROLE = 'lab';

export type Role = typeof MILL_ROLE | typeof LAB_ROLE;

export const MILL_ADMIN_ROLE = 'admin';
export const MILL_USER_ROLE = 'user';

export type MillRole = typeof MILL_ADMIN_ROLE | typeof MILL_USER_ROLE;

export interface IMill {
  id?: number;
  landingPageHeader: string;
  landingPageContent: string;
  landingPageInfoIsUpdated?: boolean;
  blocked?: boolean;
  blockReason?: string;
  blockedMillClientMessage?: string;
  company: ICompanyDetail;
  contractType?: IContractType;
  customContract?: ICustomContract;
  millOrderStat?: IMillOrderStats;
  dataUsed?: number;
  subscriptionCancelDate?: number;
}

export interface ICompany {
  id?: number;
  name: string;
  address: IAddress;
  files?: any;
  logo?: any;
  email?: string;
  web?: string;
  taxId?: string;
}

export interface ILab {
  id?: number;
  company: ICompanyDetail;
  shippingAddress: IAddress;
  shippingAtSameAddress?: boolean;
}

export interface IUserMillLab {
  lab?: ILab;
  mill?: IMill;
}

export interface IMillOrderStats {
  id: number;
  mill: IMill;
  month: number;
  year: number;
  ordersCreated: number;
  dataUsed: number;
  projectIds: string;
}
