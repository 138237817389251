import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ILandingPage } from '../../../../../thunks/settings';
import { LandingPageComponent } from '../../../../Shared/LandingPage';
import { Button } from '../../../../../components/Button';

interface IPreviewLandingPage extends ILandingPage {
  show: boolean;
  previewUrl: string;
  closePreview: () => void;
}

export const PreviewLandingPage: React.FC<IPreviewLandingPage> = (
  props: IPreviewLandingPage
) => {
  const { t } = useTranslation();
  const { show, closePreview } = props;

  return (
    <Modal show={show} fullscreen="true" id="preview-modal" scrollable={show}>
      <Modal.Body>
        <div className="preview-close">
          <Button
            className="btn-outline-primary mb-100 mt-30"
            onClick={closePreview}
          >
            {t('settings.landingPage.preview.closeBtnTxt')}
          </Button>
        </div>

        <LandingPageComponent {...props} />
      </Modal.Body>
    </Modal>
  );
};
