import React from 'react';

export const SearchEmptyIcon = () => (
  <svg
    width="172"
    height="172"
    viewBox="0 0 172 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.188 100.587C114.487 92.642 118.25 82.5938 118.25 71.6668C118.25 45.9396 97.3936 25.0835 71.6663 25.0835C45.9391 25.0835 25.083 45.9396 25.083 71.6668C25.083 97.3941 45.9391 118.25 71.6663 118.25C82.5933 118.25 92.6415 114.488 100.586 108.188L132.366 139.968C134.465 142.067 137.868 142.067 139.967 139.968C142.066 137.868 142.066 134.465 139.967 132.366L108.188 100.587ZM107.5 71.6668C107.5 91.457 91.4565 107.5 71.6663 107.5C51.8761 107.5 35.833 91.457 35.833 71.6668C35.833 51.8766 51.8761 35.8335 71.6663 35.8335C91.4565 35.8335 107.5 51.8766 107.5 71.6668Z"
      fill="#C7CED8"
    />
  </svg>
);
