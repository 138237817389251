import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { downloadAppThunk } from 'thunks/support';
import { isErrorString } from 'utils/handle-api-error';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { getSessionStorageItem } from 'utils/localStorage';

export const DownloadAppFormContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.downloadApp.error);
  const { t } = useTranslation();
  const location = useLocation();

  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);

  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleSubmit = () => {
    dispatch(downloadAppThunk());
  };

  return (
    <>
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}

      <div className="support-download-app">
        <p className="fs-20 lh-26 mb-56">{t('support.downloadApp.info')}</p>

        <Button className="form-lab-contact" onClick={handleSubmit}>
          {t('support.downloadApp.download')}
        </Button>
      </div>
    </>
  );
};
