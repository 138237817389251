import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSelectedMillId } from 'utils/common';
import { RootState } from 'store/store';
import { IMill } from 'interfaces/mill';
import Api from '../api';
import { toastSlice } from '../slices';
import i18n from '../translations';

export interface ILabContact {
  topic: string;
  message: string;
}

export const sendLabContactEmailThunk = createAsyncThunk(
  'send/lab/contact/email',
  async (payload: ILabContact, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.post(
        `/mills/${millId}/support/contact/send-email`,
        payload
      );

      const message =
        typeof response.message === 'object'
          ? response.message.errors
          : response.message;

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('support.contact.sendEmailToast.title'),
          message,
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadAppThunk = createAsyncThunk(
  'support/lab/download/app',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get('/desktop-app');
      window.location.href = response;
      return response;
    } catch (err) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('support.downloadApp.toastTitle'),
          message: i18n.t('support.downloadApp.notFound'),
        })
      );
      return rejectWithValue(i18n.t('support.downloadApp.notFound'));
    }
  }
);
