import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

export interface IImageUploadControlProps {
  setFieldValue: any;
  maxFiles?: number;
  accept?: string;
  imageUrl?: any;
  imageName?: string;
}

export const ImageUploadControl = ({
  setFieldValue,
  accept = 'image/*',
  maxFiles = 1,
  imageUrl = [],
  imageName,
}: IImageUploadControlProps) => {
  const { t } = useTranslation();
  const imgPath = imageUrl.length ? imageUrl[0].path : imageName || '';
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop: (files) => {
      const newFiles = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFieldValue('files', newFiles);
    },
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="image-upload-control" data-cy="image-upload-control">
          <div className="input-text">
            <input
              type="text"
              value={imgPath}
              onChange={() => {}}
              className="form-control bg-white"
              readOnly
            />
          </div>
          <div className="browse-btn">
            <Button className="img-upload-btn">
              {t('imageUpload.button')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
