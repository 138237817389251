import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { getSelectedMillId } from '../utils/common';
import Api from '../api';
import { IMill } from '../interfaces/mill';

export const responsiblePersonsThunk = createAsyncThunk(
  'responsible-persons',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const millId = getSelectedMillId(state.mill.data as IMill);
    return Api.get(`/mills/${millId}/responsible-persons`);
  }
);
