import React from 'react';
import { previewLandingPageSlice } from '../../../../../slices/preview';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { ILandingPage } from '../../../../../thunks/settings';
import { PreviewLandingPage } from './PreviewLandingPage';

export const PreviewLandingPageContainer: React.FC<ILandingPage> = (
  values: ILandingPage
) => {
  const dispatch = useAppDispatch();
  const { id, landingPageHeader, landingPageContent, company } = useAppSelector(
    (state) => state.mill.data
  );

  const logo = company?.logo;
  const show = useAppSelector((state) => state.preview.show);

  const onClosePreview = () => {
    dispatch(previewLandingPageSlice.actions.close());
  };

  const previewUrl = values.files?.length ? values.files[0].preview : '';

  return (
    <>
      <PreviewLandingPage
        id={id}
        landingPageHeader={values.landingPageHeader || landingPageHeader}
        landingPageContent={values.landingPageContent || landingPageContent}
        logo={logo}
        previewUrl={previewUrl}
        show={show}
        closePreview={onClosePreview}
      />
    </>
  );
};
