import { clientOptionsSelector } from '../slices/clients';
import { responsiblePersonsOptionsSelector } from '../slices/responsible-persons';
import { useAppSelector } from '../store/hooks';

export function useClientOptions(anyLabel: string) {
  return useAppSelector(clientOptionsSelector(anyLabel));
}

export function useResponsiblePersonsOptions(anyLabel: string) {
  return useAppSelector(responsiblePersonsOptionsSelector(anyLabel));
}
