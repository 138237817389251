import React from 'react';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { toastSlice } from 'slices';
import { Spinner2 } from 'components';
import { usersSlice } from '../../../../slices';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  addUserThunk,
  editUserThunk,
  IUserPayload,
} from '../../../../thunks/users';
import { UserForm } from './UserForm';

const schema: yup.SchemaOf<IUserPayload> = yup.object().shape({
  id: yup.number().optional(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  millRole: yup.string().required(),
});

export const UserFormContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const error = useAppSelector((state) => state.users.addUser?.error);
  const { showAddUser, isEditMode, editingUser } = useAppSelector(
    (state) => state.users
  );
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  const handleSubmit = async (values: IUserPayload) => {
    try {
      setIsProcessing(true);
      if (isEditMode) {
        await dispatch(editUserThunk(values));
      } else {
        await dispatch(addUserThunk(values));
      }
      setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
      dispatch(
        toastSlice.actions.show({
          title: t('settings.licenseKey.error.title'),
          message: t('csettings.licenseKey.error.message'),
        })
      );
    }
  };

  return (
    <>
      <Spinner2 show={isProcessing} />
      <UserForm
        show={showAddUser}
        onClose={usersSlice.actions.closeAddUser}
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={
          {
            id: editingUser?.id || undefined,
            firstName: editingUser?.firstName || '',
            lastName: editingUser?.lastName || '',
            email: editingUser?.email || '',
            millRole: editingUser?.millRole || 'user',
          } as IUserPayload
        }
        isEditMode={isEditMode}
        error={error}
      />
    </>
  );
};
