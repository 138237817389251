import React from 'react';

export const LogoIcon = () => (
  <svg
    viewBox="0 0 514.96997 99.599999"
    width="514.96997"
    height="99.599998"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="#f28b00"
      y="67.059998"
      width="514.96997"
      height="32.540001"
      id="rect56"
      x="0"
      strokeWidth="1.04902"
    />
    <path
      fill="#7b7b7a"
      d="m 45.91,0 -4.63,29.24 h 0.15 C 45.33,25 49.55,23 55,23 c 10,0 13.73,6.58 12.15,16.53 l -3.8,24 H 49.4 L 52.59,43.4 c 0.72,-4.59 0.79,-9.34 -4.8,-9.34 -5.59,0 -8,4.75 -8.75,9.34 L 35.85,63.53 H 21.92 L 32,0 Z"
      id="path58"
    />
    <polygon
      fill="#7b7b7a"
      points="122.59,24.26 81.81,83.12 66.19,83.12 83.54,58.17 69.93,24.26 86,24.26 93.3,44.39 106.74,24.26 "
      id="polygon60"
    />
    <path
      fill="#7b7b7a"
      d="m 135.62,43.93 c -0.79,5 1.9,8.81 7.56,8.81 a 10.15,10.15 0 0 0 10.35,-8.81 c 0.77,-4.82 -1.88,-8.87 -7.55,-8.87 a 10.34,10.34 0 0 0 -10.36,8.87 m 2.87,-15.23 h 0.16 A 16.51,16.51 0 0 1 151.42,23 c 11.71,0 18.27,9.8 16.49,21 -1.78,11.2 -11.33,20.82 -23,20.82 -4.51,0 -8.93,-1.61 -11.37,-5 l -3.7,23.34 h -13.9 l 9.33,-58.86 h 13.93 z"
      id="path62"
    />
    <path
      fill="#7b7b7a"
      d="M 203.12,38.19 C 203,34.44 200,32 196.22,32 a 9.52,9.52 0 0 0 -9.25,6.2 z m 11.66,8 h -29.31 c -0.9,5.66 1.6,8.73 7.34,8.73 a 8.3,8.3 0 0 0 7.23,-3.6 h 13.39 c -3.68,9.26 -13.15,13.47 -22.11,13.47 -13,0 -21.57,-7.35 -19.42,-20.9 C 174,30.85 184.18,23 197,23 c 13.62,0 20.17,8.42 18,21.82 z"
      id="path64"
    />
    <path
      fill="#7b7b7a"
      d="m 237.52,30.69 h 0.16 a 16.29,16.29 0 0 1 13.81,-7 h 2.68 l -2.12,13.39 a 10.26,10.26 0 0 0 -5.69,-1.3 c -7.73,0 -10.46,5.13 -11.53,11.86 l -2.51,15.85 h -13.93 l 6.22,-39.27 h 13.93 z"
      id="path66"
    />
    <path
      fill="#7b7b7a"
      d="m 271.82,50.82 h 3.37 c 9,0 16.61,-4.9 16.61,-16.15 0,-10.33 -6.81,-16.15 -16.45,-16.15 h -3.53 z m -15,-45 h 21.28 a 28.86,28.86 0 1 1 0,57.71 h -21.28 z"
      id="path68"
    />
    <polygon
      fill="#7b7b7a"
      points="326.32,40.87 326.32,50.82 344.16,50.82 344.16,63.53 311.32,63.53 311.32,5.82 344.16,5.82 344.16,18.52 326.32,18.52 326.32,28.17 343.24,28.17 343.24,40.87 "
      id="polygon70"
    />
    <polygon
      fill="#7b7b7a"
      points="394.52,5.82 409.52,5.82 409.52,63.53 394.52,63.53 367.04,28.17 366.89,28.17 366.89,63.53 351.88,63.53 351.88,5.82 366.89,5.82 394.36,41.1 394.52,41.1 "
      id="polygon72"
    />
    <polygon
      fill="#7b7b7a"
      points="414.88,5.82 454.68,5.82 454.68,18.52 442.28,18.52 442.28,63.53 427.28,63.53 427.28,18.52 414.88,18.52 "
      id="polygon74"
    />
    <path
      fill="#7b7b7a"
      d="m 463.29,10.09 c 0.64,0 1,-0.14 1,-0.87 0,-0.73 -0.73,-0.58 -1.29,-0.58 h -1.23 v 1.45 z m 2,3.27 H 463.9 L 462.65,11 h -0.88 v 2.41 h -1.28 V 7.64 h 2.78 c 1.32,0 2.22,0.24 2.22,1.75 0,1.06 -0.54,1.49 -1.56,1.56 z m 1.56,-2.79 a 4.2,4.2 0 1 0 -8.39,0 4.2,4.2 0 1 0 8.39,0 m -9.7,0 a 5.51,5.51 0 1 1 5.5,5.5 5.48,5.48 0 0 1 -5.5,-5.5"
      id="path76"
    />
    <path
      fill="#ffffff"
      d="m 359.32,80.62 a 4.47,4.47 0 0 0 -3.61,-1.74 4.17,4.17 0 0 0 -4.22,4.39 4.1,4.1 0 0 0 4.28,4.3 4.72,4.72 0 0 0 3.55,-1.68 v 4.94 a 11.6,11.6 0 0 1 -3.63,0.73 8.82,8.82 0 0 1 -5.94,-2.33 7.72,7.72 0 0 1 -2.54,-6 8.28,8.28 0 0 1 2.33,-5.8 8.55,8.55 0 0 1 6,-2.56 9.54,9.54 0 0 1 3.76,0.8 z"
      id="path78"
    />
    <path
      fill="#ffffff"
      d="m 379.24,82.93 a 8.76,8.76 0 0 1 -17.51,0 c 0,-4.79 4.2,-8.15 8.76,-8.15 4.56,0 8.75,3.36 8.75,8.15 z M 366,83 a 4.5,4.5 0 1 0 9,0 4.5,4.5 0 0 0 -9,0 z"
      id="path80"
    />
    <path
      fill="#ffffff"
      d="m 381.83,75.31 h 4.11 l 7.54,9.69 v 0 -9.69 h 4.11 v 15.83 h -4.11 L 386,81.44 v 0 9.7 h -4.11 z"
      id="path82"
    />
    <path
      fill="#ffffff"
      d="m 400.33,75.31 h 4.11 L 412,85 v 0 -9.69 h 4.12 V 91.14 H 412 l -7.54,-9.7 v 0 9.7 h -4.11 z"
      id="path84"
    />
    <path
      fill="#ffffff"
      d="m 422.94,78.79 v 2.65 h 4.64 v 3.49 h -4.64 v 2.72 h 4.89 v 3.49 h -9 V 75.31 h 9 v 3.48 z"
      id="path86"
    />
    <path
      fill="#ffffff"
      d="m 442.31,80.62 a 4.47,4.47 0 0 0 -3.61,-1.74 4.17,4.17 0 0 0 -4.22,4.39 4.1,4.1 0 0 0 4.28,4.3 4.72,4.72 0 0 0 3.55,-1.68 v 4.94 a 11.6,11.6 0 0 1 -3.63,0.73 8.82,8.82 0 0 1 -5.94,-2.33 7.75,7.75 0 0 1 -2.54,-6 8.28,8.28 0 0 1 2.33,-5.8 8.55,8.55 0 0 1 6,-2.56 9.54,9.54 0 0 1 3.76,0.8 z"
      id="path88"
    />
    <path
      fill="#ffffff"
      d="M 452.15,91.14 H 448 V 78.79 h -3.4 v -3.48 h 10.92 v 3.48 h -3.41 z"
      id="path90"
    />
  </svg>
);
