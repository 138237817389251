import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { EmailIcon, UserIcon } from 'icons';
import { Button, Select } from 'components';
import { Modal } from 'components/Modal';
import { IUserPayload } from 'thunks/users';
import { hasError, isErrorString, showError } from 'utils/handle-api-error';
import { roleOptions } from './Table/columns';

interface IClientFormProps {
  show: boolean;
  initialValues: IUserPayload;
  validationSchema: any | (() => any);
  isEditMode: boolean;
  error?: any;
  onClose: () => any;
  onSubmit: (
    values: IUserPayload,
    form: FormikHelpers<IUserPayload>
  ) => void | Promise<any>;
}

export const UserForm = ({
  show,
  isEditMode,
  initialValues,
  validationSchema,
  error = undefined,
  onClose,
  onSubmit,
}: IClientFormProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={isEditMode ? t('settings.users.edit') : t('settings.users.add')}
      show={show}
      onClose={onClose}
      dialogClassName="user-dialog"
      closeOnOutSideClick={false}
    >
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
          dirty,
        }) => {
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit} data-cy="user-form">
                <Form.Group controlId="firstName">
                  <Form.Label>{t('firstName.label')}</Form.Label>
                  <Form.Control
                    name="firstName"
                    placeholder={t('firstName.placeholder')}
                    value={values.firstName}
                    onChange={handleChange}
                    data-cy="user-form-firstName"
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                  <UserIcon />
                </Form.Group>

                <Form.Group controlId="lastName">
                  <Form.Label>{t('lastName.label')}</Form.Label>
                  <Form.Control
                    name="lastName"
                    placeholder={t('firstName.placeholder')}
                    value={values.lastName}
                    onChange={handleChange}
                    data-cy="user-form-lastName"
                    isInvalid={touched.lastName && !!errors.lastName}
                  />
                  <UserIcon />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>{t('email.label')}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder={t('email.placeholder')}
                    value={values.email}
                    onChange={handleChange}
                    data-cy="user-form-email"
                    isInvalid={
                      touched.email &&
                      (!!errors.email || hasError(error, 'email'))
                    }
                  />
                  <EmailIcon />
                  <Form.Control.Feedback type="invalid">
                    {showError(error, 'email')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="millRole" className="mb-60">
                  <Form.Label>{t('settings.users.columns.role')}</Form.Label>
                  <Select
                    options={roleOptions}
                    onChange={(option) => {
                      setFieldValue('millRole', option.value);
                    }}
                    data-cy="user-form-millrole"
                    value={roleOptions.find(
                      (rm) => rm.value === values.millRole
                    )}
                  />
                </Form.Group>

                <Button dimension="lg" full type="submit">
                  {isEditMode
                    ? t('settings.users.edit')
                    : t('settings.users.add')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

UserForm.defaultProps = {
  error: undefined,
};
