import { createSlice } from '@reduxjs/toolkit';

export interface ISignupState {
  show: boolean;
}

export const initialState: ISignupState = {
  show: false,
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    show(state) {
      state.show = true;
    },
    close(state) {
      state.show = false;
    },
  },
});
