import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface IPrivateRouteProps {
  path: string;
  component: React.ComponentType<any>;
  isAvailable: boolean;
  redirectTo: string;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  path,
  component: RouteCompoment,
  isAvailable,
  redirectTo,
  ...rest
}: IPrivateRouteProps) => {
  return (
    <Route
      exact
      path={path}
      {...rest}
      render={(props) =>
        isAvailable ? (
          <RouteCompoment {...props} />
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
};
