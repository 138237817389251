import React from 'react';
import { Switch } from 'components';
import { useMillRole } from 'hooks/role';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateUserSettingsThunk } from 'thunks/account';
import i18n from 'translations';

interface INotification {
  id: string;
  label: string;
}

const commonNotifications: INotification[] = [
  {
    id: 'notificationOnNewMessageReceived',
    label: i18n.t(
      'account.settings.notifications.notificationOnNewMessageReceived'
    ),
  },
  // {
  //   id: 'sendEmailOnNewMessageReceived',
  //   label: i18n.t(
  //     'account.settings.notifications.sendEmailOnNewMessageReceived'
  //   ),
  // },
  {
    id: 'notificationOnNewProjectIsCreated',
    label: i18n.t(
      'account.settings.notifications.notificationOnNewProjectIsCreated'
    ),
  },
  // {
  //   id: 'sendEmailOnNewProjectIsCreated',
  //   label: i18n.t(
  //     'account.settings.notifications.sendEmailOnNewProjectIsCreated'
  //   ),
  // },
];

const millNotifications: INotification[] = [...commonNotifications];

const labNotifications: INotification[] = [
  ...commonNotifications,
  // {
  //   id: 'notificationOnOrderProcessStart',
  //   label: i18n.t(
  //     'account.settings.notifications.notificationOnOrderProcessStart'
  //   ),
  // },
  // {
  //   id: 'notificationOnOrderProcessCompleted',
  //   label: i18n.t(
  //     'account.settings.notifications.notificationOnOrderProcessCompleted'
  //   ),
  // },
];

export const NotificationsContainer = () => {
  const dispatch = useAppDispatch();
  const userSettings: any = useAppSelector(
    (state) => state.auth.data.user.userSettings
  );
  const isMillRole = useMillRole();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateUserSettingsThunk({
        [e.target.name]: e.target.checked,
      })
    );
  };

  return (
    <>
      {(isMillRole ? millNotifications : labNotifications).map(
        (notification) => (
          <Switch
            key={notification.id}
            label={notification.label}
            id={notification.id}
            data-cy={`check-${notification.id}`}
            onChange={handleChange}
            defaultChecked={!!userSettings[notification.id]}
            className="mb-40"
          />
        )
      )}
    </>
  );
};
