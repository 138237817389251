import React from 'react';

export const LangIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20.5 12C20.5 12.6889 20.4181 13.3586 20.2634 14H16.1603C16.2197 13.3414 16.25 12.6743 16.25 12C16.25 11.1544 16.2023 10.3202 16.1095 9.5H20.1264C20.3693 10.2904 20.5 11.1299 20.5 12ZM14.5992 9.5C14.6987 10.3187 14.75 11.1531 14.75 12C14.75 12.6752 14.7174 13.3424 14.6538 14H9.34622C9.28259 13.3424 9.25 12.6752 9.25 12C9.25 11.1531 9.30128 10.3187 9.40078 9.5H14.5992ZM15.8871 8H19.5019C18.4911 6.10825 16.7857 4.64358 14.7263 3.94665C15.2374 5.23974 15.6289 6.59575 15.8871 8ZM12.9283 3.55011C13.5669 4.95119 14.0517 6.44215 14.3601 8H9.63995C9.94827 6.44215 10.4331 4.95119 11.0717 3.55011C11.3766 3.51699 11.6863 3.5 12 3.5C12.3137 3.5 12.6234 3.51699 12.9283 3.55011ZM9.27368 3.94665C7.2143 4.64358 5.50889 6.10825 4.4981 8H8.1129C8.37107 6.59575 8.76264 5.23974 9.27368 3.94665ZM7.8905 9.5H3.8736C3.63072 10.2904 3.5 11.1299 3.5 12C3.5 12.6889 3.58195 13.3586 3.73663 14H7.83969C7.78034 13.3414 7.75 12.6743 7.75 12C7.75 11.1544 7.7977 10.3202 7.8905 9.5ZM8.02697 15.5H4.25174C5.21666 17.6328 7.03627 19.2962 9.27368 20.0534C8.70224 18.6074 8.28016 17.0828 8.02697 15.5ZM11.0717 20.4499C11.3766 20.483 11.6863 20.5 12 20.5C12.3137 20.5 12.6234 20.483 12.9283 20.4499C13.6349 18.8996 14.1533 17.2393 14.4525 15.5H9.54747C9.84675 17.2393 10.3651 18.8996 11.0717 20.4499ZM15.973 15.5C15.7198 17.0828 15.2978 18.6074 14.7263 20.0534C16.9637 19.2962 18.7833 17.6328 19.7483 15.5H15.973Z"
      fill="#3A3F4B"
    />
  </svg>
);
