import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NullableBoolean = boolean | null;

export interface IConsentPayload {
  showUserConsentModal: boolean;
  title: string;
  message: string;
  canClose: boolean;
  policyIsChecked: NullableBoolean;
  dataProcessingIsChecked: NullableBoolean;
}

export const initialState: IConsentPayload = {
  showUserConsentModal: false,
  title: '',
  message: '',
  canClose: false,
  policyIsChecked: null,
  dataProcessingIsChecked: null,
};

export const userConsentSlice = createSlice({
  name: 'userConsent',
  initialState,
  reducers: {
    showUserConsentModal: (state, action: PayloadAction<IConsentPayload>) => {
      state.showUserConsentModal = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.canClose = action.payload.canClose;
      state.policyIsChecked = null;
      state.dataProcessingIsChecked = null;
    },
    selectPolicy: (state, action: PayloadAction<NullableBoolean>) => {
      state.policyIsChecked = action.payload;
    },
    selectDataProcessing: (state, action: PayloadAction<NullableBoolean>) => {
      state.dataProcessingIsChecked = action.payload;
    },
    closeUserConsentModal: (state) => {
      state.policyIsChecked = null;
      state.dataProcessingIsChecked = null;
      state.showUserConsentModal = false;
    },
  },
});
