import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  Navbar,
  Nav,
  NavDropdown,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router-dom';

import { DropDownIcon, LogoIcon } from 'icons';
import { authSlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useLabRole, useMillRole } from 'hooks/role';
import labCustomLogo from 'assets/images/custom-logo.svg';
import blockedMillLogo from 'assets/images/block-mill.svg';

import {
  LAB_ACCOUNT_COMPANY_INFO_ROUTE,
  LAB_ACCOUNT_PROFILE_ROUTE,
  LAB_ACCOUNT_SETTINGS_ROUTE,
  LAB_HOME_ROUTE,
  MILL_ACCOUNT_PROFILE_ROUTE,
  MILL_ACCOUNT_SETTINGS_ROUTE,
  ROOT_ROUTE,
} from 'constants/routes';
import { placeholderImage } from 'utils/image';
import { forceUserToEnterMillInfo, millThunk } from 'thunks/mill';
import { forceUserToEnterLabInfo, labByCompanyIdThunk } from 'thunks/lab';

import { getLocalStorageItem } from 'utils/localStorage';
import { getProgressStats } from 'utils/common';
import { SELECTED_MILL } from 'constants/constants';

import { fetchAppVersion } from 'thunks/app-version';

import { ProgressCircle } from 'components/ProgressCircle';
import { Avatar } from './Avatar';
import { Language } from './Language';
import { Notifications } from './Notifications';

const renderTooltip = (props: any, text?: string) => (
  <Tooltip id="button-tooltip" {...props}>
    {text}
  </Tooltip>
);

interface IProgressStats {
  pct: number;
  variant: string;
}

export const UserHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user, mills } = useAppSelector((state) => state.auth.data);
  const { version } = useAppSelector((state) => state.appVersion);

  const { company: userCompany } = user;

  const { data: millData, apiStatus: millApiStatus } = useAppSelector(
    (state) => state.mill
  );
  const { data: labData, apiStatus: labApiStatus } = useAppSelector(
    (state) => state.lab
  );

  const isMillRole = useMillRole();
  const isLabRole = useLabRole();
  const location = useLocation();

  const dataUsedStats: IProgressStats = getProgressStats(
    millData.contractType?.name === 'custom'
      ? millData.customContract?.maxOrdersCount
      : millData.contractType?.maxOrdersCount,
    millData.millOrderStat?.ordersCreated
  );

  useEffect(() => {
    if (millData && isMillRole && millApiStatus === 'fulfilled') {
      const { company } = millData;
      const { companyInfoIsUpdated = false } = company;

      forceUserToEnterMillInfo(companyInfoIsUpdated, dispatch);
    }
  }, [location, millData, millApiStatus]);

  useEffect(() => {
    if (labData && isLabRole && labApiStatus === 'fulfilled') {
      const { company } = labData;
      const { companyInfoIsUpdated = false } = company;

      forceUserToEnterLabInfo(companyInfoIsUpdated, dispatch);
    }
  }, [location, labData, labApiStatus]);

  useEffect(() => {
    if (isLabRole && !millData.id) {
      const selectedMillId = getLocalStorageItem(SELECTED_MILL);
      dispatch(millThunk(selectedMillId));
    }
  }, [millData.id]);

  useEffect(() => {
    if (isLabRole && !labData.id && userCompany && userCompany.id) {
      dispatch(labByCompanyIdThunk(userCompany.id));
    }
  }, [userCompany]);

  useEffect(() => {
    if (!version) {
      dispatch(fetchAppVersion());
    }
  }, []);

  const millId = millData?.id;

  const handleMillProfile = () => {
    window.location.href = MILL_ACCOUNT_PROFILE_ROUTE;
  };

  const handleLabProfile = () => {
    window.location.href = LAB_ACCOUNT_PROFILE_ROUTE;
  };

  const handleMillSettings = () => {
    window.location.href = MILL_ACCOUNT_SETTINGS_ROUTE;
  };

  const handleLabCompanyInfo = () => {
    window.location.href = LAB_ACCOUNT_COMPANY_INFO_ROUTE;
  };

  const handleLabSettings = () => {
    window.location.href = LAB_ACCOUNT_SETTINGS_ROUTE;
  };

  const handleLogout = () => {
    let redirectTo = ROOT_ROUTE;
    if (millId && isLabRole) {
      redirectTo = generatePath(LAB_HOME_ROUTE, {
        millId,
      });
    }
    const payload = { redirectTo };
    dispatch(authSlice.actions.logout());
    dispatch(authSlice.actions.gotoHome(payload));
  };

  const showMillSelectionModal = () => {
    if (mills.length > 1) {
      dispatch(authSlice.actions.showMillSelectionModal());
    }
  };

  const versionClasses = `app-version ${!isLabRole ? 'app-version-mill' : ''}`;

  return (
    <Navbar expand="md" className="h-100 user-navbar">
      <div className="user-navbar-brand">
        {isLabRole ? (
          millApiStatus === 'fulfilled' && (
            <div
              onKeyDown={showMillSelectionModal}
              onClick={showMillSelectionModal}
              role="button"
              tabIndex={0}
            >
              <img
                src={placeholderImage(millData.company.logo, labCustomLogo)}
                alt=""
                className="mr-18"
              />
              {mills.length > 1 && <DropDownIcon />}
            </div>
          )
        ) : (
          <LogoIcon />
        )}
        <div className={versionClasses}>v{version}</div>
      </div>

      <Navbar.Toggle aria-controls="user-navbar-nav" className="ml-auto" />
      <Navbar.Collapse data-cy="user-navbar-nav" id="user-navbar-nav">
        <Nav className="ml-auto align-center">
          {millData.blocked && (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={(props: any) => {
                return renderTooltip(
                  props,
                  isLabRole
                    ? millData.blockedMillClientMessage
                    : millData.blockReason
                );
              }}
            >
              <div className="blocked-mill">
                <img src={blockedMillLogo} className="blink" alt="" />
              </div>
            </OverlayTrigger>
          )}
          <>
            <Notifications />
            {isMillRole && <ProgressCircle progress={dataUsedStats.pct} />}
          </>

          <NavDropdown
            title={<Avatar user={user} />}
            id="user-dropdown"
            data-cy="user-dropdown"
            className={classNames('user-dropdown my-auto', {
              'has-avatar': !!user.avatar,
            })}
          >
            <div className="arrow" />
            {isMillRole && (
              <>
                <NavDropdown.Item
                  data-cy="user-header-profile"
                  onClick={handleMillProfile}
                >
                  {t('header.profile')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  data-cy="user-header-settings"
                  onClick={handleMillSettings}
                >
                  {t('header.settings')}
                </NavDropdown.Item>
              </>
            )}
            {isLabRole && (
              <>
                <NavDropdown.Item
                  data-cy="user-header-company-info"
                  onClick={handleLabCompanyInfo}
                >
                  {t('header.companyInfo')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  data-cy="user-header-profile"
                  onClick={handleLabProfile}
                >
                  {t('header.profile')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  data-cy="user-header-settings"
                  onClick={handleLabSettings}
                >
                  {t('header.settings')}
                </NavDropdown.Item>
              </>
            )}
            <NavDropdown.Item
              data-cy="user-header-signout"
              onClick={handleLogout}
            >
              {t('signOut.title')}
            </NavDropdown.Item>
          </NavDropdown>
          <Language />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
