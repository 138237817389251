import React from 'react';
import {} from 'react-router-dom';
import { ToastContainer } from 'components/Toast/ToastContainer';
import { ManHeader } from './ManHeader';
import { ManSidebar } from './ManSidebar';

interface IManagerProps {
  children: React.ReactNode;
}

const ManagerLayout: React.FC<IManagerProps> = ({
  children,
}: IManagerProps) => {
  return (
    <>
      <header className="header">
        <ManHeader />
      </header>
      <main>
        <div className="d-flex">
          <div className="sidebar-width">
            <ManSidebar />
          </div>

          <div className="manager-main">{children}</div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default ManagerLayout;
