import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserLayout } from 'components/Layout';
import { ITabConfig } from 'components/TabsControl/TabsControl';
import { TabsControl } from 'components/TabsControl';
import { useQuery } from 'hooks/query';
import {
  MILL_CONTRACT_TYPE_PAGE_ROUTE,
  MILL_CONTRACT_BILLING_ROUTE,
  MILL_CONTRACT_INVOICES_ROUTE,
  MILL_CONTRACT_USAGE_DETAILS_ROUTE,
} from 'constants/routes';
import i18n from 'translations';
import Api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SUBSCRIPTION_PLANS } from 'constants/constants';
import { authSlice, toastSlice } from 'slices';
import { ContractTypeContainer } from './ContractType/ContractTypeContainer';
import { BillingTableContainer } from './Billing';
import { InvoicesTableContainer } from './Invoices';
import { UsageDetailContainer } from './Usage/UsageDetailContainer';
import { UpcomingInvoiceContainer } from './UpcomingInvoice';

const tabs: ITabConfig[] = [
  {
    eventKey: 'contract-types',
    title: i18n.t('contract.tabs.contractType.title'),
    children: (
      <Row>
        <Col lg={{ span: 12 }}>
          <ContractTypeContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'usage-details',
    title: i18n.t('contract.tabs.usageDetail.title'),
    children: (
      <Row className="page-account">
        <Col lg={{ span: 12 }}>
          <UsageDetailContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'billing',
    title: i18n.t('contract.tabs.billing.title'),
    children: (
      <Row className="page-account">
        <Col lg={{ span: 12 }}>
          <BillingTableContainer />
        </Col>
      </Row>
    ),
  },
  {
    eventKey: 'invoices',
    title: i18n.t('contract.tabs.invoices.title'),
    children: (
      <Row className="page-account">
        <Col lg={{ span: 12 }}>
          <InvoicesTableContainer />
        </Col>
      </Row>
    ),
  },
];

export const ContractContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const history = useHistory();
  const mill = useAppSelector((state) => state.mill.data);
  const btnEnable =
    mill?.contractType && mill?.contractType?.name !== SUBSCRIPTION_PLANS.BASIC;
  const handleTabChange = (eventKey: any) => {
    if (eventKey === 'contract-types') {
      history.push(MILL_CONTRACT_TYPE_PAGE_ROUTE);
    } else if (eventKey === 'usage-details') {
      history.push(MILL_CONTRACT_USAGE_DETAILS_ROUTE);
    } else if (eventKey === 'billing') {
      history.push(MILL_CONTRACT_BILLING_ROUTE);
    } else if (eventKey === 'invoices') {
      history.push(MILL_CONTRACT_INVOICES_ROUTE);
    }
  };

  const updatePaymentDetails = async () => {
    try {
      const response = await Api.get('payments/update/card/details');
      const { statusCode, url } = response;

      if (statusCode === 200) {
        window.location.href = url;
      }
    } catch (error) {
      console.log(error);
      dispatch(
        toastSlice.actions.show({
          title: t('contract.error'),
          message: error.message,
        })
      );
    }
  };

  const upcomingInvoiceDetails = async () => {
    dispatch(authSlice.actions.showUpcomingInvoiceModal());
  };

  const UpdatePaymentDetailsButton: React.FC = () => {
    return (
      <>
        <div className="row mt-10">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="text-right">
              <Button
                disabled={!btnEnable}
                onClick={updatePaymentDetails}
                className="btn-header"
              >
                {t('contract.tabs.billing.manage')}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const UpcomingInvoiceButton: React.FC = () => {
    return (
      <>
        <div className="row mt-10">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="text-right">
              <Button
                disabled={!btnEnable}
                onClick={upcomingInvoiceDetails}
                className="btn-header"
              >
                {t('contract.tabs.invoices.upcoming')}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <UserLayout mainClasses="page page-tabs flex-column">
      <div className="page-header py-60 py-md-70 px-20 pl-md-126 pr-md-54">
        <Container fluid>
          <Row>
            <Col className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
              <h1 className="fs-44 mb-88">{t('contract.title')}</h1>
            </Col>
            <Col>
              {btnEnable && <UpdatePaymentDetailsButton />}
              {btnEnable && <UpcomingInvoiceButton />}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="px-20 pl-md-126 pr-md-54">
        <TabsControl
          activeKey={query.get('tab') || 'contract-types'}
          id="mill-contract"
          tabs={tabs}
          onTabChange={handleTabChange}
        />
      </div>
      <UpcomingInvoiceContainer />
    </UserLayout>
  );
};
