import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  accountCreationThunk,
  getEmailByConfirmationTokenThunk,
  IAccountCreationForm,
  ISigninPayload,
  resendEmailConfirmationThunk,
} from 'thunks/auth';
import { useQuery } from 'hooks/query';

import { authSlice } from 'slices';
import { CreateNewAccount } from './CreateNewAccount';
import { signInSchema } from '../Signin/SigninContainer';

export const CreateNewAccountContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { error, emailByConfirmationToken } = useAppSelector(
    (state) => state.auth
  );
  const query = useQuery();
  const location = useLocation();
  const { millId } = useParams() as any;
  const isLabUser = location.pathname.includes('/lab/home');
  const confirmation = query.get('confirmation') || '';

  React.useEffect(() => {
    dispatch(getEmailByConfirmationTokenThunk(confirmation));
    return () => {
      dispatch(authSlice.actions.clearEmailByConfirmationToken());
    };
  }, []);

  const handleSubmit = (values: IAccountCreationForm) => {
    if (confirmation) {
      dispatch(
        accountCreationThunk({
          ...values,
          confirmationToken: confirmation,
          millId: isLabUser ? millId : null,
        })
      );
    }
  };

  const sendConfirmationEmail = (values: IAccountCreationForm) => {
    if (confirmation) {
      dispatch(
        resendEmailConfirmationThunk({
          ...values,
          confirmationToken: confirmation,
        })
      );
    }
  };

  return (
    <CreateNewAccount
      onSubmit={handleSubmit}
      validationSchema={signInSchema}
      initialValues={
        {
          identifier: emailByConfirmationToken || '',
          password: '',
          companyName: '',
        } as ISigninPayload
      }
      isLabUser={isLabUser}
      actualEmailByConfirmationToken={emailByConfirmationToken}
      sendConfirmationEmail={sendConfirmationEmail}
      error={error}
    />
  );
};
