import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../api';
import { IMill } from '../interfaces/mill';
import {
  componentSlice,
  confirmationSlice,
  resetSlice,
  toastSlice,
} from '../slices';
import { RootState } from '../store/store';
import i18n from '../translations';
import { getSelectedMillId } from '../utils/common';

export interface IGetComponentPayload {
  id: string;
  projectId: string;
}

export interface IComponentPayload extends IGetComponentPayload {
  status: string;
}

export interface IUploadDownloadForm {
  name: string;
  files: any[];
}

export interface IUploadDownloadPayload extends IUploadDownloadForm {
  component: string;
}

export interface IMessageForm {
  message: string;
}

export interface IMessagePayload extends IMessageForm {
  component: string;
}

export interface IDownloadComponentDocsPayload {
  projectId: string | number;
  componentId: string | number;
}

export const componentThunk = createAsyncThunk(
  'component',
  async (payload: IGetComponentPayload) => {
    const { id, projectId } = payload;
    return Api.get(`/projects/${projectId}/components/${id}`);
  }
);

export const updateComponentThunk = createAsyncThunk(
  'component/update',
  async (payload: IComponentPayload, { dispatch, rejectWithValue }) => {
    try {
      const { id, projectId } = payload;
      const response = Api.put(
        `/projects/${projectId}/components/${id}`,
        payload
      );

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.updateToast.title'),
          message: i18n.t('component.updateToast.message'),
        })
      );

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDownloadThunk = createAsyncThunk(
  'downloads/delete',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.delete(`/downloads/${id}`);

      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.download.deleteToast.title'),
          message: i18n.t('component.download.deleteToast.message'),
        })
      );

      return response;
    } catch (err) {
      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.download.deleteToast.title'),
          message: err.response.data.message.errors,
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadDownloadThunk = createAsyncThunk(
  'downloads/upload',
  async (payload: IUploadDownloadPayload, { dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify({ ...payload, files: undefined }));
      if (payload.files.length) {
        formData.append('files.file', payload.files[0]);
      }
      const response = await Api.post(`/downloads`, formData);

      dispatch(componentSlice.actions.closeUploadDownload());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.download.uploadToast.title'),
          message: i18n.t('component.download.uploadToast.message'),
        })
      );

      return response;
    } catch (err) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.download.uploadToast.title'),
          message: err.response.data.message.errors,
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadAllDocumentsThunk = createAsyncThunk(
  'component/documents/all',
  async (
    payload: IDownloadComponentDocsPayload,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { projectId, componentId } = payload;
      const response = await Api.get(
        `/projects/${projectId}/components/${componentId}/download/documents`
      );
      window.location.href = response;

      return response;
    } catch (err) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.downloadAll'),
          message:
            err.response.data.message.errors || err.response.data.message,
        })
      );
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadAllProjectDocumentsThunk = createAsyncThunk(
  'project/documents/all',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await Api.get(`/project/${id}/download/documents`);
      dispatch(resetSlice.actions.endLoading());

      if (response.message) {
        dispatch(
          toastSlice.actions.show({
            title: i18n.t('component.downloadAll'),
            message: response.message,
          })
        );
        return response;
      }

      window.location.href = response;
      return response;
    } catch (err) {
      console.log({ err });
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.downloadAll'),
          message:
            err.response.data.message.errors || err.response.data.message,
        })
      );
      dispatch(resetSlice.actions.endLoading());
      return rejectWithValue(err.response.data);
    }
  }
);

export const createMessageThunk = createAsyncThunk(
  'message/create',
  async (payload: IMessagePayload, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.post(`/mills/${millId}/messages`, payload);

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.messageCreateToast.title'),
          message: i18n.t('component.messageCreateToast.message'),
        })
      );

      return response;
    } catch (err) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('component.messageCreateToast.title'),
          message: err.response.data.message.errors,
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);
