import { createSlice } from '@reduxjs/toolkit';
import { IApiAction, IApiState } from 'interfaces/api';
import {
  getContractTypesThunk,
  IContractType,
  IPromotionsType,
  IPromotion,
} from 'thunks/contract-type';

export interface IContractTypeState extends IApiState {
  data?: [IContractType];
  activePromotion?: IPromotion;
}

export const initialState: IContractTypeState = {
  data: undefined,
  activePromotion: undefined,
};

export const contractTypeSlice = createSlice({
  name: 'contract-type-slice',
  initialState,
  reducers: {},
  extraReducers: {
    [getContractTypesThunk.pending.type]: (state) => {
      state.data = undefined;
    },
    [getContractTypesThunk.fulfilled.type]: (
      state,
      action: IApiAction<IPromotionsType>
    ) => {
      state.data = action.payload.data.sort((a, b) => a.id - b.id);
      state.activePromotion = action.payload.promotions;
      state.apiStatus = action.meta.requestStatus;
    },
    [getContractTypesThunk.pending.type]: (
      state,
      action: IApiAction<IPromotionsType>
    ) => {
      state.apiStatus = action.meta.requestStatus;
    },
    [getContractTypesThunk.rejected.type]: (
      state,
      action: IApiAction<IPromotionsType>
    ) => {
      state.apiStatus = action.meta.requestStatus;
    },
  },
});
