import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Autolinker from 'autolinker';
import { ILandingPage } from '../../../thunks/settings';
import labCustomLogo from '../../../assets/images/custom-logo.svg';
import { isTokenPresent } from '../../../slices/auth';
import { placeholderImage } from '../../../utils/image';
import { simpleFormat } from '../../../utils/format';

export interface ILandingPageProps extends ILandingPage {
  previewUrl?: string;
  onSignin?: () => void;
}
export const LandingPage = ({
  logo,
  landingPageHeader,
  landingPageContent,
  previewUrl,
  onSignin,
}: ILandingPageProps) => {
  const { t } = useTranslation();
  const AutoLinkerRef = React.useRef(
    new Autolinker({
      newWindow: false,
    })
  );

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 6, offset: 2 }}>
          <img
            src={previewUrl || placeholderImage(logo, labCustomLogo)}
            alt={t('settings.landingPage.logoAlt')}
            className="mb-84"
          />
          <h1 className="fs-34 mb-10">{landingPageHeader}</h1>
          <div
            className="fs-24 lh-36 mb-74"
            dangerouslySetInnerHTML={{
              __html: simpleFormat(
                AutoLinkerRef.current.link(landingPageContent)
              ),
            }}
          />
          {!isTokenPresent() && (
            <Button className="mb-60 mb-md-120 px-32" onClick={onSignin}>
              {t('settings.landingPage.preview.signIn')}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};
