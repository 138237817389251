import { AsyncThunk } from '@reduxjs/toolkit';
import { IApiAction } from '../interfaces/api';

interface IAdditionalArgs {
  pending?: any;
  rejected?: any;
  fulfilled?: any;
}

type IAdditionalParams = IAdditionalArgs | undefined;

export const defaultAsyncExtraReducers = (
  thunk: AsyncThunk<string, any, any>,
  additionalParams: IAdditionalParams = undefined
) => {
  const { pending, rejected, fulfilled } = additionalParams || {};
  return {
    [thunk.pending.type]: (state: any, action: IApiAction<any>) => {
      return {
        ...state,
        apiStatus: action.meta.requestStatus,
        data: action.payload,
        error: undefined,
        ...pending,
      };
    },
    [thunk.rejected.type]: (state: any, action: IApiAction<any>) => {
      return {
        ...state,
        apiStatus: action.meta.requestStatus,
        error: action.payload.data,
        ...rejected,
      };
    },
    [thunk.fulfilled.type]: (state: any, action: IApiAction<any>) => {
      return {
        ...state,
        apiStatus: action.meta.requestStatus,
        data: action.payload,
        error: undefined,
        ...fulfilled,
      };
    },
  };
};
