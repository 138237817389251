import { createAsyncThunk } from '@reduxjs/toolkit';
import { MILL_CONTRACT_TYPE_PAGE_ROUTE } from 'constants/routes';
import { toastSlice, confirmationSlice } from 'slices';
import i18n from 'translations';
import { NullableBoolean } from 'slices/user-consent';
import { IConfirmation } from 'slices/confirmation';
import Api from '../api';

export interface IAddOn {
  id: number;
  type: string;
  key: string;
  value: number;
  unit: string;
  price: number;
}

export interface IMillSubscriptionPlan {
  sessionId: string;
}

export interface IMillAddOns {
  filesKeepDurationKey: string;
}

export interface IMillConsentAddOns {
  policyAgreement: NullableBoolean;
  dataProcessingAgreement: NullableBoolean;
  filesKeepDurationKey: string;
  confirmationMessage: string;
}

export interface IPolicyCheck {
  policyAgreement: NullableBoolean;
  dataProcessingAgreement: NullableBoolean;
}

export const subscribePlanThunk = createAsyncThunk(
  'payments-success',
  async (params: IMillSubscriptionPlan, { rejectWithValue, dispatch }) => {
    const { sessionId } = params;
    try {
      const response = await Api.post(`/payments/success`, {
        sessionId,
      });

      window.location.href = MILL_CONTRACT_TYPE_PAGE_ROUTE;

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('contract.tabs.contractType.subscribe'),
          message: i18n.t('contract.tabs.contractType.subscriptionSuccess'),
        })
      );

      return response;
    } catch (error) {
      toastSlice.actions.show({
        title: i18n.t('contract.tabs.contractType.subscribe'),
        message: i18n.t('contract.tabs.contractType.subscriptionFailure'),
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMillAddOnThunk = createAsyncThunk(
  'mill-add-on',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get(`/mill-addons`);
      return response;
    } catch (error) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('contract.tabs.contractType.subscribe'),
          message: i18n.t('contract.tabs.contractType.subscriptionFailure'),
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAddOnsListThunk = createAsyncThunk(
  'add-on-list',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.get(`/add-ons`);
      dispatch(getMillAddOnThunk());
      return response;
    } catch (error) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('contract.tabs.contractType.subscribe'),
          message: i18n.t('contract.tabs.contractType.subscriptionFailure'),
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const addOnsSubscriptionThunk = createAsyncThunk(
  'add-on-selection',
  async (params: IMillConsentAddOns, { rejectWithValue, dispatch }) => {
    const {
      policyAgreement,
      dataProcessingAgreement,
      filesKeepDurationKey,
      confirmationMessage,
    } = params;
    try {
      const response = await Api.post(`/add-ons`, {
        policyAgreement,
        dataProcessingAgreement,
        filesKeepDurationKey,
      });

      dispatch(
        confirmationSlice.actions.show({
          message: confirmationMessage,
          title: i18n.t('contract.tabs.contractType.subscribe'),
          buttonOk: i18n.t('auth.registerConfirm.buttonOk'),
        } as IConfirmation)
      );

      return response;
    } catch (error) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('contract.tabs.contractType.subscribe'),
          message: i18n.t('contract.tabs.contractType.subscriptionFailure'),
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const unsubscribePlanThunk = createAsyncThunk(
  'payments-delete',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.delete(`/payments`);
      window.location.href = MILL_CONTRACT_TYPE_PAGE_ROUTE;

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('contract.tabs.contractType.unsubscribe'),
          message: i18n.t('contract.tabs.contractType.unsubscriptionSuccess'),
        })
      );

      return response;
    } catch (error) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('contract.tabs.contractType.unsubscribe'),
          message: i18n.t('contract.tabs.contractType.unsubscriptionFailure'),
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const resubscribePlanThunk = createAsyncThunk(
  'payments-resume',
  async (params: IPolicyCheck, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.post(`/payments/resume`, {
        policyAgreement: params.policyAgreement,
        dataProcessingAgreement: params.dataProcessingAgreement,
      });
      window.location.href = MILL_CONTRACT_TYPE_PAGE_ROUTE;

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('contract.tabs.contractType.resubscribe'),
          message: i18n.t('contract.tabs.contractType.resubscriptionSuccess'),
        })
      );

      return response;
    } catch (error) {
      dispatch(
        toastSlice.actions.show({
          title: i18n.t('contract.tabs.contractType.resubscribe'),
          message: i18n.t('contract.tabs.contractType.resubscriptionFailure'),
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);
