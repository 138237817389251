import React from 'react';

export const PasswordIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 13C11.4477 13 11 13.4477 11 14V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V14C13 13.4477 12.5523 13 12 13Z"
      fill="#C7CED8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 7.77778V9.5H7.5C6.39543 9.5 5.5 10.3954 5.5 11.5V17.5C5.5 18.6046 6.39543 19.5 7.5 19.5H16.5C17.6046 19.5 18.5 18.6046 18.5 17.5V11.5C18.5 10.3954 17.6046 9.5 16.5 9.5H15.75V7.77778C15.75 5.77613 14.0157 4.25 12 4.25C9.98433 4.25 8.25 5.77613 8.25 7.77778ZM12 5.75C10.702 5.75 9.75 6.71117 9.75 7.77778V9.5H14.25V7.77778C14.25 6.71117 13.298 5.75 12 5.75ZM7.5 11H16.5C16.7761 11 17 11.2239 17 11.5V17.5C17 17.7761 16.7761 18 16.5 18H7.5C7.22386 18 7 17.7761 7 17.5V11.5C7 11.2239 7.22386 11 7.5 11Z"
      fill="#C7CED8"
    />
  </svg>
);
