import React from 'react';
import {
  ForgotContainer,
  ResetContainer,
  SigninContainer,
  SignupContainer,
} from '../Auth';
import { CreateNewAccountModalContainer } from '../Auth/CreateNewAccountModal/CreateNewAccountModalContainer';
import { ExistingAccountUserInvitationContainer } from '../Auth/ExistingAccountUserInvitation/ExistingAccountUserInvitationContainer';
import { InvitationContainer } from '../Auth/Invitation/InvitationContainer';
import { LoggedInUserInvitationContainer } from '../Auth/LoggedInUserInvitation/LoggedInUserInvitationContainer';
import { MillSelectionContainer } from '../Modal/MillSelection/MillSelectionContainer';
import { ToastContainer } from '../Toast/ToastContainer';
import { Header, PublicLabHeader } from './Header';
import { Main } from './Main';

interface IHomeLabLayout {
  children: React.ReactNode;
  mainClasses?: string;
}

export const PublicLabLayout: React.FC<IHomeLabLayout> = ({
  children,
  mainClasses,
}: IHomeLabLayout) => {
  return (
    <>
      <Header>
        <PublicLabHeader />
      </Header>
      <Main additionalClasses={mainClasses}>{children}</Main>
      <ToastContainer />
      <SigninContainer />
      <SignupContainer />
      <ForgotContainer />
      <ResetContainer />
      <ExistingAccountUserInvitationContainer />
      <InvitationContainer />
      <CreateNewAccountModalContainer />
      <MillSelectionContainer />
      <LoggedInUserInvitationContainer />
    </>
  );
};

PublicLabLayout.defaultProps = {
  mainClasses: '',
};
