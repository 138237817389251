import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getContractTypesThunk,
  CONFIRMATION_ACTIONS,
} from 'thunks/contract-type';
import { loadStripe } from '@stripe/stripe-js';
import Api from 'api';
import { unsubscribePlanThunk, resubscribePlanThunk } from 'thunks/payments';
import { authSlice, confirmationSlice, toastSlice } from 'slices';
import { IConfirmation } from 'slices/confirmation';
import { useTranslation } from 'react-i18next';
import { MILL_CONTRACT_TYPE_PAGE_ROUTE } from 'constants/routes';
import { redirectRoute } from 'thunks/settings';
import { AddonSelectionContainer } from 'components/Modal/AddonSelection/AddonSelectionContainer';
import { Spinner2 } from 'components';
import { UserConsentContainer } from 'components/Modal/AddonSelection/UserConsentContainer';
import { ContractType } from './ContractType';

const stripPromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

export const ContractTypeContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data: contractTypes, apiStatus } = useAppSelector(
    (state) => state.contractTypes
  );
  const activePromotions = useAppSelector(
    (state) => state.contractTypes.activePromotion
  );
  const { showSubscriptionConsentModel } = useAppSelector(
    (state) => state.auth
  );
  const mill = useAppSelector((state) => state.mill.data);
  const [strLookupKey, setLookupKey] = React.useState<string>('');
  const [subscriptionUpdate, setSubscriptionUpdate] = React.useState<boolean>(
    false
  );
  const [resumeSubscription, setResumeSubscription] = React.useState<boolean>(
    false
  );
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [strConsentTitle, setConsentTitle] = React.useState<string>('');
  const [strConsentMessage, setConsentMessage] = React.useState<string>('');

  React.useEffect(() => {
    dispatch(getContractTypesThunk());
  }, []);

  const { filesKeepDurationKey, addOns } = useAppSelector(
    (state) => state.payments
  );

  const closeConsentDialog = () => {
    dispatch(authSlice.actions.closeSubscriptionConsentModel());
  };

  const updateSubscription = async (
    lookupKey: string,
    isSubscriptionUpdate: boolean,
    policyAgreement: boolean,
    dataProcessingAgreement: boolean
  ) => {
    try {
      closeConsentDialog();
      setIsProcessing(true);
      const stripe = await stripPromise;
      const response = await Api.post('checkout', {
        lookupKey,
        policyAgreement,
        dataProcessingAgreement,
      });
      const { statusCode, sessionId } = response;

      if (statusCode !== 200) {
        dispatch(
          toastSlice.actions.show({
            title: t('contract.tabs.contractType.subscribe'),
            message: t('contract.tabs.contractType.subscriptionFailure'),
          })
        );
        return;
      }

      if (isSubscriptionUpdate) {
        dispatch(
          toastSlice.actions.show({
            title: t('contract.tabs.contractType.subscribe'),
            message: t('contract.tabs.contractType.subscriptionSuccess'),
          })
        );
        redirectRoute(MILL_CONTRACT_TYPE_PAGE_ROUTE, 1000);
        return;
      }
      setIsProcessing(false);
      stripe?.redirectToCheckout({ sessionId });
    } catch (error) {
      setIsProcessing(false);
      dispatch(
        toastSlice.actions.show({
          title: t('contract.tabs.contractType.subscribe'),
          message: t('contract.tabs.contractType.subscriptionFailure'),
        })
      );
    }
  };

  const userConsentSubscription = async (
    policyAgreement: boolean,
    dataProcessingAgreement: boolean
  ) => {
    closeConsentDialog();
    if (!resumeSubscription) {
      setIsProcessing(true);
      await updateSubscription(
        strLookupKey,
        subscriptionUpdate,
        policyAgreement,
        dataProcessingAgreement
      );
      setIsProcessing(false);
    } else {
      setIsProcessing(true);
      await dispatch(
        resubscribePlanThunk({
          policyAgreement,
          dataProcessingAgreement,
        })
      );
      setIsProcessing(false);
    }
  };

  const handleCancelConfirmation = () => {
    dispatch(
      confirmationSlice.actions.show({
        message: t('contract.tabs.contractType.cancelConfirmation'),
        title: t('contract.tabs.contractType.unsubscribe'),
        buttonOk: t('contract.tabs.contractType.confirm'),
        buttonCancel: t('contract.tabs.contractType.cancel'),
        actionKey: CONFIRMATION_ACTIONS.UNSUBSCRIBE,
      } as IConfirmation)
    );
  };

  const openConsentDialog = async (title: string, message: string) => {
    setConsentTitle(title);
    setConsentMessage(message);
    dispatch(authSlice.actions.showSubscriptionConsentModel());
  };

  const handleResubscription = () => {
    openConsentDialog(
      t('contract.tabs.contractType.resubscribe'),
      t('contract.tabs.contractType.resubscriptionConfirmation')
    );
  };

  const handleSubscription = () => {
    openConsentDialog(
      t('contract.tabs.contractType.subscribe'),
      t('contract.tabs.contractType.subscriptionConsent')
    );
  };

  const handleUpgradeReSubscription = () => {
    openConsentDialog(
      t('contract.tabs.contractType.subscribe'),
      t('contract.tabs.contractType.subscriptionConfirmation')
    );
  };

  const handleUpgradeSubscription = () => {
    openConsentDialog(
      t('contract.tabs.contractType.confirm'),
      t('contract.tabs.contractType.upgradesubscriptionConfirmation')
    );
  };

  const subscribe = async (
    lookupKey: string,
    isSubscriptionUpdate: boolean, // just upgrade
    isResumingSubscription: boolean // was canceled, now resubscribed and upgrade
  ) => {
    try {
      setIsProcessing(true);
      setLookupKey(lookupKey);
      setSubscriptionUpdate(isSubscriptionUpdate);
      setResumeSubscription(isResumingSubscription);
      if (!!isSubscriptionUpdate && !!isResumingSubscription)
        // was canceled -> now will subscribe & upgrade
        handleUpgradeReSubscription();
      else if (!!isSubscriptionUpdate && !isResumingSubscription) {
        // was subscribed -> now user wants to just upgrade
        handleUpgradeSubscription();
      } else if (!isSubscriptionUpdate && !!isResumingSubscription)
        // was unsubscribed, but in cancellation period, user wants to resubscribe.
        handleResubscription();
      else handleSubscription(); // New subscription

      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      dispatch(
        toastSlice.actions.show({
          title: t('contract.tabs.contractType.subscribe'),
          message: t('contract.tabs.contractType.subscriptionFailure'),
        })
      );
    }
  };

  const changeSubscription = async (actionKey?: string) => {
    if (actionKey === CONFIRMATION_ACTIONS.UNSUBSCRIBE) {
      setIsProcessing(true);
      dispatch(confirmationSlice.actions.close());
      await dispatch(unsubscribePlanThunk());
      setIsProcessing(false);
    } else if (actionKey === CONFIRMATION_ACTIONS.RESUBSCRIBE) {
      await dispatch(
        resubscribePlanThunk({
          policyAgreement: false,
          dataProcessingAgreement: false,
        })
      );
    }
    dispatch(confirmationSlice.actions.close());
  };

  const showAddonModal = () => {
    dispatch(authSlice.actions.showAddonSelectionModal());
  };

  return (
    <>
      <Spinner2 show={apiStatus === 'pending' || isProcessing} />
      <ContractType
        types={contractTypes}
        subscribe={subscribe}
        unsubscribe={changeSubscription}
        handleCancelConfirmation={handleCancelConfirmation}
        subscribedContractType={mill?.contractType}
        customContract={mill.customContract}
        showAddonModal={showAddonModal}
        filesKeepDurationKey={filesKeepDurationKey}
        addOns={addOns}
        subscriptionCancelDate={mill.subscriptionCancelDate}
        activePromotion={activePromotions}
      />
      <AddonSelectionContainer />
      <UserConsentContainer
        show={showSubscriptionConsentModel}
        title={strConsentTitle}
        message={strConsentMessage}
        canClose
        userConsent={userConsentSubscription}
        onClose={closeConsentDialog}
      />
    </>
  );
};
