import React from 'react';
import { ExpandColumnRendererProps } from 'react-bootstrap-table-next';
import { generatePath } from 'react-router-dom';
import { Table } from '../../../../components';
import {
  LAB_COMPONENT_ROUTE,
  MILL_COMPONENT_ROUTE,
  ORDERS_PARTIAL_ROUTE,
} from '../../../../constants/routes';
import { DropDownIcon, DropDownReverseIcon } from '../../../../icons';
import { IComponent } from '../../../../interfaces/component';
import { IOrder } from '../../../../interfaces/order';
import { componentsColumns } from './componentsColumn';

export interface IExportRow {
  nonExpandable: any[];
  history: any;
  isMillRole: boolean;
}

export const expandRow = ({
  nonExpandable,
  history,
  isMillRole,
}: IExportRow): any => {
  const handleRowEvents = {
    onClick: (e: any, row: IComponent) => {
      const params = {
        projectId: row.projectId || row.project?.id,
        id: row.id,
      };

      const link = isMillRole
        ? generatePath(MILL_COMPONENT_ROUTE, params)
        : generatePath(LAB_COMPONENT_ROUTE, params);

      history.push(link, {
        from: ORDERS_PARTIAL_ROUTE,
      });
    },
  };

  return {
    parentClassName: 'table-expanded-row',
    renderer: ({ components }: IOrder) => (
      <Table
        remote={false}
        data={components}
        columns={componentsColumns}
        sizePerPage={components.length ? components.length : 1}
        totalSize={components.length}
        wrapperClasses="table-components"
        rowEvents={handleRowEvents}
      />
    ),
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandColumnRenderer: ({
      expanded,
      expandable,
    }: ExpandColumnRendererProps) => {
      if (!expandable) {
        return <div />;
      }
      if (expanded) {
        return <DropDownReverseIcon />;
      }
      return <DropDownIcon />;
    },
    nonExpandable,
  };
};
