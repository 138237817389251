import React from 'react';

interface ISpinner2 {
  show: boolean;
}
export const Spinner2: React.FC<ISpinner2> = ({ show }: ISpinner2) => {
  return (
    <>
      {show && (
        <div className="loader-spinner2">
          <div className="lds-hourglass" />
        </div>
      )}
      {!show && <div />}
    </>
  );
};
