import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components';
import { useQuery } from '../../hooks/query';

import { PublicLayout } from '../../components/Layout/PublicLayout';
import { useAppDispatch } from '../../store/hooks';
import { confirmationThunk } from '../../thunks/auth';
import { resetSlice, signupSlice } from '../../slices';
import { authSlice, isTokenPresent } from '../../slices/auth';
import { getLocalStorageItem } from '../../utils/localStorage';
import { API_TOKEN } from '../../constants/constants';
import { useLabRole } from '../../hooks/role';

export const Home: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLabRole = useLabRole();
  const jwt = getLocalStorageItem(API_TOKEN);
  const query = useQuery();

  useEffect(() => {
    const activation = query.get('activation');
    const confirmation = query.get('confirmation');

    if (jwt && activation && isLabRole) {
      dispatch(authSlice.actions.showLoggedInUserInvitationModal());
    } else if (activation && isLabRole) {
      dispatch(authSlice.actions.showAccountCreation());
    } else if (activation) {
      dispatch(authSlice.actions.showCreateNewAccountModal());
    } else if (confirmation) {
      dispatch(confirmationThunk({ confirmation }));
    }

    const code = query.get('code');
    if (code) {
      dispatch(resetSlice.actions.show());
    }
  }, []);

  const handleSignup = () => {
    dispatch(signupSlice.actions.show());
  };

  return (
    <PublicLayout mainClasses="page-home">
      <div className="py-60 py-md-100 home-header">
        <Container className="public-page-col">
          <Row>
            <Col md={12}>
              <div className="text-primary label mb-8">
                {t('home.subtitle')}
              </div>
              <h1 className="text-white fs-54 mb-10">{t('home.title')}</h1>
              <p className="text-white fs-20 mb-62">{t('home.content')}</p>
              {!isTokenPresent() && (
                <Button className="home-button" onClick={handleSignup}>
                  {t('signUp.title')}
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="home-main">
        <Container className="public-page-col">
          <h2 className="pt-60 pt-md-100 pb-60 pb-md-120 fs-34">
            {t('home.reasons.title')}
          </h2>
          <div className="pb-60 pb-md-100">
            <Row>
              {[...Array(3)].map((_, i) => (
                <Col key={i} md={4}>
                  <h3 className="fs-24 mb-10">
                    {t(`home.reasons.reason${i + 1}.title`)}
                  </h3>
                  <p>{t(`home.reasons.reason${i + 1}.content`)}</p>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </PublicLayout>
  );
};
