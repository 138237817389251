import React from 'react';
import * as yup from 'yup';

import { forgotSlice, signinSlice } from '../../../slices';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { forgotThunk, IForgotPayload } from '../../../thunks/auth';
import { Forgot } from './Forgot';

const schema: yup.SchemaOf<IForgotPayload> = yup.object().shape({
  email: yup.string().required().email(),
});

export const ForgotContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state) => state.auth);
  const { show } = useAppSelector((state) => state.forgot);

  const handleSubmit = (values: IForgotPayload) => {
    dispatch(forgotThunk(values));
  };

  const handleSignin = () => {
    dispatch(forgotSlice.actions.close());
    dispatch(signinSlice.actions.show());
  };

  return (
    <Forgot
      show={show}
      onClose={forgotSlice.actions.close}
      onSubmit={handleSubmit}
      onSignin={handleSignin}
      validationSchema={schema}
      initialValues={
        {
          email: '',
        } as IForgotPayload
      }
      error={error}
    />
  );
};
