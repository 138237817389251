import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import FallBackComponent from './components/ErrorBoundary/FallBackComponent';

import store from './store/store';

import './translations';
import 'antd/dist/antd.css';
import './styles/index.scss';

import App from './App';

const tagManagerArgs = {
  gtmId: 'GTM-TGWWZPZ',
  // auth: 'ITROy8jpiZjQuOML4pHo1g',
  // preview: 'env-2',
};

TagManager.initialize(tagManagerArgs);

// add some debugging information on uncaught exceptions
window.onerror = (errMsg, source, lineNum, colNum, errObj) => {
  const winExternal = window.external as any;

  // if window app has exposed LogError message for unhandled exceptions
  if (winExternal.LogError && typeof winExternal.LogError === 'function') {
    winExternal.LogError(errMsg, source, lineNum, colNum);
  }

  const errObjStackTrace = JSON.stringify(errObj);
  console.log(errObjStackTrace);

  // show err stack trace in an alert, helpful in windows app
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    alert(errObjStackTrace);
  }
};

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.log('Error boundary caught following error');
  console.log({ error, info });
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={FallBackComponent}
        onError={myErrorHandler}
      >
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
