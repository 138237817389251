import { useQuery } from 'hooks/query';
import React from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { SelectCallback } from 'react-bootstrap/esm/helpers';

export interface ITabConfig {
  eventKey: string;
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
}

export interface ITabsControl {
  activeKey: string;
  id: string;
  tabs: ITabConfig[];
  onTabChange?: SelectCallback;
}

export const TabsControl: React.FC<ITabsControl> = (props: ITabsControl) => {
  const query = useQuery();
  const { activeKey, id, tabs, onTabChange } = props;

  // to stop navigation if form is dirty and user decides to stay on the current tab.
  const [selectedTab, setSelectedTab] = React.useState(activeKey);

  React.useEffect(() => {
    const tab = query.get('tab') || activeKey;
    setSelectedTab(tab);
  });

  return (
    <Container fluid>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={selectedTab}
            id={id}
            onSelect={onTabChange}
          >
            {tabs.map((tab: ITabConfig) => {
              const { eventKey, title, children, disabled = false } = tab;
              return (
                <Tab
                  key={eventKey}
                  eventKey={eventKey}
                  title={title}
                  disabled={disabled}
                >
                  {selectedTab === eventKey && children}
                </Tab>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};
