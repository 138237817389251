import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiAction, IApiMeta, IApiState } from '../interfaces/api';
import { IMill, IMillOrderStats } from '../interfaces/mill';
import { millThunk } from '../thunks/mill';
import {
  ICompanyDetail,
  updateCompanyDetailsThunk,
  updateCompanyLandingPageDetailsThunk,
} from '../thunks/settings';

export interface IMillState extends IApiState {
  data: IMill;
}

export const initialState: IMillState = {
  data: {
    id: undefined,
    landingPageHeader: '',
    landingPageContent: '',
    millOrderStat: {} as IMillOrderStats,
    company: {} as ICompanyDetail,
    subscriptionCancelDate: undefined,
  },
};

export const millSlice = createSlice({
  name: 'mill',
  initialState,
  reducers: {
    setSelectedMill(state, action) {
      state.data.id = action.payload.id;
    },
    updateProjectCreatedCount(state) {
      if (state.data.millOrderStat) {
        const ordersCount = state.data.millOrderStat.ordersCreated || 0;
        state.data.millOrderStat.ordersCreated = +ordersCount + 1;
      }
    },
  },
  extraReducers: {
    [millThunk.fulfilled.type]: (
      state,
      action: PayloadAction<IMill, string, IApiMeta>
    ) => {
      state.data = action.payload;
      state.apiStatus = action.meta.requestStatus;
    },
    [updateCompanyLandingPageDetailsThunk.fulfilled.type]: (
      state,
      action: IApiAction<IMill>
    ) => {
      return {
        ...state,
        data: action.payload,
        error: undefined,
      };
    },
    [updateCompanyDetailsThunk.fulfilled.type]: (
      state,
      action: IApiAction<ICompanyDetail>
    ) => {
      state.data.company = action.payload;
    },
  },
});
