import React from 'react';
import { Link } from 'react-router-dom';

import { TableActions } from 'components/Table/TableActions';
import { IUser } from 'interfaces/user';
import i18n from 'translations';

export interface IActionsProps {
  user: IUser;
  showActions: boolean;
  onDelete: (user: IUser) => void;
  onEdit: (user: IUser) => void;
}

export const Actions = ({
  user,
  showActions,
  onDelete,
  onEdit,
}: IActionsProps) => {
  return (
    <TableActions>
      {showActions && (
        <>
          <Link
            data-cy="edit-user-action"
            to="?tab=users"
            onClick={() => onEdit(user)}
          >
            {i18n.t('settings.users.actions.edit')}
          </Link>
          <Link
            data-cy="delete-user-action"
            to="?tab=users"
            onClick={() => onDelete(user)}
          >
            {i18n.t('settings.users.actions.delete')}
          </Link>
        </>
      )}
    </TableActions>
  );
};
