import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  useAddNewDealerMutation,
  useGetOneDealerQuery,
  useUpdateDealerMutation,
} from 'thunks/RTKQuery/dealer';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { dealerDetailSlice, toastSlice } from 'slices';
import { Spinner2 } from 'components/Spinner2';
import { isFetchBaseQueryError } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { IDealerObject, IValidationDealer } from '../Models/dealers';
import { DealersDetailView } from './DealersDetailView';

interface IDealerDetailContainer {
  isUpdateMode: boolean;
  selectedId: number;
  setErrorString: (err: string) => void;
}

const schema: yup.SchemaOf<IValidationDealer> = yup.object().shape({
  dealerId: yup.string().required(),
  name: yup.string().required(),
});

export const DealersDetailViewContainer = ({
  isUpdateMode,
  selectedId,
  setErrorString,
}: IDealerDetailContainer) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataResult = useAppSelector((state) => state.dealerDetails.data);
  const imageUri =
    dataResult && dataResult.logo ? dataResult?.logo?.url : undefined;
  const fixedConfigJson =
    dataResult && dataResult.fixedConfigJson
      ? JSON.stringify(dataResult.fixedConfigJson)
      : '';
  const {
    data: detailData,
    isLoading: dealerDetailLoading,
    error: dealerDetailError,
  } = useGetOneDealerQuery(selectedId, selectedId === 0 ? { skip: true } : {});

  useEffect(() => {
    dispatch(dealerDetailSlice.actions.setDetailData({ data: detailData }));
  }, [detailData]);

  const [
    addNewDealer,
    {
      isLoading: dealerSaveLoading,
      error: dealerSaveError,
      status: dealerSaveStatus,
    },
  ] = useAddNewDealerMutation();
  const [
    updateDealer,
    {
      isLoading: dealerUpdateLoading,
      error: dealerUpdateError,
      status: dealerUpdateStatus,
    },
  ] = useUpdateDealerMutation();

  useEffect(() => {
    if (dealerDetailError && 'message' in dealerDetailError) {
      setErrorString(`Error :${dealerDetailError.message}`);
    }
    if (dealerSaveError && isFetchBaseQueryError(dealerSaveError)) {
      const msg = dealerSaveError as any;
      setErrorString(`Error :${msg.data.message.errors}`);
    }
    if (dealerUpdateError && isFetchBaseQueryError(dealerUpdateError)) {
      const msg = dealerUpdateError as any;
      setErrorString(`Error :${msg.data.message.errors}`);
    }
  }, [dealerDetailError, dealerSaveError, dealerUpdateError]);

  const handleSubmit = async (values: IDealerObject) => {
    setErrorString('');
    if (!isUpdateMode) {
      await addNewDealer(values);
    } else {
      await updateDealer(values);
    }
  };

  useEffect(() => {
    if (dealerSaveStatus === QueryStatus.fulfilled && !dealerSaveError) {
      dispatch(
        toastSlice.actions.show({
          title: t('dealer.save.title'),
          message: t('dealer.save.message'),
        })
      );
    }
    if (dealerUpdateStatus === QueryStatus.fulfilled && !dealerUpdateError) {
      dispatch(
        toastSlice.actions.show({
          title: t('dealer.update.title'),
          message: t('dealer.update.message'),
        })
      );
    }
  }, [dealerSaveStatus, dealerUpdateStatus]);

  return (
    <>
      <Spinner2
        show={dealerDetailLoading || dealerUpdateLoading || dealerSaveLoading}
      />
      <DealersDetailView
        detailData={{ ...dataResult, fixedConfigJson }}
        imageUri={imageUri}
        validationSchema={schema}
        onSubmit={handleSubmit}
      />
    </>
  );
};
