import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MILL_SETTINGS_COMPANY_DETAILS_ROUTE,
  MILL_SETTINGS_ROUTE,
} from 'constants/routes';
import { LAB_ROLE, MILL_ROLE } from 'interfaces/user';
import { authSlice, toastSlice } from 'slices';
import { RootState } from 'store/store';
import Api from '../api';
import { forceUserToEnterLabInfo } from './lab';

export interface IMillSubscriptionPlan {
  sessionId: string;
}

export const forceUserToEnterMillInfo = (
  companyInfoIsUpdated: boolean,
  dispatch: any
) => {
  const { location } = window;

  if (
    !companyInfoIsUpdated &&
    !location.pathname.includes(MILL_SETTINGS_ROUTE)
  ) {
    window.location.href = MILL_SETTINGS_COMPANY_DETAILS_ROUTE;
    dispatch(
      toastSlice.actions.show({
        title: 'Update company Info',
        message: 'Please update company information first.',
      })
    );
  }
};

export const forceUserConsent = (
  needPoliciesApproval: boolean,
  dispatch: any
) => {
  if (needPoliciesApproval) {
    dispatch(authSlice.actions.showConsentModel());
  }
};

export const millThunk = createAsyncThunk(
  'mill-get',
  async (id: string, { dispatch, getState }) => {
    const response = await Api.get(`/mills/${id}`);
    const state = getState() as RootState;
    const { user } = state.auth.data;

    const { company, needPoliciesApproval } = response;
    const { companyInfoIsUpdated } = company;

    if (user.role?.type === MILL_ROLE) {
      forceUserToEnterMillInfo(companyInfoIsUpdated, dispatch);
      forceUserConsent(needPoliciesApproval, dispatch);
    } else if (user.role?.type === LAB_ROLE) {
      forceUserToEnterLabInfo(companyInfoIsUpdated, dispatch);
    }

    return response;
  }
);

export const millConsentThunk = createAsyncThunk(
  'mill-user-consent',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const url = `/mills/${id}/approval`;
      const response = await Api.get(url);
      dispatch(authSlice.actions.closeConsentModel());

      return response;
      // eslint-disable-next-line prettier/prettier
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
