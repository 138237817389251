export default {
  formats: {
    date: {
      short: 'DD.MM.YYYY',
      long: 'DD.MM.YYYY HH:mm',
      describe: 'MMMM Do YYYY, h:mm A',
    },
  },
  language: {
    de: 'Allemand',
    en: 'Anglais',
    it: 'Italien',
    fr: 'Français',
    sp: 'Espagnol',
    pl: 'Polonais',
  },
  search: 'Recherche',
  goBack: 'Retour',
  signIn: {
    title: 'Retour',
    dontHaveAccount: 'Vous n’avez pas de compte ?',
  },
  signUp: {
    title: 'Inscription',
    info:
      'En vous inscrivant, vous acceptez de <0>{{privacy}}</0> and <1>{{terms}}</1>.',
    promotions:
      'Je ne veux pas recevoir de mises à jour par courriel ou de promotions.',
    alreadyHaveAccount: 'Vous avez déjà un compte ?',
    serviceterms: 'J’accepte le <0>{{service}}</0>.',
    dataprocessing: 'J’accepte le <0>{{agreement}}</0>.',
  },
  signOut: {
    title: 'Déconnectez',
  },
  email: {
    label: 'Adresse e-mail',
    placeholder: 'Adresse e-mail',
    taken: 'Adresse e-mail déja utiliser',
  },
  password: {
    label: 'Mot de pass',
    placeholder: 'Entrer le mot de passe',
    mismatch: 'Les mots de passe doivent correspondre.',
  },
  passwordConfirmation: {
    label: 'Confirmer le mot de passe',
    placeholder: 'Entrer le mot de passe de confirmation',
  },
  firstName: {
    label: 'Prénom',
    placeholder: 'Entrer le prénom',
  },
  lastName: {
    label: 'Nom de famille',
    placeholder: 'Entrer le nom de famille',
  },
  activationKey: {
    label: "Clé d'activation",
    placeholder: 'Entrer la clé d’activation',
  },
  companyName: {
    label: "Nom de l'entreprise",
    placeholder: 'Entrer le nom de l’entreprise',
  },
  contactPerson: {
    label: 'Personne de contact',
    placeholder: 'Personne de contact',
  },
  country: {
    label: 'Pays',
  },
  address: {
    label: 'Adresse',
    placeholder: 'Ajouter une adresse',
  },
  city: {
    label: 'Ville',
    placeholder: 'Ajoutez une ville',
  },
  zip: {
    label: 'Code Postal',
    placeholder: 'Entrez le code postal',
  },
  web: {
    label: 'Internet',
    placeholder: 'Entrer sur le Web',
  },
  uid: {
    label: 'TaxId',
    placeholder: 'Entrer le numéroID',
  },
  vatId: {
    label: 'Numéro de TVA',
    placeholder: 'TVA (par exemple : E123456789)',
  },
  state: {
    label: 'État',
    placeholder: 'Entrer l’état',
  },
  street: {
    label: 'Rue',
    placeholder: 'Entre la rue',
  },
  phone: {
    label: 'Téléphone',
    placeholder: 'Entrer le téléphone',
  },
  forgot: {
    title: 'Mot de passe oublié',
    info:
      'Ne vous inquiétez pas! Remplissez simplement votre courriel et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
    button: 'Envoyer le mot de passe de réinitialisation',
    password: 'Mot de passe oublié?',
  },
  header: {
    profile: 'Profil',
    settings: 'Paramètres',
    companyInfo: "Informations sur l'entreprise",
    selectMill: 'Sélectionner le centre de fraisage',
  },
  footer: {
    menu: {
      aboutUs: {
        title: 'Sur nous',
        content:
          'Follow-Me! Technology Group développe et distribue hyperDENT®. Un système logiciel qui couvre l’ensemble du processus de production avec un seul logiciel de FAO. Le siège de FOLLOW-ME! Technology GmbH est basée à Munich avec notre PDG Dr. Ferdinand Schwenkert et CTO Günter Linseis, qui innovent constamment de nouvelles solutions intelligentes dans notre gamme de produits, en gardant une perspective prospective. <br> <br> Nos filiales du FOLLOW-ME! Technology Group est présent dans le monde entier, notamment en Italie, en Chine, au Japon, en Corée, à Singapour et aux États-Unis.',
        ceoMessage:
          '“Nous voulons fournir des solutions logicielles dentaires qui créent de la valeur en améliorant les processus de production par la numérisation et l’automatisation.”',
      },
      contact: {
        title: 'Contact',
        content:
          '<strong>FOLLOW-ME! TECHNOLOGY GmbH, Regerstrasse 27, 81541 Munich, Allemagne. <br> Tél. : +49 89 452 170 60 . <br> Courriel : info@follow-me-tech.com</strong>',
      },
      desktopApp: {
        title: 'Application de bureau',
        content: '<strong>Téléchargez l’application Desktop ici</strong>',
      },
      privacyPolicy: {
        title: 'Politique de confidentialité',
        content:
          '<strong>La protection des données revêt une très grande importance pour FOLLOW-ME! Technology GmbH. L’utilisation de notre site Web est généralement possible sans avoir à fournir de données personnelles. Les données personnelles ne sont traitées que dans la mesure nécessaire à la capacité de fournir un site Web fonctionnel et les contenus et services offerts par son intermédiaire. Le traitement des données personnelles de nos utilisateurs n’a normalement lieu qu’avec le consentement préalable de l’utilisateur. Une exception existe dans les cas où il n’est pas possible d’obtenir le consentement préalable pour des raisons de fait et où le traitement des données est autorisé en vertu des dispositions de la loi. </strong>',
      },
      termsOfService: {
        title: 'Modalités de service',
        content:
          '<strong>Nous travaillons actuellement à la mise à jour de nos conditions d’utilisation, veuillez consulter ultérieurement les conditions d’utilisation mises à jour pour utiliser hyperDENT Connect.</strong>',
      },
      dataProcessing: {
        title: 'Accord de traitement des données',
        content:
          '<strong>Nous travaillons actuellement à la mise à jour de notre accord de traitement des données. Veuillez consulter ultérieurement la mise à jour de l’accord de traitement des données pour l’utilisation d’hyperDENT Connect.</strong>',
      },
    },
  },
  home: {
    home: 'Home',
    subtitle: 'hyperDENT Connect',
    title: 'En contact avec des experts',
    content: 'Partagez facilement vos restaurations dentaires numériques.',
    reasons: {
      title: 'Pourquoi utiliser hyperDENT Connect ?',
      reason1: {
        title: 'La dentisterie numérique change les exigences. ',
        content:
          'Une productivité plus élevée, plus de flexibilité, plus d’espace de stockage et des processus plus légers.',
      },
      reason2: {
        title: 'Personnalisez vos services cloud.',
        content:
          'Des services cloud sur mesure, performants et évolutifs ouvrent de toutes nouvelles possibilités aux centres d’usinage pour atteindre vos propres objectifs.',
      },
      reason3: {
        title: 'Accès facile au cloud ',
        content:
          'hyperDENT Connect vous permet d’accéder aux fichiers de vos clients et d’utiliser l’application depuis n’importe quel appareil pouvant accéder à Internet.',
      },
    },
    policiesupdate: {
      title: 'Mise à jour de la politique',
      content:
        'Veuillez donner votre consentement sur nos conditions d’utilisation et notre contrat de traitement des données mis à jour.',
    },
  },
  welcome: {
    title: 'Bienvenue sur hyperDENT Connect',
    content:
      'hyperDENT Connect est votre plateforme de partage de fichiers dentaires numériques. Le partage sans effort pour les clients de millingcenter, le rapport d’état en temps réel et la communication facile connecte millingcenters avec leurs clients. ',
    landingPage: {
      title: "1. Créer une page d'accueil",
      content: '',
      button: 'Paramètres de la page d’accueil',
    },
    inviteClients: {
      title: '2. Inviter les clients',
      content:
        'Ajoutez des laboratoires dentaires ou des cliniques à vos comptes clients en les invitant avec un lien d’invitation prédéfini. ',
      button: 'Inviter des clients',
    },
  },
  labWelcome: {
    title: 'Titre pédagogique',
    content: 'Instructional headline: Text ici',
    serviceApp: {
      title: 'Application de service',
      content: '',
      button: 'Application de téléchargement',
    },
    dashboard: {
      title: 'Tableau de bord',
      content: '',
      button: 'Accédez au tableau de bord',
    },
  },
  orders: {
    title: 'Commandes',
    deleted: 'Supprimer',
    deletedFilesInfo:
      'Les fichiers de ce projet ont été supprimés car la limite de conservation des fichiers a été atteinte.',
    noSearch: 'Aucun résultat de recherche',
    noOrders: 'Aucun ordre créé',
    winApp: 'Les commandes sont créées à partir de l’application Windows',
    downloadApp: "Télécharger l'application",
    columns: {
      orderId: 'Id de commande',
      name: 'Nom de commande',
      date: 'Date',
      messages: 'Messages',
      components: 'Composants',
      client: 'Client',
      responsible: 'Projet responsable',
      download: 'Téléchargement',
      report: 'Rapport',
    },
    component: {
      columns: {
        name: 'Composant',
        status: 'Statut',
        messages: 'Messages',
        files: 'Fichiers',
        comment: 'Commentaire',
      },
    },
    messages: 'Messages',
    message: 'Message',
    newMessage: 'Nouveau',
    select: {
      any: {
        client: 'Tout client',
        responsible: 'Tout utilisateur',
      },
    },
  },
  project: {
    download: {
      flat: 'Plat',
      hierarchical: 'Hiérarchique',
    },
  },
  component: {
    orders: 'Commandes',
    component: 'Composant',
    status: {
      label: 'État des composants',
      any: 'Tout statut',
      open: 'Ouvert',
      closed: 'Fermé',
      downloaded: 'Télécharger',
    },
    orderId: 'ID commande',
    project: 'Projet',
    preview: 'Aperçu des composants',
    documents: 'Documents composant',
    downloadAll: 'Télécharger tout',
    downloads: 'Documents à télécharger',
    uploadPdf: 'Téléverser le document PDF',
    noFiles: 'Aucun fichier',
    messages: {
      title: 'Messages',
      message: {
        placeholder: 'Écrire un message',
      },
      button: 'Envoyer message',
    },
    upload: {
      title: 'Ajouter un document',
      name: {
        label: 'Nom du document',
        placeholder: 'Entrer le nom du document',
      },
      button: 'Téléverser',
    },
    download: {
      deleteConfirm: {
        title: 'Supprimer le document',
        message: 'Voulez-vous vraiment supprimer le document?',
        buttonOk: 'Supprimer',
        buttonCancel: 'Annuler',
      },
      deleteToast: {
        title: 'Supprimer le document',
        message: 'Document supprimé avec succès.',
      },
      uploadToast: {
        title: 'Téléverser un document',
        message: 'Document téléversé avec succès.',
      },
    },
    updateToast: {
      title: 'Mis à jour composant',
      message: 'Le composant a été mis à jour avec succès.',
    },
    messageCreateToast: {
      title: 'Envoyer message',
      message: 'Message envoyer avec succés.',
    },
    updateOrderToast: {
      title: 'Mise à jour de la commande',
      message: 'La commande a été mise à jour avec succès.',
    },
  },
  fileUpload: {
    label: 'Glisser-déposer ou cliquer pour téléverser',
  },
  clients: {
    title: 'Clients',
    addClient: 'Ajouter un client',
    status: {
      label: 'Statut',
      any: 'Tout statut',
      active: 'Actif',
      inactive: 'Inactif',
    },
    columns: {
      company: 'Entreprise',
      email: 'E-mail',
      lab: 'Nom du laboratoire',
      contactPerson: 'Personne de contact',
      status: 'Statut',
    },
    actions: {
      sendInvite: "Envoyer l'invitation",
      delete: 'Supprimer',
      blocked: 'Bloquer',
      unBlock: 'Débloquer',
    },
    deleteConfirm: {
      title: 'Supprimer le client',
      message: 'Voulez-vous vraiment supprimer le client?',
      buttonOk: 'Supprimer',
      buttonCancel: 'Annuler',
    },
    blockedConfirm: {
      title: 'Bloquer client',
      message: 'Êtes-vous sûr de vouloir bloquer l’utilisateur ?',
      buttonOk: 'Bloquer',
      buttonCancel: 'Annuler',
    },
    unblockedConfirm: {
      title: 'Débloquer le client',
      message: 'Êtes-vous sûr de vouloir débloquer le client ?',
      buttonOk: 'Débloquer',
      buttonCancel: 'Annuler',
    },
    deleteToast: {
      title: 'Supprimer le client',
      message: 'Client supprimé avec succès.',
    },
    blockToast: {
      title: 'Bloquer client',
      message: 'Client bloqué avec succès.',
    },
    unblockToast: {
      title: 'Débloquer le client',
      message: 'Client débloqué avec succès.',
    },
    blockNotAllowedToast: {
      title: 'Débloquer le client',
      message: 'Ce client est bloqué par le système.',
    },
    client: {
      title: 'Ajouter un client',
      button: 'Ajouter un client',
    },
    activation: {
      title: "Instructions d'activation",
      info:
        'Client ajouté avec succès. Vous pouvez copier et utiliser ce modèle de message pour contacter le nouveau client.',
      message: {
        label: "Message d'invitations",
        placeholder: "Entrer message d'invitations",
      },
      copy: 'Copier le message',
      send: 'E-mail envoyer',
      confirm: {
        title: 'E.mail d’instruction',
        message: 'Le mail avec instructions a été envoyé avec succès au client',
        buttonOk: 'Fermer',
      },
      errorConfirm: {
        title: 'Erreur des instructions d’activation',
        buttonOk: 'Ok',
      },
    },
    millingcenterClientAdded: {
      title: "Client du centre d'usinage ajouté",
      message:
        "Client de centre d'usinage '{{millClient}}' a été ajouté à l’espace de travail.",
      buttonOk: 'Excellent',
    },
  },
  messages: {
    title: 'Messages',
    status: {
      any: 'Tout message',
      read: 'Lire',
      unread: 'Non lu',
    },
    noMessages: 'Vous n’avez aucun message',
  },
  settings: {
    title: 'Paramètres',
    tabs: {
      landingPage: "Page d'accueil",
      companyDetails: "Détails de l'entreprise",
      users: 'Utilisateur',
      licenseKeys: 'Clés de licence',
    },
    company: {
      logo: {
        title: "Logo de l'entreprise",
        label: 'Logo',
      },
      info: {
        title: 'Informations sur la société',
      },
      button: 'Mis à jour',
      message: {
        update: 'Veuillez mettre à jour les coordonnées de votre entreprise.',
      },
      error: {
        title: 'Erreur est survenue',
        message:
          'Une erreur s’est produite lors de l’enregistrement des détails de la page de destination.',
      },
    },
    users: {
      add: 'Ajouter un utilisateur',
      edit: 'Modifier un utilisateur',
      roles: {
        title: 'Rôles',
        all: 'Tout rôles',
        admin: 'Admin',
        user: 'Utilisateur',
      },
      columns: {
        name: 'Nom',
        email: 'E-mail',
        role: 'Rôle',
      },
      actions: {
        delete: 'Supprimer',
        edit: 'Modifier',
      },
      deleteConfirm: {
        title: "Supprimer l'utilisateur",
        message: 'Voulez-vous vraiment supprimer l’utilisateur ?',
        buttonOk: 'Supprimer',
        buttonCancel: 'Annuler',
      },
      addToast: {
        title: 'Ajouter un utilisateur',
        message: 'Utilisateur créé avec succès.',
      },
      editToast: {
        title: "Modifier l'utilisateur",
        message: 'L’utilisateur est mis à jour avec succès.',
      },
      deleteToast: {
        title: "Supprimer l'utilisateur",
        message: 'Utilisateur supprimé avec succès.',
      },
      error: {
        title: 'Erreur est survenue',
        message:
          'Une erreur s’est produite lors de l’enregistrement des détails des utilisateurs.',
      },
    },
    licenseKey: {
      add: 'Ajouter une clé de licence',
      columns: {
        licenseKey: 'Clé de licence',
        placeholder: 'Entrer la clé de licence',
        enableCollection: 'Activer la collecte de données',
      },
      actions: {
        delete: 'Supprimer',
      },
      deleteConfirm: {
        title: 'Supprimer la clé de licence',
        message: 'Voulez-vous vraiment supprimer la clé de licence ?',
        buttonOk: 'Supprimer',
        buttonCancel: 'Annuler',
      },
      addToast: {
        title: 'Ajouter une clé de licence',
        message: 'License Key added successfully.',
      },
      editToast: {
        title: 'Modifier la clé de licence',
        message:
          'Les informations de clé de licence sont mises à jour avec succès.',
      },
      deleteToast: {
        title: 'Supprimer la clé de licence',
        message: 'Clé de licence supprimée avec succès.',
      },
      error: {
        title: 'Erreur est survenue',
        message:
          'Une erreur s’est produite lors de l’enregistrement des détails des clés de licence.',
      },
    },
    landingPage: {
      intro: 'Créez votre page de destination.',
      copy: {
        label: 'Copier url',
        button: 'Copier',
      },
      logo: 'Logo',
      header: {
        label: 'Entête',
        placeholder: 'Écrire le titre de l’en-tête',
      },
      content: {
        label: 'Contenu',
        placeholder: 'écrire une description',
      },
      update: 'Mis à jour',
      preview: {
        btnTxt: 'Aperçu de la page d’accueil',
        closeBtnTxt: "Fermer l'aperçu",
        signIn: 'Connexion',
      },
      logoAlt: "Logo d'entreprise",
      notify: {
        title: 'Mise à jour la page d’accueil',
        message:
          'La page de destination n’a pas encore été examinée. Aimeriez-vous le faire maintenant?',
        okText: 'Procéder',
        cancelText: 'Sauter pour l’instant',
      },
    },
  },
  imageUpload: {
    button: 'Parcourir',
  },
  countries: {
    empty: 'Choisir un pays',
    de: 'Allemagne',
    us: 'États-Unis',
    es: 'Espagne',
  },
  support: {
    title: 'Support',
    tabs: {
      contact: 'Contact',
      downloadApp: 'Application de téléchargement',
    },
    contact: {
      info: "Besoin d'aide ?",
      title: 'Nous écrire',
      topic: {
        label: 'Sujet',
        placeholder: 'Quel est votre sujet ?',
      },
      topics: {
        empty: 'Choisir un sujet',
        topicA: 'Sujet A',
        topicB: 'Sujet B',
        topicC: 'Sujet C',
      },
      message: {
        label: 'Message',
      },
      submit: 'Soumettre',
      callUs: 'Appelez-nous',
      line: 'Lignes de support',
      phone: 'Tel.: +49 89 452 170 60',
      fax: 'Fax.: +49 89 452 170 69',
      sendEmailToast: {
        title: 'Email de contact',
      },
    },
    downloadApp: {
      info: 'Application de bureau pour votre ordinateur',
      download: 'Téléchargement',
      toastTitle: 'Application de téléchargement',
      notFound: 'L’application n’est pas disponible',
    },
  },
  account: {
    title: 'Compte',
    tabs: {
      company: "Informations sur l'entreprise",
      settings: 'Paramètres',
      profile: 'Profil',
    },
    company: {
      companyInfo: 'Gérer les informations de votre entreprise.',
      logo: "Logo de l'entreprise",
      information: 'Info',
      contact: 'Contact',
      address: 'Adresse',
      shipping: {
        title: 'Expédition',
        address: 'Adresse de livraison',
        sameAsAddress: 'Même adresse',
        street: {
          label: 'Rue',
          placeholder: 'Entrer la rue d’expédition',
        },
        city: {
          label: 'Ville',
          placeholder: 'Entrer la ville d’expédition',
        },
        zip: {
          label: 'Code postal',
          placeholder: 'Entrez le code postal d’expédition.',
        },
      },
      button: 'Mis à jour',
    },
    settings: {
      info: 'Gérez vos paramètres personnels.',
      password: {
        title: 'Mot de passe',
        label: 'Nouveau mot de passe',
        placeholder: 'Saisir un nouveau mot de passe',
      },
      passwordConfirmation: {
        label: 'Confirmer le mot de passe',
        placeholder: 'Saisir et confirmer le mot de passe',
      },
      updatePassword: 'Mettre à jour le mot de passe',
      notifications: {
        title: 'Notifications',
        notificationOnNewMessageReceived: 'Nouveau message reçu',
        sendEmailOnNewMessageReceived: 'E-mail sur le nouveau message',
        notificationOnNewProjectIsCreated: 'Nouvel ordre créé',
        sendEmailOnNewProjectIsCreated: 'E-mail sur la nouvelle commande',
        notificationOnOrderProcessStart: 'Processus de commande démarrer',
        notificationOnOrderProcessCompleted: 'Processus de commande terminé',
      },
      language: {
        title: 'Langue',
      },
      project: {
        title: 'Project',
        folderStructure: 'Structure des dossiers de téléchargement',
      },
    },
    profile: {
      info: 'Gérez vos informations de profil.',
      button: 'Mis à jour',
    },
    updateProfileToast: {
      title: 'Mise à jour de profil',
      message: 'Mis à jour du profile avec succès.',
    },
    updatePasswordToast: {
      title: 'Mettre à jour le mot de passe',
      message: 'Mot de passe mis à jour avec succès.',
    },
    updateNotificationToast: {
      title: 'Mise à jour des notifications',
      message: 'Mis à jour des notifications avec succès.',
    },
    updateLanguageToast: {
      title: 'Mise à jour de la langue',
      message: 'Mise à jour de la langue avec succès.',
    },
    updateCompanyToast: {
      title: 'Mise à jour de la société',
      message: 'Mise à jour de la société avec succès.',
    },
    invalidVAT: {
      title: 'Numéro de TVA non valide',
      message:
        'Le numéro de TVA fourni n’est pas correct, veuillez entrer un numéro de TVA VALIDE.',
    },
  },
  switch: {
    on: 'On',
    off: 'Off',
  },
  auth: {
    registerConfirm: {
      title: 'Lien de confirmation',
      message:
        'Nous avons envoyé un lien de confirmation par e-mail. Veuillez vérifier votre e-mail et activer votre compte.',
      buttonOk: "D'accord",
    },
    confirmationConfirm: {
      title: "S'inscrire",
      message:
        'Bienvenue à bord de {{name}}! Votre compte est maintenant actif. Vous pouvez commencer à utiliser notre plateforme.',
      buttonOk: 'Excellent',
    },
    forgotConfirm: {
      title: 'Confirmation du mot de passe oublié',
      message:
        'Pour réinitialiser votre mot de passe, veuillez consulter votre e-mail.',
    },
    resetConfirm: {
      title: 'Confirmation de réinitialisation du mot de passe',
      message: 'Votre mot de passe a été modifié avec succès.',
    },
    accountCreationConfirm: {
      title: 'Inscription réussi avec succès',
      error: 'Erreur d’inscription',
      message:
        'Bienvenue à bord de {{name}}! Votre compte est maintenant actif. Vous pouvez commencer à utiliser notre plateforme.',
      buttonOk: 'Excellent',
    },
    millCenterAdded: {
      title: "Centre d'usinage ajouter",
      message: "Vous avez rejoint le '{{millName}}' centre d'usinage.",
      buttonOk: 'Excellent',
    },
  },
  reset: {
    title: 'Réinitialiser le mot de passe',
    info: 'Veuillez entrer votre nouveau mot de passe',
    button: 'Changer votre mot de passe',
  },
  accountCreation: {
    title: 'Création du compte',
    info:
      'Pour terminer la création de votre compte, veuillez créer un mot de passe.',
    password: {
      label: 'Créer un mot de passe',
      placeholder: 'Entrer le mot de passe',
    },
    button: 'Activer le compte',
  },
  invitation: {
    title: 'Invitation',
    emailLabel: 'E-mail',
    message: "Vous avez été invité à '{{millName}}' l’espace de travai.",
    submitBtnText: 'Créer un nouveau compte',
    connectToAccount: 'Créer un nouveau compte',
    connectToAccountMsg:
      'Si vous avez déjà un compte hyperflow, vous pouvez y ajouter cet espace de travail.',
    or: 'Ou',
    addMillToAccountTxt: 'Ajouter une usinage à ce compte',
    createNewAccount: 'Créer un nouveau compte',
    sendConfirmationEmail: 'Envoyer un mail de confirmation',
    existingAccount: 'Compte existant',
    existingAccountMsg:
      'Le mail que vous avez entré est associé à un compte hyperconnect existant. Vous pouvez ajouter une nouvelle usine à ce compte ou créer un nouveau compte en utilisant une adresse e-mail différente.',
  },
  landingPage: {
    signIn: 'Connexion',
  },
  unsavedChanges: {
    message:
      'Vous avez des changements non enregistrés, êtes-vous sûr de vouloir partir ?',
  },
  contract: {
    title: 'Contrat',
    error: "Une erreur s'est produite",
    tabs: {
      contractType: {
        title: 'Type de contrat',
        subscribe: 'Inscrire',
        unsubscribe: 'Désinscrire',
        resubscribe: 'Se réabonner',
        addOnConfirmation:
          'La durée de conservation des fichiers pour {{addOnDuration}} jours (€{{getPromotionalPrice}}/mois) est ajoutée à votre abonnement et sera facturée dans la facture à venir.',
        addOnUnsubscription:
          'Le module complémentaire de base avec une durée minimale de conservation des fichiers est sélectionné avec votre forfait.',
        subscriptionSuccess: 'Vous avez souscrit avec succès à votre forfait',
        subscriptionFailure:
          'Une erreur s’est produite lors de l’inscription à votre forfait. Veuillez contacter votre administrateur ou réessayer plus tard',
        unsubscriptionSuccess:
          'Vous avez réussi à vous désabonner du programme sélectionné',
        unsubscriptionFailure:
          'Une erreur s’est produite lors de la désabonnement de votre forfait. Veuillez contacter votre administrateur ou réessayer plus tard',
        resubscriptionSuccess:
          'Vous avez repris avec succès votre plan sélectionné',
        resubscriptionFailure:
          'Une erreur est survenue lors de la reprise du plan sélectionné. Veuillez contacter votre administrateur ou réessayer plus tard',
        month: 'Mois',
        cancelsAt: 'L’abonnement se termine à : ',
        free: 'Libre',
        silver: 'Argent',
        gold: 'Or',
        basic: 'Base',
        essential: 'Essentiel',
        enterprise: 'Entreprise',
        custom: 'Sur mesure',
        storage: '{{dataLimit}}Mbs de stockage en cloud',
        orders: 'Créer jusqu’à {{ordersLimit}} commandes',
        clients: 'Inviter jusqu’à {{clients}} clients',
        supportAvailable: 'Support est disponible',
        supportNotAvailable: 'Support n’est pas disponible',
        cancel: 'Annuler',
        confirm: 'Confirmer',
        upgrade: 'Mise à niveau',
        cancelConfirmation:
          'Êtes-vous sûr de vouloir annuler votre abonnement?',
        resubscriptionConfirmation:
          'Vous êtes sûr de vouloir reprendre votre abonnement ?',
        subscriptionConfirmation:
          'Votre abonnement sera repris et mis à jour. Confirmez-vous ?',
        upgradesubscriptionConfirmation:
          'Êtes-vous certain de vouloir mettre à niveau ou modifier votre abonnement?',
        subscriptionConsent:
          'Merci d’avoir choisi l’abonnement à hyperDENT Connect, veuillez donner votre consentement pour continuer.',
        payment: {
          success:
            'Votre paiement a été reçu avec succès. Vous serez redirigé sous peu.',
          failure:
            'Le processus de paiement a échoué en raison d’une interuption manuelle ou de renseignements invalides sur la carte. Vous serez redirigé sous peu.',
        },
      },
      usageDetail: {
        title: "Détails d'utilisation",
        resourceName: 'Nom de ressource',
        used: 'Utilisé',
        limit: 'Limite',
        usagePercentage: 'Pourcentage utiliser',
        errorMessage:
          'Une erreur est survenue lors du chargement des détails d’utilisation.',
      },
      billing: {
        title: 'Facturation',
        custEmail: 'E-mail du client',
        custComp: 'Nom de la société',
        fromPeriod: 'Période de',
        toPeriod: "Période jusqu'à",
        amount: 'Montant',
        errorMessage:
          'Une erreur est survenue lors du chargement des détails de facturation.',
        manage: 'Gérer la facturation',
      },
      invoices: {
        title: 'Factures',
        id: 'Id de facture',
        itemDesc: 'Description',
        amountDue: 'Montant dû',
        download: 'Téléchargement',
        isPaid: 'Est payé',
        paid: 'Payé',
        due: 'Dû',
        errorMessage:
          'Une erreur est survenue lors du chargement de la liste des factures.',
        totalAmountDue: 'Montant total dû',
        dateDue: 'Date limite',
        upcoming: 'Prochaine facture',
        buttonOk: "D'accord",
      },
    },
    addons: {
      title: 'Modules complémentaires',
      keepFiles: 'Durée de conservation des fichiers',
      days: 'Jours',
      month: 'Mois',
    },
  },
  errorBoundary: {
    errorMessage: 'Oups ! , quelque chose a mal tourné',
    errorFix: 'Erreur technique, nous travaillons à la corriger',
  },
  notifications: {
    label: 'Notifications',
    new: 'Nouvelle notification',
    none: 'Vous n’avez pas de notification.',
    markAsUnread: 'Marquer comme non lu',
    filter: {
      all: 'Tout',
      unread: 'Non lus',
    },
  },
  reports: {
    download: {
      error: {
        title: 'Erreur',
        message:
          'Quelque chose a mal tourné lors de la génération du rapport PDF',
      },
      success: {
        title: 'Succès',
        message: 'Le rapport PDF est généré avec succès.',
      },
    },
    dateFormat: 'Format de la date du rapport',
  },
  licenseContent: {
    heading: 'License Content',
    placeholder: 'Search License Key',
    licenses: 'Licenses',
    products: 'Products',
    dongleId: 'Dongle Id',
    isNetwork: 'Is Network',
    isCommercial: 'Is Commercial',
    format: 'Format',
    invalid: 'Invalid Key Format',
    detail: 'License Details',
    columns: {
      ticket: 'Ticket',
      product: 'Product',
      licenseExpiry: 'License Expiration',
      maintenanceExpiry: 'Maintenance Expiry',
    },
    detailColumns: {
      code: 'Code',
      expiryDate: 'Expiration Date',
      featureMap: 'Feature Map',
      permissions: 'Permissions',
      information: 'Information',
      formalDongle: 'Former Dongle No.',
      endOfMaintenance: 'END of Maintenance',
      count: 'Count',
      productCode: 'Product Code',
    },
    tabActive: 'Active Licenses',
    tabOrdered: 'Ordered Licenses',
    tabDetails: 'Active Product Details',
  },
  dealer: {
    save: {
      title: 'Dealer Saved',
      message: 'Dealer data has been saved successfully',
    },
    update: {
      title: 'Update',
      message: 'Dealer data has been updated successfully',
    },
  },
};
