import { IMill } from '../components/Modal/MillSelection/MillSelection';

export interface IActiveMill extends IMill {
  isActive: boolean;
}

export const getActiveMillId = (mills: [IActiveMill]) => {
  if (!mills.length) return { id: '', name: '', logo: {} };

  const [firstMill] = mills;
  let activeMill = mills.find((m) => m.isActive);

  if (!activeMill) {
    activeMill = firstMill;
  }

  const { id, company } = activeMill;
  const { name, logo } = company;
  return { id: id.toString(), name, logo };
};
