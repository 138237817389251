import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Button } from 'components';
import { IMessageForm } from 'thunks/component';

interface IMessageFormProps {
  initialValues: IMessageForm;
  validationSchema: any | (() => any);
  onSubmit: (
    values: IMessageForm,
    form: FormikHelpers<IMessageForm>
  ) => void | Promise<any>;
}

export const MessageForm = ({
  initialValues,
  validationSchema,
  onSubmit,
}: IMessageFormProps) => {
  const { t } = useTranslation();
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
        return (
          <>
            <Prompt when={dirty} message={t('unsavedChanges.message')} />
            <Form onSubmit={handleSubmit}>
              <div className="message-form d-flex flex-column bg-white mb-60">
                <Form.Group controlId="message" className="mb-0">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    placeholder={t('component.messages.message.placeholder')}
                    value={values.message}
                    onChange={handleChange}
                    isInvalid={touched.message && !!errors.message}
                  />
                </Form.Group>

                <div className="form-action d-flex flex-column align-items-end p-20 bg-gray-sky">
                  <Button type="submit" variant="outline-primary">
                    {t('component.messages.button')}
                  </Button>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
