import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import {
  MANAGER_DEALER_ACTIVITY_ROUTE,
  MANAGER_HD_USAGE_INFO_ROUTE,
  MANAGER_MILLS_VIEW_ROUTE,
  MANAGER_ORDERS_VIEW_ROUTE,
  MANAGER_SETTINGS_ROUTE,
  MANAGER_USERS_VIEW_ROUTE,
  MANAGER_USER_ACTIVITY_ROUTE,
} from 'constants/routes';
import React from 'react';
import { Nav } from 'react-bootstrap';

import { Link } from 'react-router-dom';

// q is a string with any existing query parameters, always include & before each value
export const idsToLinks = (ids: string[], url: string, key: string, q = '') => {
  const dataLength = ids.length;

  const elements = ids.map((val, index) => {
    const keyField = +`${key}${val}`;
    return (
      <React.Fragment key={keyField}>
        <Link
          className="remove-underline"
          key={keyField}
          to={`${url}?id=${val}${q}`}
        >
          {val}
        </Link>
        {index < dataLength - 1 && ', '}
      </React.Fragment>
    );
  });
  return elements;
};

export const ManagerHome: React.FC = () => {
  return (
    <ManagerLayout>
      <h1> Welcome to the hdConnect analytics </h1>

      <p>
        You can go to any of the following analytics views by navigating from
        the sidebar or from the below mentioned links{' '}
      </p>

      <Nav className="flex-column">
        <Link to={MANAGER_USERS_VIEW_ROUTE}>hdConnect MillingCenter Users</Link>
        <Link to={MANAGER_MILLS_VIEW_ROUTE}>MillingCenters Overview </Link>
        <Link to={MANAGER_ORDERS_VIEW_ROUTE}>MillingCenters Orders View</Link>
        <Link to={MANAGER_HD_USAGE_INFO_ROUTE}>
          hyperDent Concurrent Usage View
        </Link>
        <Link to={MANAGER_USER_ACTIVITY_ROUTE}>
          MillingCenters Activity Overview
        </Link>
        <Link to={MANAGER_DEALER_ACTIVITY_ROUTE}>Dealers View</Link>
        <Link to={MANAGER_SETTINGS_ROUTE}>Manager User Settings</Link>
      </Nav>
    </ManagerLayout>
  );
};
