import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { EmailIcon, UserIcon } from 'icons';
import { Button } from 'components';
import { Modal } from 'components/Modal';
import { IClientPayload } from 'thunks/clients';
import { hasError, isErrorString, showError } from 'utils/handle-api-error';
import { Prompt } from 'react-router-dom';

interface IClientFormProps {
  show: boolean;
  initialValues: IClientPayload;
  validationSchema: any | (() => any);
  error?: any;
  onClose: () => any;
  onSubmit: (
    values: IClientPayload,
    form: FormikHelpers<IClientPayload>
  ) => void | Promise<any>;
}

export const ClientForm = ({
  show,
  initialValues,
  validationSchema,
  error = undefined,
  onClose,
  onSubmit,
}: IClientFormProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      data-cy="add-mill-client-modal"
      title={t('clients.client.title')}
      show={show}
      onClose={onClose}
    >
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="firstName">
                  <Form.Label>{t('firstName.label')}</Form.Label>
                  <Form.Control
                    name="firstName"
                    data-cy="add-client-first-name"
                    placeholder={t('firstName.placeholder')}
                    value={values.firstName}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                  <UserIcon />
                </Form.Group>

                <Form.Group controlId="lastName">
                  <Form.Label>{t('lastName.label')}</Form.Label>
                  <Form.Control
                    name="lastName"
                    data-cy="add-client-last-name"
                    placeholder={t('lastName.placeholder')}
                    value={values.lastName}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                  />
                  <UserIcon />
                </Form.Group>
                <Form.Group controlId="email" className="mb-60">
                  <Form.Label>{t('email.label')}</Form.Label>
                  <Form.Control
                    type="email"
                    data-cy="add-client-email"
                    name="email"
                    placeholder={t('email.placeholder')}
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={
                      touched.email &&
                      (!!errors.email || hasError(error, 'email'))
                    }
                  />
                  <EmailIcon />
                  <Form.Control.Feedback type="invalid">
                    {showError(error, 'email')}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button dimension="lg" full type="submit">
                  {t('clients.client.button')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

ClientForm.defaultProps = {
  error: undefined,
};
