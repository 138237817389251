import React from 'react';

export interface IFormInfoProps {
  label: string;
  value?: string;
  parentClasses?: string;
  dataCy?: string;
}

export const FormInfo = ({
  label,
  value,
  parentClasses,
  dataCy,
}: IFormInfoProps) => {
  return (
    <div className={`d-flex mb-16 align-items-center ${parentClasses}`}>
      <div className="label">{label}</div>
      <div data-cy={dataCy || `data-${label}`} className="value">
        {value}
      </div>
    </div>
  );
};
