import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Link, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ForcedPolicyConsent } from 'components/Modal/AddonSelection/ForcedPolicyConsent';
import { UserIcon, EmailIcon, PasswordIcon } from '../../../icons';
import { ISignupPayload } from '../../../thunks/auth';
import {
  hasError,
  isErrorString,
  showError,
} from '../../../utils/handle-api-error';
import { Button } from '../../Button';
import { Modal } from '../../Modal';

interface ISignupProps {
  show: boolean;
  onClose: () => any;
  initialValues: ISignupPayload;
  onSubmit: (
    values: ISignupPayload,
    form: FormikHelpers<ISignupPayload>
  ) => void | Promise<any>;
  onSignin: () => void;
  validationSchema: any | (() => any);
  error: any;
}

export const Signup: React.FC<ISignupProps> = ({
  show,
  onClose,
  initialValues,
  onSubmit,
  onSignin,
  validationSchema,
  error,
}: ISignupProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('signUp.title')}
      show={show}
      onClose={onClose}
      closeOnOutSideClick={false}
    >
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form data-cy="signup-form" onSubmit={handleSubmit}>
                <Form.Group controlId="firstName">
                  <Form.Label>{t('firstName.label')}</Form.Label>
                  <Form.Control
                    name="firstName"
                    data-cy="signup-first-name"
                    placeholder={t('firstName.placeholder')}
                    value={values.firstName}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                  />
                  <UserIcon />
                </Form.Group>

                <Form.Group controlId="lastName">
                  <Form.Label>{t('lastName.label')}</Form.Label>
                  <Form.Control
                    name="lastName"
                    data-cy="signup-last-name"
                    placeholder={t('lastName.placeholder')}
                    value={values.lastName}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                  />
                  <UserIcon />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>{t('email.label')}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    data-cy="signup-email"
                    placeholder={t('email.placeholder')}
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={
                      touched.email &&
                      (!!errors.email || hasError(error, 'email'))
                    }
                  />
                  <EmailIcon />
                  <Form.Control.Feedback type="invalid">
                    {showError(error, 'email')}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="activationKey">
                  <Form.Label>{t('activationKey.label')}</Form.Label>
                  <Form.Control
                    name="activationKey"
                    data-cy="signup-activation-key"
                    placeholder={t('activationKey.placeholder')}
                    value={values.activationKey}
                    onChange={handleChange}
                    isInvalid={
                      touched.activationKey &&
                      (!!errors.activationKey ||
                        hasError(error, 'activationKey'))
                    }
                  />
                  <PasswordIcon />
                  <Form.Control.Feedback type="invalid">
                    {showError(error, 'activationKey')}
                  </Form.Control.Feedback>
                </Form.Group>

                <ForcedPolicyConsent />

                <Button dimension="lg" full type="submit">
                  {t('signUp.title')}
                </Button>

                <p className="form-link mt-20 mb-0">
                  {t('signUp.alreadyHaveAccount')}{' '}
                  <Link to="#" onClick={onSignin}>
                    {t('signIn.title')}
                  </Link>
                </p>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
