import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Select } from 'components';
import { ComponentStatus, IComponent } from 'interfaces/component';
import { capitalize } from 'utils/format';
import { FullName } from 'components/User';
import { IOption } from 'components/Select';
import i18n from 'translations';
import { useResponsiblePersonsOptions } from 'hooks/clients';
import { CompanyLogo } from 'components/User/CompanyLogo';

export interface IBasicInfoProps {
  isMillRole: boolean;
  component?: IComponent;
  onStatusChange: (status: ComponentStatus) => void;
  onResponsibleChange: (value: string) => void;
  onUserClick: () => void;
}

export const statusOptions = [
  { value: 'Open', label: i18n.t('component.status.open') },
  { value: 'Downloaded', label: i18n.t('component.status.downloaded') },
  { value: 'Closed', label: i18n.t('component.status.closed') },
];

export const BasicInfo = ({
  component,
  isMillRole,
  onStatusChange,
  onResponsibleChange,
  onUserClick,
}: IBasicInfoProps) => {
  const { t } = useTranslation();
  const responsibleOptions = useResponsiblePersonsOptions(
    t('orders.select.any.responsible')
  );

  const handleStatusChange = (option: IOption) => {
    onStatusChange(option.value);
  };

  const handleResponsibleChange = (option: IOption) => {
    onResponsibleChange(option.value);
  };

  return (
    <>
      {component?.project?.isDeleted && (
        <div className="mb-20">
          <strong className="text-danger">
            {t('orders.deletedFilesInfo')}
          </strong>
        </div>
      )}
      <div className="basic-info border-bottom d-md-flex pb-60 mb-60">
        <div className="info mr-md-60 mb-32 mb-md-0">
          <div
            className={classNames('label-small', {
              'mb-4': !isMillRole,
              'mb-16': isMillRole,
            })}
          >
            {t('component.orderId')}
          </div>
          <div className="value">{component?.project?.id}</div>
        </div>
        <div className="info mr-md-60 mb-32 mb-md-0">
          <div
            className={classNames('label-small', {
              'mb-4': !isMillRole,
              'mb-16': isMillRole,
            })}
          >
            {t('component.project')}
          </div>
          <div className="value">{component?.project?.name}</div>
        </div>
        {isMillRole && component ? (
          <div className="info mr-md-60 mb-32 mb-md-0">
            <div className="d-flex flex-column flex-md-row w-100">
              <div className="select-status width-auto mb-24 mb-md-0 pr-32">
                <FormLabel>{t('orders.columns.responsible')}</FormLabel>
                <Select
                  options={responsibleOptions}
                  onChange={handleResponsibleChange}
                  defaultValue={
                    component &&
                    responsibleOptions.find(
                      (s) => s.value === component.project?.responsible?.id
                    )
                  }
                  size="sm"
                />
              </div>
              <div className="select-wrapper mb-24 mb-md-0 mr-md-32 border-left pl-32">
                <FormLabel>{t('component.status.label')}</FormLabel>
                <div className="select-status">
                  <Select
                    options={statusOptions}
                    onChange={handleStatusChange}
                    value={
                      component &&
                      statusOptions.find((s) => s.value === component.status)
                    }
                    size="sm"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="info mr-md-32 mb-32 mb-md-0">
              <div className="label-small mb-4">
                {t('orders.columns.responsible')}
              </div>
              <div className="value">
                {component?.project?.responsible && (
                  <FullName author={component?.project?.responsible} />
                )}
              </div>
            </div>
            <div className="info mr-md-32 mb-32 mb-md-0 border-left pl-32">
              <div className="label-small mb-4">
                {t('component.status.label')}
              </div>
              <div className="value">
                {component?.status && capitalize(component?.status)}
              </div>
            </div>
          </>
        )}
        {isMillRole && component?.project?.author && (
          <div className="info align-self-center ml-auto d-flex align-items-center">
            <Link to="#" onClick={onUserClick} className="color-gray-dark">
              <span className="mr-24">
                <CompanyLogo logo={component?.project?.author?.company?.logo} />
              </span>
              {component && (
                <span>
                  <FullName author={component?.project?.author} />
                </span>
              )}
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
