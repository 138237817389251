import React from 'react';

export const UploadIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.28613 5C4.28613 4.30964 4.84578 3.75 5.53613 3.75H19.5361C20.2265 3.75 20.7861 4.30964 20.7861 5V17C20.7861 17.6904 20.2265 18.25 19.5361 18.25H17.5361C17.1219 18.25 16.7861 18.5858 16.7861 19C16.7861 19.4142 17.1219 19.75 17.5361 19.75H19.5361C21.0549 19.75 22.2861 18.5188 22.2861 17V5C22.2861 3.48122 21.0549 2.25 19.5361 2.25H5.53613C4.01735 2.25 2.78613 3.48122 2.78613 5V17C2.78613 18.5188 4.01735 19.75 5.53613 19.75H7.53613C7.95035 19.75 8.28613 19.4142 8.28613 19C8.28613 18.5858 7.95035 18.25 7.53613 18.25H5.53613C4.84578 18.25 4.28613 17.6904 4.28613 17V5Z"
      fill="white"
    />
    <path
      d="M13.0665 8.46967C12.7736 8.17678 12.2987 8.17678 12.0058 8.46967L8.0058 12.4697C7.71291 12.7626 7.71291 13.2374 8.0058 13.5303C8.2987 13.8232 8.77357 13.8232 9.06646 13.5303L11.7861 10.8107V21.25C11.7861 21.6642 12.1219 22 12.5361 22C12.9503 22 13.2861 21.6642 13.2861 21.25V10.8107L16.0058 13.5303C16.2987 13.8232 16.7736 13.8232 17.0665 13.5303C17.3594 13.2374 17.3594 12.7626 17.0665 12.4697L13.0665 8.46967Z"
      fill="white"
    />
  </svg>
);
