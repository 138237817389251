import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOption } from '../components/Select';
import { IApiAction, IApiMeta, IApiState } from '../interfaces/api';
import { ITable } from '../interfaces/table';
import { IUser } from '../interfaces/user';
import { RootState } from '../store/store';
import {
  activationInstructionsThunk,
  addClientThunk,
  blockClientThunk,
  clientsThunk,
} from '../thunks/clients';
import {
  defaultTableState,
  filterSlice,
  paginationSlice,
  sortSlice,
} from '../utils/table';

export interface IAddClientState extends IApiState {
  user?: IUser;
}

export interface IClientsState extends ITable<IUser> {
  showAddClient: boolean;
  showActivation: boolean;
  addClient: IAddClientState;
  activationInstructions: string;
  selectedUserId?: number;
  blockedByMill?: boolean;
}

export const initialState: IClientsState = {
  showAddClient: false,
  showActivation: false,
  addClient: {},
  activationInstructions: '',
  selectedUserId: undefined,
  blockedByMill: false,
  ...defaultTableState(),
  sort: 'created_at:desc',
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    showAddClient(state) {
      state.showAddClient = true;
    },
    closeAddClient(state) {
      state.showAddClient = false;
      state.addClient = {};
    },
    showActivation(state) {
      state.showActivation = true;
    },
    closeActivation(state) {
      state.showActivation = false;
    },
    selectUser(state, action: PayloadAction<number>) {
      state.selectedUserId = action.payload;
    },
    deselectUser(state) {
      state.selectedUserId = undefined;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.sizePerPage = action.payload;
    },
    ...paginationSlice<IClientsState>(),
    ...sortSlice<IClientsState>(),
    ...filterSlice<IClientsState>(),
  },
  extraReducers: {
    [addClientThunk.pending.type]: (state, action: IApiAction<any>) => {
      state.addClient.user = undefined;
      state.addClient.apiStatus = action.meta.requestStatus;
      state.addClient.error = undefined;
    },
    [addClientThunk.rejected.type]: (state, action: IApiAction<any>) => {
      state.addClient.user = undefined;
      state.addClient.apiStatus = action.meta.requestStatus;
      state.addClient.error = action.payload.data;
    },
    [addClientThunk.fulfilled.type]: (state, action: IApiAction<any>) => {
      state.addClient.user = action.payload;
      state.addClient.apiStatus = action.meta.requestStatus;
      state.addClient.error = undefined;
    },
    [blockClientThunk.fulfilled.type]: (state, action: IApiAction<any>) => {
      const index = state.data.findIndex((u) => u.id === action.payload.userId);
      if (index !== -1) {
        state.data[index].blockedByMill = action.payload.blockedByMill;
      }
      state.addClient.error = undefined;
    },

    [clientsThunk.fulfilled.type]: (
      state,
      action: PayloadAction<ITable<IUser>, string, IApiMeta>
    ) => {
      state.count = action.payload.count;
      state.data = action.payload.data;
      state.apiStatus = action.meta.requestStatus;
    },
    [clientsThunk.pending.type]: (
      state,
      action: PayloadAction<ITable<IUser>, string, IApiMeta>
    ) => {
      state.apiStatus = action.meta.requestStatus;
    },
    [clientsThunk.rejected.type]: (
      state,
      action: PayloadAction<ITable<IUser>, string, IApiMeta>
    ) => {
      state.apiStatus = action.meta.requestStatus;
    },
    [activationInstructionsThunk.pending.type]: (state) => {
      state.activationInstructions = '';
    },
    [activationInstructionsThunk.rejected.type]: (state) => {
      state.activationInstructions = '';
    },
    [activationInstructionsThunk.fulfilled.type]: (
      state,
      action: IApiAction<any>
    ) => {
      state.activationInstructions = action.payload.message;
    },
  },
});

export const clientsSelector = (state: RootState) => state.clients;

export const clientOptionsSelector = (anyLabel: string) => {
  return createSelector(clientsSelector, (state: IClientsState) => {
    const options = state.data.map((client) => {
      return {
        value: client.id,
        label: `${client.firstName} ${client.lastName}`,
      } as IOption;
    });
    options.unshift({ value: '', label: anyLabel } as IOption);
    return options;
  });
};
