import sanitizeHtml from 'sanitize-html';

export function capitalize(str: string) {
  if (!str) return str;
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function formatOrderId(id?: number) {
  if (!id) return '';
  return id?.toString().padStart(8, '0');
}

export function simpleFormat(text: string) {
  const txt = sanitizeHtml(text);
  return txt
    .replace(/(?:\r\n|\r|\n)/g, '<br/>')
    .replace(/\s\s/g, '&nbsp;&nbsp;');
}
