import React from 'react';
import * as yup from 'yup';

import { Signin } from './Signin';
import { signinSlice, forgotSlice, signupSlice } from '../../../slices';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ISigninPayload, loginThunk } from '../../../thunks/auth';
import { useQuery } from '../../../hooks/query';

export const signInSchema: yup.SchemaOf<ISigninPayload> = yup.object().shape({
  identifier: yup.string().required().email(),
  password: yup.string().required(),
  linkToAnExistingAccount: yup.boolean().optional(),
  invitedMillId: yup.string().optional(),
  confirmationToken: yup.string().optional(),
  invitingMillCenter: yup.string().optional(),
  companyName: yup.string().optional(),
});

export const SigninContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();

  const confirmationToken = query.get('confirmation') || '';

  const { error } = useAppSelector((state) => state.auth);
  const {
    show,
    showSignUpLink,
    linkToExistingAccount,
    invitedMillId,
  } = useAppSelector((state) => state.signin);

  const handleSubmit = (values: ISigninPayload) => {
    dispatch(loginThunk(values));
  };

  const handleSignup = () => {
    dispatch(signinSlice.actions.close());
    dispatch(signupSlice.actions.show());
  };

  const handleReset = () => {
    dispatch(signinSlice.actions.close());
    dispatch(forgotSlice.actions.show());
  };

  return (
    <Signin
      show={show}
      showSignUpLink={showSignUpLink}
      onClose={signinSlice.actions.close}
      onSubmit={handleSubmit}
      onSignup={handleSignup}
      onReset={handleReset}
      validationSchema={signInSchema}
      initialValues={
        {
          identifier: '',
          password: '',
          linkToAnExistingAccount: linkToExistingAccount,
          invitedMillId,
          confirmationToken,
        } as ISigninPayload
      }
      error={error}
    />
  );
};
