import React from 'react';

import { IUser } from '../../interfaces/user';

interface IFullNameProps {
  author?: IUser;
}

export const FullName = ({ author = undefined }: IFullNameProps) => {
  return <>{author ? `${author.firstName} ${author.lastName}` : ''}</>;
};

FullName.defaultProps = {
  author: undefined,
};
