import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';

import { IComponent } from 'interfaces/component';
import i18n from 'translations';

import placeholder from 'assets/images/placeholder.svg';
import { capitalize } from 'utils/format';
import { placeholderImage } from 'utils/image';

export const componentsColumns: ColumnDescription<any, IComponent>[] = [
  {
    dataField: 'image',
    text: '',
    sort: false,
    style: {
      width: '180px',
      textAlign: 'center',
    },
    formatter: (cell, row: IComponent) => {
      return (
        <img
          src={placeholderImage(row.image, placeholder)}
          alt=""
          className="component-image"
        />
      );
    },
  },
  {
    dataField: 'name',
    text: i18n.t('orders.component.columns.name'),
    sort: true,
    formatter: (cell, row: IComponent) => {
      return (
        <>
          {row.name}
          <div>
            <small>{row.displayName}</small>
          </div>
        </>
      );
    },
    style: {
      width: '400px',
    },
  },
  {
    dataField: 'status',
    text: i18n.t('orders.component.columns.status'),
    sort: false,
    formatter: (cell, row: IComponent) => {
      return capitalize(row.status);
    },
    style: {
      width: '200px',
    },
  },
  {
    dataField: 'messages',
    text: i18n.t('orders.component.columns.messages'),
    sort: false,
    formatter: (cell, row: IComponent) => {
      const msg = '-';
      if (row.newMessages && row.newMessages > 0) {
        return (
          <div className="new-message">
            {row.messages.length}{' '}
            {row.messages.length === 1
              ? i18n.t('orders.message')
              : i18n.t('orders.messages')}{' '}
            ({row.newMessages} {i18n.t('orders.newMessage')})
          </div>
        );
        // eslint-disable-next-line no-else-return
      } else if (row.messages.length > 0) {
        return `${row.messages.length} ${
          row.messages.length === 1
            ? i18n.t('orders.message')
            : i18n.t('orders.messages')
        }`;
      }
      return msg;
    },
  },

  {
    dataField: 'comment',
    text: i18n.t('orders.component.columns.comment'),
    sort: true,
    style: {
      width: '300px',
    },
  },

  {
    dataField: 'documents',
    text: i18n.t('orders.component.columns.files'),
    sort: false,
    formatter: (cell, row: IComponent) => {
      return row.documents.length;
    },
    style: {
      width: '216px',
    },
  },
];
