import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addLicenseKeyThunk,
  deleteLicenseKeyThunk,
  editLicenseKeyThunk,
  licenseKeyThunk,
} from 'thunks/license-keys';
import { IApiAction, IApiState, IApiMeta } from '../interfaces/api';
import { ITable } from '../interfaces/table';
import { ILicenseKey } from '../interfaces/user';

import {
  defaultTableState,
  filterSlice,
  paginationSlice,
  sortSlice,
} from '../utils/table';

export interface IAddLicenseKeyState extends IApiState {
  licenseKey?: ILicenseKey;
}

export interface ILicenseKeyState extends ITable<ILicenseKey> {
  showAddLicenseKey: boolean;
  addLicenseKey: IAddLicenseKeyState;
  selectedLicenseKeyId?: number;
}

export const initialState: ILicenseKeyState = {
  showAddLicenseKey: false,
  addLicenseKey: {},
  selectedLicenseKeyId: undefined,
  ...defaultTableState(),
  sort: 'licenseKey:asc',
};

export const licenseKeySlice = createSlice({
  name: 'licenseKey',
  initialState,
  reducers: {
    showAddLicenseKey(state) {
      state.showAddLicenseKey = true;
    },
    closeAddLicenseKey(state) {
      state.showAddLicenseKey = false;
      state.addLicenseKey = {};
    },
    selectLicenseKey(state, action: PayloadAction<number>) {
      state.selectedLicenseKeyId = action.payload;
    },
    deselecLicenseKey(state) {
      state.selectedLicenseKeyId = undefined;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.sizePerPage = action.payload;
    },
    ...paginationSlice<ILicenseKeyState>(),
    ...sortSlice<ILicenseKeyState>(),
    ...filterSlice<ILicenseKeyState>(),
  },
  extraReducers: {
    [addLicenseKeyThunk.pending.type]: (state, action: IApiAction<any>) => {
      state.addLicenseKey.licenseKey = undefined;
      state.addLicenseKey.apiStatus = action.meta.requestStatus;
      state.addLicenseKey.error = undefined;
    },
    [addLicenseKeyThunk.rejected.type]: (state, action: IApiAction<any>) => {
      state.addLicenseKey.licenseKey = undefined;
      state.addLicenseKey.apiStatus = action.meta.requestStatus;
      state.addLicenseKey.error = action.payload.data;
    },
    [addLicenseKeyThunk.fulfilled.type]: (state, action: IApiAction<any>) => {
      state.addLicenseKey.licenseKey = action.payload;
      state.addLicenseKey.apiStatus = action.meta.requestStatus;
      state.data = [action.payload, ...state.data];
      state.addLicenseKey.error = undefined;
    },
    [editLicenseKeyThunk.pending.type]: (state, action: IApiAction<any>) => {
      state.addLicenseKey.apiStatus = action.meta.requestStatus;
      state.addLicenseKey.error = undefined;
    },
    [editLicenseKeyThunk.rejected.type]: (state, action: IApiAction<any>) => {
      state.addLicenseKey.apiStatus = action.meta.requestStatus;
      state.addLicenseKey.error = action.payload.data;
    },
    [deleteLicenseKeyThunk.fulfilled.type]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
    },
    [editLicenseKeyThunk.fulfilled.type]: (state, action: IApiAction<any>) => {
      const index = state.data.findIndex(
        (u) => u.id === action.payload.licenseKey.id
      );
      if (index !== -1) {
        state.data[index] = action.payload.licenseKey;
      }
      state.addLicenseKey.apiStatus = action.meta.requestStatus;
      state.addLicenseKey.error = undefined;
    },
    [licenseKeyThunk.fulfilled.type]: (
      state,
      action: PayloadAction<ITable<ILicenseKey>, string, IApiMeta>
    ) => {
      state.count = action.payload.count;
      state.data = action.payload.data;
      state.apiStatus = action.meta.requestStatus;
    },
  },
});
