import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ILicenseKey } from 'interfaces/user';
import i18n from 'translations';

export const columns: ColumnDescription<any, ILicenseKey>[] = [
  {
    dataField: 'avatar',
    text: '',
    sort: false,
    style: {
      width: '72px',
      height: '72px',
    },
  },
  {
    dataField: 'licenseKey',
    text: i18n.t('settings.licenseKey.columns.licenseKey'),
    formatter: (cell, row: ILicenseKey) => {
      return (
        <div className="d-flex align-items-center">
          <span>{`${row.licenseKey}`}</span>
        </div>
      );
    },
    sort: true,
  },
  {
    dataField: 'switch',
    text: i18n.t('settings.licenseKey.columns.enableCollection'),
    isDummyField: true,
  },
  {
    dataField: 'actions',
    text: '',
    isDummyField: true,
  },
];
