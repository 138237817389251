import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SELECTED_MILL, TAB_FORM_IS_DIRTY } from 'constants/constants';
import { previewLandingPageSlice } from 'slices/preview';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useLocation } from 'react-router-dom';
import { millThunk } from 'thunks/mill';
import {
  ILandingPage,
  updateCompanyLandingPageDetailsThunk,
} from 'thunks/settings';
import { getLocalStorageItem, getSessionStorageItem } from 'utils/localStorage';
import { toastSlice } from 'slices';
import { Spinner2 } from 'components';
import { LandingPageForm } from './LandingPageForm';

const schema: yup.SchemaOf<ILandingPage> = yup.object().shape({
  id: yup.number().required(),
  logo: yup.object().optional(),
  files: yup.array().optional(),
  landingPageHeader: yup.string().required(),
  landingPageContent: yup.string().required(),
  landingPageInfoIsUpdated: yup.boolean().optional(),
});

export const LandingPageContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const error = useAppSelector((state) => state.company.error);
  const { id, landingPageHeader, landingPageContent, company } = useAppSelector(
    (state) => state.mill.data
  );
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  const logo = company?.logo;

  React.useEffect(() => {
    const millId = getLocalStorageItem(SELECTED_MILL);
    dispatch(millThunk(millId));
  }, []);

  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);

  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleSubmit = async (values: ILandingPage) => {
    try {
      setIsProcessing(true);
      await dispatch(
        updateCompanyLandingPageDetailsThunk({
          ...values,
          landingPageInfoIsUpdated: true,
        })
      );
      setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
      dispatch(
        toastSlice.actions.show({
          title: t('settings.company.error.title'),
          message: t('csettings.company.error.message'),
        })
      );
    }
  };

  const showLandingPagePreview = () => {
    dispatch(previewLandingPageSlice.actions.show());
  };

  return (
    <>
      <p className="mt-36 mb-46">{t('settings.landingPage.intro')}</p>
      <Spinner2 show={isProcessing} />
      <LandingPageForm
        initialValues={
          {
            id: id || '',
            landingPageHeader: landingPageHeader || '',
            landingPageContent: landingPageContent || '',
            logo: logo || {},
          } as ILandingPage
        }
        validationSchema={schema}
        onSubmit={handleSubmit}
        error={error}
        showLandingPagePreview={showLandingPagePreview}
      />
    </>
  );
};
