import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { UserLayout } from '../../components/Layout';
import { Button } from '../../components';
import { OpenIcon } from '../../icons';

import createLangingPage from '../../assets/images/create-landing-page.svg';
import inviteClient from '../../assets/images/invite-client.svg';
import {
  MILL_CLIENTS_ROUTE,
  MILL_SETTINGS_LANDING_PAGE_ROUTE,
} from '../../constants/routes';

export const Welcome: React.FC = () => {
  const { t } = useTranslation();
  return (
    <UserLayout mainClasses="page page-light page-welcome">
      <div className="py-60 py-md-120">
        <Container fluid>
          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <h1 className="fs-34 mb-10">{t('welcome.title')}</h1>
              <p className="fs-20 mb-114">{t('welcome.content')}</p>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Row>
                <Col md={5}>
                  <img
                    src={createLangingPage}
                    alt={t('welcome.landingPage.title')}
                    className="mb-26"
                  />
                  <h3 className="mb-10 fs-24">
                    {t('welcome.landingPage.title')}
                  </h3>
                  <p className="fs-16 mb-46">
                    {t('welcome.landingPage.content')}
                  </p>
                  <Button
                    icon={<OpenIcon />}
                    href={MILL_SETTINGS_LANDING_PAGE_ROUTE}
                  >
                    {t('welcome.landingPage.button')}
                  </Button>
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <img
                    src={inviteClient}
                    alt={t('welcome.inviteClients.title')}
                    className="mb-26"
                  />
                  <h3 className="mb-10 fs-24">
                    {t('welcome.inviteClients.title')}
                  </h3>
                  <p className="fs-16 mb-46">
                    {t('welcome.inviteClients.content')}
                  </p>
                  <Button icon={<OpenIcon />} href={MILL_CLIENTS_ROUTE}>
                    {t('welcome.inviteClients.button')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </UserLayout>
  );
};
