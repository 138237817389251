import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { SupportIcon } from 'icons';
import { Button, Switch } from 'components';
import { Modal } from 'components/Modal';
import { ILicenseKeyPayload } from 'thunks/license-keys';
import { isErrorString } from 'utils/handle-api-error';

interface IClientFormProps {
  show: boolean;
  initialValues: ILicenseKeyPayload;
  validationSchema: any | (() => any);
  error?: any;
  onClose: () => any;
  onSubmit: (
    values: ILicenseKeyPayload,
    form: FormikHelpers<ILicenseKeyPayload>
  ) => void | Promise<any>;
}

export const LicenseKeysForm = ({
  show,
  initialValues,
  validationSchema,
  error = undefined,
  onClose,
  onSubmit,
}: IClientFormProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('settings.licenseKey.add')}
      show={show}
      onClose={onClose}
      dialogClassName="license-dialog"
      closeOnOutSideClick={false}
    >
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
          dirty,
        }) => {
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit} data-cy="license-form">
                <Form.Group controlId="licenseKey">
                  <Form.Label>
                    {t('settings.licenseKey.columns.licenseKey')}
                  </Form.Label>
                  <Form.Control
                    name="licenseKey"
                    placeholder={t('settings.licenseKey.columns.placeholder')}
                    value={values.licenseKey}
                    onChange={handleChange}
                    data-cy="license-form-licenseKey"
                    isInvalid={touched.licenseKey && !!errors.licenseKey}
                  />
                  <SupportIcon />
                </Form.Group>
                <Form.Group controlId="enableDataCollection">
                  <Form.Label>
                    {t('settings.licenseKey.columns.enableCollection')}
                  </Form.Label>
                  <Switch
                    key={values.id}
                    label={' '}
                    id=""
                    data-cy={`check-${values.id}`}
                    onChange={() =>
                      setFieldValue(
                        'enableDataCollection',
                        !values.enableDataCollection
                      )
                    }
                    defaultChecked={!!values.enableDataCollection}
                    className="mb-40"
                  />
                </Form.Group>
                <Button dimension="lg" full type="submit">
                  {t('settings.licenseKey.add')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

LicenseKeysForm.defaultProps = {
  error: undefined,
};
