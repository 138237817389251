import React, { useState, useEffect } from 'react';
import NoDataFound from 'components/NoDataFound';
import { Spinner2 } from 'components/Spinner2';
import Api from 'api';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import BootstrapTable from 'react-bootstrap-table-next';
import { Row, Col, FormLabel, Button } from 'react-bootstrap';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getPageSize } from '../Settings';
import { columns } from './columns';

interface IDateObject {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export const UserActivityView: React.FC = () => {
  const [state, setState] = useState({ data: [] });

  const [pageSize] = useState<number>(+getPageSize());
  const [dateObject, setDateObject] = useState<IDateObject>({
    startDate: moment().add(-7, 'days'),
    endDate: moment(),
  });
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const { data } = state;

  const getPageUrl = () => {
    return `/v1/analytics/project/activity`;
  };

  const fetchData = () => {
    setIsLoading(true);
    const dateObj = {
      start: moment(dateObject.startDate).format('YYYY-MM-DD'),
      end: moment(dateObject.endDate).format('YYYY-MM-DD'),
    };
    Api.post(getPageUrl(), dateObj)
      .then((response) => {
        setState({ data: response });
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlendDatesChange = (arg: IDateObject) => {
    if (!arg.startDate && !arg.endDate) return;
    setDateObject(arg);
    fetchData();
  };

  const resetDate = () => {
    setDateObject({ startDate: moment().add(-7, 'days'), endDate: moment() });
    fetchData();
  };
  const handleFocusChange = (arg: FocusedInputShape | null) => {
    setFocusedInput(arg);
  };

  return (
    <ManagerLayout>
      <h1 className="ml-2 mb-4 mt-3">Millingcenters Activity Overview</h1>
      <Row className="mt-1">
        <Col md={7} />
        <Col md={5}>
          <div className="select-wrapper mb-24 mb-md-0">
            <FormLabel>Select Interval</FormLabel>
            <DateRangePicker
              isOutsideRange={() => false}
              startDate={dateObject.startDate}
              startDateId="start_date_id"
              endDate={dateObject.endDate}
              endDateId="end_date_id"
              onDatesChange={handlendDatesChange}
              focusedInput={focusedInput}
              onFocusChange={handleFocusChange}
            />
            <Button
              disabled={!(dateObject?.startDate || dateObject?.endDate)}
              className="m-2 reset-button"
              onClick={resetDate}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
      <hr />
      {!isLoading && data.length > 0 && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          sort={{ dataField: 'createdAt', order: 'asc' }}
          pagination={paginationFactory({
            sizePerPage: pageSize,
            hidePageListOnlyOnePage: true,
          })}
          bordered={false}
        />
      )}
      {!isLoading && data.length === 0 && <NoDataFound />}
      <Spinner2 show={isLoading} />
    </ManagerLayout>
  );
};
