import { createAsyncThunk } from '@reduxjs/toolkit';
import { IMill } from 'interfaces/mill';
import Api from 'api';
import { clientsSlice, toastSlice } from 'slices';
import { confirmationSlice, IConfirmation } from 'slices/confirmation';
import { RootState } from 'store/store';
import { tableQuery } from 'utils/table';
import i18n from 'translations';
import { getSelectedMillId } from 'utils/common';
import { MILL_SETTINGS_LANDING_PAGE_ROUTE } from 'constants/routes';

export const CONFIRMATION_ACTIONS = {
  DELETE_CLIENT: 'DELETE_CLIENT',
  LANDING_PAGE: 'LANDING_PAGE_UPDATE',
  BLOCKED_CLIENT: 'BLOCKED_CLIENT',
};
export interface IClientPayload {
  firstName: string;
  lastName: string;
  email: string;
}
export interface IBlockClientPayload {
  userId: number;
  blockedByMill: boolean;
}
export interface IActivationPayload {
  message: string;
  landingPageInfoIsUpdated?: boolean;
}

export const clientsThunk = createAsyncThunk(
  'clients',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const query = tableQuery(state.clients);
    const millId = getSelectedMillId(state.mill.data as IMill);

    return Api.get(`/mills/${millId}/clients?${query}`);
  }
);

export const activationInstructionsThunk = createAsyncThunk(
  'clients/activation-instructions',
  async (id: number, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.get(
        `/mills/${millId}/clients/activation-instructions/${id}`
      );

      dispatch(clientsSlice.actions.selectUser(id));

      dispatch(clientsThunk());

      dispatch(clientsSlice.actions.showActivation());

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendActivationInstructionsThunk = createAsyncThunk(
  'clients/activation-instructions',
  async (
    payload: IActivationPayload,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.post(
        `/mills/${millId}/clients/send-activation-instructions/${state.clients.selectedUserId}`,
        payload
      );

      dispatch(clientsSlice.actions.closeActivation());

      if (payload.landingPageInfoIsUpdated !== true) {
        dispatch(
          confirmationSlice.actions.show({
            title: i18n.t('settings.landingPage.notify.title'),
            message: i18n.t('settings.landingPage.notify.message'),
            buttonOk: i18n.t('settings.landingPage.notify.okText'),
            buttonCancel: i18n.t('settings.landingPage.notify.cancelText'),
            redirectUrl: MILL_SETTINGS_LANDING_PAGE_ROUTE,
            actionKey: CONFIRMATION_ACTIONS.LANDING_PAGE,
          })
        );
      } else {
        dispatch(
          confirmationSlice.actions.show({
            title: i18n.t('clients.activation.confirm.title'),
            message: i18n.t('clients.activation.confirm.message'),
            buttonOk: i18n.t('clients.activation.confirm.buttonOk'),
          } as IConfirmation)
        );
      }

      return response;
    } catch (err) {
      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('clients.activation.errorConfirm.title'),
          message: err.response.data.message,
          buttonOk: i18n.t('clients.activation.errorConfirm.buttonOk'),
        } as IConfirmation)
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const addClientThunk = createAsyncThunk(
  'clients/add-client',
  async (payload: IClientPayload, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.post(`/mills/${millId}/clients`, payload);

      const { user } = response;
      if (user.confirmed) {
        dispatch(clientsThunk());
        dispatch(
          confirmationSlice.actions.show({
            title: i18n.t('clients.millingcenterClientAdded.title'),
            message: i18n.t('clients.millingcenterClientAdded.message', {
              millClient: `${user.firstName} ${user.lastName}`,
            }),
            buttonOk: i18n.t('clients.millingcenterClientAdded.buttonOk'),
          } as IConfirmation)
        );
      } else {
        dispatch(activationInstructionsThunk(user.id));
      }

      dispatch(clientsSlice.actions.closeAddClient());

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteClientThunk = createAsyncThunk(
  'clients/delete',
  async (id: number, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.delete(`/mills/${millId}/clients/${id}`);

      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('clients.deleteToast.title'),
          message: i18n.t('clients.deleteToast.message'),
        })
      );

      dispatch(clientsThunk());

      return response;
    } catch (err) {
      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('clients.deleteToast.title'),
          message: err.response.data.message.errors,
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const blockClientThunk = createAsyncThunk(
  'clients/block',
  async (
    payload: IBlockClientPayload,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const millId = getSelectedMillId(state.mill.data as IMill);
      const response = await Api.post(
        `/mill/${millId}/updateLabBlockStatus`,
        payload
      );

      if (response.blockedByMill) {
        dispatch(
          toastSlice.actions.show({
            title: i18n.t('clients.blockToast.title'),
            message: i18n.t('clients.blockToast.message'),
          })
        );
      } else {
        dispatch(
          toastSlice.actions.show({
            title: i18n.t('clients.unblockToast.title'),
            message: i18n.t('clients.unblockToast.message'),
          })
        );
      }
      return response;
    } catch (err) {
      dispatch(confirmationSlice.actions.close());

      dispatch(
        toastSlice.actions.show({
          title: i18n.t('clients.blockToast.title'),
          message: err.response.data.message.errors,
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);
