import { IImage } from './image';
import { IOrder } from './order';
import { IUser } from './user';

export const COMPONENT_STATUS_OPEN = 'Open';
export const COMPONENT_STATUS_CLOSED = 'Closed';
export const COMPONENT_STATUS_DOWNLOADED = 'Downloaded';

export type ComponentStatus =
  | typeof COMPONENT_STATUS_OPEN
  | typeof COMPONENT_STATUS_CLOSED
  | typeof COMPONENT_STATUS_DOWNLOADED;

export interface IMessage {
  id: number;
  message: string;
  created_at: string;
  author?: IUser;
  read?: boolean;
  companyName: string;
}

export interface IComponent {
  id: number;
  name: string;
  created_at: string;
  messages: IMessage[];
  project?: IOrder;
  projectId?: number;
  newMessages?: number;
  documents: any[];
  downloads: any[];
  status: ComponentStatus;
  author: IUser;
  image?: IImage;
  filesUpdateStatus?: string;
  comment?: string;
  displayName?: string;
}
