import React from 'react';
import { Form } from 'react-bootstrap';
import { Link, Prompt } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { EmailIcon, PasswordIcon } from '../../../icons';
import { Button } from '../../Button';
import { Modal } from '../../Modal/Modal';
import { ISigninPayload } from '../../../thunks/auth';
import { isErrorString } from '../../../utils/handle-api-error';

interface ISigninProps {
  show: boolean;
  showSignUpLink: boolean;
  initialValues: ISigninPayload;
  validationSchema: any | (() => any);
  error?: any;
  onClose: () => any;
  onSubmit: (
    values: ISigninPayload,
    form: FormikHelpers<ISigninPayload>
  ) => void | Promise<any>;
  onSignup: () => void;
  onReset: () => void;
}

export const Signin: React.FC<ISigninProps> = ({
  show,
  showSignUpLink,
  initialValues,
  validationSchema,
  error = undefined,
  onClose,
  onSubmit,
  onSignup,
  onReset,
}: ISigninProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('signIn.title')}
      show={show}
      onClose={onClose}
      closeOnOutSideClick={false}
    >
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        data-cy="signin-form"
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit} data-cy="signin-form">
                <Form.Group controlId="identifier">
                  <Form.Label>{t('email.label')}</Form.Label>
                  <Form.Control
                    type="email"
                    name="identifier"
                    data-cy="si-identifier"
                    placeholder={t('email.placeholder')}
                    value={values.identifier}
                    onChange={handleChange}
                    isInvalid={touched.identifier && !!errors.identifier}
                  />
                  <EmailIcon />
                </Form.Group>

                <Form.Group className="mb-0" controlId="password">
                  <Form.Label>{t('password.label')}</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    data-cy="si-password"
                    placeholder={t('password.placeholder')}
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <PasswordIcon />
                </Form.Group>

                <p className="form-link mt-10 mb-38">
                  <Link to="#" onClick={onReset}>
                    {t('forgot.password')}
                  </Link>
                </p>

                <Button data-cy="si-submit" dimension="lg" full type="submit">
                  {t('signIn.title')}
                </Button>

                {showSignUpLink && (
                  <p className="form-link mt-20 mb-0">
                    {t('signIn.dontHaveAccount')}{' '}
                    <Link to="#" onClick={onSignup}>
                      {t('signUp.title')}
                    </Link>
                  </p>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

Signin.defaultProps = {
  error: undefined,
};
