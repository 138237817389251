import { ColumnDescription } from 'react-bootstrap-table-next';
import { IUser } from 'interfaces/user';
import i18n from 'translations';

export const columns: ColumnDescription<any, IUser>[] = [
  {
    dataField: 'name',
    text: i18n.t('contract.tabs.usageDetail.resourceName'),
    sort: true,
  },
  {
    dataField: 'used',
    text: i18n.t('contract.tabs.usageDetail.used'),
    sort: true,
  },
  {
    dataField: 'limit',
    text: i18n.t('contract.tabs.usageDetail.limit'),
    sort: true,
  },
  {
    dataField: 'percentage',
    text: i18n.t('contract.tabs.usageDetail.usagePercentage'),
    sort: true,
    formatter: (cell: any, row: IUser) => {
      return row.percentage ? `${+row.percentage.toFixed(2)} %` : `${0} %`;
    },
  },
];
