import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { ILabContact } from 'thunks/support';
import { isErrorString } from 'utils/handle-api-error';
import { Prompt } from 'react-router-dom';
import { setSessionStorage } from 'utils/localStorage';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';

interface IContactFormProps {
  initialValues: ILabContact;
  validationSchema: any | (() => any);
  onSubmit: (
    values: ILabContact,
    form: FormikHelpers<ILabContact>
  ) => void | Promise<any>;
  error?: any;
}

export const ContactForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  error = undefined,
}: IContactFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}

      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
          setSessionStorage(TAB_FORM_IS_DIRTY, `${dirty}`);
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit} className="form-lab-contact">
                <h4 className="mb-26">{t('support.contact.title')}</h4>

                <Form.Group controlId="topic">
                  <Form.Label>{t('support.contact.topic.label')}</Form.Label>
                  <Form.Control
                    name="topic"
                    placeholder={t('support.contact.topic.placeholder')}
                    value={values.topic}
                    onChange={handleChange}
                    isInvalid={touched.topic && !!errors.topic}
                  />
                </Form.Group>

                <Form.Group controlId="message" className="mb-72">
                  <Form.Label>{t('support.contact.message.label')}</Form.Label>
                  <Form.Control
                    name="message"
                    placeholder={t('clients.activation.message.placeholder')}
                    value={values.message}
                    onChange={handleChange}
                    as="textarea"
                    rows={8}
                    isInvalid={touched.message && !!errors.message}
                  />
                </Form.Group>

                <Button dimension="md" type="submit" className="mb-72">
                  {t('support.contact.submit')}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

ContactForm.defaultProps = {
  error: undefined,
};
