import React from 'react';
import { IIcon } from './Icon';

export const OpenIcon = ({ color = '#ffffff' }: IIcon) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.125 3.875C18.8154 3.875 19.375 4.43464 19.375 5.125L19.375 19.125C19.375 19.8154 18.8154 20.375 18.125 20.375L6.125 20.375C5.43464 20.375 4.875 19.8154 4.875 19.125L4.875 17.125C4.875 16.7108 4.53921 16.375 4.125 16.375C3.71079 16.375 3.375 16.7108 3.375 17.125L3.375 19.125C3.375 20.6438 4.60622 21.875 6.125 21.875L18.125 21.875C19.6438 21.875 20.875 20.6438 20.875 19.125L20.875 5.125C20.875 3.60622 19.6438 2.375 18.125 2.375L6.125 2.375C4.60622 2.375 3.375 3.60622 3.375 5.125L3.375 7.125C3.375 7.53921 3.71079 7.875 4.125 7.875C4.53921 7.875 4.875 7.53921 4.875 7.125L4.875 5.125C4.875 4.43464 5.43464 3.875 6.125 3.875L18.125 3.875Z"
      fill={color}
    />
    <path
      d="M14.6553 12.6553C14.9482 12.3624 14.9482 11.8876 14.6553 11.5947L10.6553 7.59467C10.3624 7.30178 9.88756 7.30178 9.59467 7.59467C9.30178 7.88756 9.30178 8.36244 9.59467 8.65533L12.3143 11.375L1.875 11.375C1.46079 11.375 1.125 11.7108 1.125 12.125C1.125 12.5392 1.46079 12.875 1.875 12.875L12.3143 12.875L9.59467 15.5947C9.30178 15.8876 9.30178 16.3624 9.59467 16.6553C9.88756 16.9482 10.3624 16.9482 10.6553 16.6553L14.6553 12.6553Z"
      fill={color}
    />
  </svg>
);
