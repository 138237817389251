import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { IMessageItem } from '../../../interfaces/message';
import {
  LAB_COMPONENT_ROUTE,
  MESSAGES_PARTIAL_ROUTE,
  MILL_COMPONENT_ROUTE,
} from '../../../constants/routes';

import placeholder from '../../../assets/images/placeholder.svg';
import { AvatarImage } from '../../../components/User';

interface IRoleMessageItemProps {
  item: IMessageItem;
}

interface IMessageItemProps extends IRoleMessageItemProps {
  isMillRole: boolean;
}

const LabMessageItem = ({ item }: IRoleMessageItemProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="image mr-0 mr-md-24">
        <img src={placeholder} alt="" className="img-fluid" />
      </div>
      <div className="heading d-flex flex-column align-self-md-center mr-24 my-24 my-md-0">
        <div className="title word-break">{item.component.name}</div>
        <div className="subtitle fs-14 lh-20 color-gray">
          <span className="mr-12 word-break">{item.component.displayName}</span>
          <span className="">#{item.project.id}</span>
        </div>
      </div>
      <div className="content">
        <div className="intro">
          {`${item.message.author?.firstName} ${item.message.author?.lastName}`}
          {', '}
          {moment(item.message.created_at).format(t('formats.date.long'))}
        </div>
        <div className="message">{item.message.message}</div>
      </div>
    </>
  );
};

const MillMessageItem = ({ item }: IRoleMessageItemProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="image mr-0 mr-md-24">
        <AvatarImage user={item.message.author} />
      </div>
      <div className="heading d-flex flex-column align-self-md-center mr-24 my-24 my-md-0">
        <div className="title">{`${item.message.author?.firstName} ${item.message.author?.lastName}`}</div>
        <div className="subtitle fs-14 lh-20 color-gray">
          <span className="mr-12 word-break">{item.component.name}</span>
          <div>
            <small>{item.component.displayName}</small>
          </div>
          <span className="">#{item.project.id}</span>
        </div>
      </div>
      <div className="content">
        <div className="intro">
          {`${item.message.companyName}`}
          {', '}
          {moment(item.message.created_at).format(t('formats.date.long'))}
        </div>
        <div className="message">{item.message.message}</div>
      </div>
    </>
  );
};

export const MessageItem = ({ item, isMillRole }: IMessageItemProps) => {
  const history = useHistory();

  const handleMessageItemClick = () => {
    const params = {
      projectId: item.project.id,
      id: item.component.id,
    };

    const link = isMillRole
      ? generatePath(MILL_COMPONENT_ROUTE, params)
      : generatePath(LAB_COMPONENT_ROUTE, params);

    history.push(link, { from: MESSAGES_PARTIAL_ROUTE });
  };

  return (
    <li
      className={classNames('messages-list-item', {
        unread: !item.message.read,
      })}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={handleMessageItemClick}
        onKeyDown={handleMessageItemClick}
        className="link p-24 d-flex flex-column flex-md-row"
      >
        {isMillRole ? (
          <MillMessageItem item={item} />
        ) : (
          <LabMessageItem item={item} />
        )}
      </div>
    </li>
  );
};
