import React from 'react';
import { Form } from 'react-bootstrap';
import { Link, Prompt } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { EmailIcon } from '../../../icons';
import { Button } from '../../Button';
import { Modal } from '../../Modal/Modal';
import { IForgotPayload } from '../../../thunks/auth';
import {
  hasError,
  isErrorString,
  showError,
} from '../../../utils/handle-api-error';

interface IForgotProps {
  show: boolean;
  initialValues: IForgotPayload;
  validationSchema: any | (() => any);
  error?: any;
  onClose: () => any;
  onSubmit: (
    values: IForgotPayload,
    form: FormikHelpers<IForgotPayload>
  ) => void | Promise<any>;
  onSignin: () => void;
}

export const Forgot = ({
  show,
  initialValues,
  validationSchema,
  error = undefined,
  onClose,
  onSubmit,
  onSignin,
}: IForgotProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('forgot.title')} show={show} onClose={onClose}>
      {isErrorString(error) && (
        <div className="alert alert-error">{error.errors}</div>
      )}
      <p className="mb-40 text-center">{t('forgot.info')}</p>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => {
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email" className="mb-50">
                  <Form.Label>{t('email.label')}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder={t('email.placeholder')}
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={
                      touched.email &&
                      (!!errors.email || hasError(error, 'email'))
                    }
                  />
                  <EmailIcon />
                  <Form.Control.Feedback type="invalid">
                    {showError(error, 'email')}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button dimension="lg" full type="submit">
                  {t('forgot.button')}
                </Button>

                <p className="form-link mt-20 mb-0">
                  {t('signIn.dontHaveAccount')}{' '}
                  <Link to="#" onClick={onSignin}>
                    {t('signIn.title')}
                  </Link>
                </p>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

Forgot.defaultProps = {
  error: undefined,
};
