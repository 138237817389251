import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ILicenseContentDetails } from 'interfaces/public/license';
import i18n from 'translations';
import moment from 'moment';

export const expandColumns: ColumnDescription<
  any,
  ILicenseContentDetails
>[] = [
  {
    dataField: 'enable',
    text: '',
    formatter: (cell: any, row: ILicenseContentDetails) => {
      return (
        <input
          type={'checkbox'}
          style={{ zIndex: 0 }}
          defaultChecked={!!row.enable}
          disabled={true}
        />
      );
    },
  },
  {
    dataField: 'name',
    text: i18n.t('settings.users.columns.name'),
    sort: true,
    style: {
      width: '500px',
    },
  },
  {
    dataField: 'productCode',
    text: i18n.t('licenseContent.detailColumns.productCode'),
    sort: true,
    hidden:true,
  },
  {
    dataField: 'count',
    text: i18n.t('licenseContent.detailColumns.count'),
    sort: true,
  },
  {
    dataField: 'expiryaDate',
    text: i18n.t('licenseContent.detailColumns.expiryDate'),
    style: {
      width: '200px',
    },
    formatter: (cell: any, row: ILicenseContentDetails) => {
      const d = moment(row.expiryaDate);
      if(!d.isValid())  return row.expiryaDate;
      return d.utc().format(i18n.t('formats.date.short'));;
    },
  },
  {
    dataField: '',
    text: 'Permissions',
    sort: true,
  },
  {
    dataField: 'epd',
    text: 'EPD',
    sort: true,
  },
];
