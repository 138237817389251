import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOption } from '../components/Select';
import { IApiMeta } from '../interfaces/api';
import { ITable } from '../interfaces/table';
import { IUser } from '../interfaces/user';
import { RootState } from '../store/store';
import { responsiblePersonsThunk } from '../thunks/responsible-persons';

export interface IReponsiblePersonsState extends ITable<IUser> {}

export const initialState: IReponsiblePersonsState = {
  count: 0,
  data: [],
} as any;

export const responsiblePersonsSlice = createSlice({
  name: 'responsiblePersons',
  initialState,
  reducers: {},
  extraReducers: {
    [responsiblePersonsThunk.fulfilled.type]: (
      state,
      action: PayloadAction<ITable<IUser>, string, IApiMeta>
    ) => {
      state.count = action.payload.count;
      state.data = action.payload.data;
      state.apiStatus = action.meta.requestStatus;
    },
  },
});

export const responsiblePersonsSelector = (state: RootState) =>
  state.responsiblePersons;

export const responsiblePersonsOptionsSelector = (anyLabel: string) => {
  return createSelector(
    responsiblePersonsSelector,
    (state: IReponsiblePersonsState) => {
      const options = state.data.map((person) => {
        return {
          value: person.id,
          label: `${person.firstName} ${person.lastName}`,
        } as IOption;
      });
      options.unshift({ value: '', label: anyLabel } as IOption);
      return options;
    }
  );
};
