import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { signupSlice } from 'slices';
import { useAppDispatch } from 'store/hooks';

import { PublicLayout } from '../../components/Layout';

export const PrivacyPolicy: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(signupSlice.actions.close());
  }, []);
  return (
    <PublicLayout mainClasses="page page-privacy-policy">
      <Container className='content-container'>
        <Row className='public-page'>
          <Col className='public-page-col'>
            <h1 className="mt-102 mb-58 fs-34">{t('footer.menu.privacyPolicy.title')}</h1>
            <div>
              <Trans>
                {t('footer.menu.privacyPolicy.content')}
              </Trans>
            </div>
          </Col>
        </Row>
      </Container>
    </PublicLayout>
  );
};
