import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  LAB_MESSAGES_ROUTE,
  LAB_ORDERS_ROUTE,
  LAB_SUPPORT_CONTACT_ROUTE,
  MILL_CLIENTS_ROUTE,
  MILL_CONTRACT_ROUTE,
  MILL_MESSAGES_ROUTE,
  MILL_ORDERS_ROUTE,
  MILL_SETTINGS_LANDING_PAGE_ROUTE,
} from '../../../constants/routes';
import { useLabRole, useMillAdminRole, useMillRole } from '../../../hooks/role';
import {
  ClientIcon,
  ContractIcon,
  MessageIcon,
  OrderIcon,
  SettingsIcon,
  SupportIcon,
} from '../../../icons';

const MillSidebar: React.FC = () => {
  const isMillAdminRole = useMillAdminRole();

  return (
    <>
      <NavLink
        exact
        to={MILL_ORDERS_ROUTE}
        className="sidebar-link"
        isActive={(match, location) => {
          if (!match) {
            const path = location.pathname;
            if (
              path.startsWith(MILL_ORDERS_ROUTE) &&
              path.includes('/components')
            ) {
              return true;
            }
            return false;
          }
          return true;
        }}
      >
        <OrderIcon />
      </NavLink>
      <NavLink exact to={MILL_CLIENTS_ROUTE} className="sidebar-link">
        <ClientIcon />
      </NavLink>
      <NavLink exact to={MILL_MESSAGES_ROUTE} className="sidebar-link">
        <MessageIcon />
      </NavLink>
      {isMillAdminRole && (
        <>
          <NavLink
            exact
            to={MILL_SETTINGS_LANDING_PAGE_ROUTE}
            className="sidebar-link"
          >
            <SettingsIcon />
          </NavLink>
          <NavLink exact to={MILL_CONTRACT_ROUTE} className="sidebar-link">
            <ContractIcon />
          </NavLink>
        </>
      )}
    </>
  );
};

const LabSidebar: React.FC = () => {
  return (
    <>
      <NavLink
        exact
        to={LAB_ORDERS_ROUTE}
        className="sidebar-link"
        isActive={(match, location) => {
          if (!match) {
            const path = location.pathname;
            if (
              path.startsWith(LAB_ORDERS_ROUTE) &&
              path.includes('/components')
            ) {
              return true;
            }
            return false;
          }
          return true;
        }}
      >
        <OrderIcon />
      </NavLink>
      <NavLink exact to={LAB_MESSAGES_ROUTE} className="sidebar-link">
        <MessageIcon />
      </NavLink>
      <NavLink exact to={LAB_SUPPORT_CONTACT_ROUTE} className="sidebar-link">
        <SupportIcon />
      </NavLink>
    </>
  );
};

export const Sidebar: React.FC = () => {
  const isMill = useMillRole();
  const isLab = useLabRole();

  return (
    <aside className="sidebar d-none d-md-flex">
      {isMill && <MillSidebar />}
      {isLab && <LabSidebar />}
    </aside>
  );
};
