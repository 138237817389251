import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AvatarImage } from 'components/User';

import i18n from 'translations';
import moment from 'moment';

export const columns: ColumnDescription<any, any>[] = [
  {
    dataField: 'avatar',
    text: '',
    sort: false,
    style: {
      width: '72px',
      height: '72px',
    },
    formatter: () => {
      return <AvatarImage />;
    },
  },
  {
    dataField: 'customer_email',
    text: i18n.t('contract.tabs.billing.custEmail'),
  },
  {
    dataField: 'customer_name',
    text: i18n.t('contract.tabs.billing.custComp'),
  },
  {
    dataField: 'period_start',
    text: i18n.t('contract.tabs.billing.fromPeriod'),
    sort: true,
    formatter: (cell: any, row: any) => {
      return moment(+`${row.lines.data[0].period.start}000`).format(
        i18n.t('formats.date.short')
      );
    },
  },
  {
    dataField: 'period_end',
    text: i18n.t('contract.tabs.billing.toPeriod'),
    sort: true,
    formatter: (cell: any, row: any) => {
      return moment(+`${row.lines.data[0].period.end}999`).format(
        i18n.t('formats.date.short')
      );
    },
  },
  {
    dataField: 'total',
    text: i18n.t('contract.tabs.billing.amount'),
    sort: true,
    formatter: (cell: any, row: any) => {
      return `€${row.total / 100}`;
    },
  },
];
