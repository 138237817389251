import React from 'react';
import { Modal } from './Modal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Button } from '../Button';
import { confirmationSlice } from '../../slices';

interface IConfirmationProps {
  onConfirm?: (actionKey?: string) => void;
}

export const Confirmation: React.FC<IConfirmationProps> = ({
  onConfirm,
}: IConfirmationProps) => {
  const dispatch = useAppDispatch();
  const {
    show,
    title,
    message,
    buttonOk,
    buttonCancel,
    redirectUrl,
    actionKey,
  } = useAppSelector((state) => state.confirmation);

  const handleClose = () => {
    dispatch(confirmationSlice.actions.close());
    if (!buttonCancel && redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const handleExited = () => {
    dispatch(confirmationSlice.actions.reset());
  };

  return (
    <Modal
      title={title}
      show={show}
      onClose={handleClose}
      onExited={handleExited}
    >
      <p className="text-center mb-50">{message}</p>
      {buttonCancel ? (
        <div className="d-flex">
          <Button
            dimension="lg"
            full
            onClick={handleClose}
            variant="outline-primary"
            className="mr-6"
          >
            {buttonCancel}
          </Button>
          <Button
            data-cy="confirmation-btn-ok"
            dimension="lg"
            full
            onClick={() => {
              if (typeof onConfirm === 'function') {
                onConfirm(actionKey);
              }
            }}
            className="ml-6"
          >
            {buttonOk}
          </Button>
        </div>
      ) : (
        <Button
          data-cy="confirmation-btn-close"
          dimension="lg"
          full
          onClick={handleClose}
        >
          {buttonOk}
        </Button>
      )}
    </Modal>
  );
};

Confirmation.defaultProps = {
  onConfirm: undefined,
};
