import React from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { IIdParam } from '../../../interfaces/api';
import { componentSlice } from '../../../slices';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  IUploadDownloadForm,
  uploadDownloadThunk,
} from '../../../thunks/component';
import { UploadDownloadForm } from './UploadDownloadForm';

const schema: yup.SchemaOf<IUploadDownloadForm> = yup.object().shape({
  name: yup.string().required(),
  files: yup.array().length(1).required(),
});

export const UploadDownloadContainer: React.FC = () => {
  const { id } = useParams<IIdParam>();
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.component.error);
  const showUploadDownload = useAppSelector(
    (state) => state.component.showUploadDownload
  );

  const handleSubmit = (values: IUploadDownloadForm) => {
    dispatch(uploadDownloadThunk({ ...values, component: id }));
  };

  return (
    <UploadDownloadForm
      show={showUploadDownload}
      onClose={componentSlice.actions.closeUploadDownload}
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={
        {
          name: '',
          files: [],
        } as IUploadDownloadForm
      }
      error={error}
    />
  );
};
