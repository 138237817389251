import React from 'react';

const NoDataFound: React.FC = () => {
  return (
    <div className="alert alert-primary text-center" role="alert">
      No data found
    </div>
  );
};

export default NoDataFound;
