import { toastSlice } from '../slices/toast';
import { AppDispatch } from '../store/store';

export const DEFAULT_ERROR_MESSAGE =
  'Oops. Something went wrong. Please try again.';

export const isErrorString = (error: any) =>
  !!error && !!error.errors && typeof error.errors === 'string';

export const hasError = (error: any, field: string) =>
  !!error && !!error.errors && !!error.errors[field];

export const showError = (error: any, field: string) =>
  !!error && !!error.errors && error.errors[field];

const parseErrorMessages = (response: any, customTitle: string) => {
  const { error, message } = response;

  let title = '';
  let parsedMessage = '';

  if (typeof message === 'string') {
    title = error;
    parsedMessage = message;
  } else if (Array.isArray(message)) {
    const names: string[] = [];
    const messages: string[] = [];
    message.forEach((msg: any) => {
      msg.messages.forEach((err: any) => {
        names.push(err.id);
        messages.push(err.message);
      });
    });

    title = names.join(' ');
    parsedMessage = messages.join(' ');
  }

  return {
    title: customTitle || title,
    message: parsedMessage || DEFAULT_ERROR_MESSAGE,
  };
};

export const handleApiError = (
  response: any,
  dispatch: AppDispatch,
  customTitle = ''
) => {
  if (response.error) {
    const { title, message } = parseErrorMessages(response, customTitle);
    dispatch(toastSlice.actions.show({ title, message }));
  }
};
