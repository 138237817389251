import React from 'react';
import { isWinAppViewerMode } from '../../../slices/auth';
import { Sidebar } from './Sidebar';

interface IMainProps {
  children: React.ReactNode;
  mainClasses?: string;
}

export const UserMain: React.FC<IMainProps> = ({
  children,
  mainClasses,
}: IMainProps) => {
  return (
    <div className="user-main d-block d-md-flex">
      <main className={`main ${mainClasses}`}>
        {!isWinAppViewerMode() && <Sidebar />}
        {children}
      </main>
    </div>
  );
};

UserMain.defaultProps = {
  mainClasses: '',
};
