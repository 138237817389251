import {
  isLabRoleSelector,
  isMillAdminRoleSelector,
  isMillUserRoleSelector,
  isMillRoleSelector,
  isManagerRoleSelector,
} from '../slices/auth';
import { useAppSelector } from '../store/hooks';

export function useManagerRole() {
  return useAppSelector(isManagerRoleSelector());
}
export function useMillRole() {
  return useAppSelector(isMillRoleSelector());
}

export function useMillAdminRole() {
  return useAppSelector(isMillAdminRoleSelector());
}

export function useMillUserRole() {
  return useAppSelector(isMillUserRoleSelector());
}

export function useLabRole() {
  return useAppSelector(isLabRoleSelector());
}
