import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMillRole } from '../../hooks/role';
import { CameraIcon } from '../../icons';

import avatarMillPlaceholder from '../../assets/images/avatar-mill.svg';
import avatarLabPlaceholder from '../../assets/images/avatar-lab.svg';

export interface IImageUploadProps {
  setFieldValue: any;
  maxFiles?: number;
  accept?: string;
  values: any[];
  imageUrl?: string;
}

export const ImageUpload = ({
  setFieldValue,
  accept = 'image/*',
  maxFiles = 1,
  values,
  imageUrl,
}: IImageUploadProps) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const isMillRole = useMillRole();
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop: (files) => {
      const newFiles = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFieldValue('files', newFiles);
    },
  });

  useEffect(() => {
    if (values && values.length) {
      setPreviewUrl(values[0].preview);
    } else if (imageUrl) {
      setPreviewUrl(imageUrl);
    }
  }, [values]);

  const placeholder = isMillRole ? avatarMillPlaceholder : avatarLabPlaceholder;

  return (
    <div>
      <div {...getRootProps({ className: 'image-upload' })}>
        <input {...getInputProps()} />
        <div
          className="image-upload-image"
          data-cy="image-upload-circle-image"
          style={{ backgroundImage: `url(${previewUrl || placeholder})` }}
        >
          <aside className="button">
            <CameraIcon />
          </aside>
        </div>
      </div>
    </div>
  );
};
