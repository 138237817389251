import React from 'react';

import { CreateNewAccountModal } from './CreateNewAccountModal';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { authSlice } from '../../../slices';

export const CreateNewAccountModalContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showCreateNewAccountModal } = useAppSelector((state) => state.auth);

  const onClose = () => {
    dispatch(authSlice.actions.closeCreateNewAccountModal());
  };
  return (
    <CreateNewAccountModal show={showCreateNewAccountModal} onClose={onClose} />
  );
};
