import React from 'react';
import { Link } from 'react-router-dom';
import { TableActions } from '../../../../components/Table/TableActions';
import { IUser } from '../../../../interfaces/user';
import i18n from '../../../../translations';

export interface IClientsActionsProps {
  user: IUser;
  onActivate: (user: IUser) => void;
  onDelete: (user: IUser) => void;
  onBlocked: (user: IUser) => void;
}

export const ClientsActions = ({
  user,
  onActivate,
  onDelete,
  onBlocked,
}: IClientsActionsProps) => {
  return (
    <TableActions>
      {user.confirmed && (
        <Link data-cy="client-blocked" to="#" onClick={() => onBlocked(user)}>
          {user.blockedByMill || user.blockedBySystem
            ? i18n.t('clients.actions.unBlock')
            : i18n.t('clients.actions.blocked')}
        </Link>
      )}
      {!user.confirmed && (
        <Link
          data-cy="client-send-invite"
          to="#"
          onClick={() => onActivate(user)}
        >
          {i18n.t('clients.actions.sendInvite')}
        </Link>
      )}

      <Link data-cy="client-delete" to="#" onClick={() => onDelete(user)}>
        {i18n.t('clients.actions.delete')}
      </Link>
    </TableActions>
  );
};
