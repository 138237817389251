import React, { useState } from 'react';
import { FormikHelpers } from 'formik';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Spinner2 } from 'components';
import { sendLabContactEmailThunk, ILabContact } from 'thunks/support';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { getSessionStorageItem } from 'utils/localStorage';
import { ContactForm } from './ContactForm';

const schema: yup.SchemaOf<ILabContact> = yup.object().shape({
  topic: yup.string().required(),
  message: yup.string().required(),
});

export const ContactFormContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const error = useAppSelector((state) => state.contact.error);
  const contactInfo = useAppSelector((state) => state.contact.data);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const tabIsDirty = getSessionStorageItem(TAB_FORM_IS_DIRTY);
  React.useEffect(() => {
    if (tabIsDirty === 'true') {
      window.location.reload();
    }
  }, [location.search]);

  const handleSubmit = async (
    values: ILabContact,
    form: FormikHelpers<ILabContact>
  ) => {
    setIsProcessing(true);
    await dispatch(sendLabContactEmailThunk(values));
    setIsProcessing(false);
    form.resetForm();
  };

  return (
    <>
      <Spinner2 show={isProcessing} />
      <ContactForm
        initialValues={contactInfo}
        validationSchema={schema}
        onSubmit={handleSubmit}
        error={error}
      />
    </>
  );
};
