import React, { useEffect, useState } from 'react';
import { Button, Col, FormLabel, Row } from 'react-bootstrap';
import { Select, IOption } from 'components/Select';
import Api from 'api';
import { Spinner2 } from 'components/Spinner2';
import NoDataFound from 'components/NoDataFound';
import { Table } from 'components/Table';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { useQuery } from 'hooks/query';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import { TableChangeState } from 'react-bootstrap-table-next';
import { getColumns } from './columns';
import { getPageSize } from '../Settings';

const API_MILLS_URL = '/v2/analytics/mills';

interface IDateObject {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export const OrdersView: React.FC = () => {
  const query = useQuery();
  const [state, setState] = useState({ data: [], count: 0, page: 1 });
  const [selectedObjects, setSelectedObjects] = useState({
    selectedMillId: '',
    selectedProjectId: '',
  });
  const [pageSize] = useState<number>(+getPageSize());

  const [millInfo, setMillInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [dateObject, setDateObject] = useState<IDateObject>({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const { data, count, page } = state;
  const { selectedMillId, selectedProjectId } = selectedObjects;

  const apiCallFunctionForProject = (url: string) => {
    return Api.post(url, { ids: selectedProjectId ? [selectedProjectId] : [] });
  };

  const getPageUrl = (pageNum: number) => {
    return `${API_MILLS_URL}/${selectedMillId}/projects?_start=${
      (pageNum - 1) * pageSize
    }&_limit=${pageSize}`;
  };

  const fetchData = (pageNum: number) => {
    setIsLoadingTableData(true);
    let url = `${getPageUrl(pageNum)}&fromDate=&toDate=`;
    if (dateObject?.startDate && dateObject?.endDate) {
      const start = moment(dateObject.startDate).format('YYYY-MM-DD');
      const end = moment(dateObject.endDate).format('YYYY-MM-DD');
      url = `${getPageUrl(pageNum)}&fromDate=${start}&toDate=${end}`;
    }
    apiCallFunctionForProject(url)
      .then((response) => {
        response.data.sort((a: any, b: any) => {
          return a.id - b.id;
        });
        setState({ data: response.data, count: response.count, page: pageNum });
        setIsLoadingTableData(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingTableData(false);
      });
  };

  useEffect(() => {
    setSelectedObjects({
      selectedMillId: query.get('millId') || '',
      selectedProjectId: query.get('id') || '',
    });
    setIsLoading(true);
    Api.get(`${API_MILLS_URL}/all`)
      .then((response) => {
        response.sort((a: any, b: any) => {
          return a.id - b.id;
        });
        const transformedResponse = response.map(
          (o: { name: any; id: any }) => ({
            label: `${o.id} - ${o.name}`,
            value: o.id,
          })
        );
        setMillInfo(transformedResponse);
        setIsLoading(false);
        if (!selectedMillId) {
          setSelectedObjects({
            ...selectedObjects,
            selectedMillId: transformedResponse[0].value,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!selectedMillId) return;
    fetchData(page);
  }, [selectedMillId, dateObject]);

  const handlendDatesChange = (arg: IDateObject) => {
    if (!arg.startDate && !arg.endDate) return;
    setState({ ...state, page: 1 });
    setDateObject(arg);
  };

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    setFocusedInput(arg);
  };

  const handleStatusChange = (option: IOption) => {
    setSelectedObjects({
      ...selectedObjects,
      selectedMillId: option.value,
    });
  };

  const resetDate = () => {
    setDateObject({ startDate: null, endDate: null });
    fetchData(page);
  };

  const handleTableChange = (
    _type: any,
    changedState: TableChangeState<any>
  ) => {
    fetchData(changedState.page);
  };

  return (
    <ManagerLayout>
      <h1 className="ml-2 mb-4 mt-3">Orders</h1>
      <Spinner2 show={isLoading} />
      {!isLoading && !selectedProjectId && millInfo.length > 0 && (
        <Row className="mt-1">
          <Col md={7}>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-40">
              <div className="select-wrapper mb-24 mb-md-0">
                <FormLabel>Select Mill</FormLabel>
                <div className="mt-2">
                  <Select options={millInfo} onChange={handleStatusChange} />
                </div>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="select-wrapper mb-24 mb-md-0">
              <FormLabel>Select Interval</FormLabel>
              <DateRangePicker
                isOutsideRange={() => false}
                startDate={dateObject.startDate}
                startDateId="start_date_id"
                endDate={dateObject.endDate}
                endDateId="end_date_id"
                onDatesChange={handlendDatesChange}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
              />
              <Button
                disabled={!(dateObject?.startDate || dateObject?.endDate)}
                className="m-2 reset-button"
                onClick={resetDate}
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <hr />
      {!isLoadingTableData && count > 0 && (
        <Table
          data={data}
          columns={getColumns(data)}
          sizePerPage={pageSize}
          totalSize={count}
          page={page}
          onTableChange={handleTableChange}
        />
      )}
      <Spinner2 show={isLoadingTableData} />
      {!isLoadingTableData && count === 0 && selectedMillId && <NoDataFound />}
    </ManagerLayout>
  );
};
