import React from 'react';

export const DropDownReverseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24001 15.7545C5.56002 16.0818 6.07885 16.0818 6.39886 15.7545L12 10.024L17.6011 15.7545C17.9211 16.0818 18.44 16.0818 18.76 15.7545C19.08 15.4271 19.08 14.8962 18.76 14.5688L12.5794 8.24555C12.2594 7.91815 11.7406 7.91815 11.4206 8.24555L5.24001 14.5688C4.92 14.8962 4.92 15.4271 5.24001 15.7545Z"
      fill="#2E323B"
    />
  </svg>
);
