import React from 'react';

import { useTranslation } from 'react-i18next';
import { authSlice, signinSlice } from 'slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loginThunkToLinkToAnExistingAccount } from 'thunks/auth';
import { useQuery } from 'hooks/query';
import { useParams, useLocation } from 'react-router-dom';
import { LoggedInUserInvitation } from './LoggedInUserInvitation';
// import { ISigninPayload, loginThunk } from 'thunks/auth';

export const LoggedInUserInvitationContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const query = useQuery();
  const location = useLocation();

  const { millId: invitedMillId } = useParams() as any;
  const isLabUser = location.pathname.includes('/lab/home');

  const { error, showLoggedInUserInvitationModal } = useAppSelector(
    (state) => state.auth
  );
  const { id: millId, company } = useAppSelector((state) => state.mill.data);
  const millName = company?.name;

  const title = t('invitation.title');
  const message = t('invitation.message', { millName });
  const confirmation = query.get('confirmation') || '';

  const onConnectToThisAccount = () => {
    const payload = {
      confirmationToken: confirmation,
      millId: isLabUser ? invitedMillId : millId?.toString() || '',
    };

    dispatch(authSlice.actions.closeLoggedInUserInvitationModal());
    dispatch(loginThunkToLinkToAnExistingAccount(payload));
  };

  const onCreateNewAccount = () => {
    dispatch(authSlice.actions.closeLoggedInUserInvitationModal());
    dispatch(authSlice.actions.showCreateNewAccountModal());
  };

  return (
    <LoggedInUserInvitation
      show={showLoggedInUserInvitationModal}
      title={title}
      message={message}
      onClose={signinSlice.actions.close}
      error={error}
      onConnectToThisAccount={onConnectToThisAccount}
      onCreateNewAccount={onCreateNewAccount}
    />
  );
};
