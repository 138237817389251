import React from 'react';
import { ExpandColumnRendererProps } from 'react-bootstrap-table-next';

import { Table } from 'components/Table';
import { IhdUserGeoInfo } from 'interfaces/Manager/IhdUserGeoInfo';
// import { HDLineChart } from 'components/LineChart';
import { DropDownIcon, DropDownReverseIcon } from 'icons';
import { detailColumns } from './detailColumns';

export interface IExportRow {
  nonExpandable: any[];
}

export const expandRow = ({ nonExpandable }: IExportRow): any => {
  return {
    parentClassName: 'table-expanded-row',
    renderer: ({ jsonData }: IhdUserGeoInfo) => (
      <>
        <Table
          rowStyle={{ verticalAlign: 'middle' }}
          remote={false}
          data={jsonData}
          columns={detailColumns}
          sizePerPage={jsonData.length}
          totalSize={jsonData.length}
          wrapperClasses="table-components"
        />
        {/* {multipleLogins.length > 1 && (
          <div style={{ width: 'auto', height: 200 }}>
            <HDLineChart data={multipleLogins} />
          </div>
        )} */}
      </>
    ),
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandColumnRenderer: ({
      expanded,
      expandable,
    }: ExpandColumnRendererProps) => {
      if (!expandable) {
        return <div />;
      }
      if (expanded) {
        return <DropDownReverseIcon />;
      }
      return <DropDownIcon />;
    },
    nonExpandable,
  };
};
